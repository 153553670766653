import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {notify} from "reapop";

import {dashboardScenarioDetailPath} from "../../../helpers/routesHelper";

import {assignUserScenarioAttributes, updateUserScenario} from "../../../store/actions/userScenariosActions";
import UserScenarioEdit from "../../../components/shared/user_scenarios/Edit";
import FetchUser from "../../../components/data_loaders/FetchUser";

class Edit extends Component {
  get user() {
    const {currentUser} = this.props;
    const userScenario = this.props.userScenarios.current;
    const user = this.props.users.current;

    if (userScenario.id && user.id) {
      return userScenario.user.id === user.id ? user : currentUser;
    }

    return {};
  }

  onSubmit = (userScenario) => {
    const {id, userId, updateUserScenario} = this.props;
    updateUserScenario(userScenario, this.props.history, dashboardScenarioDetailPath(userId, id));
  };

  render() {
    return (
      <Fragment>
        <FetchUser id={this.props.userId}/>
        <UserScenarioEdit
          id={this.props.id}
          user={this.user}
          onSubmit={this.onSubmit}
          backPath={dashboardScenarioDetailPath(this.props.userId, this.props.id)}
        />
      </Fragment>
    );
  }
}

Edit.propTypes = {
  id: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  users: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  userScenarios: PropTypes.object.isRequired,
  assignUserScenarioAttributes: PropTypes.func.isRequired,
  updateUserScenario: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    currentUser: state.profiles.current,
    users: state.users,
    userScenarios: state.userScenarios
  }
}

const mapActionsToProps = {
  assignUserScenarioAttributes,
  updateUserScenario,
  notify
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Edit));
