import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {PunditContainer} from "react-pundit";

import {isAdmin} from "../helpers/userRolesHelper";
import {isAuthenticated, isFetchCompanyError, isFetchProfileError} from "../helpers/errorsHelpers";

import {setFlash} from "../store/actions/flashActions";
import policies from "../policies";

import NotificationsWrapper from "./shared/NotificationsWrapper";
import UseLocale from "./components/UseLocale";
import UseTimezone from "./components/UseTimezone";
import Router from "./Router";
import FetchCompany from "./data_loaders/FetchCompany";
import FetchProfile from "./data_loaders/FetchProfile";

class App extends Component {
  componentDidMount() {
    this.setFlash();
  }

  setFlash() {
    const {flash, storeFlash, setFlash} = this.props;

    if (!storeFlash.isCollectionLoaded) {
      setFlash(flash);
    }
  }

  render() {
    const {profiles, companies} = this.props;
    const currentUser = profiles.current;

    if (!profiles.current.id && !isFetchProfileError(profiles)) {
      return <FetchProfile />;
    }

    if (
      isAuthenticated(profiles) &&
      !isAdmin(currentUser) &&
      !companies.current.id &&
      !isFetchCompanyError(companies)
    ) {
      return <FetchCompany />;
    }

    return (
      <PunditContainer policies={policies} user={currentUser}>
        <NotificationsWrapper>
          <UseTimezone />
          <UseLocale currentUser={currentUser} />
          <Router />
        </NotificationsWrapper>
      </PunditContainer>
    );
  }
}

App.propTypes = {
  companies: PropTypes.object.isRequired,
  flash: PropTypes.array,
  profiles: PropTypes.object.isRequired,
  storeFlash: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    companies: state.companies,
    profiles: state.profiles,
    storeFlash: state.flash
  };
}

const mapActionsToProps = {
  setFlash
};

export default connect(mapStateToProps, mapActionsToProps)(App);
