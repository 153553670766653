import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {VisibleIf} from "react-pundit";

import {isManager, isStudent} from "../../helpers/userRolesHelper";
import {
  packCategoriesPath,
  coursesPath,
  tagsPath,
  usersPath,
  dashboardUserPath,
  dashboardPath
} from "../../helpers/routesHelper";

import ApplicationLayout from "../layouts/ApplicationLayout";
import ChoiceItem from "../../components/shared/ChoiceItem";
import PackShareNotifications from "./_PackShareNotifications";

class Show extends Component {
  get progressDashboardPath() {
    const {currentUser} = this.props;
    return isManager(currentUser) ? dashboardPath() : dashboardUserPath(currentUser.id);
  }

  render() {
    const {currentUser, packShares} = this.props;

    const isLoading = packShares.isLoading;

    return (
      <ApplicationLayout isLoading={isLoading}>
        <div className="container container_full_height no-scroll">
          <div className="row h-100 align-content-center align-items-center justify-content-center pl-4 pr-4">
            <VisibleIf type="ProgressDashboard" action="Index">
              <ChoiceItem
                path={this.progressDashboardPath}
                title={I18n.t("main_pages.show.progress_tracking")}
                type="progress"
              />
            </VisibleIf>

            <VisibleIf type="Tag" action="Index">
              <ChoiceItem path={tagsPath()} title={I18n.t("mongoid.models.tag.other")} type="tags"/>
            </VisibleIf>

            <VisibleIf type="Course" action="Index">
              <ChoiceItem path={coursesPath()} title={I18n.t("mongoid.models.course.other")} type="courses"/>
            </VisibleIf>

            <VisibleIf type="UserScenario" action="Index">
              <ChoiceItem path={packCategoriesPath()} title={I18n.t("main_pages.show.scenarios")} type="scenarios"/>
            </VisibleIf>

            <VisibleIf type="User" action="Index">
              <ChoiceItem path={usersPath()} title={I18n.t("main_pages.show.accounts")} type="accounts"/>
            </VisibleIf>

            <div className="col-12">
              {/* Adds a row to allow using align-content */}
            </div>
          </div>
        </div>
        {isStudent(currentUser) && <PackShareNotifications/>}
      </ApplicationLayout>
    )
  }
}


Show.propTypes = {
  currentUser: PropTypes.object.isRequired,
  packShares: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    currentUser: state.profiles.current,
    packShares: state.packShares
  }
}

export default connect(mapStateToProps)(Show);
