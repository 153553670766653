import {SUCCESS_STATUS_CODE} from "../../constants";

export const ACTIONS = {
  CHANGE_COMPANIES_LOADING: "CHANGE_COMPANIES_LOADING",
  FETCH_COMPANY: "FETCH_COMPANY",
  FETCH_COMPANY_FULFILLED: "FETCH_COMPANY_FULFILLED",
  FETCH_COMPANY_REJECTED: "FETCH_COMPANY_REJECTED",
  ASSIGN_COMPANY_ATTRIBUTES: "ASSIGN_COMPANY_ATTRIBUTES",
  CLEAR_COMPANY_ATTRIBUTES: "CLEAR_COMPANY_ATTRIBUTES",
  UPDATE_COMPANY_FULFILLED: "UPDATE_COMPANY_FULFILLED",
  UPDATE_COMPANY_REJECTED: "UPDATE_COMPANY_REJECTED"
};

const DEFAULT_SUCCESS_STATUS = {errors: {}, status: SUCCESS_STATUS_CODE};
const DEFAULT_STATUSES = {
  [ACTIONS.FETCH_COMPANY]: {errors: {}, status: null}
};

const INITIAL_FORM_ATTRIBUTES = {
  errors: {}
};

export default function reducer(state = {
  current: {},
  formAttributes: INITIAL_FORM_ATTRIBUTES,
  isLoading: false,
  loadingCount: 0,
  statuses: DEFAULT_STATUSES
}, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_COMPANIES_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);
      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.FETCH_COMPANY: {
      return {...state, current: {}};
    }
    case ACTIONS.FETCH_COMPANY_FULFILLED: {
      return {
        ...state,
        current: action.company,
        statuses: {...state.statuses, [ACTIONS.FETCH_COMPANY]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_COMPANY_REJECTED: {
      return {
        ...state,
        statuses: {...state.statuses, [ACTIONS.FETCH_COMPANY]: {errors: action.errors, status: action.status}}
      };
    }

    case ACTIONS.ASSIGN_COMPANY_ATTRIBUTES: {
      return {...state, formAttributes: {errors: state.formAttributes.errors, ...action.attributes}};
    }
    case ACTIONS.CLEAR_COMPANY_ATTRIBUTES: {
      return {...state, formAttributes: INITIAL_FORM_ATTRIBUTES};
    }

    case ACTIONS.UPDATE_COMPANY_FULFILLED: {
      return {...state, current: action.company};
    }
    case ACTIONS.UPDATE_COMPANY_REJECTED: {
      return {...state, formAttributes: {...state.formAttributes, errors: action.errors}};
    }
  }
  return state;
}
