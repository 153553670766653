export const ACTIONS = {
  OPEN_DROPDOWN_MENU: "OPEN_DROPDOWN_MENU",
  CLOSE_DROPDOWN_MENU: "CLOSE_DROPDOWN_MENU"
};

export default function reducer(state = {
  status: "closed"
}, action) {
  switch (action.type) {
    case ACTIONS.OPEN_DROPDOWN_MENU: {
      return {...state, status: "opened"};
    }
    case ACTIONS.CLOSE_DROPDOWN_MENU: {
      return {...state, status: "closed"};
    }
  }
  return state;
}
