import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {MODAL_NAME as SHARE_PACK_MODAL_NAME} from "./_PackShareModal";

import {closeModal, openModal} from "../../store/actions/modalsActions";
import {createPackShare} from "../../store/actions/packSharesActions";

import BaseModal from "../../components/shared/modals/BaseModal";

export const MODAL_NAME = "PACK_SHARE_CREATE_CONFIRMATION_MODAL";

class PackShareCreateConfirmationModal extends Component {
  onConfirmedChange = (e) => {
    const {options} = this.props.modals;
    this.props.openModal(MODAL_NAME, {...options, confirmed: e.target.checked});
  };

  closeModal = () => {
    this.props.openModal(SHARE_PACK_MODAL_NAME);
  };

  submitModal = () => {
    const {userId, packIds, username} = this.props.modals.options;
    const packShare = {user_id: userId, pack_ids: packIds};
    this.props.createPackShare(packShare, username, {onSuccess: () => {
      this.props.openModal(SHARE_PACK_MODAL_NAME);
    }});
  };

  render() {
    const {username, confirmed} = this.props.modals.options;
    const {isLoading} = this.props.packShares;

    return (
      <BaseModal modalName={MODAL_NAME} isRenderButton={false} contentStyle={{width: "550px"}}>
        <div className="bem-popup__content">
          <div className="pack-share-confirmation__description">
            {I18n.t("packs.popups.pack_share_create_confirmation.description", {name: username})}
          </div>
          <div className="pack-share-confirmation__confirmation">
            <input
              type="checkbox"
              id="invite_confirmation"
              className="form-check-input"
              autoFocus
              onChange={this.onConfirmedChange}
            />
            <label htmlFor="invite_confirmation" className="form-check-label">
              {I18n.t("packs.popups.pack_share_create_confirmation.confirmation_label", {name: username})}
            </label>
          </div>
          <div className="pack-share-confirmation__actions">
            <button className="pack-share-confirmation__button button button_color_grey" onClick={this.closeModal}>
              {I18n.t("cancel")}
            </button>
            <button className="pack-share-confirmation__button button ml-3"
                    onClick={this.submitModal}
                    disabled={!confirmed || isLoading}>
              {I18n.t("invite")}
            </button>
          </div>
        </div>
      </BaseModal>
    );
  }
}

PackShareCreateConfirmationModal.propTypes = {
  packShares: PropTypes.object.isRequired,
  modals: PropTypes.shape({
    options: PropTypes.shape({
      username: PropTypes.string,
      userId: PropTypes.string,
      packIds: PropTypes.array,
      confirmed: PropTypes.bool
    })
  }),
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  createPackShare: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    packShares: state.packShares,
    modals: state.modals
  }
}

const mapActionsToProps = {
  openModal,
  closeModal,
  createPackShare
};

export default connect(mapStateToProps, mapActionsToProps)(PackShareCreateConfirmationModal);
