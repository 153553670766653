export const ACTIONS = {
  CHANGE_SESSIONS_LOADING: "CHANGE_SESSIONS_LOADING",
  CHANGE_SESSIONS_SHOW_CAPTCHA: "CHANGE_SESSIONS_SHOW_CAPTCHA",
  ENABLE_SESSIONS_SUBMIT: "ENABLE_SESSIONS_SUBMIT",
  DISABLE_SESSIONS_RESET_CAPTCHA: "DISABLE_SESSIONS_RESET_CAPTCHA",
  ASSIGN_SESSION_ATTRIBUTES: "ASSIGN_SESSION_ATTRIBUTES",
  CLEAR_SESSION_ATTRIBUTES: "CLEAR_SESSION_ATTRIBUTES",
  CREATE_SESSION_FULFILLED: "CREATE_SESSION_FULFILLED",
  CREATE_SESSION_REJECTED: "CREATE_SESSION_REJECTED",
  DESTROY_SESSION_FULFILLED: "DESTROY_SESSION_FULFILLED"
};

const INITIAL_FORM_ATTRIBUTES = {
  email: "",
  password: "",
  errors: {}
};

export default function reducer(state = {
  formAttributes: INITIAL_FORM_ATTRIBUTES,
  isLoading: false,
  loadingCount: 0,
  isShowCaptcha: false,
  isResetCaptcha: false,
  isSubmitDisabled: false,
  errors: {}
}, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_SESSIONS_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);
      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.CHANGE_SESSIONS_SHOW_CAPTCHA: {
      return {
        ...state,
        isShowCaptcha: action.isShowCaptcha,
        isSubmitDisabled: action.isShowCaptcha,
        isResetCaptcha: action.isShowCaptcha
      };
    }

    case ACTIONS.ENABLE_SESSIONS_SUBMIT: {
      return {...state, isSubmitDisabled: false};
    }

    case ACTIONS.DISABLE_SESSIONS_RESET_CAPTCHA: {
      return {...state, isResetCaptcha: false};
    }

    case ACTIONS.ASSIGN_SESSION_ATTRIBUTES: {
      return {...state, formAttributes: {errors: state.formAttributes.errors, ...action.attributes}};
    }
    case ACTIONS.CLEAR_SESSION_ATTRIBUTES: {
      return {...state, formAttributes: INITIAL_FORM_ATTRIBUTES};
    }

    case ACTIONS.CREATE_SESSION_REJECTED: {
      const attributesWithNewErrors = {...state.formAttributes};
      return {...state, formAttributes: attributesWithNewErrors};
    }
  }
  return state;
}
