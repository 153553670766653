import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import _ from "lodash";

import {openModal} from "../../../store/actions/modalsActions";
import {updateGroup} from "../../../store/actions/groupsActions";

import DefaultModal from "../../../components/shared/modals/DefaultModal";
import SearchBar from "../../../components/shared/_SearchBar";
import {sortUsersByName} from "../../../helpers/sortUsersHelper";
import FetchUsers from "../../../components/data_loaders/FetchUsers";

export const MODAL_NAME = "MANAGE_USERS_MODAL";
const TABS = {
  ADD_LIST: "ADD_LIST",
  REMOVE_LIST: "REMOVE_LIST"
};

class ManageUsersModal extends Component {
  changeTab = tab => () => {
    const {query} = this.props.modals.options;
    this.props.openModal(MODAL_NAME, {query, tab});
  };

  changeManageUsersQuery = query => {
    const {tab} = this.props.modals.options;
    this.props.openModal(MODAL_NAME, {query, tab});
  };

  usersForAdd = query => {
    const {group, users, groupUsers} = this.props;
    const addedUserIds = new Set(groupUsers.map(user => user.id));
    const usersForAdd = users.collection.filter(user => !addedUserIds.has(user.id));

    const queryRegExp = new RegExp(query, "i");
    const filteredUsers = usersForAdd.filter(
      user => !query || queryRegExp.test(user.full_name) || queryRegExp.test(user.email)
    );

    return sortUsersByName(filteredUsers);
  };

  usersForRemove = () => {
    return sortUsersByName(this.props.groupUsers);
  };

  addUser = user => {
    const {group, updateGroup, groupUsers} = this.props;
    const newUserIds = [...groupUsers.map(user => user.id), user.id];
    updateGroup({id: group.id, user_ids: newUserIds});
  };

  removeUser = user => {
    const {group, updateGroup, groupUsers} = this.props;
    const newUserIds = _.reject(groupUsers, groupUser => groupUser.id === user.id).map(
      user => user.id
    );
    updateGroup({id: group.id, user_ids: newUserIds});
  };

  renderActionButton(user, onClick, isAddAction) {
    if (isAddAction) {
      return (
        <button className="user-list-item__action button" onClick={() => onClick(user)}>
          {I18n.t("add")}
        </button>
      );
    }

    return (
      <button
        className="user-list-item__action user-list-item__action--cancel button"
        onClick={() => onClick(user)}
      >
        {I18n.t("remove")}
      </button>
    );
  }

  renderUserList(users, onClick, isAddAction) {
    return _.map(users, user => (
      <div key={user.id} className="user-list-item">
        <div className="user-list-item__avatar avatar">
          <img alt="" src={user.avatar.thumb_url} />
        </div>
        <div className="user-list-item__name">{user.full_name}</div>
        {this.renderActionButton(user, onClick, isAddAction)}
      </div>
    ));
  }

  renderAddList() {
    const query = this.props.modals.options.query || "";
    const users = this.usersForAdd(query);

    return (
      <Fragment>
        <div className="mb-3">
          <SearchBar changeUsersQuery={this.changeManageUsersQuery} query={query} />
        </div>
        {this.renderUserList(users, this.addUser, true)}
      </Fragment>
    );
  }

  renderRemoveList() {
    const users = this.usersForRemove();

    return this.renderUserList(users, this.removeUser, false);
  }

  render() {
    const tab = this.props.modals.options.tab || TABS.ADD_LIST;

    return (
      <DefaultModal
        isRenderButton={false}
        modalName={MODAL_NAME}
        modalTitle={I18n.t("dashboard.groups.manage_students")}
      >
        <FetchUsers clearQuery />
        <div className="bem-popup__content bem-popup__content_margin_scroll">
          <div className="tabs">
            <a
              className={classnames("tabs__tab", {"tabs__tab--active": tab === TABS.ADD_LIST})}
              href="#"
              onClick={this.changeTab(TABS.ADD_LIST)}
            >
              {I18n.t("add")}
            </a>
            <a
              className={classnames("tabs__tab", {"tabs__tab--active": tab === TABS.REMOVE_LIST})}
              href="#"
              onClick={this.changeTab(TABS.REMOVE_LIST)}
            >
              {I18n.t("remove")}
            </a>
          </div>
          {tab === TABS.ADD_LIST && this.renderAddList()}
          {tab === TABS.REMOVE_LIST && this.renderRemoveList()}
        </div>
      </DefaultModal>
    );
  }
}

ManageUsersModal.propTypes = {
  group: PropTypes.object.isRequired,
  modals: PropTypes.shape({
    options: PropTypes.shape({
      query: PropTypes.string,
      tab: PropTypes.string
    })
  }),
  openModal: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    modals: state.modals,
    users: state.users
  };
}

const mapActionsToProps = {
  openModal,
  updateGroup
};

export default connect(mapStateToProps, mapActionsToProps)(ManageUsersModal);
