import React from "react";

const ScenarioOwner = ({avatarUrl, name}) => (
  <div className="scenario-owner scenario-owner--scenario-review">
    <div className="avatar scenario-owner__avatar">
      <img alt="Avatar" src={avatarUrl} />
    </div>
    <div className="scenario-owner__title">
      <div className="scenario-owner__name">{name}</div>
    </div>
  </div>
);

export default ScenarioOwner;
