import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {VisibleIf} from "react-pundit";
import {Link} from "react-router-dom";
import classnames from "classnames";

import {REMOVING_STATES} from "../../constants";

import {editUserScenarioPath, userScenarioPath} from "../../helpers/routesHelper";
import {scenarioTags} from "../../helpers/scenariosHelper";
import {listItemDateLabel} from "../../helpers/listItemsHelper";

import {
  copyUserScenario,
  deselectUserScenario,
  destroyUserScenario,
  duplicateUserScenario,
  selectUserScenario,
  setUserScenarioRemovingState
} from "../../store/actions/userScenariosActions";
import ContextMenu from "../../components/shared/context_menu/ContextMenu";
import ContextMenuItem from "../../components/shared/context_menu/ContextMenuItem";
import DeleteListItem from "../../components/shared/DeleteListItem";
import UserScenarioPolicy from "../../policies/UserScenarioPolicy";
import CopyUserScenarioModal from "./_CopyUserScenarioModal";

class UserScenario extends Component {
  get isSelected() {
    const {userScenarios, userScenario} = this.props;

    return userScenarios.selectedIds.includes(userScenario.id);
  }

  showRemoveDialog = event => {
    if (event) event.preventDefault();
    this.props.setUserScenarioRemovingState(this.props.userScenario.id, REMOVING_STATES.REMOVING);
  };

  duplicateUserScenario = event => {
    event.preventDefault();
    const {userScenario, duplicateUserScenario} = this.props;
    duplicateUserScenario(userScenario.id);
  };

  toggleSelect = event => {
    event.preventDefault();
    const {userScenario, selectUserScenario, deselectUserScenario} = this.props;

    if (this.isSelected) {
      deselectUserScenario(userScenario.id);
    } else {
      selectUserScenario(userScenario.id);
    }
  };

  onDestroy = () => {
    const {userScenario, destroyUserScenario} = this.props;
    destroyUserScenario(userScenario.pack.id, userScenario.id);
  };

  renderContextMenu() {
    const {userScenario, currentUser} = this.props;
    const canDestroy = new UserScenarioPolicy().Destroy(userScenario, currentUser);
    const canUpdate = new UserScenarioPolicy().Update(userScenario, currentUser);
    const canDuplicate = new UserScenarioPolicy().Duplicate(userScenario, currentUser);
    const canCopy = new UserScenarioPolicy().Copy(userScenario, currentUser);

    if (!canUpdate && !canUpdate && !canDuplicate && !canCopy) {
      return null;
    }

    return (
      <ContextMenu className="scenario-list-item__context-menu">
        {canUpdate && (
          <ContextMenuItem buttonLinkTo={editUserScenarioPath(userScenario.id)}>
            {I18n.t("edit")}
          </ContextMenuItem>
        )}
        {canCopy && (
          <CopyUserScenarioModal
            copyUserScenario={this.props.copyUserScenario}
            userScenario={userScenario}
          />
        )}
        {canDuplicate && (
          <ContextMenuItem onClick={this.duplicateUserScenario}>
            {I18n.t("duplicate")}
          </ContextMenuItem>
        )}
        {canDestroy && (
          <ContextMenuItem color="red" onClick={this.showRemoveDialog}>
            {I18n.t("delete")}
          </ContextMenuItem>
        )}
      </ContextMenu>
    );
  }

  render() {
    const {userScenario, setUserScenarioRemovingState} = this.props;

    return (
      <Link
        className={classnames("scenario-list-item", {
          "scenario-list-item--selected": this.isSelected
        })}
        to={userScenarioPath(userScenario.id)}
      >
        <div className="scenario-list-item__icon scenario-list-item__icon--scenario" />
        <div className="scenario-list-item__content">
          <div className="scenario-list-item__title" title={userScenario.title}>
            {userScenario.title}
          </div>
          <div className="scenario-list-item__secondary-content">
            <div className="scenario-list-item__tags-content">
              <div className="scenario-list-item__tags">{scenarioTags(userScenario)}</div>
            </div>
            <div className="scenario-list-item__date">
              {listItemDateLabel(userScenario.updated_at)}
            </div>
          </div>
        </div>
        <div className="scenario-list-item__actions">
          {this.renderContextMenu()}
          <VisibleIf action="Update" model={userScenario} type="UserScenario">
            <div
              className="scenario-list-item__icon scenario-list-item__icon--check-box"
              onClick={this.toggleSelect}
            />
          </VisibleIf>
        </div>
        <DeleteListItem
          objectWithState={userScenario}
          onDestroy={this.onDestroy}
          setRemovingState={setUserScenarioRemovingState}
        />
      </Link>
    );
  }
}

UserScenario.propTypes = {
  copyUserScenario: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  deselectUserScenario: PropTypes.func.isRequired,
  destroyUserScenario: PropTypes.func.isRequired,
  duplicateUserScenario: PropTypes.func.isRequired,
  selectUserScenario: PropTypes.func.isRequired,
  setUserScenarioRemovingState: PropTypes.func.isRequired,
  userScenario: PropTypes.object.isRequired,
  userScenarios: PropTypes.object.isRequired
};

const mapActionsToProps = {
  copyUserScenario,
  deselectUserScenario,
  destroyUserScenario,
  duplicateUserScenario,
  selectUserScenario,
  setUserScenarioRemovingState
};

function mapStateToProps(state) {
  return {
    currentUser: state.profiles.current,
    userScenarios: state.userScenarios
  };
}

export default connect(mapStateToProps, mapActionsToProps)(UserScenario);
