import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {SortableContainer} from "react-sortable-hoc";
import arrayMove from "array-move";
import {connect} from "react-redux";

import SortableTag from "./SortableTag";
import AddTagModal from "./_AddTagModal";
import EditTagModal from "./_EditTagModal";

import {closeModal, openModal} from "../../store/actions/modalsActions";
import {assignTagAttributes, createTag, reorderTags} from "../../store/actions/tagsActions";
import {notify} from "reapop";

const MODAL_NAME = "EDIT_TAG_MODAL";

const SortableTagListContainer = SortableContainer(({tags, openEditModal, formAttributes, assignTagAttributes}) => {
  return (
    <tbody>
      {tags.sort((a,b) => a.order - b.order).map((tag, index) => (
        <SortableTag
          key={tag.id}
          index={index}
          tagId={tag.id}
          formAttributes={formAttributes}
          assignTagAttributes={assignTagAttributes}
          openEditModal={openEditModal}
        />)
      )}
    </tbody>
  );
});

class SortableTagList extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isEditModalOpen: false
    };
    this.onLinesReorderingEnd = this.onLinesReorderingEnd.bind(this);
  }

  openEditModal = (tag) => {
    event && event.preventDefault();
    this.props.openModal(MODAL_NAME, tag);
  };

  componentDidMount() {
    if(this.state.isEditModalOpen){
      this.props.openModal(MODAL_NAME);
    }
  };

  onLinesReorderingEnd = async ({oldIndex, newIndex}) => {
    if(oldIndex != newIndex){
      await this.props.reorderTags(oldIndex, newIndex, history);
    }
  };

  openModal = (event) => {
  };

  render() {
    const attributes = this.props.formAttributes;
    const {assignTagAttributes} = this.props;

    return (
      <Fragment>

        <table className="course-edit__scenarios-table" ref={this.tableRef}>

          <thead>
          <tr>
            <th/>
            <th>{I18n.t("mongoid.models.tag.one")}</th>
            <th colSpan={2}/>
          </tr>
          </thead>

          <SortableTagListContainer
            onSortEnd={this.onLinesReorderingEnd}
            helperClass="course-scenario--dragging"
            helperContainer={this.tableRef.current}
            tags={this.props.tags.collection}
            formAttributes={attributes}
            assignTagAttributes={assignTagAttributes}
            openEditModal={this.openEditModal}
            useDragHandle
          />

        </table>

        <div className="text-center mt-4">
          <AddTagModal formAttributes={attributes}/>
          <EditTagModal
            formAttributes={attributes}
            assignTagAttributes={this.props.assignTagAttributes}
          />
        </div>

      </Fragment>
    );
  }
}

SortableTagList.propTypes = {
  tags: PropTypes.object.isRequired,
  formAttributes: PropTypes.object.isRequired,
  assignTagAttributes: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {};
};

const mapActionsToProps = {
  assignTagAttributes,
  openModal,
  closeModal,
  createTag,
  reorderTags
};

export default connect(mapStateToProps, mapActionsToProps)(SortableTagList);
