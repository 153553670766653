import {SUCCESS_STATUS_CODE} from "../../constants";

export const ACTIONS = {
  CHANGE_METRICS_LOADING: "CHANGE_METRICS_LOADING",
  FETCH_METRICS: "FETCH_METRICS",
  FETCH_METRICS_FULFILLED: "FETCH_METRICS_FULFILLED",
  FETCH_METRICS_REJECTED: "FETCH_METRICS_REJECTED",
  FETCH_MORE_METRICS: "FETCH_MORE_METRICS",
  FETCH_MORE_METRICS_FULFILLED: "FETCH_MORE_METRICS_FULFILLED",
  FETCH_MORE_METRICS_REJECTED: "FETCH_MORE_METRICS_REJECTED",
  FETCH_METRIC: "FETCH_METRIC",
  FETCH_METRIC_FULFILLED: "FETCH_METRIC_FULFILLED",
  FETCH_METRIC_REJECTED: "FETCH_METRIC_REJECTED",
  UPDATE_METRIC_FULFILLED: "UPDATE_METRIC_FULFILLED",
  DESTROY_METRIC_FULFILLED: "DESTROY_METRIC_FULFILLED",
  DESTROY_METRIC_REJECTED: "DESTROY_METRIC_FULFILLED"
};

const DEFAULT_SUCCESS_STATUS = {errors: {}, status: SUCCESS_STATUS_CODE};
const DEFAULT_STATUSES = {
  [ACTIONS.FETCH_METRICS]: {errors: {}, status: null},
  [ACTIONS.FETCH_MORE_METRICS]: {errors: {}, status: null},
  [ACTIONS.FETCH_METRIC]: {errors: {}, status: null}
};

const INITIAL_METRIC_ATTRIBUTES = {
  user_scenario: {},
  completed_scenario: {}
};

export default function reducer(state = {
  collection: [],
  current: INITIAL_METRIC_ATTRIBUTES,
  userId: "",
  userScenarioId: "",
  isCollectionLoaded: false,
  isLoading: false,
  loadingCount: 0,
  pagination: {},
  statuses: DEFAULT_STATUSES
}, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_METRICS_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);
      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.FETCH_METRICS: {
      return {...state, userId: "", userScenarioId: "", collection: [], pagination: {}, isCollectionLoaded: false};
    }
    case ACTIONS.FETCH_METRICS_FULFILLED: {
      return {
        ...state,
        userId: action.userId,
        userScenarioId: action.userScenarioId,
        collection: action.payload.metrics,
        pagination: action.payload.pagination,
        isCollectionLoaded: true,
        statuses: {...state.statuses, [ACTIONS.FETCH_METRICS]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_METRICS_REJECTED: {
      return {
        ...state,
        statuses: {...state.statuses, [ACTIONS.FETCH_METRICS]: {errors: action.errors, status: action.status}}
      };
    }

    case ACTIONS.FETCH_MORE_METRICS_FULFILLED: {
      return {
        ...state,
        collection: [...state.collection, ...action.payload.metrics],
        pagination: action.payload.pagination,
        statuses: {...state.statuses, [ACTIONS.FETCH_MORE_METRICS]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_MORE_METRICS_REJECTED: {
      return {
        ...state,
        statuses: {...state.statuses, [ACTIONS.FETCH_MORE_METRICS]: {errors: action.errors, status: action.status}}
      };
    }

    case ACTIONS.FETCH_METRIC: {
      return {...state, current: {}};
    }
    case ACTIONS.FETCH_METRIC_FULFILLED: {
      return {
        ...state,
        current: action.metric,
        statuses: {...state.statuses, [ACTIONS.FETCH_METRIC]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_METRIC_REJECTED: {
      return {
        ...state,
        statuses: {...state.statuses, [ACTIONS.FETCH_METRIC]: {errors: action.errors, status: action.status}}
      };
    }

    case ACTIONS.UPDATE_METRIC_FULFILLED: {
      return {...state, current: action.metric};
    }

    case ACTIONS.DESTROY_METRIC_FULFILLED: {
      let newState = {...state};

      if (state.current.id === action.metricId) {
        newState = {...newState, current: INITIAL_METRIC_ATTRIBUTES};
      }

      return {
        ...newState,
        collection: _.reject(state.collection, {id: action.metricId}),
        pagination: {...state.pagination, total_count: state.pagination.total_count - 1}
      };
    }
  }
  return state;
}
