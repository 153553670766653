import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchUserScenarioHeaderError} from "../../helpers/errorsHelpers";

import {fetchUserScenarioHeader} from "../../store/actions/userScenarioHeadersActions";

class FetchUserScenarioHeader extends Component {
  componentDidMount() {
    this.fetchUserScenarioHeader(true);
  }

  componentDidUpdate() {
    this.fetchUserScenarioHeader(false);
  }

  fetchUserScenarioHeader(isComponentMount) {
    const {id, userScenarioHeaders} = this.props;
    const isReasonToFetch = isComponentMount || userScenarioHeaders.current.id !== id;

    if (isReasonToFetch && !userScenarioHeaders.isLoading && !isFetchUserScenarioHeaderError(userScenarioHeaders)) {
      this.props.fetchUserScenarioHeader(id);
    }
  }

  render() {
    return null;
  }
}

FetchUserScenarioHeader.propTypes = {
  id: PropTypes.string.isRequired,
  userScenarioHeaders: PropTypes.object.isRequired,
  fetchUserScenarioHeader: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    userScenarioHeaders: state.userScenarioHeaders
  }
}

const mapActionsToProps = {
  fetchUserScenarioHeader
};

export default connect(mapStateToProps, mapActionsToProps)(FetchUserScenarioHeader);
