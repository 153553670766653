import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchMetricError} from "../../helpers/errorsHelpers";

import {fetchMetric} from "../../store/actions/metricsActions";

class FetchMetric extends Component {
  componentDidMount() {
    this.fetchMetric(true);
  }

  componentDidUpdate() {
    this.fetchMetric(false);
  }

  fetchMetric(isComponentMount) {
    const {id, metrics} = this.props;
    const isReasonToFetch = isComponentMount || metrics.current.id !== id;

    if (isReasonToFetch && !metrics.isLoading && !isFetchMetricError(metrics)) {
      this.props.fetchMetric(id);
    }
  }

  render() {
    return null;
  }
}

FetchMetric.propTypes = {
  id: PropTypes.string.isRequired,
  metrics: PropTypes.object.isRequired,
  fetchMetric: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    metrics: state.metrics
  }
}

const mapActionsToProps = {
  fetchMetric
};

export default connect(mapStateToProps, mapActionsToProps)(FetchMetric);
