import React from "react";

import WrappedText from "./WrappedText";

const FreeTalkColumns = ({line}) => (
  <td>
    <WrappedText text={line.filtered_text} type="warning" words={line.words} />
  </td>
);

export default FreeTalkColumns;
