import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import _ from "lodash";

import {defaultPack} from "../../../../helpers/packsHelper";
import {isAdmin} from "../../../../helpers/userRolesHelper";
import {
  isFetchCharactersError,
  isFetchDialogflowAgentsError,
  isFetchEnvironmentsError
} from "../../../../helpers/errorsHelpers";

import {assignUserScenarioAttributes} from "../../../../store/actions/userScenariosActions";
import {notifyError} from "../../../../store/actions/notificationsActions";

import FetchCharacters from "../../../data_loaders/FetchCharacters";
import FetchDialogflowAgents from "../../../data_loaders/FetchDialogflowAgents";
import FetchEnvironments from "../../../data_loaders/FetchEnvironment";
import FetchUserScenario from "../../../data_loaders/FetchUserScenario";
import UserScenarioForm from "../Form";

class Edit extends Component {
  componentDidMount() {
    this.assignUserScenarioAttributes(true);
  }

  componentDidUpdate() {
    this.assignUserScenarioAttributes(false);
  }

  assignUserScenarioAttributes = isComponentMount => {
    const {
      id,
      user,
      userScenarios: {current: userScenario, formAttributes}
    } = this.props;

    if (!user.id || userScenario.id !== id || (!isComponentMount && formAttributes.id === id)) {
      return;
    }

    let pack = _.find(user.packs, {id: userScenario.pack.id});
    if (!pack) {
      pack = defaultPack(user.packs);
    }
    this.props.assignUserScenarioAttributes({
      ...userScenario,
      environment_id: userScenario.environment.id,
      errors: {},
      pack_id: pack.id
    });
  };

  render() {
    const {id, currentUser, characters, environments, dialogflowAgents, userScenarios} = this.props;

    const isLoaded =
      userScenarios.formAttributes.id === id &&
      characters.isCollectionLoaded &&
      environments.isCollectionLoaded &&
      (!isAdmin(currentUser) || dialogflowAgents.isCollectionLoaded);

    const isLoadingError =
      isFetchCharactersError(characters) ||
      isFetchEnvironmentsError(environments) ||
      isFetchDialogflowAgentsError(dialogflowAgents);

    const isLoading =
      (!isLoaded && !isLoadingError) ||
      characters.isLoading ||
      environments.isLoading ||
      dialogflowAgents.isLoading ||
      userScenarios.isLoading;

    return (
      <Fragment>
        <FetchCharacters />
        <FetchEnvironments />
        {isAdmin(currentUser) && <FetchDialogflowAgents />}
        <FetchUserScenario id={id} />
        <UserScenarioForm
          assignUserScenarioAttributes={this.props.assignUserScenarioAttributes}
          backPath={this.props.backPath}
          characters={characters}
          currentUser={currentUser}
          dialogflowAgents={dialogflowAgents}
          environments={environments}
          formAttributes={userScenarios.formAttributes}
          isLoaded={isLoaded}
          isLoading={isLoading}
          notifyError={this.props.notifyError}
          onSubmit={this.props.onSubmit}
          user={this.props.user}
        />
      </Fragment>
    );
  }
}

Edit.propTypes = {
  assignUserScenarioAttributes: PropTypes.func.isRequired,
  backPath: PropTypes.string.isRequired,
  characters: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  dialogflowAgents: PropTypes.object.isRequired,
  environments: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  notifyError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  userScenarios: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    characters: state.characters,
    currentUser: state.profiles.current,
    dialogflowAgents: state.dialogflowAgents,
    environments: state.environments,
    userScenarios: state.userScenarios
  };
}

const mapActionsToProps = {
  assignUserScenarioAttributes,
  notifyError
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Edit));
