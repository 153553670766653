import React, {Fragment, useEffect} from "react";
import {connect} from "react-redux";

import {closeModals} from "store/actions/modalsActions";

import DashboardLayout from "views/layouts/DashboardLayout";
import LevelSummary from "./LevelSummary";
import Metrics from "./Metrics";
import SortableCourseList from "./SortableCourseList";
import {isStudent} from "../../../../helpers/userRolesHelper";

const Show = ({dashboardUsers, userId, groupId = "", company, metrics, closeModals}) => {
  useEffect(() => closeModals, [closeModals]);

  const user = dashboardUsers.current;

  return (
    <DashboardLayout
      groupId={groupId}
      headerTitle={I18n.t("dashboard.titles.show")}
      userId={userId}
    >
      {isStudent(user) && (
        <div className="alert alert-danger porgress-tracking-alert text-center" role="alert">
          {I18n.t("errors.messages.progress_tracking")}
        </div>
      )}
      {user.id && (
        <Fragment>
          <div className="pd-top">
            <div className="pd-name__avatar avatar">
              <img alt="" src={user.avatar.profile_url} />
            </div>
            <div className="pd-name">
              <div className="pd-name__user">{user.full_name}</div>
              <div className="pd-name__type">{company.title}</div>
            </div>
          </div>
          <LevelSummary user={user} />
          <SortableCourseList company={company} groupId={groupId} user={user} />
          <Metrics groupId={groupId} metrics={metrics} user={user} />
        </Fragment>
      )}
    </DashboardLayout>
  );
};

function mapStateToProps(state) {
  return {
    company: state.companies.current,
    dashboardUsers: state.dashboardUsers,
    metrics: state.metrics
  };
}

const mapActionsToProps = {
  closeModals
};

export default connect(mapStateToProps, mapActionsToProps)(Show);
