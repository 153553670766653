import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {VisibleIf} from "react-pundit";

import {newUserPath, rootPath} from "../../helpers/routesHelper";
import {isAdmin} from "../../helpers/userRolesHelper";
import {isFetchUsersError} from "../../helpers/errorsHelpers";

import {changeUsersQuery} from "../../store/actions/usersActions";

import FetchUsers from "../../components/data_loaders/FetchUsers";
import ApplicationLayout from "../layouts/ApplicationLayout";
import User from "./_User";
import Pagination from "../../components/shared/Pagination";
import SearchBar from "../../components/shared/_SearchBar";

class Index extends Component {
  userCounter() {
    const company = this.props.companies.current;

    return `${company.users_count}/${company.max_users_count}`;
  }

  render() {
    const {currentUser, users, companies, page, changeUsersQuery} = this.props;
    const company = companies.current;

    const isCompanyLoaded = company.id;
    const areUsersLoaded = users.isCollectionLoaded;
    const isLoaded = isCompanyLoaded && areUsersLoaded;
    const isLoadingError = isFetchUsersError(users);
    const isLoading = (!isLoaded && !isLoadingError) || users.isLoading || companies.isLoading;

    return (
      <ApplicationLayout headerTitle={I18n.t("header_titles.users.index")} isLoading={isLoading}>
        <FetchUsers page={page} />
        <div className="container">
          <div className="row py-4">
            <div className="col-12">
              <div className="users-top-section lg-search">
                <SearchBar changeUsersQuery={changeUsersQuery} query={users.inputQuery} />
                {isCompanyLoaded && !isAdmin(currentUser) && (
                  <div className="user__add">
                    <div className="user__counter">
                      <span>Accounts:</span>
                      {this.userCounter()}
                    </div>
                    <VisibleIf action="CreateUser" model={company} type="Company">
                      <Link className="button button_theme_plus" to={newUserPath()} />
                    </VisibleIf>
                  </div>
                )}
              </div>
              {isLoaded && users.collection.map(user => <User key={user.id} user={user} />)}
              {areUsersLoaded && (
                <div className="page-index-bottom">
                  <Pagination pagination={users.pagination} />
                  <Link className="button button_color_grey ml-auto" to={rootPath()}>
                    {I18n.t("btn_back")}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </ApplicationLayout>
    );
  }
}

Index.defaultProps = {
  page: 1
};

Index.propTypes = {
  changeUsersQuery: PropTypes.func.isRequired,
  companies: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  page: PropTypes.number,
  users: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    companies: state.companies,
    currentUser: state.profiles.current,
    users: state.users
  };
}

const mapActionsToProps = {
  changeUsersQuery
};

export default connect(mapStateToProps, mapActionsToProps)(Index);
