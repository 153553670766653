import React, {memo} from "react";

const ParticipantFocuses = ({line, participants}) => {
  return line.participant_focuses.map((percentage, index) => {
    const participant = participants[index];
    const isLineParticipant = participant.id === line.participant_id;
    const content = isLineParticipant ? "-" : `${percentage}%`;

    return (
      <td key={participant.id}>
        <div className="scenario-edit__form-value">{content}</div>
      </td>
    );
  });
};

export default memo(ParticipantFocuses);
