import _ from "lodash";

import {SUCCESS_STATUS_CODE} from "../../constants";

export const ACTIONS = {
  CHANGE_COURSE_CATEGORIES_LOADING: "CHANGE_COURSE_CATEGORIES_LOADING",
  FETCH_COURSE_CATEGORIES: "FETCH_COURSE_CATEGORIES",
  FETCH_COURSE_CATEGORIES_FULFILLED: "FETCH_COURSE_CATEGORIES_FULFILLED",
  FETCH_COURSE_CATEGORIES_REJECTED: "FETCH_COURSE_CATEGORIES_REJECTED",
  FETCH_COURSE_CATEGORY: "FETCH_COURSE_CATEGORY",
  FETCH_COURSE_CATEGORY_FULFILLED: "FETCH_COURSE_CATEGORY_FULFILLED",
  FETCH_COURSE_CATEGORY_REJECTED: "FETCH_COURSE_CATEGORY_REJECTED",
};

const DEFAULT_SUCCESS_STATUS = {errors: {}, status: SUCCESS_STATUS_CODE};
const DEFAULT_STATUSES = {
  [ACTIONS.FETCH_COURSE_CATEGORIES]: {errors: {}, status: null},
  [ACTIONS.FETCH_COURSE_CATEGORY]: {errors: {}, status: null}
};


export default function reducer(state = {
  collection: [],
  current: {},
  isCollectionLoaded: false,
  isLoading: false,
  loadingCount: 0,
  statuses: DEFAULT_STATUSES
}, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_COURSE_CATEGORIES_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);
      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.FETCH_COURSE_CATEGORIES: {
      return {...state, collection: [], isCollectionLoaded: false};
    }
    case ACTIONS.FETCH_COURSE_CATEGORIES_FULFILLED: {
      return {
        ...state,
        collection: action.payload.course_categories,
        isCollectionLoaded: true,
        statuses: {...state.statuses, [ACTIONS.FETCH_COURSE_CATEGORIES]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_COURSE_CATEGORIES_REJECTED: {
      return {
        ...state,
        statuses: {...state.statuses, [ACTIONS.FETCH_COURSE_CATEGORIES]: {errors: action.errors, status: action.status}}
      };
    }

    case ACTIONS.FETCH_COURSE_CATEGORY: {
      return {...state, current: {}};
    }
    case ACTIONS.FETCH_COURSE_CATEGORY_FULFILLED: {
      const clonedCollection = [...state.collection];
      const courseCategoryIndex = _.findIndex(clonedCollection, {id: action.courseCategory.id});
      if (courseCategoryIndex >= 0) {
        clonedCollection[courseCategoryIndex] = action.courseCategory;
        return {
          ...state,
          current: action.courseCategory,
          collection: clonedCollection,
          statuses: {...state.statuses, [ACTIONS.FETCH_COURSE_CATEGORY]: DEFAULT_SUCCESS_STATUS}
        };
      }
      return {
        ...state,
        current: action.courseCategory,
        statuses: {...state.statuses, [ACTIONS.FETCH_COURSE_CATEGORY]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_COURSE_CATEGORY_REJECTED: {
      return {
        ...state,
        errors: action.errors,
        statuses: {...state.statuses, [ACTIONS.FETCH_COURSE_CATEGORY]: {errors: action.errors, status: action.status}}
      };
    }
  }
  return state;
}
