import React, {Component} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import _ from "lodash";

import {OVERVIEW_WEEKS_COUNT} from "../../../../../constants";
import {courseProgress} from "../helpers";

import Chart from "./Chart";
import Progress from "./Progress";
import UserCell from "../UserCell";

class UserRow extends Component {
  get courseProgress() {
    return courseProgress(this.props.userActivity);
  }

  get tier() {
    return this.props.userActivity.activity_durations.tier;
  }

  get unlockedScenariosPercentage() {
    const {courses} = this.props.userActivity;
    const unlockedCount = _.sum(courses.map(x => x.unlocked_scenario_ids.length));
    const scenariosCount = _.sum(courses.map(x => x.user_scenarios_count));

    return scenariosCount > 0 ? (unlockedCount * 100) / scenariosCount : 0;
  }

  get chartWeeks() {
    const {group} = this.props;
    const {weeks} = this.props.userActivity.activity_durations;
    const isCountLastWeek = moment() > moment(group.last_week_starts_at).add(1, "week");

    return isCountLastWeek ? weeks : weeks.slice(0, -1);
  }

  durationOrDash = duration => {
    return duration >= 0 ? this.formatDuration(duration) : "-";
  };

  formatDays(days) {
    return parseFloat(days.toFixed(1));
  }

  formatDuration(duration) {
    const secondsInMinute = 60;

    return Math.floor(duration / secondsInMinute);
  }

  scoreMuted(duration) {
    return duration === null || this.lowScore(duration, this.formatDuration);
  }

  lowScore(score, formatter) {
    return formatter(score) === 0;
  }

  renderSummaryScore(scoreValue, formatter) {
    return (
      <td
        className={classnames(
          "activity-stats__cell",
          "activity-stats__score",
          "activity-stats__score--summary",
          {"activity-stats__score--bad": this.lowScore(scoreValue, formatter)}
        )}
      >
        {formatter(scoreValue)}
      </td>
    );
  }

  render() {
    const {group, userActivity} = this.props;
    const activityRanges = userActivity.activity_durations;
    const {weeks} = activityRanges;

    return (
      <tr className="activity-stats__row">
        <td className="activity-stats__cell">
          <UserCell groupId={group.id} user={userActivity} />
        </td>

        <td className="activity-stats__cell activity-stats__cell--tier">
          <div data-tier={this.tier}>{this.tier}</div>
        </td>

        <td className="activity-stats__cell activity-stats__cell--progress">
          {this.courseProgress >= 0 && (
            <Progress
              percentage={this.courseProgress}
              secondaryPercentage={this.unlockedScenariosPercentage}
            />
          )}
        </td>

        {_.range(OVERVIEW_WEEKS_COUNT).map(i => (
          <td
            key={i}
            className={classnames("activity-stats__cell activity-stats__score", {
              "activity-stats__score--muted": this.scoreMuted(weeks[i])
            })}
          >
            {this.durationOrDash(weeks[i])}
          </td>
        ))}

        <td className="activity-stats__cell">
          <Chart weeks={this.chartWeeks} />
        </td>

        {this.renderSummaryScore(activityRanges.weekly_average_duration, this.formatDuration)}

        {this.renderSummaryScore(activityRanges.weekly_average_days, this.formatDays)}
      </tr>
    );
  }
}

UserRow.propTypes = {
  group: PropTypes.object.isRequired,
  userActivity: PropTypes.object.isRequired
};

export default UserRow;
