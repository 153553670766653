import React from "react";
import _ from "lodash";

import KeyPointsReviewModal from "./KeyPointsReviewModal";

const KeyPoints = ({isUserLine, isListeningSummaryLine, line, metric}) => {
  const completedScenario = metric.completed_scenario;

  if (isListeningSummaryLine) {
    const keyPointsValueResult = _.find(metric.results, {metric: "key_points_value"});

    return (
      <KeyPointsReviewModal
        id={line.id}
        keyPoints={completedScenario.key_points}
        keyPointsValueResult={keyPointsValueResult}
        userKeyPoints={completedScenario.user_key_points}
      />
    );
  }

  if (isUserLine) {
    const keyPointsValueResult = _.find(line.results, {metric: "key_points_value"});

    return (
      <KeyPointsReviewModal
        id={line.id}
        keyPoints={line.key_points}
        keyPointsValueResult={keyPointsValueResult}
        userKeyPoints={line.user_key_points}
      />
    );
  }


  return null;
};

export default KeyPoints;
