import React, {memo} from "react";
import classnames from "classnames";

const Progress = ({percentage = 0, secondaryPercentage = 0}) => {
  const primaryStyle = {width: `${percentage}%`};
  const secondaryStyle = {width: `${secondaryPercentage}%`};
  const isProgressComplete = percentage === 100;

  return (
    <div className="progress progress--activity">
      <div className="progress__label">{percentage}%</div>
      <div className="progress__progress">
        <div className="progress__bar progress__bar--secondary" style={secondaryStyle} />
        <div
          className={classnames("progress__bar", {
            "progress__bar--complete": isProgressComplete
          })}
          style={primaryStyle}
        />
      </div>
    </div>
  );
};

export default memo(Progress);
