import React, {Component} from "react"
import PropTypes from "prop-types";
import classnames from "classnames";

export const MODAL_NAME = "PACK_MODAL";

import DefaultModal from "../../components/shared/modals/DefaultModal";

class FormModal extends Component {
  render() {
    const {title, buttonClass} = this.props;

    return (
      <DefaultModal modalName={MODAL_NAME}
                    modalTitle={title}
                    buttonClass={classnames("top-panel__button hide-on-sm-and-down", buttonClass)}>
        <div className="bem-popup__content bem-popup__content_margin_scroll">
          <div className="mt-1">
            {this.props.children}
          </div>
        </div>
      </DefaultModal>
    );
  }
}

FormModal.propTypes = {
  title: PropTypes.string.isRequired,
  buttonClass: PropTypes.string.isRequired
};

export default FormModal;
