import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import _ from "lodash";

import {OWN_PACK_CATEGORY} from "../../constants";

import {isPublic} from "../../helpers/packsHelper";
import {listItemDateLabel} from "../../helpers/listItemsHelper";

import {moveUserScenarios} from "../../store/actions/userScenariosActions";
import {closeModal} from "../../store/actions/modalsActions";

import DefaultModal from "../../components/shared/modals/DefaultModal";
import FetchPacks from "../../components/data_loaders/FetchPacks";

const MODAL_NAME = "MOVE_USER_SCENARIOS_MODAL";

class MoveUserScenariosModal extends Component {
  renderPackItem = (pack) => {
    const isCurrentPack = this.isCurrentPack(pack);
    const packClass = isCurrentPack ? "scenario-list-item--selected scenario-list-item--non-hoverable" : "";
    const visibilityClass =
      isPublic(pack) ? "scenario-list-item__icon--pack-public" : "scenario-list-item__icon--pack-private";

    return (
      <button key={pack.id} className={classnames("scenario-list-item", packClass)}
              onClick={(event) => this.moveUserScenarios(event, pack)}
              disabled={isCurrentPack}>
        <div className={classnames("scenario-list-item__icon", visibilityClass)}/>
        <div className="scenario-list-item__content">
          <div className="scenario-list-item__title" title={pack.title}>
            {pack.title}
          </div>
          <div className="scenario-list-item__secondary-content">
            <div className="scenario-list-item__subtitle"
                 title={I18n.t("packs.scenarios", {scenarios_count: pack.user_scenarios_count})}>
              {I18n.t("packs.scenarios", {scenarios_count: pack.user_scenarios_count})}
            </div>
            <div className="scenario-list-item__date">
              {listItemDateLabel(pack.updated_at)}
            </div>
          </div>
        </div>
      </button>
    );
  };

  isCurrentPack = (pack) => {
    return pack.id === this.props.packs.current.id;
  };

  moveUserScenarios = (event, pack) => {
    event.preventDefault();
    if (!this.isCurrentPack(pack)) {
      this.props.moveUserScenarios(pack.id, this.props.userScenarios.selectedIds, {
        onSuccess: () => {
          this.props.closeModal(MODAL_NAME);
        }
      });
    }
  };

  render() {
    const {packs} = this.props;

    return (
      <DefaultModal modalName={MODAL_NAME}
                    modalTitle={I18n.t("packs.popups.scenario_move.title")}
                    buttonClass="batch-select-actions__button button button_size_xs"
                    buttonText={I18n.t("move")}>
        <div className="bem-popup__content">
          <FetchPacks categoryId={OWN_PACK_CATEGORY}/>
          {_.map(packs.collection, this.renderPackItem)}
        </div>
      </DefaultModal>
    );
  }
}

MoveUserScenariosModal.propTypes = {
  packs: PropTypes.object.isRequired,
  userScenarios: PropTypes.object.isRequired,
  moveUserScenarios: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    packs: state.packs,
    userScenarios: state.userScenarios
  }
}

const mapActionsToProps = {
  moveUserScenarios,
  closeModal
};

export default connect(mapStateToProps, mapActionsToProps)(MoveUserScenariosModal);
