import React, {useEffect} from "react";
import {connect} from "react-redux";

import {fetchCoursesUser} from "store/actions/coursesUsersActions";

import Loader from "components/shared/Loader";
import SvgIcon from "components/SvgIcon";
import StatusIcon from "./StatusIcon";
import SwitchUnlocked from "./SwitchUnlocked";

const calculateProgress = scenario => {
  const requiredModes = scenario.modes.filter(mode => mode.required);
  const passedModes = requiredModes.filter(mode => mode.status === "passed");

  return requiredModes.length ? Math.round((passedModes.length * 100.0) / requiredModes.length) : 0;
};

const Scenarios = ({coursesUsersId, coursesUsers, fetchCoursesUser}) => {
  const {isLoading} = coursesUsers.loadingState[coursesUsersId] || {};
  const course = coursesUsers.collections[coursesUsersId];
  const scenarios = course ? course.user_scenarios : [];

  useEffect(() => {
    fetchCoursesUser(coursesUsersId);
  }, [coursesUsersId, fetchCoursesUser]);

  if (!scenarios.length && isLoading) {
    return (
      <div className="position-relative py-4">
        <Loader isShow size="small" />
      </div>
    );
  }

  if (!scenarios.length) return null;

  return (
    <table className="pd-course__scenarios-table">
      <thead>
        <tr>
          <th colSpan={2}>{I18n.t("mongoid.models.scenario.one")}</th>

          <th>
            <SvgIcon icon="mode-listening-lite" />
          </th>

          <th>
            <SvgIcon icon="mode-reading-lite" />
          </th>

          <th>
            <SvgIcon icon="mode-translating-lite" />
          </th>

          <th>
            <SvgIcon icon="mode-free-talk-lite" />
          </th>

          <th aria-label="Empty space" />

          <th colSpan={2}>{I18n.t("completion")}</th>

          <th>{I18n.t("unlocked")}</th>
        </tr>
      </thead>
      <tbody>
        {scenarios.map((scenario, scenarioIndex) => {
          const progress = calculateProgress(scenario);

          return (
            <tr key={scenario.id} className="pd-course-scenario">
              <td className="pd-course-scenario__number">{scenarioIndex + 1}</td>

              <td className="pd-course-scenario__title">{scenario.title}</td>

              <td className="pd-course-scenario__icon">
                <StatusIcon mode="listening" scenario={scenario} />
              </td>

              <td className="pd-course-scenario__icon">
                <StatusIcon mode="reading" scenario={scenario} />
              </td>

              <td className="pd-course-scenario__icon">
                <StatusIcon mode="translating" scenario={scenario} />
              </td>

              <td className="pd-course-scenario__icon">
                <StatusIcon mode="free_talk" scenario={scenario} />
              </td>

              <td />

              <td className="pd-course-scenario__icon">
                {progress === 100 && <SvgIcon icon="mode-state-passed" />}
              </td>

              <td className="pd-course-scenario__progress">{progress}%</td>

              <td className="text-right">
                <SwitchUnlocked course={course} scenario={scenario} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

function mapStateToProps(state) {
  return {
    coursesUsers: state.coursesUsers
  };
}

const mapActionsToProps = {
  fetchCoursesUser
};

export default connect(mapStateToProps, mapActionsToProps)(Scenarios);
