import _ from "lodash";

import {buildLine} from "./buildLine";

export function extractScenarioData(csvLines, file, participants) {
  // const title = extractScenarioTitle(file);
  // const lines = csvLines.map((csvLine) => extractLine(csvLine, participants));
  //
  // return {
  //   title,
  //   lines
  // }
  return {};
}

function extractScenarioTitle(file) {
  return file.name.replace(/\.csv$/, "");
}

function extractLine(csvLine, participants) {
  const fields = extractFields(csvLine);
  const participant = extractParticipant(participants, fields.identification);

  const line = buildLine(participant);
  line.text = fields.text;
  line.translation = fields.translation;

  return line;
}

function extractFields(csvLine) {
  return {
    identification: csvLine[0] && csvLine[0].toString().toLowerCase(),
    text: csvLine[1] || "",
    keyPointsScope: csvLine[2],
    hint: csvLine[3]
  }
}

function extractParticipant(participants, identification) {
  let participant = _.find(participants, {identification});

  if (!participant) {
    participant = _.find(participants, {participant_type: USER_PARTICIPANT_TYPE});
  }

  return participant;
}
