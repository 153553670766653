import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import classnames from "classnames";

import {REMOVING_STATES} from "../../constants";

import {tagPath, editTagPath} from "../../helpers/routesHelper";
import {listItemDateLabel} from "../../helpers/listItemsHelper";

import {destroyTag, setTagRemovingState} from "../../store/actions/tagsActions";
import ContextMenu from "../../components/shared/context_menu/ContextMenu";
import ContextMenuItem from "../../components/shared/context_menu/ContextMenuItem";
import DeleteListItem from "../../components/shared/DeleteListItem";

class Tag extends Component {
  showRemoveDialog = (event) => {
    event && event.preventDefault();
    this.props.setTagRemovingState(this.props.tag.id, REMOVING_STATES.REMOVING);
  };

  onDestroy = () => {
    const {tag, destroyTag} = this.props;
    destroyTag(tag.id);
  };

  render() {
    const {tag, setTagRemovingState} = this.props;

    return (
      <div className="scenario-list-item">
        <div className="scenario-list-item__icon scenario-list-item__icon--tag"/>
        <div className="scenario-list-item__content">
          <div className="scenario-list-item__title" title={tag.english_name}>
            {tag.english_name}
          </div>
          <div className="scenario-list-item__secondary-content">
            <div className="scenario-list-item__date ml-0">
              {listItemDateLabel(tag.updated_at)}
            </div>
          </div>
        </div>
        <div className="scenario-list-item__actions">
          <ContextMenu className="scenario-list-item__context-menu">
            <ContextMenuItem color="green">
              <Link to={editTagPath(tag.id)}>
                {I18n.t("edit")}  
              </Link>
            </ContextMenuItem>
            <ContextMenuItem onClick={this.showRemoveDialog} color="red">
              {I18n.t("delete")}
            </ContextMenuItem>
          </ContextMenu>
        </div>
        <DeleteListItem objectWithState={tag} onDestroy={this.onDestroy} setRemovingState={setTagRemovingState}/>
      </div>
    );
  }
}

Tag.propTypes = {
  setTagRemovingState: PropTypes.func.isRequired,
  destroyTag: PropTypes.func.isRequired
};

const mapActionsToProps = {
  setTagRemovingState,
  destroyTag
};

export default connect(null, mapActionsToProps)(Tag);
