import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import moment from "moment-timezone";
import _ from "lodash";

import {MODAL_NAME as CONFIRM_MODAL_NAME} from "./_PackShareCreateConfirmationModal";

import {isFetchPackSharesError, isFetchUsersError} from "../../helpers/errorsHelpers";

import {closeModal, openModal} from "../../store/actions/modalsActions";
import {destroyPackShare} from "../../store/actions/packSharesActions";
import FetchPackShares from "../../components/data_loaders/FetchPackShares";

import DefaultModal from "../../components/shared/modals/DefaultModal";
import FetchUsers from "../../components/data_loaders/FetchUsers";
import PackShareCreateConfirmationModal from "./_PackShareCreateConfirmationModal";

export const MODAL_NAME = "PACK_SHARE_MODAL";
const TABS = {
  INVITE_LIST: "INVITE_LIST",
  LOGS: "LOGS"
};

class PackShareModal extends Component {
  get isSingleMode() {
    return this.props.mode === "single";
  }

  changeTab = (tab) => {
    this.props.openModal(MODAL_NAME, {tab});
  };

  renderInviteList() {
    const {users} = this.props;
    const userPackShares = this.extractUserPackShares();

    return (
      <Fragment>
        <div className="pack-share__invite-description">
          {I18n.t("packs.popups.pack_share.invite_description")}
        </div>
        <div>
          {_.map(users.collection, (user) => (
            <div className="user-list-item" key={user.id}>
              <div className="user-list-item__avatar avatar">
                <img src={user.avatar.thumb_url} alt=""/>
              </div>
              <div className="user-list-item__name">
                {user.full_name}
              </div>
              {this.renderActionButton(user, userPackShares)}
            </div>
          ))}
        </div>
      </Fragment>
    );
  }

  renderLogs() {
    const {packShares, packIds} = this.props;
    const selectedPackShares = _.filter(packShares.collection, packShare => packIds.includes(packShare.pack.id));

    return (
      <div>
        {selectedPackShares.map(packShare => {
          const user = packShare.user;
          const activityTime = packShare.confirmed_at || packShare.canceled_at || packShare.created_at;

          return (
            <div className="pack-share__log-item" key={packShare.id}>
              <div className="pack-share__log-time">
                {moment(activityTime).format(I18n.t("time.moment_formats.short"))}
              </div>
              <div className="pack-share__user-avatar avatar">
                <img src={user.avatar.thumb_url} alt=""/>
              </div>
              <div className="pack-share__user-name">
                {user.full_name}
              </div>
              <div className="pack-share__log-action">
                {this.logAction(packShare)}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  extractUserPackShares = () => {
    const {packShares} = this.props;
    const userPackShares = {};
    packShares.collection.forEach((packShare) => {
      if (!packShare.canceled_at && !packShare.confirmed_at) {
        if (!userPackShares[packShare.user.id]) {
          userPackShares[packShare.user.id] = [];
        }
        userPackShares[packShare.user.id].push(packShare);
      }
    });
    return userPackShares;
  };

  renderActionButton = (user, userPackShares) => {
    const packShares = userPackShares[user.id];
    const {packIds} = this.props;
    const sharedPackIds = _.map(packShares, packShare => packShare.pack.id);
    const allPacksAreShared = _.difference(packIds, sharedPackIds).length === 0;

    if (allPacksAreShared) {
      const packShareIds = packIds.map(packId => _.find(packShares, packShare => packShare.pack.id === packId).id);
      return (
        <button className="user-list-item__action user-list-item__action--cancel button"
                onClick={() => this.cancelPackShare(packShareIds, user.full_name)}>
          {I18n.t("cancel")}
        </button>
      );
    }

    return (
      <button className="user-list-item__action button" onClick={() => this.sharePacks(user)}>
        {I18n.t("invite")}
      </button>
    );
  };

  sharePacks = (user) => {
    const {packIds} = this.props;
    this.props.openModal(CONFIRM_MODAL_NAME, {username: user.full_name, userId: user.id, packIds});
  };

  cancelPackShare = (packShareIds, username) => {
    this.props.destroyPackShare(packShareIds, username);
  };

  logAction = (packShare) => {
    if (packShare.canceled_at) {
      return I18n.t("packs.popups.pack_share.canceled");
    }

    if (packShare.confirmed_at) {
      return I18n.t("packs.popups.pack_share.created");
    }

    return I18n.t("packs.popups.pack_share.invite_sent");
  };

  render() {
    const {users, packShares, button} = this.props;
    const tab = this.props.modals.options.tab || TABS.INVITE_LIST;

    const isLoaded = users.isCollectionLoaded && packShares.isCollectionLoaded;
    const isLoadingError = isFetchUsersError(users) || isFetchPackSharesError(packShares);
    const isLoading = (!isLoaded && !isLoadingError) || users.isLoading || packShares.isLoading;

    return (
      <Fragment>
        <DefaultModal modalName={MODAL_NAME} modalTitle={I18n.t("packs.popups.pack_share.title")} button={button}>
          <FetchUsers clearQuery/>
          <FetchPackShares/>
          {
            !isLoading &&
            <div className="bem-popup__content bem-popup__content_margin_scroll">
              <div className="pack-share">
                {
                  this.isSingleMode &&
                  <div className="tabs">
                    <a href="#" className={classnames("tabs__tab", {"tabs__tab--active": tab === TABS.INVITE_LIST})}
                       onClick={() => this.changeTab(TABS.INVITE_LIST)}>
                      {I18n.t("packs.popups.pack_share.send_invite")}
                    </a>
                    <a href="#" className={classnames("tabs__tab", {"tabs__tab--active": tab === TABS.LOGS})}
                       onClick={() => this.changeTab(TABS.LOGS)}>
                      {I18n.t("packs.popups.pack_share.logs")}
                    </a>
                  </div>
                }
                {tab === TABS.INVITE_LIST && this.renderInviteList()}
                {tab === TABS.LOGS && this.renderLogs()}
              </div>
            </div>
          }
        </DefaultModal>
        <PackShareCreateConfirmationModal/>
      </Fragment>
    );
  }
}

PackShareModal.propTypes = {
  packIds: PropTypes.array.isRequired,
  mode: PropTypes.oneOf(["single", "batch"]),
  users: PropTypes.object.isRequired,
  button: PropTypes.object.isRequired,
  modals: PropTypes.shape({
    options: PropTypes.shape({
      tab: PropTypes.string,
    })
  }),
  packShares: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  destroyPackShare: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    users: state.users,
    packShares: state.packShares,
    modals: state.modals
  }
}

const mapActionsToProps = {
  openModal,
  closeModal,
  destroyPackShare
};

export default connect(mapStateToProps, mapActionsToProps)(PackShareModal);
