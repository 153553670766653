import {SUCCESS_STATUS_CODE, UNAUTHENTICATED_STATUS_CODE} from "../../constants";

export const ACTIONS = {
  CHANGE_PROFILES_LOADING: "CHANGE_PROFILES_LOADING",
  FETCH_PROFILE: "FETCH_PROFILE",
  FETCH_PROFILE_FULFILLED: "FETCH_PROFILE_FULFILLED",
  FETCH_PROFILE_REJECTED: "FETCH_PROFILE_REJECTED",
  ASSIGN_PROFILE_ATTRIBUTES: "ASSIGN_PROFILE_ATTRIBUTES",
  CLEAR_PROFILE_ATTRIBUTES: "CLEAR_PROFILE_ATTRIBUTES",
  UPDATE_PROFILE_FULFILLED: "UPDATE_PROFILE_FULFILLED",
  UPDATE_PROFILE_REJECTED: "UPDATE_PROFILE_REJECTED",
  UNLOAD_PROFILE: "UNLOAD_PROFILE"
};

const DEFAULT_SUCCESS_STATUS = {errors: {}, status: SUCCESS_STATUS_CODE};
const DEFAULT_STATUSES = {
  [ACTIONS.FETCH_PROFILE]: {errors: {}, status: null}
};

const INITIAL_FORM_ATTRIBUTES = {
  avatar: {},
  birthday_hash: {
    "birthday(1i)": "",
    "birthday(2i)": "",
    "birthday(3i)": ""
  },
  email: "",
  errors: {},
  first_name: "",
  first_name_ja: "",
  gender: "male",
  language_code: "en",
  last_name: "",
  last_name_ja: "",
  password: "",
  password_confirmation: "",
  pin_code: ""
};

export default function reducer(state = {
  current: {},
  formAttributes: INITIAL_FORM_ATTRIBUTES,
  isLoading: false,
  loadingCount: 0,
  statuses: DEFAULT_STATUSES
}, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_PROFILES_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);
      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.FETCH_PROFILE: {
      return {...state, current: {}};
    }
    case ACTIONS.FETCH_PROFILE_FULFILLED: {
      return {
        ...state,
        current: action.profile,
        statuses: {...state.statuses, [ACTIONS.FETCH_PROFILE]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_PROFILE_REJECTED: {
      return {
        ...state,
        errors: action.errors,
        statuses: {...state.statuses, [ACTIONS.FETCH_PROFILE]: {errors: action.errors, status: action.status}}
      };
    }

    case ACTIONS.ASSIGN_PROFILE_ATTRIBUTES: {
      return {...state, formAttributes: {errors: state.formAttributes.errors, ...action.attributes}};
    }
    case ACTIONS.CLEAR_PROFILE_ATTRIBUTES: {
      return {...state, formAttributes: INITIAL_FORM_ATTRIBUTES};
    }

    case ACTIONS.UPDATE_PROFILE_FULFILLED: {
      return {...state, current: action.profile};
    }
    case ACTIONS.UPDATE_PROFILE_REJECTED: {
      const attributesWithNewErrors = {...state.formAttributes, errors: action.errors};
      return {...state, formAttributes: attributesWithNewErrors};
    }

    case ACTIONS.UNLOAD_PROFILE: {
      return {...state, current: {}, statuses: {...state.statuses, [ACTIONS.FETCH_PROFILE]: UNAUTHENTICATED_STATUS_CODE}};
    }
  }
  return state;
}
