/* eslint-disable */
import React, {memo} from "react";

const fn = ({className}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31">
    <g fill="#C6C1BB" fillRule="evenodd">
      <path d="M16 8c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 1c-3.866 0-7 3.134-7 7s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7z"/>
      <path d="M13 15.5H19V16.5H13z"/>
    </g>
  </svg>
);

export default memo(fn);

