import React, {memo} from "react";

const Participant = ({avatarUrl, name}) => (
  <div className="pd-metrics__participant">
    <div className="pd-metrics__participant-avatar avatar">
      <img alt="" src={avatarUrl} />
    </div>
    <span className="pd-metrics__participant-name">{name}</span>
  </div>
);

export default memo(Participant);
