import {SERVICE_UNAVAILABLE_STATUS_CODE} from "../../constants";
import {newUserSessionPath} from "../../helpers/routesHelper";

export function defaultErrorAction(action, response, options = {redirect: true}) {
  if (response) {
    if (response.status === 401 && options.redirect) {
      window.location = newUserSessionPath();
    }
    return {type: action, errors: response.data.errors || {}, status: response.status, ...options};
  } else {
    return {type: action, errors: {}, status: SERVICE_UNAVAILABLE_STATUS_CODE, ...options};
  }
}
