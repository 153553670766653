import {ACTIONS} from "../reducers/dialogflowAgentsReducer";

import webToolApi from "../../helpers/webToolApiHelper";

import {defaultErrorAction} from "./errorsActions";
import {notifyResponseError} from "./notificationsActions";

export function changeDialogflowAgentsLoading(isLoading) {
  return {type: ACTIONS.CHANGE_DIALOGFLOW_AGENTS_LOADING, isLoading};
}

export function fetchDialogflowAgents() {
  return (dispatch) => {
    dispatch(changeDialogflowAgentsLoading(true));
    dispatch({type: ACTIONS.FETCH_DIALOGFLOW_AGENTS});

    webToolApi().get(Routes.dialogflow_agents_path())
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_DIALOGFLOW_AGENTS_FULFILLED, payload: response.data});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_DIALOGFLOW_AGENTS_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("dialogflow_agents.messages.index.error"), response));
      })
      .finally(() => {
        dispatch(changeDialogflowAgentsLoading(false));
      });
  }
}
