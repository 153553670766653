import {ACTIONS} from "../reducers/groupsReducer";

import webToolApi from "../../helpers/webToolApiHelper";
import {groupPath, dashboardPath} from "../../helpers/routesHelper";

import {defaultErrorAction} from "./errorsActions";
import {notifyResponseError, notifySuccess} from "./notificationsActions";

export function changeGroupsLoading(isLoading) {
  return {type: ACTIONS.CHANGE_GROUPS_LOADING, isLoading};
}

export function fetchGroups() {
  return (dispatch) => {
    dispatch(changeGroupsLoading(true));
    dispatch({type: ACTIONS.FETCH_GROUPS});

    webToolApi().get(Routes.groups_path())
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_GROUPS_FULFILLED, payload: response.data});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_GROUPS_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("groups.messages.index.error"), response));
      })
      .finally(() => {
        dispatch(changeGroupsLoading(false));
      });
  }
}

export function fetchGroup(id) {
  return (dispatch) => {
    dispatch(changeGroupsLoading(true));
    dispatch({type: ACTIONS.FETCH_GROUP});

    webToolApi().get(Routes.group_path(id))
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_GROUP_FULFILLED, group: response.data.group});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_GROUP_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("groups.messages.show.error"), response));
      })
      .finally(() => {
        dispatch(changeGroupsLoading(false));
      });
  }
}

export function assignGroupAttributes(attributes) {
  return {type: ACTIONS.ASSIGN_GROUP_ATTRIBUTES, attributes};
}

export function clearGroupAttributes() {
  return {type: ACTIONS.CLEAR_GROUP_ATTRIBUTES};
}

export function createGroup(history) {
  return (dispatch) => {
    dispatch(changeGroupsLoading(true));

    webToolApi().post(Routes.groups_path())
      .then((response) => {
        dispatch({type: ACTIONS.CREATE_GROUP_FULFILLED, group: response.data.group});
        dispatch(notifySuccess(I18n.t("groups.messages.create.success")));
        dispatch(clearGroupAttributes());
        dispatch(changeGroupsLoading(false));
        history.push(groupPath(response.data.group.id));
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.CREATE_GROUP_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("groups.messages.create.error"), response));
        dispatch(changeGroupsLoading(false));
      });
  }
}

export function updateGroup(group, {onSuccess} = {}) {
  return (dispatch) => {
    dispatch(changeGroupsLoading(true));

    webToolApi().put(Routes.group_path(group.id), {group})
      .then((response) => {
        dispatch({type: ACTIONS.UPDATE_GROUP_FULFILLED, group: response.data.group});
        dispatch(notifySuccess(I18n.t("groups.messages.update.success")));
        dispatch(clearGroupAttributes());
        onSuccess && onSuccess();
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.UPDATE_GROUP_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("groups.messages.update.error"), response));
      })
      .finally(() => {
        dispatch(changeGroupsLoading(false));
      });
  }
}

export function destroyGroup(groupId, history) {
  return (dispatch) => {
    dispatch(changeGroupsLoading(true));

    webToolApi().delete(Routes.group_path(groupId))
      .then(() => {
        dispatch({type: ACTIONS.DESTROY_GROUP_FULFILLED, groupId});
        dispatch(notifySuccess(I18n.t("groups.messages.destroy.success")));
        history.push(dashboardPath());
        dispatch(changeGroupsLoading(false));
      })
      .catch(({response}) => {
        dispatch(notifyResponseError(I18n.t("groups.messages.destroy.error"), response));
        dispatch(changeGroupsLoading(false));
      });
  }
}
