import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchUserScenarioError} from "../../helpers/errorsHelpers";

import {fetchUserScenario} from "../../store/actions/userScenariosActions";

class FetchUserScenario extends Component {
  componentDidMount() {
    this.fetchUserScenario(true);
  }

  componentDidUpdate() {
    this.fetchUserScenario(false);
  }

  fetchUserScenario(isComponentMount) {
    const {id, userScenarios} = this.props;
    const isReasonToFetch = isComponentMount || userScenarios.current.id !== id;

    if (isReasonToFetch && !userScenarios.isLoading && !isFetchUserScenarioError(userScenarios)) {
      this.props.fetchUserScenario(id);
    }
  }

  render() {
    return null;
  }
}

FetchUserScenario.propTypes = {
  id: PropTypes.string.isRequired,
  userScenarios: PropTypes.object.isRequired,
  fetchUserScenario: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    userScenarios: state.userScenarios
  }
}

const mapActionsToProps = {
  fetchUserScenario
};

export default connect(mapStateToProps, mapActionsToProps)(FetchUserScenario);
