import React, {useCallback} from "react";
import {connect} from "react-redux";

import {destroyCoursesUser} from "store/actions/coursesUsersActions";
import {fetchDashboardUser} from "store/actions/dashboardUsersActions";

import ConfirmationModal from "components/shared/modals/ConfirmationModal";
import ContextMenuItem from "components/shared/context_menu/ContextMenuItem";

const MODAL_NAME = "UNASSIGN_COURSE_MODAL";

const Button = props => (
  <ContextMenuItem color="red" {...props}>
    {I18n.t("remove")}
  </ContextMenuItem>
);

const UnassignCourseModal = ({course, destroyCoursesUser, fetchDashboardUser, groupId}) => {
  const modalName = `${MODAL_NAME}_${course.id}`;

  const handleSubmit = useCallback(
    closeModal => {
      destroyCoursesUser(course.id, {
        onSuccess: () => {
          fetchDashboardUser(course.user_id, {groupId});
          closeModal();
        }
      });
    },
    [course.id, course.user_id, destroyCoursesUser, fetchDashboardUser, groupId]
  );

  return (
    <ConfirmationModal
      actionsStyle="DELETE_CANCEL"
      button={<Button />}
      contentStyle={{width: "550px"}}
      message={I18n.t("dashboard.popups.unassign_course", {title: course.title})}
      modalName={modalName}
      onSubmit={handleSubmit}
    />
  );
};

function mapStateToProps(state) {
  return {
    modals: state.modals
  };
}

const mapActionsToProps = {
  destroyCoursesUser,
  fetchDashboardUser
};

export default connect(mapStateToProps, mapActionsToProps)(UnassignCourseModal);
