import React, {Fragment, useCallback, useMemo, useState} from "react";
import classnames from "classnames";

import {sortActivities} from "./helpers";

import InactiveUsersSwitch from "./InactiveUsersSwitch";
import Overall from "./Overall";
import Weekly from "./Weekly";

import moment from "moment-timezone";

const TABS = {
  OVERALL: "OVERALL",
  WEEKLY: "WEEKLY"
};

const Renderers = {
  [TABS.OVERALL]: Overall,
  [TABS.WEEKLY]: Weekly
};

const Overview = props => {
  const [tab, setTab] = useState(TABS.OVERALL);
  const Renderer = Renderers[tab];

  const handleTabChange = useCallback(
    newTab => () => {
      setTab(newTab);
    },
    [setTab]
  );

  const sortedActivities = useMemo(() => sortActivities(props.userActivities), [props]);
  const lastUpdatedAt = props.userActivities.lastUpdatedAt;

  return (
    <Fragment>
      <div className="d-flex justify-content-between">
        <div className="tabs tabs--left d-flex">
          <button
            className={classnames("tabs__tab", {"tabs__tab--active": tab === TABS.OVERALL})}
            onClick={handleTabChange(TABS.OVERALL)}
          >
            {I18n.t("dashboard.groups.overview_tabs.overall")}
          </button>
          <button
            className={classnames("tabs__tab", {"tabs__tab--active": tab === TABS.WEEKLY})}
            onClick={handleTabChange(TABS.WEEKLY)}
          >
            {I18n.t("dashboard.groups.overview_tabs.weekly")}
          </button>
          <div className="d-flex"
            style={{
              alignItems: "center",
              gap: 4,
            }}
          >
            <button
              className={classnames("button button_color_grey")}
              style={{
                marginLeft: '2em',
                padding: ".2em",
                fontSize: ".75em",
                height: "fit-content",
              }}
              onClick={props.refreshData}
            >
              {I18n.t("dashboard.groups.overview_tabs.refresh_data")}
            </button>
            {lastUpdatedAt && <div
              style={{
                fontSize: ".75em",
              }}
            >
              Last Updated: {moment(lastUpdatedAt).format('L (ddd) HH:mm:ss z')}
            </div>}
          </div>
          
        </div>

        <InactiveUsersSwitch />
      </div>
      <Renderer sortedActivities={sortedActivities} {...props} />
    </Fragment>
  );
};

export default Overview;