import {PunditPolicy} from "react-pundit";

import {isManager, isStudent} from "../helpers/userRolesHelper";

class PackCategoryPolicy extends PunditPolicy {
  constructor() {
    super("PackCategory");
  }

  ShowGeneral(model, user) {
    return true;
  }

  ShowCompany(model, user) {
    return isStudent(user) || isManager(user);
  }

  ShowOwn(model, user) {
    return isStudent(user);
  }
}

export default PackCategoryPolicy;
