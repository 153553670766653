import React, {useCallback, useMemo} from "react";
import TextareaAutosize from "react-autosize-textarea";
import Select2 from "react-select2-wrapper";
import {SortableHandle} from "react-sortable-hoc";
import classnames from "classnames";
import _ from "lodash";

import handleFieldChange from "../../../../helpers/handleFieldChange";
import {isTooLong} from "../../../../helpers/linesHelper";
import {renderSelect2Participant} from "../../../../helpers/participantsHelper";

import ContextMenu from "../../context_menu/ContextMenu";
import DeleteLineModal from "./DeleteLineModal";
import Errors from "./Errors";
import ParticipantFocuses from "./ParticipantFocuses";

const DragHandle = SortableHandle(() => (
  <div className="scenario-line__icon scenario-line__icon_drag" />
));

const Line = ({assignUserScenarioAttributes, formAttributes, id}) => {
  const lineIndex = _.findIndex(formAttributes.lines, {id});
  const line = formAttributes.lines[lineIndex];
  const {participants} = formAttributes;

  const participantSelect2Data = useMemo(
    () =>
      participants.map(participant => ({
        avatar_url: participant.avatar_url,
        id: participant.id,
        text: participant.role
      })),
    [participants]
  );

  const handleChange = useCallback(
    event => {
      const changedAttributes = {...formAttributes, ...handleFieldChange(event, formAttributes)};
      assignUserScenarioAttributes(changedAttributes);
    },
    [assignUserScenarioAttributes, formAttributes]
  );

  const handleParticipantChange = useCallback(
    event => {
      const changedAttributes = {...formAttributes, ...handleFieldChange(event, formAttributes)};
      const lineParticipantIndex = _.findIndex(participants, {
        id: event.target.value
      });
      changedAttributes.lines[lineIndex].participant_focuses[lineParticipantIndex] = 0;
      assignUserScenarioAttributes(changedAttributes);
    },
    [assignUserScenarioAttributes, formAttributes, lineIndex, participants]
  );

  if (!line) return null;

  return (
    <tr className="scenario-line">
      <td>
        <div className="scenario-line__line-number">{lineIndex + 1}</div>
      </td>

      <td>
        <Select2
          className="select2-participant select2-transparent"
          data={participantSelect2Data}
          name={`lines.${lineIndex}.participant_id`}
          onChange={handleParticipantChange}
          options={{
            minimumResultsForSearch: -1,
            templateResult: renderSelect2Participant,
            templateSelection: renderSelect2Participant
          }}
          value={line.participant_id}
        />
        <Errors formAttributes={formAttributes} name={`lines[${lineIndex}].participant_id`} />
      </td>

      <td>
        <TextareaAutosize
          className={classnames(
            "transparent-form-group__input transparent-form-group__input--text-area",
            {"scenario-line__text-input_error": isTooLong(line)}
          )}
          data-gramm_editor={false}
          name={`lines.${lineIndex}.text`}
          onChange={handleChange}
          placeholder={I18n.t("user_scenarios.line_text_placeholder")}
          rows={2}
          value={line.text}
        />
        <Errors formAttributes={formAttributes} name={`lines[${lineIndex}].text`} />
        <Errors formAttributes={formAttributes} name={`lines[${lineIndex}].participant_focuses`} />
      </td>

      <td>
        <TextareaAutosize
          className="transparent-form-group__input transparent-form-group__input--text-area"
          data-gramm_editor={false}
          name={`lines.${lineIndex}.translation`}
          onChange={handleChange}
          placeholder={I18n.t("user_scenarios.line_translation_placeholder")}
          rows={2}
          value={line.translation}
        />
        <Errors formAttributes={formAttributes} name={`lines[${lineIndex}].translation`} />
      </td>

      <ParticipantFocuses
        formAttributes={formAttributes}
        line={line}
        lineIndex={lineIndex}
        onChange={handleChange}
      />

      <td>
        <div className="scenario-edit__table-actions">
          <ContextMenu className="scenario-edit__actions-context-menu">
            <DeleteLineModal
              assignUserScenarioAttributes={assignUserScenarioAttributes}
              formAttributes={formAttributes}
              lineId={line.id}
            />
          </ContextMenu>
          <DragHandle />
        </div>
      </td>
    </tr>
  );
};

export default Line;
