import React from "react";

import {isAdmin} from "../../../../helpers/userRolesHelper";
import {isFetchUserScenarioError} from "../../../../helpers/errorsHelpers";

import ApplicationLayout from "../../../../views/layouts/ApplicationLayout";
import AudioPlayer from "../../scenario_content/AudioPlayer";
import Cover from "../../_Cover";
import DialogflowAgents from "./DialogflowAgents";
import FetchUserScenario from "../../../data_loaders/FetchUserScenario";
import Lines from "./Lines";
import Participants from "./Participants";
import Slides from "./Slides";
import TopPanel from "./TopPanel";

const Show = ({id, currentUser, userScenarios, backPath, editPath}) => {
  const userScenario = userScenarios.current;

  const isLoaded = userScenario.id && userScenario.id === id;
  const isLoadingError = isFetchUserScenarioError(userScenarios);
  const isLoading = (!isLoaded && !isLoadingError) || userScenarios.isLoading;

  return (
    <ApplicationLayout headerTitle={I18n.t("user_scenarios.titles.show")} isLoading={isLoading}>
      <FetchUserScenario id={id} />
      {isLoaded && (
        <div className="container">
          <div className="row py-4">
            <div className="col-12">
              <TopPanel
                backPath={backPath}
                editPath={editPath}
                scenario={userScenario}
                user={userScenario.user}
              />

              <div className="scenario-edit mt-5">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-xl-4 col-lg-5">
                        <Cover formAttributes={userScenario} theme="scenario-image" />
                      </div>

                      <div className="col-xl-8 col-lg-7">
                        <div className="transparent-form-group">
                          <label className="transparent-form-group__label" htmlFor="description">
                            {I18n.t("mongoid.attributes.user_scenario.description")}
                          </label>
                          <div className="scenario-edit__form-value">
                            {userScenario.description}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="transparent-form-group">
                      <label className="transparent-form-group__label" htmlFor="environment_id">
                        {I18n.t("mongoid.attributes.user_scenario.environment")}
                      </label>
                      <div className="scenario-edit__form-value">
                        {userScenario.environment.title}
                      </div>
                    </div>

                    {userScenario.environment.image_url && (
                      <img alt="" src={userScenario.environment.image_url} width="200" />
                    )}
                  </div>
                </div>

                <div className="transparent-form-group">
                  <label className="transparent-form-group__label">
                    {I18n.t("user_scenarios.roles")}
                  </label>
                  <Participants participants={userScenario.participants} />
                </div>

                {isAdmin(currentUser) && (
                  <div className="transparent-form-group">
                    <label className="transparent-form-group__label">
                      {I18n.t("user_scenarios.chatbot")}
                    </label>
                    <DialogflowAgents dialogflowAgents={userScenario.dialogflow_agents} />
                  </div>
                )}

                <div className="transparent-form-group">
                  <label className="transparent-form-group__label">
                    {I18n.t("mongoid.attributes.user_scenario.summary")}
                  </label>
                  <div className="scenario-edit__form-value">
                    {userScenario.summary}
                  </div>
                </div>

                <div className="transparent-form-group">
                  <label className="transparent-form-group__label">
                    {I18n.t("user_scenarios.script")}
                  </label>
                  <div className="mb-3">
                    <AudioPlayer
                      audioTrackUrl={userScenario.sample_conversation_url}
                      id={userScenario.id}
                    />
                  </div>
                  <Lines scenario={userScenario} />
                </div>

                <div className="transparent-form-group">
                  <label className="transparent-form-group__label">
                    {I18n.t("mongoid.models.scenario_slide.other")}
                  </label>
                  <Slides slides={userScenario.slides} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </ApplicationLayout>
  );
};

export default Show;
