import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import _ from "lodash";

import {pauseAudio, playAudio} from "../../../store/actions/audioPlayersActions";

class PlayAudioButton extends Component {
  togglePlaying = () => {
    if (this.isPlaying) {
      this.props.pauseAudio(this.props.id);
    } else {
      this.props.playAudio(this.props.id, this.props.audioTrackUrl);
    }
  };

  get audioPlayerData() {
    return _.find(this.props.audioPlayers.collection, {id: this.props.id}) || {};
  }

  get isPlaying() {
    const audioPlayerData = this.audioPlayerData;
    return audioPlayerData.url === this.props.audioTrackUrl && audioPlayerData.isPlaying;
  }

  render() {
    const {type, title, disabled} = this.props;

    return (
      <button className={classnames(
                "audio-player-mf__button",
                `audio-player-mf__button_${type}`,
                {"audio-player-mf__button_pause": this.isPlaying}
              )}
              title={title}
              disabled={disabled}
              onClick={this.togglePlaying}>
        {this.props.children}
      </button>
    )
  }
}

PlayAudioButton.defaultProps = {
  type: "ear",
  disabled: false
};

PlayAudioButton.propTypes = {
  id: PropTypes.string.isRequired,
  audioTrackUrl: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(["ear", "play"]),
  disabled: PropTypes.bool,
  audioPlayers: PropTypes.object.isRequired,
  playAudio: PropTypes.func.isRequired,
  pauseAudio: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    audioPlayers: state.audioPlayers
  }
}

const mapActionsToProps = {
  playAudio,
  pauseAudio
};

export default connect(mapStateToProps, mapActionsToProps)(PlayAudioButton);
