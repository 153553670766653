import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";

import {isFetchCourseCategoriesError} from "helpers/errorsHelpers";
import {listItemDateLabel} from "helpers/listItemsHelper";

import {closeModal, openModal} from "store/actions/modalsActions";
import {createCoursesUser} from "store/actions/coursesUsersActions";
import {fetchDashboardUser} from "store/actions/dashboardUsersActions";

import DefaultModal from "components/shared/modals/DefaultModal";
import Loader from "components/shared/Loader";
import FetchCourseCategories from "components/data_loaders/FetchCourseCategories";

const PAGES = {
  CATEGORIES: "CATEGORIES",
  COURSES: "COURSES"
};
const CATEGORIES = {
  COMPANY: "company",
  GENERAL: "general"
};
const MODAL_NAME = "ASSIGN_COURSE_MODAL";

class AssignCourseModal extends Component {
  onBack() {
    const {page} = this.props.modals.options;

    if (page === PAGES.COURSES) {
      return () => this.changePage(PAGES.CATEGORIES);
    }

    return null;
  }

  get modalTitle() {
    const {options} = this.props.modals;

    switch (options.page) {
      case PAGES.CATEGORIES:
        return I18n.t("all");
      case PAGES.COURSES:
        return this.categoryTitle(options.categoryId);
      default:
        return "";
    }
  }

  setDefaultPage = () => {
    this.changePage(PAGES.CATEGORIES);
  };

  categoryTitle = categoryId => {
    if (categoryId === CATEGORIES.GENERAL) {
      return I18n.t("dashboard.popups.assign_course.general_courses");
    }

    return I18n.t("dashboard.popups.assign_course.company_courses", {
      company: this.props.company.title
    });
  };

  changePage = (page, categoryId) => {
    this.props.openModal(MODAL_NAME, {categoryId, page});
  };

  assignCourse(course) {
    const {closeModal, createCoursesUser, fetchDashboardUser, groupId, userId} = this.props;
    createCoursesUser(
      {course_id: course.id, user_id: userId},
      {
        onSuccess: () => {
          fetchDashboardUser(userId, {groupId});
          closeModal(MODAL_NAME);
        }
      }
    );
  }

  renderCategories() {
    const {courseCategories} = this.props;

    const isLoaded = courseCategories.isCollectionLoaded;
    const isLoadingError = isFetchCourseCategoriesError(courseCategories);
    const isLoading = !isLoaded && !isLoadingError;

    return (
      <Fragment>
        <FetchCourseCategories />
        {isLoaded &&
          courseCategories.collection.map(courseCategory => {
            const categoryTitle = this.categoryTitle(courseCategory.id);

            return (
              <button
                key={courseCategory.id}
                className="scenario-list-item"
                onClick={() => this.changePage(PAGES.COURSES, courseCategory.id)}
              >
                <div className="scenario-list-item__icon scenario-list-item__icon--course" />
                <div className="scenario-list-item__content">
                  <div className="scenario-list-item__title" title={categoryTitle}>
                    {categoryTitle}
                  </div>
                  <div className="scenario-list-item__secondary-content">
                    <div className="scenario-list-item__subtitle">
                      {I18n.t("courses.categories.courses", {count: courseCategory.courses.length})}
                    </div>
                  </div>
                </div>
                <div className="scenario-list-item__icon scenario-list-item__icon--arrow-right" />
              </button>
            );
          })}
        <Loader isShow={isLoading} />
      </Fragment>
    );
  }

  renderCourses() {
    const {courseCategories} = this.props;
    const {categoryId} = this.props.modals.options;

    const courseCategory = _.find(courseCategories.collection, {id: categoryId});
    const {courses} = courseCategory;

    return courses.map(course => (
      <button
        key={course.id}
        className="scenario-list-item"
        onClick={() => this.assignCourse(course)}
      >
        <div className="scenario-list-item__icon scenario-list-item__icon--course" />
        <div className="scenario-list-item__content">
          <div className="scenario-list-item__title" title={course.title}>
            {course.title}
          </div>
          <div className="scenario-list-item__secondary-content">
            <div className="scenario-list-item__date ml-0">
              {listItemDateLabel(course.updated_at)}
            </div>
          </div>
        </div>
      </button>
    ));
  }

  renderCurrentPage() {
    switch (this.props.modals.options.page) {
      case PAGES.CATEGORIES:
        return this.renderCategories();
      case PAGES.COURSES:
        return this.renderCourses();
      default:
        return null;
    }
  }

  render() {
    return (
      <DefaultModal
        buttonClass="button button_theme_plus button_size_xs"
        modalName={MODAL_NAME}
        modalTitle={this.modalTitle}
        onBack={this.onBack()}
        onOpen={this.setDefaultPage}
      >
        <div className="bem-popup__content">{this.renderCurrentPage()}</div>
      </DefaultModal>
    );
  }
}

AssignCourseModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  courseCategories: PropTypes.object.isRequired,
  createCoursesUser: PropTypes.func.isRequired,
  fetchDashboardUser: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  modals: PropTypes.shape({
    options: PropTypes.shape({
      categoryId: PropTypes.string,
      page: PropTypes.string
    })
  }),
  openModal: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    courseCategories: state.courseCategories,
    modals: state.modals
  };
}

const mapActionsToProps = {
  closeModal,
  createCoursesUser,
  fetchDashboardUser,
  openModal
};

export default connect(mapStateToProps, mapActionsToProps)(AssignCourseModal);
