import React from "react";

import {formatMetricTime} from "../../../../helpers/dateTimeHelper";
import ScenarioMode from "./ScenarioMode";

const Header = ({metric}) => {
  const completedScenario = metric.completed_scenario;

  return (
    <div className="scenario-review-header">
      <div className="scenario-review-header__date">{formatMetricTime(metric.created_at)}</div>
      <div className="scenario-review-header__title">{completedScenario.title}</div>
      <ScenarioMode completedScenario={completedScenario} />
    </div>
  );
};

export default Header;
