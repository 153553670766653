import React, {Component} from "react"
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";

import {isFetchCourseError} from "../../helpers/errorsHelpers";

import ApplicationLayout from "../layouts/ApplicationLayout";
import Cover from '../../components/shared/_Cover';
import FetchCourse from "../../components/data_loaders/FetchCourse";
import TopPanel from "./_TopPanelShow";
import UserScenario from "./_UserScenario";

class Show extends Component {
  render() {
    const {id, courses} = this.props;
    const course = courses.current;

    const isLoaded = course.id;
    const isLoadingError = isFetchCourseError(courses);
    const isLoading = (!isLoaded && !isLoadingError) || courses.isLoading;

    return (
      <ApplicationLayout headerTitle={I18n.t("courses.titles.show")} isLoading={isLoading}>
        <FetchCourse id={id}/>
        {
          isLoaded &&
          <div className="container">
            <div className="row py-4">
              <div className="col-12">
                <TopPanel course={course}/>

                <div className="course-image-wrapper mt-3">
                  <Cover formAttributes={course} theme="course-image"/>
                </div>

                <div>
                  <ul className="simple-list">
                    {_.map(course.course_tags, (tag) => (
                      <li key={tag.label} className="simple-list__item">{tag.label}</li>
                    ))}
                  </ul>
                </div>

                <div className="pt-3">
                  {_.map(course.user_scenarios, (userScenario) => (
                    <UserScenario key={userScenario.id} userScenario={userScenario}/>
                  ))}
                </div>
              </div>
            </div>
          </div>
        }
      </ApplicationLayout>
    )
  }
}

Show.propTypes = {
  id: PropTypes.string.isRequired,
  courses: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    courses: state.courses,
    tags: state.tags
  }
}

export default connect(mapStateToProps)(Show);
