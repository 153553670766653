import React from "react";
import classnames from "classnames";

import {modeToBemModifier} from "../../../../helpers/modesHelper";

import Body from "./Body";
import Header from "./Header";

const ScenarioContent = ({metric, user}) => {
  const {mode} = metric.completed_scenario;
  const cTable = classnames(
    "scenario-review-lines__table",
    `scenario-review-lines__table--${modeToBemModifier(mode)}`
  );

  return (
    <div className="scenario-review-lines">
      <div className="scenario-review-lines__title">
        {I18n.t("dashboard.scenario_review.review")}
      </div>

      <table className={cTable}>
        <Header mode={mode} />
        <Body metric={metric} user={user} />
      </table>
    </div>
  );
};

export default ScenarioContent;
