import React, {Component} from "react";
import PropTypes from "prop-types";

class Loader extends Component {
  render() {
    if (!this.props.isShow) {
      return null;
    }
    return <div className={`loader loader_${this.props.size}`}/>;
  }
}

Loader.defaultProps = {
  size: "medium"
};

Loader.propTypes = {
  isShow: PropTypes.bool,
  size: PropTypes.oneOf(["large", "medium", "small", "extra-small"])
};

export default Loader;
