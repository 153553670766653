import axios from "axios";
import jstz from "jstz";

const instance = axios.create();

export default function webToolApi() {
  instance.defaults.headers.common["X-CSRF-Token"] = Rails.csrfToken();
  instance.defaults.headers.common["Timezone"] = jstz.determine().name();
  return instance;
}
