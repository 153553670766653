import React from "react";
import DatePicker from "react-datepicker";
import classnames from "classnames";

import DateInput from "./DateInput";
import Switch from "./Switch";

const ToggleableDatePicker = ({
  className,
  label,
  minDate,
  name,
  onChange,
  showPopperArrow,
  value
}) => (
  <div className={classnames("form-group form-group_size_sm", className)}>
    <label className="form-group__label form-group__label_width_sm profile-section__label">{label}</label>
    <div className="date-picker">
      <Switch name={name} onChange={onChange} value={value || ""} />
      <label className="date-picker__label">
        {!value && I18n.t("off")}
        {value && (
          <DatePicker
            customInput={<DateInput />}
            dateFormat="yyyy/M/d"
            minDate={minDate}
            onChange={onChange}
            popperModifiers={{
              preventOverflow: {
                enabled: true
              }
            }}
            selected={value ? Date.parse(value) : ""}
            showPopperArrow={showPopperArrow}
          />
        )}
      </label>
    </div>
  </div>
);

export default ToggleableDatePicker;
