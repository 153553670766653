import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {closeModal} from "../../../store/actions/modalsActions";

import BaseModal from "./BaseModal";

class NotificationModal extends Component {
  closeModal = (event) => {
    event && event.preventDefault();
    this.props.onClose && this.props.onClose();
    this.props.closeModal(this.props.modalName);
  };

  render() {
    const {message} = this.props;

    return (
      <BaseModal modalName={this.props.modalName}
                 isRenderButton={this.props.isRenderButton}
                 button={this.props.button}
                 buttonClass={this.props.buttonClass}
                 buttonText={this.props.buttonText}
                 buttonDisabled={this.props.buttonDisabled}
                 buttonTitle={this.props.buttonTitle}
                 contentStyle={this.props.contentStyle}
                 onOpen={this.props.onOpen}
                 onClose={this.props.onClose}>
        <div className="bem-popup__content">
          <div className="bem-popup__message">
            {message}
          </div>
          <div className="bem-popup__notification_actions">
            <button className="button" onClick={this.closeModal} autoFocus>{I18n.t("btn_ok")}</button>
          </div>
        </div>
      </BaseModal>
    );
  }
}

NotificationModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  isRenderButton: PropTypes.bool,
  button: PropTypes.object,
  buttonClass: PropTypes.string,
  buttonText: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  buttonTitle: PropTypes.string,
  contentStyle: PropTypes.object,
  message: PropTypes.string.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  closeModal: PropTypes.func.isRequired
};

const mapActionsToProps = {
  closeModal
};

export default connect(null, mapActionsToProps)(NotificationModal);
