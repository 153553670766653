import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {editUserScenarioPath, packPath} from "../../helpers/routesHelper";

import UserScenarioShow from "../../components/shared/user_scenarios/Show";
import UserScenarioPolicy from "../../policies/UserScenarioPolicy";

class Show extends Component {
  get backPath() {
    if (this.props.backPath) return this.props.backPath;

    const {userScenarios} = this.props;
    const page = userScenarios.pagination.current_page;
    const userScenario = userScenarios.current;
    if (userScenario.id) {
      const packId = userScenario.pack.id;
      return page > 1 ? {pathname: packPath(packId), search: `page=${page}`} : packPath(packId);
    }
    return "";
  }

  get editPath() {
    const {userScenarios, currentUser} = this.props;
    const userScenario = userScenarios.current;
    if (userScenario.id) {
      const canUpdate = new UserScenarioPolicy().Update(userScenario, currentUser);
      if (canUpdate) {
        return editUserScenarioPath(userScenario.id);
      }
    }
    return "";
  }

  render() {
    return (
      <UserScenarioShow
        id={this.props.id}
        currentUser={this.props.currentUser}
        userScenarios={this.props.userScenarios}
        company={this.props.company}
        backPath={this.backPath}
        editPath={this.editPath}
      />
    );
  }
}

Show.propTypes = {
  backPath: PropTypes.string,
  id: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  userScenarios: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.profiles.current,
    userScenarios: state.userScenarios,
    company: state.companies.current
  }
}

export default connect(mapStateToProps)(Show);
