import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import sanitizeHtml from "sanitize-html"

import {MODAL_NAME as CONFIRM_MODAL_NAME} from "./_PackShareConfirmationModal";

import {closeModal, openModal} from "../../store/actions/modalsActions";

import PackShareConfirmationModal from "./_PackShareConfirmationModal";
import FetchPackShares from "../../components/data_loaders/FetchPackShares";

class PackShareNotifications extends Component {
  openPackShareConfirmation = (packShare) => {
    this.props.openModal(CONFIRM_MODAL_NAME, {packShare});
  };

  render() {
    const {company, packShares} = this.props;

    return (
      <div className="pack-share-notifications">
        <FetchPackShares/>
        {packShares.collection.map((packShare) => (
          <div key={packShare.id}
               className="pack-share-notifications__notification"
               onClick={() => this.openPackShareConfirmation(packShare)}
               dangerouslySetInnerHTML={{
                 __html: sanitizeHtml(I18n.t("main_pages.pack_share_notification_html", {
                   company: company.title, pack: packShare.pack.title
                 }), {allowedTags: ["b"]})
              }}
          />
        ))}
        <PackShareConfirmationModal/>
      </div>
    );
  }
}

PackShareNotifications.propTypes = {
  company: PropTypes.object.isRequired,
  packShares: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    company: state.companies.current,
    packShares: state.packShares
  }
}

const mapActionsToProps = {
  openModal,
  closeModal
};

export default connect(mapStateToProps, mapActionsToProps)(PackShareNotifications);
