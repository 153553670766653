import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import {userScenarioPath} from "../../helpers/routesHelper";

import {updateUserScenario} from "../../store/actions/userScenariosActions";

import UserScenarioEdit from "../../components/shared/user_scenarios/Edit";

class Edit extends Component {
  get backPath() {
    return this.props.backPath || userScenarioPath(this.props.id);
  }

  onSubmit = (userScenario) => {
    this.props.updateUserScenario(userScenario, this.props.history, userScenarioPath(this.props.id));
  };

  render() {
    return (
      <UserScenarioEdit
        id={this.props.id}
        user={this.props.currentUser}
        onSubmit={this.onSubmit}
        backPath={this.backPath}
      />
    );
  }
}

Edit.propTypes = {
  backPath: PropTypes.string,
  id: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  updateUserScenario: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    currentUser: state.profiles.current
  }
}

const mapActionsToProps = {
  updateUserScenario
};


export default connect(mapStateToProps, mapActionsToProps)(withRouter(Edit));
