import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {isFetchTagsError} from "../../helpers/errorsHelpers";

import FetchTags from "../../components/data_loaders/FetchTags";
import ApplicationLayout from "../layouts/ApplicationLayout";
import Pagination from "../../components/shared/Pagination";
import Tag from "./_Tag";
import TopPanel from "./_TopPanelIndex";
import {assignTagAttributes} from "../../store/actions/tagsActions";
import {reorderTags} from "../../store/actions/tagsActions";
import SortableTagList from "./SortableTagList";

const Index = ({tags, page = 1}) => {
  const isLoaded = tags.isCollectionLoaded;
  const isLoadingError = isFetchTagsError(tags);
  const isLoading = (!isLoaded && !isLoadingError) || tags.isLoading;
  const attributes = tags.formAttributes;

  return (
    <ApplicationLayout headerTitle={I18n.t("tags.titles.index")} isLoading={isLoading}>
      <FetchTags />
      <div className="container">
        <div className="row py-4">
          <div className="col-12">
            <TopPanel />
            <div className="pt-3">

              <SortableTagList
                tags={tags}
                formAttributes={tags.formAttributes}
                assignTagAttributes={assignTagAttributes}
                reorderTags={reorderTags}
              />

            </div>
          </div>
        </div>
      </div>
    </ApplicationLayout>
  );
};

function mapStateToProps(state) {
  return {
    tags: state.tags
  };
}

const mapActionsToProps = {
  assignTagAttributes
};

export default connect(mapStateToProps, mapActionsToProps)(Index);
