import {Link} from "react-router-dom";
import {dashboardUserPath} from "helpers/routesHelper";
import {isActive} from "helpers/userStatusesHelper";
import React from "react";
import classnames from "classnames";

import {LazyLoadImage} from "react-lazy-load-image-component";

const UserCell = ({groupId, user}) => {
  const inactiveLabel = isActive(user) ? "" : " (inactive)";

  return (
    <div
      className={classnames("activity-stats__user", {
        "activity-stats__user--inactive": !isActive(user)
      })}
    >
      <div className="activity-stats__user-avatar avatar">
        <LazyLoadImage src={user.avatar.thumb_url} />
      </div>

      <div className="activity-stats__user-name">
        <Link
          className="activity-stats__user-full-name"
          target="_blank"
          to={dashboardUserPath(user.id, groupId)}
        >
          {user.full_name}
        </Link>
        <div className="activity-stats__user-email">
          {user.email}
          {inactiveLabel}
        </div>
      </div>
    </div>
  );
};

export default UserCell;
