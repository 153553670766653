import React from "react";
import _ from "lodash";

import {isListeningPracticeMode} from "../../../../helpers/submodesHelper";
import Score from "./Score";
import Status from "./Status";

const Results = ({metric}) => {
  const completedScenario = metric.completed_scenario;
  const isListeningPractice = isListeningPracticeMode(completedScenario);
  const enabledScores = isListeningPractice ? [] : _.filter(metric.results, {enabled: true});
  const optionalScores = isListeningPractice ? [] : _.filter(metric.results, {enabled: false});

  return (
    <div className="scenario-review-results">
      <div className="scenario-review-results__required">
        <div className="scenario-review-results__title">
          {I18n.t("dashboard.scenario_review.result")}
        </div>
        <Status isPassed={completedScenario.passed} />
        {enabledScores.map(score => (
          <Score key={score.metric} completedScenario={completedScenario} score={score} />
        ))}
      </div>
      <div className="scenario-review-results__optional">
        <div className="scenario-review-results__title">
          {I18n.t("dashboard.scenario_review.optional_metrics")}
        </div>
        {optionalScores.map(score => (
          <Score key={score.metric} completedScenario={completedScenario} score={score} />
        ))}
      </div>
    </div>
  );
};

export default Results;
