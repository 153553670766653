import _ from "lodash";

export const ACTIONS = {
  REGISTER_AUDIO_PLAYER: "REGISTER_AUDIO_PLAYER",
  PLAY_AUDIO: "PLAY_AUDIO",
  PAUSE_AUDIO: "PAUSE_AUDIO",
  MOVE_AUDIO_PLAY_HEAD: "MOVE_AUDIO_PLAY_HEAD",
  FINISH_MOVING_AUDIO_PLAY_HEAD: "FINISH_MOVING_AUDIO_PLAY_HEAD",
  PAUSE_CURRENT_AUDIO: "PAUSE_CURRENT_AUDIO",
  UPDATE_AUDIO_DURATION: "UPDATE_AUDIO_DURATION",
  UPDATE_AUDIO_CURRENT_TIME: "UPDATE_AUDIO_CURRENT_TIME"
};

const INITIAL_AUDIO_PLAYER_DATA = {
  id: "",
  player: null,
  url: "",
  isPlaying: false,
  wasPlaying: false
};

export default function reducer(state = {
  currentId: "",
  collection: []
}, action) {
  switch (action.type) {
    case ACTIONS.REGISTER_AUDIO_PLAYER: {
      if (getPlayerData(state, action.id)) {
        return state;
      }

      const playerData = {...INITIAL_AUDIO_PLAYER_DATA, id: action.id, isPlaying: false, player: action.player};
      return {...state, collection: [...state.collection, playerData]};
    }

    case ACTIONS.PLAY_AUDIO: {
      const currentPlayerData = getPlayerData(state, state.currentId);
      let newState = state;
      if (currentPlayerData) {
        if (currentPlayerData.id === action.id && currentPlayerData.url === action.url && currentPlayerData.isPlaying) {
          return state;
        }
        currentPlayerData.player.pause();
        newState = updatePlayerData(newState, {...currentPlayerData, isPlaying: false});
      }

      const playerData = getPlayerData(state, action.id);
      if (playerData) {
        const player = playerData.player;
        if (player.src !== action.url) {
          player.src = action.url;
        }
        player.play();
        newState = updatePlayerData(newState, {...playerData, url: action.url, isPlaying: true, wasPlaying: true});
        return {...newState, currentId: playerData.id};
      }

      return newState;
    }

    case ACTIONS.PAUSE_AUDIO: {
      const currentPlayerData = getPlayerData(state, state.currentId);
      if (currentPlayerData && currentPlayerData.id === action.id && currentPlayerData.isPlaying) {
        currentPlayerData.player.pause();
        return updatePlayerData(state, {...currentPlayerData, isPlaying: false});
      }
      return state;
    }

    case ACTIONS.UPDATE_AUDIO_DURATION: {
      const playerData = getPlayerData(state, action.id);
      if (playerData) {
        return updatePlayerData(state, {...playerData, duration: action.duration});
      }
      return state;
    }

    case ACTIONS.UPDATE_AUDIO_CURRENT_TIME: {
      const playerData = getPlayerData(state, action.id);
      if (playerData && !playerData.isPlayHeadDragging) {
        playerData.currentTime = Math.min(action.currentTime, playerData.duration);
        if (playerData.currentTime === playerData.duration) {
          playerData.isPlaying = false;
          playerData.player.stop();
        }
        return updatePlayerData(state, playerData);
      }
      return state;
    }

    case ACTIONS.MOVE_AUDIO_PLAY_HEAD: {
      const playerData = getPlayerData(state, action.id);
      if (playerData) {
        const currentTime = getTimeFromPercentage(action.percentage, playerData.duration);
        return updatePlayerData(state, {...playerData, currentTime, isPlayHeadDragging: true});
      }
      return state;
    }

      case ACTIONS.FINISH_MOVING_AUDIO_PLAY_HEAD: {
      const playerData = getPlayerData(state, action.id);
      if (playerData) {
        const currentTime = getTimeFromPercentage(action.percentage, playerData.duration);
        playerData.player.currentTime = currentTime;
        if (currentTime === playerData.duration) {
          playerData.isPlaying = false;
          playerData.player.stop();
        }
        return updatePlayerData(state, {...playerData, currentTime, isPlayHeadDragging: false});
      }
      return state;
    }
  }
  return state;
}

function getPlayerData(state, id) {
  const playerData = _.find(state.collection, {id});
  return playerData ? {...playerData} : null;
}

function updatePlayerData(state, newPlayerData) {
  const foundPlayerDataIndex = _.findIndex(state.collection, {id: newPlayerData.id});
  const newCollection = [...state.collection];
  newCollection[foundPlayerDataIndex] = newPlayerData;
  return {...state, collection: newCollection};
}

function getTimeFromPercentage(percentage, duration) {
  let currentTime = duration * percentage / 100;
  currentTime = Math.min(currentTime, duration);
  return Math.max(0, currentTime);
}
