import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";

import {isFetchPackCategoryError, isFetchPacksError} from "../../helpers/errorsHelpers";

import {assignPackAttributes, deselectAllPacks} from "../../store/actions/packsActions";

import ApplicationLayout from "../layouts/ApplicationLayout";
import TopPanel from "./TopPanel";
import Pagination from "../../components/shared/Pagination";
import Pack from "./_Pack";
import FetchPackCategory from "../../components/data_loaders/FetchPackCategory";
import FetchPacks from "../../components/data_loaders/FetchPacks";
import PackShareModal from "../packs/_PackShareModal";

class Show extends Component {
  componentDidMount() {
    this.props.deselectAllPacks();
  }

  render() {
    const {id, page, packCategories, packs, currentUser} = this.props;
    const {selectedIds} = packs;
    const packCategory = packCategories.current;

    const isLoaded = packCategory.id && packs.isCollectionLoaded && packs.categoryId === id;
    const isLoadingError = isFetchPackCategoryError(packCategories) || isFetchPacksError(packs);
    const isLoading = (!isLoaded && !isLoadingError) || packs.isLoading;

    return (
      <ApplicationLayout headerTitle={I18n.t("packs.titles.index")} isLoading={isLoading}>
        <FetchPackCategory id={id} />
        <FetchPacks categoryId={id} page={page} />
        {isLoaded && (
          <div className="container">
            <div className="row py-4">
              <div className="col-12">
                <TopPanel categoryId={id} />
                <div className="pt-3">
                  {packs.collection.map(pack => (
                    <Pack key={pack.id} categoryId={id} currentUser={currentUser} pack={pack} />
                  ))}
                  {!_.isEmpty(selectedIds) && (
                    <div className="batch-select-actions">
                      <div className="batch-select-actions__count-label">
                        {I18n.t("user_scenarios.batch_selected_label", {value: selectedIds.length})}
                      </div>
                      <PackShareModal
                        button={
                          <button className="batch-select-actions__button button button_size_xs">
                            {I18n.t("packs.popups.pack_share.send_invite")}
                          </button>
                        }
                        mode="batch"
                        packIds={selectedIds}
                      />
                    </div>
                  )}
                  <div className="page-index-bottom">
                    <Pagination pagination={packs.pagination} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ApplicationLayout>
    );
  }
}

Show.defaultProps = {
  page: 1
};

Show.propTypes = {
  currentUser: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  packCategories: PropTypes.object.isRequired,
  packs: PropTypes.object.isRequired,
  page: PropTypes.number
};

function mapStateToProps(state) {
  return {
    currentUser: state.profiles.current,
    packCategories: state.packCategories,
    packs: state.packs
  };
}

const mapActionsToProps = {
  assignPackAttributes,
  deselectAllPacks
};

export default connect(mapStateToProps, mapActionsToProps)(Show);
