import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Truncate from "react-truncate";

import {userPath} from "../../helpers/routesHelper";

class User extends Component {
  render() {
    const {user} = this.props;

    return (
      <Link to={userPath(user.id)} className="list-item" key={user.id}>
        <div className="list-item__avatar avatar">
          <img src={user.avatar.thumb_url} alt=""/>
        </div>
        <div className="list-item__content">
          <div className="list-item__title" title={user.full_name}>
            <Truncate lines={2}>
              {user.full_name}
            </Truncate>
          </div>
        </div>
      </Link>
    )
  }
}

User.propTypes = {
  user: PropTypes.object.isRequired
};

export default User;
