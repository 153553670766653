import React, {useCallback} from "react";
import _ from "lodash";

import ConfirmationModal from "../../modals/ConfirmationModal";
import ContextMenuItem from "../../context_menu/ContextMenuItem";
import NotificationModal from "../../modals/NotificationModal";

const MODAL_NAME = "USER_SCENARIO_DELETE_LINE_MODAL";

const Button = props => (
  <ContextMenuItem color="red" {...props}>
    {I18n.t("delete")}
  </ContextMenuItem>
);

const DeleteLineModal = ({lineId, formAttributes, assignUserScenarioAttributes}) => {
  const modalName = `${MODAL_NAME}_${lineId}`;

  const handleSubmit = useCallback(
    closeModal => {
      const changedLines = _.reject(formAttributes.lines, {id: lineId});
      assignUserScenarioAttributes({...formAttributes, lines: changedLines});
      closeModal();
    },
    [assignUserScenarioAttributes, formAttributes, lineId]
  );

  const isLastLine = formAttributes.lines.length === 1;

  if (isLastLine) {
    return (
      <NotificationModal
        button={<Button />}
        message={I18n.t("user_scenarios.popups.last_line_message")}
        modalName={MODAL_NAME}
      />
    );
  }

  return (
    <ConfirmationModal
      button={<Button />}
      message={I18n.t("user_scenarios.popups.confirm_delete_line")}
      modalName={modalName}
      onSubmit={handleSubmit}
    />
  );
};

export default DeleteLineModal;
