/* eslint-disable */
import React, {memo} from "react";

const fn = ({background, className}) => (
  <svg className={className} width="12px" height="11px" viewBox="0 0 12 11" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g className={background} id="block" transform="translate(-717.000000, -14.000000)" fill="#E0DDD9">
        <polygon id="star" points="723 23 719.473288 24.854102 720.14683 20.927051 717.293661 18.145898 721.236644 17.572949 723 14 724.763356 17.572949 728.706339 18.145898 725.85317 20.927051 726.526712 24.854102"/>
      </g>
    </g>
  </svg>
);

export default memo(fn);

