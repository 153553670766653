import {ACTIONS} from "../reducers/courseCategoriesReducer";

import webToolApi from "../../helpers/webToolApiHelper";

import {defaultErrorAction} from "./errorsActions";
import {notifyResponseError} from "./notificationsActions";

export function changeCourseCategoriesLoading(isLoading) {
  return {type: ACTIONS.CHANGE_COURSE_CATEGORIES_LOADING, isLoading};
}

export function fetchCourseCategories() {
  return (dispatch) => {
    dispatch(changeCourseCategoriesLoading(true));
    dispatch({type: ACTIONS.FETCH_COURSE_CATEGORIES});

    webToolApi().get(Routes.course_categories_path())
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_COURSE_CATEGORIES_FULFILLED, payload: response.data});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_COURSE_CATEGORIES_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("courses.categories.messages.index.error"), response));
      })
      .finally(() => {
        dispatch(changeCourseCategoriesLoading(false));
      });
  }
}

export function fetchCourseCategory(id, courseCategories = []) {
  return (dispatch) => {
    const foundCourseCategory = _.find(courseCategories, {id});
    if (foundCourseCategory) {
      return dispatch({type: ACTIONS.FETCH_COURSE_CATEGORY_FULFILLED, courseCategory: foundCourseCategory});
    }

    dispatch(changeCourseCategoriesLoading(true));
    dispatch({type: ACTIONS.FETCH_COURSE_CATEGORY});

    webToolApi().get(Routes.course_category_path(id))
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_COURSE_CATEGORY_FULFILLED, courseCategory: response.data.course_category});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_COURSE_CATEGORY_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("courses.categories.messages.show.error"), response));
      })
      .finally(() => {
        dispatch(changeCourseCategoriesLoading(false));
      });
  }
}
