import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {closeModal, openModal} from "../../store/actions/modalsActions";

import BaseModal from "../../components/shared/modals/BaseModal";
import {confirmPackShare} from "../../store/actions/packSharesActions";

export const MODAL_NAME = "PACK_SHARE_CONFIRMATION_MODAL";

class PackShareConfirmationModal extends Component {
  onConfirmedChange = (e) => {
    const {options} = this.props.modals;
    this.props.openModal(MODAL_NAME, {...options, confirmed: e.target.checked});
  };

  closeModal = () => {
    this.props.closeModal(MODAL_NAME);
  };

  submitConfirmation = () => {
    const {modals, company, confirmPackShare} = this.props;
    const {packShare} = modals.options;
    confirmPackShare(packShare, company, {
      onSuccess: this.closeModal,
      onError: this.closeModal
    });
  };

  render() {
    const {packShare, confirmed} = this.props.modals.options;

    if (!packShare) {
      return null;
    }

    const packTitle = packShare.pack.title;
    const {isLoading} = this.props.packShares;

    return (
      <BaseModal modalName={MODAL_NAME} isRenderButton={false} contentStyle={{width: "550px"}}>
        <div className="bem-popup__content">
          <div className="pack-share-confirmation__description">
            {I18n.t("main_pages.popups.pack_share_confirmation.description", {title: packTitle})}
          </div>
          <div className="pack-share-confirmation__confirmation">
            <input
              type="checkbox"
              id="invite_confirmation"
              className="form-check-input"
              autoFocus
              onChange={this.onConfirmedChange}
            />
            <label htmlFor="invite_confirmation" className="form-check-label">
              {I18n.t("main_pages.popups.pack_share_confirmation.confirmation_label", {title: packTitle})}
            </label>
          </div>
          <div className="pack-share-confirmation__actions">
            <button className="pack-share-confirmation__button button button_color_grey"
                    onClick={this.closeModal}>
              {I18n.t("cancel")}
            </button>
            <button className="pack-share-confirmation__button button ml-3"
                    onClick={this.submitConfirmation}
                    disabled={!confirmed || isLoading}>
              {I18n.t("create")}
            </button>
          </div>
        </div>
      </BaseModal>
    );
  }
}

PackShareConfirmationModal.propTypes = {
  company: PropTypes.object.isRequired,
  packShares: PropTypes.object.isRequired,
  modals: PropTypes.shape({
    options: PropTypes.shape({
      packShare: PropTypes.object,
      confirmed: PropTypes.bool
    })
  }),
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  confirmPackShare: PropTypes.func.isRequired
};


function mapStateToProps(state) {
  return {
    company: state.companies.current,
    packShares: state.packShares,
    modals: state.modals
  }
}

const mapActionsToProps = {
  openModal,
  closeModal,
  confirmPackShare
};

export default connect(mapStateToProps, mapActionsToProps)(PackShareConfirmationModal);
