import {SUCCESS_STATUS_CODE} from "../../constants";

export const ACTIONS = {
  CHANGE_PACK_SHARES_LOADING: "CHANGE_PACK_SHARES_LOADING",
  FETCH_PACK_SHARES: "FETCH_PACK_SHARES",
  FETCH_PACK_SHARES_FULFILLED: "FETCH_PACK_SHARES_FULFILLED",
  FETCH_PACK_SHARES_REJECTED: "FETCH_PACK_SHARES_REJECTED",
  CREATE_PACK_SHARE_FULFILLED: "CREATE_PACK_SHARE_FULFILLED",
  CONFIRM_PACK_SHARE_FULFILLED: "CONFIRM_PACK_SHARE_FULFILLED",
  DESTROY_PACK_SHARE_FULFILLED: "DESTROY_PACK_SHARE_FULFILLED"
};

const DEFAULT_SUCCESS_STATUS = {errors: {}, status: SUCCESS_STATUS_CODE};
const DEFAULT_STATUSES = {
  [ACTIONS.FETCH_PACK_SHARES]: {errors: {}, status: null}
};

export default function reducer(state = {
  collection: [],
  isCollectionLoaded: false,
  isLoading: false,
  loadingCount: 0,
  statuses: DEFAULT_STATUSES
}, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_PACK_SHARES_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);
      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.FETCH_PACK_SHARES: {
      return {...state, userId: "", collection: [], isCollectionLoaded: false};
    }
    case ACTIONS.FETCH_PACK_SHARES_FULFILLED: {
      return {...state,
        collection: action.payload.pack_shares,
        isCollectionLoaded: true,
        statuses: {...state.statuses, [ACTIONS.FETCH_PACK_SHARES]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_PACK_SHARES_REJECTED: {
      return {
        ...state,
        statuses: {...state.statuses, [ACTIONS.FETCH_PACK_SHARES]: {errors: action.errors, status: action.status}}
      };
    }

    case ACTIONS.CREATE_PACK_SHARE_FULFILLED: {
      return {...state, isCollectionLoaded: false, collection: []};
    }

    case ACTIONS.CONFIRM_PACK_SHARE_FULFILLED: {
      return {...state, isCollectionLoaded: false, collection: []};
    }

    case ACTIONS.DESTROY_PACK_SHARE_FULFILLED: {
      return {...state, isCollectionLoaded: false, collection: []};
    }
  }
  return state;
}
