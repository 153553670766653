import React, {Component} from "react";
import PropTypes from "prop-types";
import Select2 from "react-select2-wrapper";
import {Link} from "react-router-dom";
import moment from "moment-timezone";
import _ from "lodash";

import {enumI18n} from "helpers/enumI18nHelper";

import {enumOptionsForSelect} from "helpers/enumI18nHelper";
import {isStudent} from "helpers/userRolesHelper";
import handleFieldChange from "helpers/handleFieldChange";

import Switch from "components/shared/Switch";
import Avatar from "./_Avatar";

class Form extends Component {
  onChange = event => {
    const {formAttributes, assignProfileAttributes} = this.props;
    assignProfileAttributes({...formAttributes, ...handleFieldChange(event, formAttributes)});
  };

  onShowInitialsChange = () => {
    const attributes = this.props.formAttributes;
    const changedAttributes = {...attributes, show_initials_only: !attributes.show_initials_only};
    this.props.assignProfileAttributes(changedAttributes);
  };

  isValidEmail = email => {
    return /\S+@\S+\.\S+/.test(String(email));
  };

  isEnglish = name => {
    return /^[a-z -]+$/i.test(name);
  };

  isValid() {
    const attributes = this.props.formAttributes;
    const errors = {};
    const requiredFields = [
      "email",
      "first_name",
      "first_name_ja",
      "gender",
      "language_code",
      "last_name",
      "last_name_ja",
      "username"
    ];

    if (!this.isValidEmail(attributes.email)) {
      errors.email = [I18n.t("errors.messages.invalid")];
    }

    _.each(["first_name", "last_name"], field => {
      if (!this.isEnglish(attributes[field])) {
        errors[field] = [I18n.t("errors.messages.invalid_characters")];
      }
    });

    if (isStudent(attributes)) {
      requiredFields.push("pin_code");
    }

    _.each(
      requiredFields,
      field => {
        if (!attributes[field].trim()) {
          errors[field] = [I18n.t("errors.messages.blank")];
        }
      }
    );

    this.props.assignProfileAttributes({...attributes, errors});

    return _.isEmpty(errors);
  }

  renderErrors(name) {
    const errors = this.props.formAttributes.errors[name];
    if (_.isEmpty(errors)) return null;

    return <div className="profile-section__error">{errors.join(", ")}</div>;
  }

  onSubmit = event => {
    event.preventDefault();

    const {formAttributes, onSubmit, notifyNotSaved} = this.props;
    this.isValid() ? onSubmit(_.omit(formAttributes, "errors")) : notifyNotSaved();
  };

  render() {
    const {company, backPath, isLoading, assignProfileAttributes} = this.props;
    const attributes = this.props.formAttributes;

    return (
      <form>
        <Avatar assignAttributes={assignProfileAttributes} isUploadable user={attributes} />
        <div className="profile-section__title">{I18n.t("users.sections.general_information")}</div>
        <div className="profile-section__field">
          <label className="profile-section__label">{I18n.t("mongoid.attributes.user.id")}</label>
          <span className="textright">{attributes.id}</span>
        </div>
        <div className="profile-section__field">
          <label className="profile-section__label">
            {I18n.t("mongoid.attributes.user.company")}
          </label>
          <span className="textright">{company.title}</span>
        </div>
        <div className="profile-section__field">
          <label className="profile-section__label">
            {I18n.t("mongoid.attributes.user.type")}
          </label>
          {enumI18n("user", "role", attributes.role)}
        </div>
        <div className="profile-section__title">{I18n.t("users.sections.login_info")}</div>
        <div className="profile-section__field">
          <label className="profile-section__label required" htmlFor="email">
            {I18n.t("mongoid.attributes.user.email")}
          </label>
          <input
            className="profile-section__input"
            id="email"
            name="email"
            onChange={this.onChange}
            type="email"
            value={attributes.email}
          />
          {this.renderErrors("email")}
        </div>
        <div className="profile-section__field">
          <label className="profile-section__label required" htmlFor="username">
            {I18n.t("mongoid.attributes.user.username")}
          </label>
          <label>{attributes.username}</label>
        </div>
        <div className="profile-section__field">
          <label className="profile-section__label required" htmlFor="password">
            {I18n.t("mongoid.attributes.user.new_password")}
          </label>
          <input
            autoComplete="new-password"
            className="profile-section__input"
            id="password"
            name="password"
            onChange={this.onChange}
            type="password"
            value={attributes.password}
          />
          {this.renderErrors("password")}
        </div>
        <div className="profile-section__field">
          <label className="profile-section__label required" htmlFor="password_confirmation">
            {I18n.t("mongoid.attributes.user.confirm_password")}
          </label>
          <input
            autoComplete="new-password"
            className="profile-section__input"
            id="password_confirmation"
            name="password_confirmation"
            onChange={this.onChange}
            type="password"
            value={attributes.password_confirmation}
          />
          {this.renderErrors("password_confirmation")}
        </div>

        {isStudent(attributes) && (
        <div className="profile-section__field">
          <label className="profile-section__label required" htmlFor="pin_code">
            {I18n.t("mongoid.attributes.user.pin_code")}
          </label>
          <input
            className="profile-section__input"
            id="pin_code"
            name="pin_code"
            onChange={this.onChange}
            type="text"
            value={attributes.pin_code}
          />
          {this.renderErrors("pin_code")}
        </div>
        )}

        <div className="profile-section__title">{I18n.t("users.sections.user_info")}</div>
        <div className="profile-section__field">
          <label className="profile-section__label required" htmlFor="first_name">
            {I18n.t("mongoid.attributes.user.first_name")}
          </label>
          <input
            className="profile-section__input"
            id="first_name"
            name="first_name"
            onChange={this.onChange}
            type="text"
            value={attributes.first_name}
          />
          {this.renderErrors("first_name")}
        </div>
        <div className="profile-section__field">
          <label className="profile-section__label required" htmlFor="last_name">
            {I18n.t("mongoid.attributes.user.last_name")}
          </label>
          <input
            className="profile-section__input"
            id="last_name"
            name="last_name"
            onChange={this.onChange}
            type="text"
            value={attributes.last_name}
          />
          {this.renderErrors("last_name")}
        </div>
        <div className="profile-section__field">
          <label className="profile-section__label required" htmlFor="first_name_ja">
            {I18n.t("mongoid.attributes.user.first_name_ja")}
          </label>
          <input
            className="profile-section__input"
            id="first_name_ja"
            name="first_name_ja"
            onChange={this.onChange}
            type="text"
            value={attributes.first_name_ja}
          />
          {this.renderErrors("first_name_ja")}
        </div>
        <div className="profile-section__field">
          <label className="profile-section__label required" htmlFor="last_name_ja">
            {I18n.t("mongoid.attributes.user.last_name_ja")}
          </label>
          <input
            className="profile-section__input"
            id="last_name_ja"
            name="last_name_ja"
            onChange={this.onChange}
            type="text"
            value={attributes.last_name_ja}
          />
          {this.renderErrors("last_name_ja")}
        </div>
        {isStudent(attributes) && (
        <div className="profile-section__field align-items-start align-items-md-center">
          <label className="profile-section__label">
            {I18n.t("mongoid.attributes.user.show_initials_only")}
          </label>
          <span className="textright d-md-flex align-items-center">
            <Switch
              name="show_initials_only"
              onChange={this.onShowInitialsChange}
              value={attributes.show_initials_only}
            />
            <label>{I18n.t("mongoid.attributes.user.initials_info")}</label>
          </span>
        </div>
        )}

        <div className="profile-section__field">
          <label className="profile-section__label" htmlFor="language_code">
            {I18n.t("mongoid.attributes.user.language_code")}
          </label>
          <Select2
            className="profile-section__select select2-in"
            data={enumOptionsForSelect("user", "language_code")}
            id="language_code"
            name="language_code"
            onChange={this.onChange}
            options={{
              minimumResultsForSearch: -1,
              theme: "size--sm"
            }}
            value={attributes.language_code}
          />
          {this.renderErrors("language_code")}
        </div>
        <div className="profile-section__title">{I18n.t("users.sections.other_info")}</div>
        <div className="profile-section__field">
          <label className="profile-section__label" htmlFor="gender">
            {I18n.t("mongoid.attributes.user.gender")}
          </label>
          <Select2
            className="profile-section__select select2-in"
            data={enumOptionsForSelect("user", "gender")}
            id="gender"
            name="gender"
            onChange={this.onChange}
            options={{
              minimumResultsForSearch: -1,
              theme: "size--sm"
            }}
            value={attributes.gender}
          />
          {this.renderErrors("gender")}
        </div>
        <div className="profile-section__field align-items-start align-items-md-center">
          <label className="profile-section__label">
            {I18n.t("mongoid.attributes.user.birthday")}
          </label>
          <span className="textright d-md-flex">
            <Select2
              className="profile-section__select profile-section__select--multiple select2-in"
              data={[
                "",
                ..._.map(_.range(1, 13), month => ({
                  id: month,
                  text: I18n.t("date.abbr_month_names")[month]
                }))
              ]}
              id="birthday_hash.birthday(2i)"
              name="birthday_hash.birthday(2i)"
              onChange={this.onChange}
              options={{
                minimumResultsForSearch: -1,
                theme: "size--sm"
              }}
              value={attributes.birthday_hash["birthday(2i)"]}
            />
            <span className="mb-2 d-block d-md-none"></span>
            <Select2
              className="profile-section__select profile-section__select--multiple select2-in"
              data={["", ..._.range(1, 32)]}
              id="birthday_hash.birthday(3i)"
              name="birthday_hash.birthday(3i)"
              onChange={this.onChange}
              options={{
                minimumResultsForSearch: -1,
                theme: "size--sm"
              }}
              value={attributes.birthday_hash["birthday(3i)"]}
            />
            <span className="mb-2 d-block d-md-none"></span>
            <Select2
              className="profile-section__select profile-section__select--multiple select2-in"
              data={["", ..._.range(moment().year(), moment().year() - 100, -1)]}
              id="birthday_hash.birthday(1i)"
              name="birthday_hash.birthday(1i)"
              onChange={this.onChange}
              options={{
                minimumResultsForSearch: -1,
                theme: "size--sm"
              }}
              value={attributes.birthday_hash["birthday(1i)"]}
            />
            {this.renderErrors("birthday")}
          </span>
        </div>
        <div className="d-flex mt-5">
          <Link className="button button_color_grey mr-4 w-100" to={backPath}>
            {I18n.t("btn_cancel")}
          </Link>
          <button
            className="button w-100"
            disabled={isLoading}
            onClick={this.onSubmit}
            type="submit"
          >
            {I18n.t("btn_save")}
          </button>
        </div>
      </form>
    );
  }
}

Form.propTypes = {
  assignProfileAttributes: PropTypes.func.isRequired,
  backPath: PropTypes.string.isRequired,
  company: PropTypes.object.isRequired,
  formAttributes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  notifyNotSaved: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default Form;
