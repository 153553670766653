import {PunditComponent} from "react-pundit";

class VisibleIfNot extends PunditComponent {
  static displayName = "VisibleIfNot";

  render() {
    if (!this.passesPermissions()) {
      return this.props.children;
    }
    return null;
  }
}

export default VisibleIfNot;
