import {ACTIONS} from "../reducers/tagsReducer";

import webToolApi from "../../helpers/webToolApiHelper";
import {tagPath, tagsPath} from "../../helpers/routesHelper";

import {defaultErrorAction} from "./errorsActions";
import {notifyResponseError, notifySuccess} from "./notificationsActions";

export function changeTagsLoading(isLoading) {
  return {type: ACTIONS.CHANGE_TAGS_LOADING, isLoading};
}

export function fetchTags({page} = {}) {
  return (dispatch) => {
    dispatch(changeTagsLoading(true));
    dispatch({type: ACTIONS.FETCH_TAGS});

    webToolApi().get(Routes.tags_path({page}))
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_TAGS_FULFILLED, payload: response.data, page});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_TAGS_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("tags.messages.index.error"), response));
      })
      .finally(() => {
        dispatch(changeTagsLoading(false));
      });
  }
}

export function reorderTags(oldIndex, newIndex, history) {
  return (dispatch) => {
    dispatch(changeTagsLoading(true));
    dispatch({type: ACTIONS.REORDER_TAGS});

    webToolApi().put(Routes.reorder_tag_path({oldIndex, newIndex}))
      .then((response) => {
        dispatch({type: ACTIONS.REORDER_TAGS_FULFILLED});
        dispatch(notifySuccess(I18n.t("tags.messages.reorder.success")));
        dispatch({type: ACTIONS.FETCH_TAGS});
        dispatch(changeTagsLoading(false));
      })
      .catch(({response}) => {
        console.log(response);
        dispatch(defaultErrorAction(ACTIONS.REORDER_TAGS_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("tags.messages.reorder.error"), response));
        dispatch(changeTagsLoading(false));
      });

    dispatch(fetchTags());
  }
}

export function fetchTag(id) {
  return (dispatch) => {
    dispatch(changeTagsLoading(true));
    dispatch({type: ACTIONS.FETCH_TAG});

    webToolApi().get(Routes.tag_path(id))
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_TAG_FULFILLED, tag: response.data.tag});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_TAG_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("tags.messages.show.error"), response));
      })
      .finally(() => {
        dispatch(changeTagsLoading(false));
      });
  }
}

export function assignTagAttributes(attributes) {
  return {type: ACTIONS.ASSIGN_TAG_ATTRIBUTES, attributes};
}

export function clearTagAttributes(defaultAttributes = {}) {
  return {type: ACTIONS.CLEAR_TAG_ATTRIBUTES, defaultAttributes};
}

export function createTag(tag, history) {
  return (dispatch) => {
    dispatch(changeTagsLoading(true));

    webToolApi().post(Routes.tags_path(), {tag})
      .then((response) => {
        dispatch({type: ACTIONS.CREATE_TAG_FULFILLED, tag: response.data.tag});
        dispatch(notifySuccess(I18n.t("tags.messages.create.success")));
        dispatch(clearTagAttributes());
        dispatch(changeTagsLoading(false));
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.CREATE_TAG_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("tags.messages.create.error"), response));
        dispatch(changeTagsLoading(false));
      });
  }
}

export function updateTag(tag, {onSuccess} = {}) {
  return (dispatch) => {
    dispatch(changeTagsLoading(true));

    webToolApi().put(Routes.tag_path(tag.id), {tag})
      .then((response) => {
        dispatch({type: ACTIONS.UPDATE_TAG_FULFILLED, tag: response.data.tag});
        dispatch(notifySuccess(I18n.t("tags.messages.update.success")));
        dispatch(clearTagAttributes());
        dispatch(changeTagsLoading(false));
        onSuccess && onSuccess();
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.UPDATE_TAG_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("tags.messages.update.error"), response));
        dispatch(changeTagsLoading(false));
      });
  }
}

export function destroyTag(tagId) {
  return (dispatch) => {
    dispatch(changeTagsLoading(true));

    webToolApi().delete(Routes.tag_path(tagId))
      .then(() => {
        dispatch({type: ACTIONS.DESTROY_TAG_FULFILLED, tagId});
        dispatch(notifySuccess(I18n.t("tags.messages.destroy.success")));
      })
      .catch(({response}) => {
        dispatch(notifyResponseError(I18n.t("tags.messages.destroy.error"), response));
      })
      .finally(() => {
        dispatch(changeTagsLoading(false));
      });
  }
}

export function setTagRemovingState(tagId, removingState) {
  return {type: ACTIONS.SET_TAG_REMOVING_STATE, tagId, removingState};
}
