import React from "react";
import {SortableElement} from "react-sortable-hoc";

import Course from "./Course";

const SortableCourse = SortableElement(({course, groupId}) => {
  return <Course course={course} groupId={groupId} />;
});

export default SortableCourse;
