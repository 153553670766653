import {ACTIONS} from "../reducers/passwordsReducer";

import {afterEditPasswordPath, afterNewPasswordPath} from "helpers/routesHelper";
import webToolApi from "helpers/webToolApiHelper";

import {notifyResponseError} from "./notificationsActions";
import {defaultErrorAction} from "./errorsActions";

export function changePasswordsLoading(isLoading) {
  return {isLoading, type: ACTIONS.CHANGE_PASSWORDS_LOADING};
}

export function enablePasswordsSubmit() {
  return {type: ACTIONS.ENABLE_PASSWORDS_SUBMIT};
}

export function disablePasswordsResetCaptcha() {
  return {type: ACTIONS.DISABLE_PASSWORDS_RESET_CAPTCHA};
}

export function resetPasswordsCaptcha() {
  return {type: ACTIONS.RESET_PASSWORDS_CAPTCHA};
}

export function assignPasswordAttributes(attributes) {
  return {attributes, type: ACTIONS.ASSIGN_PASSWORD_ATTRIBUTES};
}

export function clearPasswordAttributes() {
  return {type: ACTIONS.CLEAR_PASSWORD_ATTRIBUTES};
}

export function createPassword(user, history) {
  return dispatch => {
    dispatch(changePasswordsLoading(true));

    webToolApi()
      .post(Routes.user_password_path(), {user})
      .then(() => {
        dispatch({type: ACTIONS.CREATE_PASSWORD_FULFILLED});
        dispatch(clearPasswordAttributes());
        history.push(afterNewPasswordPath(user.email));
      })
      .catch(({response}) => {
        dispatch(resetPasswordsCaptcha(true));
        dispatch(defaultErrorAction(ACTIONS.CREATE_PASSWORD_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("passwords.messages.create.error"), response));
      })
      .finally(() => {
        dispatch(changePasswordsLoading(false));
      });
  };
}

export function updatePassword(user, history) {
  return dispatch => {
    dispatch(changePasswordsLoading(true));

    webToolApi()
      .put(Routes.user_password_path(), {user})
      .then(() => {
        dispatch({type: ACTIONS.UPDATE_PASSWORD_FULFILLED});
        dispatch(clearPasswordAttributes());
        history.push(afterEditPasswordPath());
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.UPDATE_PASSWORD_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("passwords.messages.update.error"), response));
      })
      .finally(() => {
        dispatch(changePasswordsLoading(false));
      });
  };
}
