import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import moment from "moment-timezone";

import {enumI18n} from "../../helpers/enumI18nHelper";
import {usersPath, editUserPath} from "../../helpers/routesHelper";
import {isFetchUserError} from "../../helpers/errorsHelpers";

import ApplicationLayout from "../layouts/ApplicationLayout";
import FetchUser from "../../components/data_loaders/FetchUser";
import Avatar from "../profiles/_Avatar";
import EnglishScores from "../profiles/english_scores/Show";
import Switch from "../../components/shared/Switch";

class Show extends Component {
  usersPath() {
    const {users} = this.props;
    const page = users.pagination.current_page;

    return page > 1 ? {pathname: usersPath(), search: `page=${page}`} : usersPath();
  }

  render() {
    const {id, companies, users} = this.props;
    const company = companies.current;
    const user = users.current;

    const isLoaded = company.id && user.id && user.id === id;
    const isLoadingError = isFetchUserError(users);
    const isLoading = (!isLoaded && !isLoadingError) || users.isLoading || companies.isLoading;

    return (
      <ApplicationLayout headerTitle={I18n.t("header_titles.users.show")} isLoading={isLoading}>
        <FetchUser id={id} />
        {isLoaded && (
          <div className="container">
            <div className="row pt-4">
              <div className="col-12 col-md-10 col-lg-6 col-xl-5 mx-auto">
                <div className="profile-section">
                  <Avatar user={user} />
                  <div className="my-3">
                    <Link className="button button_color_grey w-50" to={editUserPath(user.id)}>
                      {I18n.t("btn_edit")}
                    </Link>
                  </div>

                  <div className="profile-section__title">
                    {I18n.t("users.sections.general_information")}
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.student_id_new")}
                    </label>
                    <div className='profile-section__details'>
                      {user.id}
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.company")}
                    </label>
                    <div className='profile-section__details'>
                      {company.title}
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.type")}
                    </label>
                    <div className='profile-section__details'>
                      {enumI18n("user", "role", user.role)}
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.status_edit")}
                    </label>
                    <div className='profile-section__details'>
                      {enumI18n("user", "status", user.status)}
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.tags")}
                    </label>
                    <div className='profile-section__details'>
                      {user.tags.toString()}
                    </div>
                  </div>

                  <div className="profile-section__title">{I18n.t("users.sections.login_info")}</div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.email")}
                    </label>
                    <div className='profile-section__details'>
                      {user.email}
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.username")}
                    </label>
                    <div className='profile-section__details'>
                      {user.username}
                    </div>
                  </div>

                   <div className="profile-section__field">
                    <label className="profile-section__label" htmlFor="password">
                      {I18n.t("mongoid.attributes.user.password")}
                    </label>
                     <div className='profile-section__details'>
                       *******
                     </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.pin_code")}
                    </label>
                    <div className='profile-section__details'>
                      {user.pin_code}
                    </div>
                  </div>

                  <div className="profile-section__title"> {I18n.t("mongoid.attributes.user.smart_profile.title")}</div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.smart_profile.domain")}
                    </label>
                    <div className='profile-section__details'>
                      {user.smart_profile.domain }
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.smart_profile.domain_details")}
                    </label>
                    <div className="profile-section__details">
                      {user.smart_profile.domain_details }
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.smart_profile.work_english_usage")}
                    </label>
                    <div className='profile-section__details'>
                      {user.smart_profile.work_english_usage }
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.smart_profile.english_speaking_level")}
                    </label>
                    <div className="profile-section__details">
                      {user.smart_profile.english_speaking_level }
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.smart_profile.english_learning_goal")}
                    </label>
                    <div className='profile-section__details'>
                      {user.smart_profile.english_learning_goal }
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.smart_profile.focus1")}
                    </label>
                    <div className='profile-section__details'>
                      { user.smart_profile.focus1 }
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.smart_profile.focus2")}
                    </label>
                    <div className='profile-section__details'>
                      { user.smart_profile.focus2 }
                    </div>
                  </div>

                  <div className="profile-section__title">{I18n.t("mongoid.attributes.user.smart_profile.toeic_title")}</div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.smart_profile.toeic_score")}
                    </label>
                    <div className='profile-section__details'>
                      { user.smart_profile.toeic_score }
                    </div>
                  </div>

                  <div className="profile-section__title">{I18n.t("users.sections.user_info")}</div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.first_name")}
                    </label>
                    <div className='profile-section__details'>
                      {user.first_name}
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.last_name")}
                    </label>
                    <div className='profile-section__details'>
                      {user.last_name}
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.first_name_ja")}
                    </label>
                    <div className='profile-section__details'>
                      {user.first_name_ja}
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.last_name_ja")}
                    </label>
                    <div className='profile-section__details'>
                      {user.last_name_ja}
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.show_initials_only")}
                    </label>
                    <div className='profile-section__details'>
                      {user.show_initials_only ? I18n.t("mongoid.attributes.user.initials_on") : I18n.t("mongoid.attributes.user.initials_off")}
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.language_code")}
                    </label>
                    <div className='profile-section__details'>
                      {enumI18n("user", "language_code", user.language_code)}
                    </div>
                  </div>

                  <div className="profile-section__title">{I18n.t("users.sections.other_info")}</div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.gender")}
                    </label>
                    <div className='profile-section__details'>
                      {enumI18n("user", "gender", user.gender)}
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.birthday")}
                    </label>
                    <div className='profile-section__details'>
                      {user.birthday && moment(user.birthday).format('YYYY/MM/DD')}
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.activation_starts_on")}
                    </label>
                    <div className='profile-section__details'>
                      {user.activation_starts_on
                          ? moment(user.activation_starts_on).format(
                              I18n.t("date.moment_formats.activity_date")
                          )
                          : I18n.t("off")}
                    </div>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.activation_ends_on")}
                    </label>
                    <div className='profile-section__details'>
                      {user.activation_ends_on
                          ? moment(user.activation_ends_on).format(
                              I18n.t("date.moment_formats.activity_date")
                          )
                          : I18n.t("off")}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        )}
      </ApplicationLayout>
    );
  }
}

Show.propTypes = {
  companies: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  users: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    companies: state.companies,
    users: state.users
  };
}

export default connect(mapStateToProps)(Show);
