import React, {useCallback} from "react";
import _ from "lodash";

import generateObjectId from "helpers/generateObjectId";
import PasteInput from "./PasteInput";

const clipboardParseError = I18n.t("user_scenarios.paste_participants.clipboard_parse_error");
const roleNotFound = I18n.t("user_scenarios.paste_participants.role_not_found");
const participantFocusesInvalid = I18n.t(
  "user_scenarios.paste_participants.participant_focuses_invalid"
);

const processRawData = rawData => {
  const parser = new DOMParser();
  const document = parser.parseFromString(rawData, "text/html");
  document.querySelectorAll("[style*='underline']").forEach(element => {
    element.innerText = `<blur>${element.innerText}</blur>`;
  });

  return _.map(document.querySelectorAll("tr"), tr =>
    _.map(tr.querySelectorAll("td"), td => td.innerText)
  );
};

const PasteLinesButton = ({assignAttributes, formAttributes, notifyError, ...restProps}) => {
  const parseParticipantFocuses = useCallback(
    (participantFocuses, participantIndex) => {
      if (participantFocuses.length !== formAttributes.participants.length) {
        notifyError(participantFocusesInvalid);
        throw new Error(participantFocusesInvalid);
      }

      const parsedParticipantFocuses = participantFocuses.map((x, index) => {
        const value = index === participantIndex ? 0 : Number.parseInt(x, 10);

        if (Number.isNaN(value)) {
          notifyError(participantFocusesInvalid);
          throw new Error(participantFocusesInvalid);
        }

        return value;
      });

      if (_.sum(parsedParticipantFocuses) !== 100) {
        notifyError(participantFocusesInvalid);
        throw new Error(participantFocusesInvalid);
      }

      return parsedParticipantFocuses;
    },
    [formAttributes.participants.length, notifyError]
  );

  const parseLines = useCallback(
    data => {
      return data.map(row => {
        const [role, , text, translation, ...participantFocuses] = row;
        const participantIndex = formAttributes.participants.findIndex(x => x.role === role);

        if (participantIndex === -1) {
          notifyError(roleNotFound);
          throw new Error(roleNotFound);
        }

        const participant = formAttributes.participants[participantIndex];
        const parsedParticipantFocuses = parseParticipantFocuses(
          participantFocuses,
          participantIndex
        );

        return {
          id: generateObjectId(),
          participant,
          participant_focuses: parsedParticipantFocuses,
          participant_id: participant.id,
          text: text || "",
          translation: translation || ""
        };
      });
    },
    [formAttributes.participants, notifyError, parseParticipantFocuses]
  );

  const processClipboardText = useCallback(
    rawData => {
      try {
        const data = processRawData(rawData);
        const lines = parseLines(data);
        assignAttributes({...formAttributes, lines});
      } catch (e) {
        notifyError(clipboardParseError);
        throw e;
      }
    },
    [assignAttributes, formAttributes, notifyError, parseLines]
  );

  return <PasteInput dataTypes={["text/html"]} onPaste={processClipboardText} {...restProps} />;
};

export default PasteLinesButton;
