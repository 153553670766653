import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchDialogflowAgentsError} from "../../helpers/errorsHelpers";

import {fetchDialogflowAgents} from "../../store/actions/dialogflowAgentsActions";

class FetchDialogflowAgents extends Component {
  componentDidMount() {
    this.fetchDialogflowAgents(true);
  }

  componentDidUpdate() {
    this.fetchDialogflowAgents(false);
  }

  fetchDialogflowAgents(isComponentMount) {
    const {dialogflowAgents} = this.props;
    const isReasonToFetch = isComponentMount || !dialogflowAgents.isCollectionLoaded;

    if (isReasonToFetch && !dialogflowAgents.isLoading && !isFetchDialogflowAgentsError(dialogflowAgents)) {
      this.props.fetchDialogflowAgents();
    }
  }

  render() {
    return null;
  }
}

FetchDialogflowAgents.propTypes = {
  dialogflowAgents: PropTypes.object.isRequired,
  fetchDialogflowAgents: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    dialogflowAgents: state.dialogflowAgents
  }
}

const mapActionsToProps = {
  fetchDialogflowAgents
};

export default connect(mapStateToProps, mapActionsToProps)(FetchDialogflowAgents);
