import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import _ from "lodash";
import handleFieldChange from "helpers/handleFieldChange";

import {
  assignPasswordAttributes,
  clearPasswordAttributes,
  createPassword,
  disablePasswordsResetCaptcha,
  enablePasswordsSubmit,
  resetPasswordsCaptcha
} from "store/actions/passwordsActions";

import SvgIcon from "components/SvgIcon";
import UnauthenticatedLayout from "views/layouts/UnauthenticatedLayout";
import Recaptcha from "../common/Recaptcha";

class New extends Component {
  componentDidMount() {
    this.props.clearPasswordAttributes();
    this.props.resetPasswordsCaptcha();
  }

  onChange = event => {
    const {passwords, assignPasswordAttributes} = this.props;
    const attributes = passwords.formAttributes;
    assignPasswordAttributes({...attributes, ...handleFieldChange(event, attributes)});
  };

  onSubmit = event => {
    event.preventDefault();

    if (this.isValid) {
      const {passwords, createPassword, history} = this.props;
      createPassword(_.pick(passwords.formAttributes, "email", "recaptcha_response"), history);
    }
  };

  get isValid() {
    const attributes = this.props.passwords.formAttributes;
    const errors = {};

    if (!attributes.email.trim()) {
      errors.email = [I18n.t("errors.messages.blank")];
    }

    const attributesWithNewErrors = {...attributes, errors};
    this.props.assignPasswordAttributes(attributesWithNewErrors);

    return _.isEmpty(errors);
  }

  renderErrors = name => {
    const errors = this.props.passwords.formAttributes.errors[name];
    if (_.isEmpty(errors)) return null;

    return <div className="form-group__error text-left">{errors.join(", ")}</div>;
  };

  verifyCaptchaCallback = response => {
    const {passwords, assignPasswordAttributes, enablePasswordsSubmit} = this.props;
    const attributes = passwords.formAttributes;
    enablePasswordsSubmit();
    assignPasswordAttributes({...attributes, recaptcha_response: response});
  };

  render() {
    const {disablePasswordsResetCaptcha, passwords} = this.props;
    const attributes = passwords.formAttributes;
    const {isSubmitDisabled} = passwords;

    const {isLoading} = passwords;

    return (
      <UnauthenticatedLayout isLoading={isLoading}>
        <div className="login-section">
          <SvgIcon className="login-section__title" icon="smart-tutor-logo" />
          <div className="login-section__subtitle">{I18n.t("passwords.reset_password")}</div>
          <form>
            <input
              autoFocus
              className="login-section__input mt-3"
              name="email"
              onChange={this.onChange}
              placeholder={I18n.t("passwords.email_placeholder")}
              type="text"
              value={attributes.email}
            />
            {this.renderErrors("email")}

            <div className="mt-4">
              <Recaptcha
                disableResetCaptcha={disablePasswordsResetCaptcha}
                isReset={passwords.isResetCaptcha}
                verifyCallback={this.verifyCaptchaCallback}
              />
            </div>

            <button
              className="login-section__submit button mt-4"
              disabled={isLoading || isSubmitDisabled}
              onClick={this.onSubmit}
            >
              {I18n.t("next")}
            </button>
          </form>
        </div>
      </UnauthenticatedLayout>
    );
  }
}

New.propTypes = {
  assignPasswordAttributes: PropTypes.func.isRequired,
  clearPasswordAttributes: PropTypes.func.isRequired,
  createPassword: PropTypes.func.isRequired,
  disablePasswordsResetCaptcha: PropTypes.func.isRequired,
  enablePasswordsSubmit: PropTypes.func.isRequired,
  passwords: PropTypes.object.isRequired,
  resetPasswordsCaptcha: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    passwords: state.passwords
  };
}

const mapActionsToProps = {
  assignPasswordAttributes,
  clearPasswordAttributes,
  createPassword,
  disablePasswordsResetCaptcha,
  enablePasswordsSubmit,
  resetPasswordsCaptcha
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(New));
