import React, {useCallback} from "react";
import Papa from "papaparse";

import generateObjectId from "helpers/generateObjectId";

import {DEFAULT_PARTICIPANT_KIND} from "../../../../constants";
import PasteInput from "./PasteInput";

const characterNotFound = I18n.t("user_scenarios.paste_participants.character_not_found");
const clipboardParseError = I18n.t("user_scenarios.paste_participants.clipboard_parse_error");
const participantKindsByTitle = {
  "mandatory role": "required",
  "non-playable role": "unplayable",
  "optional role": "optional"
};

const PasteParticipantsButton = ({
  assignAttributes,
  characters,
  formAttributes,
  notifyError,
  positions,
  ...restProps
}) => {
  const assignParticipants = useCallback(
    participants => {
      if (!participants.length) return;

      const participantId = participants[0].id;

      const lines = formAttributes.lines.map(line => ({
        ...line,
        participant_focuses: Array(participants.length).fill(0),
        participant_id: participantId
      }));

      const dialogflowAgents = formAttributes.dialogflow_agents.map(agent => ({
        ...agent,
        chatbot_participant_id: participantId,
        user_participant_id: participantId
      }));

      assignAttributes({
        ...formAttributes,
        dialogflow_agents: dialogflowAgents,
        lines,
        participants
      });
    },
    [assignAttributes, formAttributes]
  );

  const parseParticipants = useCallback(
    ({data}) => {
      return data.map(row => {
        const [role, participantName, characterName, description, position, kindTitle] = row;
        const character = characters.find(x => x.name === characterName);

        if (!character) {
          notifyError(characterNotFound);
          throw new Error(characterNotFound);
        }

        const kind =
          (kindTitle && participantKindsByTitle[kindTitle.toLowerCase()]) ||
          DEFAULT_PARTICIPANT_KIND;

        return {
          avatar_url: character.avatar_url,
          character_id: character.id,
          description: description || "",
          id: generateObjectId(),
          identification: character.identification,
          kind,
          name: participantName || "",
          position: position || positions[0],
          role: role || "",
          static_id: generateObjectId()
        };
      });
    },
    [characters, notifyError, positions]
  );

  const parseRows = useCallback(
    text => {
      const data = Papa.parse(text, {skipEmptyLines: true});

      if (data.errors.length) {
        notifyError(clipboardParseError);
        throw new Error(data.errors.map(x => x.message).join(", "));
      }

      return data;
    },
    [notifyError]
  );

  const processClipboardText = useCallback(
    text => {
      try {
        const data = parseRows(text);
        const participants = parseParticipants(data);
        assignParticipants(participants);
      } catch (e) {
        notifyError(clipboardParseError);
        throw e;
      }
    },
    [assignParticipants, notifyError, parseParticipants, parseRows]
  );

  return <PasteInput onPaste={processClipboardText} {...restProps} />;
};

export default PasteParticipantsButton;
