import React from "react";
import classnames from "classnames";
import SvgIcon from "components/SvgIcon";

import {LEVELS_COUNT} from "../../../../../../constants";

import styles from "./styles.module.css";

const Level = ({className, level}) => {
  return (
    <div className={classnames(styles.level, className)}>
      <div className={styles.label}>{I18n.t("mongoid.attributes.courses_user.level")}</div>
      {Array.from({length: LEVELS_COUNT}, (_, i) => (
        <SvgIcon
          key={i}
          background={classnames({[styles.iconFilled]: i < level})}
          className={styles.icon}
          icon="star"
        />
      ))}
    </div>
  );
};

export default Level;
