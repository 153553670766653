import queryString from "query-string";

function buildQuery(params) {
  const query = queryString.stringify(params);

  return query ? `?${query}` : "";
}

export function rootPath() {
  return "/";
}

export function adminRootPath() {
  return "/admin";
}

export function newUserSessionPath() {
  return "/users/sign_in";
}

export function newUserPasswordPath() {
  return "/users/password/new";
}

export function afterNewPasswordPath(email) {
  return `/users/password/email_sent/${email}`;
}

export function afterEditPasswordPath() {
  return "/users/password/updated";
}

export function packCategoriesPath() {
  return "/pack_categories";
}

export function usersPath() {
  return "/users";
}

export function userPath(id) {
  return `/users/${id}`;
}

export function newUserPath() {
  return "/users/new";
}

export function editUserPath(id) {
  return `/users/${id}/edit`;
}

export function coursesPath() {
  return "/courses";
}

export function coursePath(id) {
  return `/courses/${id}`;
}

export function newCoursePath() {
  return "/courses/new";
}

export function editCoursePath(id) {
  return `/courses/${id}/edit`;
}

export function tagsPath() {
  return "/tags";
}

export function tagPath(id) {
  return `/tags/${id}`;
}

export function newTagPath() {
  return "/tags/new";
}

export function editTagPath(id) {
  return `/tags/${id}/edit`;
}

export function profilePath() {
  return "/profile";
}

export function editProfilePath() {
  return "/profile/edit";
}

export function tagReorderPath() {
  return "/reorder_tag";
}

export function userScenarioPath(id, params = {}) {
  return `/user_scenarios/${id}${buildQuery(params)}`;
}

export function editUserScenarioPath(id, params = {}) {
  return `/user_scenarios/${id}/edit${buildQuery(params)}`;
}

export function newUserScenarioPath(packId) {
  return `/packs/${packId}/user_scenarios/new`;
}

export function packCategoryPath(id) {
  return `/pack_categories/${id}`;
}

export function packPath(id, params = {}) {
  return `/packs/${id}${buildQuery(params)}`;
}

export function groupPath(id) {
  return `/dashboard/groups/${id}`;
}

export function dashboardPath() {
  return "/dashboard";
}

export function dashboardUserPath(userId, groupId) {
  const params = {group_id: groupId};

  return `/dashboard/users/${userId}${buildQuery(params)}`;
}

export function editDashboardUserScenarioPath(userId, id) {
  return `/dashboard/${userId}/user_scenarios/${id}/edit`;
}

export function dashboardScenarioDetailPath(userId, userScenarioId) {
  return `/dashboard/${userId}/scenario_details/${userScenarioId}`;
}

export function dashboardScenarioReviewPath(metricId, groupId) {
  const params = {group_id: groupId};

  return `/dashboard/scenario_reviews/${metricId}${buildQuery(params)}`;
}

export function userScenariosSearchPath() {
  return "/user_scenarios/search";
}
