import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";

import {packCategoryPath} from "../../helpers/routesHelper";
import {isFetchPackError, isFetchUserScenariosError} from "../../helpers/errorsHelpers";

import {assignPackAttributes, updatePack} from "../../store/actions/packsActions";
import {deselectAllUserScenarios} from "../../store/actions/userScenariosActions";
import {closeModal} from "../../store/actions/modalsActions";

import ApplicationLayout from "../layouts/ApplicationLayout";
import FetchPack from "../../components/data_loaders/FetchPack";
import FetchUserScenarios from "../../components/data_loaders/FetchUserScenarios";
import Pagination from "../../components/shared/Pagination";
import TopPanel from "./_TopPanel";
import UserScenario from "./_UserScenario";
import MoveUserScenariosModal from "./_MoveUserScenariosModal";

class Show extends Component {
  componentDidMount() {
    this.props.deselectAllUserScenarios();
  }

  get backPath() {
    if (this.props.backPath) return this.props.backPath;

    const {packs} = this.props;
    const page = packs.pagination.current_page;
    const pack = packs.current;

    if (pack.id) {
      const categoryId = pack.category_id;

      return page > 1
        ? {pathname: packCategoryPath(categoryId), search: `page=${page}`}
        : packCategoryPath(categoryId);
    }

    return "";
  }

  render() {
    const {id, page, environmentId, currentUser, packs, userScenarios} = this.props;
    const pack = packs.current;
    const {selectedIds} = userScenarios;
    const {assignPackAttributes, updatePack, closeModal} = this.props;

    const isLoaded = pack.id && userScenarios.isCollectionLoaded && userScenarios.packId === id;
    const isLoadingError = isFetchPackError(packs) || isFetchUserScenariosError(userScenarios);
    const isLoading = (!isLoaded && !isLoadingError) || userScenarios.isLoading;

    return (
      <ApplicationLayout headerTitle={I18n.t("user_scenarios.titles.index")} isLoading={isLoading}>
        <FetchPack id={id} />
        <FetchUserScenarios environmentId={environmentId} packId={id} page={page} />
        {isLoaded && (
          <div className="container">
            <div className="row py-4">
              <div className="col-12">
                <TopPanel
                  assignPackAttributes={assignPackAttributes}
                  backPath={this.backPath}
                  closeModal={closeModal}
                  currentUser={currentUser}
                  pack={pack}
                  packs={packs}
                  updatePack={updatePack}
                />
                <div className="pt-3">
                  {_.map(userScenarios.collection, userScenario => (
                    <UserScenario key={userScenario.id} userScenario={userScenario} />
                  ))}
                  {!_.isEmpty(selectedIds) && (
                    <div className="batch-select-actions">
                      <div className="batch-select-actions__count-label">
                        {I18n.t("user_scenarios.batch_selected_label", {value: selectedIds.length})}
                      </div>
                      <MoveUserScenariosModal />
                    </div>
                  )}
                  <div className="page-index-bottom">
                    <Pagination pagination={userScenarios.pagination} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ApplicationLayout>
    );
  }
}

Show.defaultProps = {
  page: 1
};

Show.propTypes = {
  assignPackAttributes: PropTypes.func.isRequired,
  backPath: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  deselectAllUserScenarios: PropTypes.func.isRequired,
  environmentId: PropTypes.string,
  id: PropTypes.string.isRequired,
  packs: PropTypes.object.isRequired,
  page: PropTypes.number,
  updatePack: PropTypes.func.isRequired,
  userScenarios: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    currentUser: state.profiles.current,
    packs: state.packs,
    userScenarios: state.userScenarios
  };
}

const mapActionsToProps = {
  assignPackAttributes,
  closeModal,
  deselectAllUserScenarios,
  updatePack
};

export default connect(mapStateToProps, mapActionsToProps)(Show);
