import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import NotificationsSystem, {notify} from "reapop";

import {clearFlash} from "../../store/actions/flashActions";

import theme from "../../components/reapop-theme-wybo";

class NotificationsWrapper extends Component {
  componentDidMount() {
    const {flash, notify, clearFlash} = this.props;
    flash.flash.forEach((flash) => {
      const status = this.getNotificationStatus(flash[0]);
      notify({title: I18n.t(`notifications.titles.${status}`), message: flash[1], status: "error"});
    });
    clearFlash();
  }

  getNotificationStatus(flashType) {
    switch (flashType) {
      case "error": {
        return "error";
      }
      case "alert": {
        return "warning"
      }
      case "notice": {
        return "info"
      }
      case "success":{
        return "success"
      }
    }
  }

  render() {
    return (
      <Fragment>
        {this.props.children}
        <NotificationsSystem theme={theme}/>
      </Fragment>
    )
  }
}

NotificationsWrapper.propTypes = {
  flash: PropTypes.object.isRequired,
  notify: PropTypes.func.isRequired,
  clearFlash: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    flash: state.flash
  }
}

const mapActionsToProps = {
  notify,
  clearFlash
};

export default connect(mapStateToProps, mapActionsToProps)(NotificationsWrapper);
