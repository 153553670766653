import React, {Component, Fragment} from "react"
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import _ from "lodash";

import {
  pauseAudio,
  playAudio,
  registerAudioPlayer,
  moveAudioPlayHead,
  finishMovingAudioPlayHead
} from "../../../store/actions/audioPlayersActions";
import PlayAudioButton from "./PlayAudioButton";

class AudioPlayer extends Component {
  constructor(props) {
    super(props);
    this.timeLine = React.createRef();
  }

  componentDidMount() {
    this.props.registerAudioPlayer(this.props.id);
  }

  componentWillUnmount() {
    this.props.pauseAudio(this.props.id);
  }

  get audioPlayerData() {
    return _.find(this.props.audioPlayers.collection, {id: this.props.id}) || {};
  }

  get buttonTitle() {
    return this.props.audioTrackUrl ? null : I18n.t("scenario_contents.audio_not_generated");
  }

  get currentTime() {
    return this.audioPlayerData.currentTime || 0;
  }

  get duration() {
    return this.audioPlayerData.duration || 0;
  }

  get progress() {
    if (this.duration === 0) {
      return 0;
    }
    return this.currentTime * 100 / this.duration;
  }

  timeToString = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds - minutes * 60);
    const secondsPrefixZero = seconds < 10 ? "0" : "";
    return minutes + ":" + secondsPrefixZero + seconds;
  };

  onPlayHeadMouseDown = (event) => {
    $(window).on("mousemove.audio-player-mousemove", (event) => {
      this.props.moveAudioPlayHead(this.props.id, this.getPercentageAfterClick(event));
    });

    $(window).on("mouseup.audio-player-mouseup", (event) => {
      $(window).unbind("mousemove.audio-player-mousemove");
      $(window).unbind("mouseup.audio-player-mouseup");
      this.props.finishMovingAudioPlayHead(this.props.id, this.getPercentageAfterClick(event));
    });

    this.props.moveAudioPlayHead(this.props.id, this.getPercentageAfterClick(event));
  };

  onTimeLineClick = (event) => {
    this.props.finishMovingAudioPlayHead(this.props.id, this.getPercentageAfterClick(event));
  };

  getPercentageAfterClick = (event) => {
    const timeLineRect = this.timeLine.current.getBoundingClientRect();
    return (event.clientX - timeLineRect.left) * 100 / timeLineRect.width;
  };

  render() {
    const {id, audioTrackUrl, isWithoutToggle, className} = this.props;

    const styles = {
      playHead: {
        left: `${this.progress}%`,
        transform: "translateX(-50%)"
      },
      progressBar: {
        width: `${this.progress}%`
      }
    };

    return (
      <div className={
             classnames("audio-player-mf audio-player-mf_right",
                        className,
                        {"audio-player-mf_sample-conversation": !isWithoutToggle})
           }>
        {
          this.audioPlayerData.wasPlaying && !isWithoutToggle &&
          <div className="audio-player-mf__progress">
            <div className="audio-player-mf__current-time">{this.timeToString(this.currentTime)}</div>
            <div className="audio-player-mf__timeline" ref={this.timeLine} onClick={this.onTimeLineClick}>
              <div className="audio-player-mf__progress-bar" style={styles.progressBar}/>
              <div className="audio-player-mf__playhead" style={styles.playHead} onMouseDown={this.onPlayHeadMouseDown}/>
            </div>
            <div className="audio-player-mf__duration">{this.timeToString(this.duration)}</div>
          </div>
        }
        {
          audioTrackUrl &&
          <Fragment>
            {
              !isWithoutToggle &&
              <div className="scenario-content-header__audio-player-label">
                <span>{I18n.t("scenario_contents.full_scenario")}</span>
              </div>
            }
            <PlayAudioButton id={id}
                             type="ear"
                             audioTrackUrl={audioTrackUrl}
                             title={this.buttonTitle}
                             disabled={!audioTrackUrl}>
              {
                !isWithoutToggle &&
                <Fragment>
                  <span className="scenario-content-header__audio-player-button-icon"/>
                  <span className="scenario-content-header__audio-player-button-label">
                   {I18n.t("scenario_contents.sample")}
                  </span>
                </Fragment>
              }
            </PlayAudioButton>
          </Fragment>
        }
      </div>
    )
  }
}

AudioPlayer.propTypes = {
  id: PropTypes.string.isRequired,
  audioTrackUrl: PropTypes.string,
  className: PropTypes.string,
  isWithoutToggle: PropTypes.bool,
  audioPlayers: PropTypes.object.isRequired,
  registerAudioPlayer: PropTypes.func.isRequired,
  playAudio: PropTypes.func.isRequired,
  pauseAudio: PropTypes.func.isRequired,
  moveAudioPlayHead: PropTypes.func.isRequired,
  finishMovingAudioPlayHead: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    audioPlayers: state.audioPlayers
  }
}

const mapActionsToProps = {
  registerAudioPlayer,
  playAudio,
  pauseAudio,
  moveAudioPlayHead,
  finishMovingAudioPlayHead
};

export default connect(mapStateToProps, mapActionsToProps)(AudioPlayer);
