import { ACTIONS as COURSE_CATEGORY_ACTIONS } from '../reducers/courseCategoriesReducer';
import { ACTIONS as COURSE_ACTIONS } from '../reducers/coursesReducer';
import { ACTIONS as GROUP_ACTIONS } from '../reducers/groupsReducer';
import { ACTIONS as METRIC_ACTIONS } from '../reducers/metricsReducer';
import { ACTIONS as PACK_CATEGORY_ACTIONS } from '../reducers/packCategoriesReducer';
import { ACTIONS as PACK_SHARE_ACTIONS } from '../reducers/packSharesReducer';
import { ACTIONS as PACK_ACTIONS } from '../reducers/packsReducer';
import { ACTIONS as PROFILE_ACTIONS } from '../reducers/profilesReducer';
import { ACTIONS as TAG_ACTIONS } from '../reducers/tagsReducer';
import { ACTIONS as USER_ACTIVITY_ACTIONS } from '../reducers/userActivitiesReducer';
import { ACTIONS as USER_SCENARIO_HEADER_ACTIONS } from '../reducers/userScenarioHeadersReducer';
import { ACTIONS as USER_SCENARIO_ACTIONS } from '../reducers/userScenariosReducer';
import { ACTIONS as USER_ACTIONS } from '../reducers/usersReducer';

export function unloadData(dispatch) {
  dispatch({type: COURSE_CATEGORY_ACTIONS.FETCH_COURSE_CATEGORIES});
  dispatch({type: COURSE_CATEGORY_ACTIONS.FETCH_COURSE_CATEGORY});
  dispatch({type: COURSE_ACTIONS.FETCH_COURSES});
  dispatch({type: COURSE_ACTIONS.FETCH_COURSE});
  dispatch({type: GROUP_ACTIONS.FETCH_GROUPS});
  dispatch({type: GROUP_ACTIONS.FETCH_GROUP});
  dispatch({type: METRIC_ACTIONS.FETCH_METRICS});
  dispatch({type: METRIC_ACTIONS.FETCH_METRIC});
  dispatch({type: PACK_CATEGORY_ACTIONS.FETCH_PACK_CATEGORIES});
  dispatch({type: PACK_CATEGORY_ACTIONS.FETCH_PACK_CATEGORY});
  dispatch({type: PACK_SHARE_ACTIONS.FETCH_PACK_SHARES});
  dispatch({type: PACK_ACTIONS.FETCH_PACKS});
  dispatch({type: PACK_ACTIONS.FETCH_PACK});
  dispatch({type: PROFILE_ACTIONS.UNLOAD_PROFILE});
  dispatch({type: TAG_ACTIONS.FETCH_TAGS});
  dispatch({type: TAG_ACTIONS.FETCH_TAG});
  dispatch({type: USER_ACTIVITY_ACTIONS.FETCH_USER_ACTIVITIES});
  dispatch({type: USER_SCENARIO_HEADER_ACTIONS.FETCH_USER_SCENARIO_HEADER});
  dispatch({type: USER_SCENARIO_ACTIONS.FETCH_USER_SCENARIOS});
  dispatch({type: USER_SCENARIO_ACTIONS.FETCH_USER_SCENARIO});
  dispatch({type: USER_ACTIONS.FETCH_USERS});
  dispatch({type: USER_ACTIONS.FETCH_USER});
}
