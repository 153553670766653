import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import _ from "lodash";

import handleFieldChange from "../../helpers/handleFieldChange";

import Cover from '../../components/shared/_Cover';
import SortableScenarioList from "./SortableScenarioList";
import TagSelection from "./TagSelection";
import FetchTags from "../../components/data_loaders/FetchTags";

class Form extends Component {
  onChange = (event) => {
    const {formAttributes, assignCourseAttributes} = this.props;
    assignCourseAttributes({...formAttributes, ...handleFieldChange(event, formAttributes)});
  };

  onSubmit = (event) => {
    event.preventDefault();

    const attributes = _.omit(this.props.formAttributes, "errors");
    attributes.user_scenario_ids = attributes.user_scenarios.map(user_scenario => user_scenario.id);
    delete attributes.user_scenarios;

    if (this.isValid) {
      this.props.onSubmit(attributes);
    } else {
      const action = attributes.id ? "update" : "create";
      this.props.notify({
        title: I18n.t("notifications.titles.error"),
        message: I18n.t(`courses.messages.${action}.error`),
        status: "error"
      });
    }
  };

  get isValid() {
    const attributes = this.props.formAttributes;
    let errors = {};

    if (!attributes.title.trim()) {
      errors.title = [I18n.t("errors.messages.blank")];
    }

    this.props.assignCourseAttributes({...attributes, errors: errors});

    return _.isEmpty(errors);
  }

  renderErrors = (name) => {
    const errors = this.props.formAttributes.errors[name];
    if (_.isEmpty(errors)) return null;
    return <div className="form-group__error">{errors.join(", ")}</div>;
  };

  render() {
    const {backPath, assignCourseAttributes} = this.props;
    const attributes = this.props.formAttributes;

    return (
      <div className="scenario-edit mt-2">

        <div className="transparent-form-group">
          <label className="transparent-form-group__label" htmlFor="title">
            {I18n.t("mongoid.attributes.course.title")}
          </label>
          <input
            id="title"
            name="title"
            className="transparent-form-group__input"
            type="text"
            value={attributes.title}
            placeholder={I18n.t("courses.title_placeholder")}
            onChange={this.onChange}
          />
          {this.renderErrors("title")}
        </div>

        <Cover
          formAttributes={attributes}
          theme="course-image"
          assignAttributes={assignCourseAttributes}
          isUploadable
        />

        <FetchTags />

        <TagSelection
          tags={this.props.tags}
          formAttributes={attributes}
          assignCourseAttributes={assignCourseAttributes}
        />

        <SortableScenarioList
          formAttributes={attributes}
          assignCourseAttributes={assignCourseAttributes}
        />

        <div className="form-actions">
          <Link to={backPath} className="form-actions__button button button_color_grey">
            {I18n.t("btn_back")}
          </Link>
          <button type="submit" className="form-actions__button button" onClick={this.onSubmit}>
            {I18n.t("save")}
          </button>
        </div>

      </div>
    )
  }
}

Form.propTypes = {
  backPath: PropTypes.string.isRequired,
  formAttributes: PropTypes.object.isRequired,
  assignCourseAttributes: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
  tags: PropTypes.func
};

export default Form;
