import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import ConfirmationModal from "../../../components/shared/modals/ConfirmationModal";
import ContextMenuItem from "../../../components/shared/context_menu/ContextMenuItem";
import {destroyGroup} from "../../../store/actions/groupsActions";

const MODAL_NAME = "DASHBOARD_GROUP_DELETE_MODAL";

class DeleteGroupModal extends Component {
  get modalName() {
    return `${MODAL_NAME}_${this.props.userScenarioId}`;
  };

  onSubmit = (closeModal) => {
    const {groupId, history, destroyGroup} = this.props;
    destroyGroup(groupId, history);
    closeModal();
  };

  get button() {
    return (
      <ContextMenuItem color="red" hideContextMenu={this.props.hideContextMenu}>
        {I18n.t("dashboard.groups.destroy_group")}
      </ContextMenuItem>
    );
  }

  render() {
    return (
      <ConfirmationModal
        modalName={this.modalName}
        button={this.button}
        message={I18n.t("dashboard.groups.popups.destroy_group")}
        actionsStyle="DELETE_CANCEL"
        onSubmit={this.onSubmit}
      />
    );
  }
}

DeleteGroupModal.propTypes = {
  groupId: PropTypes.string.isRequired,
  destroyGroup: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  hideContextMenu: PropTypes.func // Injected by the ContextMenu component
};

const mapActionsToProps = {
  destroyGroup
};

export default connect(null, mapActionsToProps)(withRouter(DeleteGroupModal));
