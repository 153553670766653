import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchTagError} from "../../helpers/errorsHelpers";

import {fetchTag} from "../../store/actions/tagsActions";

class FetchTag extends Component {
  componentDidMount() {
    this.fetchTag(true);
  }

  componentDidUpdate() {
    this.fetchTag(false);
  }

  fetchTag(isComponentMount) {
    const {id, tags} = this.props;
    const isReasonToFetch = isComponentMount || tags.current.id !== id;

    if (isReasonToFetch && !tags.isLoading && !isFetchTagError(tags)) {
      this.props.fetchTag(id);
    }
  }

  render() {
    return null;
  }
}

FetchTag.propTypes = {
  id: PropTypes.string.isRequired,
  tags: PropTypes.object.isRequired,
  fetchTag: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    tags: state.tags
  }
}

const mapActionsToProps = {
  fetchTag
};

export default connect(mapStateToProps, mapActionsToProps)(FetchTag);
