import React from "react";

import {
  isFreeTalkMode,
  isListeningMode,
  isReadingMode,
  isTranslatingMode
} from "../../../../../../helpers/modesHelper";

import FreeTalkColumns from "./FreeTalkColumns";
import ListeningColumns from "./ListeningColumns";
import ReadingColumns from "./ReadingColumns";
import TranslatingColumns from "./TranslatingColumns";

const ModeSpecificColumns = ({isListeningSummaryLine, isUserLine, line, metric}) => {
  const {mode} = metric.completed_scenario;

  if (isListeningMode(mode)) {
    return (
      <ListeningColumns
        isListeningSummaryLine={isListeningSummaryLine}
        isUserLine={isUserLine}
        line={line}
        metric={metric}
      />
    );
  }

  if (isReadingMode(mode)) {
    return <ReadingColumns isUserLine={isUserLine} line={line} />;
  }

  if (isTranslatingMode(mode)) {
    return (
      <TranslatingColumns
        isListeningSummaryLine={isListeningSummaryLine}
        isUserLine={isUserLine}
        line={line}
        metric={metric}
      />
    );
  }

  if (isFreeTalkMode(mode)) {
    return <FreeTalkColumns line={line} />;
  }

  return null;
};

export default ModeSpecificColumns;
