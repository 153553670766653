export const ACTIONS = {
  CHANGE_ENGLISH_SCORES_LOADING: "CHANGE_ENGLISH_SCORES_LOADING",
  ASSIGN_ENGLISH_SCORE_ATTRIBUTES: "ASSIGN_ENGLISH_SCORE_ATTRIBUTES",
  CLEAR_ENGLISH_SCORE_ATTRIBUTES: "CLEAR_ENGLISH_SCORE_ATTRIBUTES",
  CREATE_ENGLISH_SCORE_REJECTED: "CREATE_ENGLISH_SCORE_REJECTED",
  UPDATE_ENGLISH_SCORE_REJECTED: "UPDATE_ENGLISH_SCORE_REJECTED",
  DESTROY_ENGLISH_SCORE_REJECTED: "DESTROY_ENGLISH_SCORE_REJECTED"
};

const INITIAL_FORM_ATTRIBUTES = {
  date: "",
  kind: "",
  score: "",
  errors: {}
};

export default function reducer(state = {
  formAttributes: INITIAL_FORM_ATTRIBUTES,
  isLoading: false,
  loadingCount: 0
}, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_ENGLISH_SCORES_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);
      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.ASSIGN_ENGLISH_SCORE_ATTRIBUTES: {
      return {...state, formAttributes: {errors: state.formAttributes.errors, ...action.attributes}};
    }

    case ACTIONS.CLEAR_ENGLISH_SCORE_ATTRIBUTES: {
      return {...state, formAttributes: INITIAL_FORM_ATTRIBUTES, date: new Date()};
    }

    case ACTIONS.CREATE_ENGLISH_SCORE_REJECTED: {
      const attributesWithNewErrors = {...state.formAttributes, errors: action.errors};
      return {...state, formAttributes: attributesWithNewErrors};
    }

    case ACTIONS.UPDATE_ENGLISH_SCORE_REJECTED: {
      const attributesWithNewErrors = {...state.formAttributes, errors: action.errors};
      return {...state, formAttributes: attributesWithNewErrors};
    }
  }
  return state;
}
