import React, {memo} from "react";
import {Link} from "react-router-dom";
import {VisibleIf} from "react-pundit";

import {editUserScenarioPath, packPath, userScenarioPath} from "../../../helpers/routesHelper";
import {currentPath} from "../../../helpers/locationHelper";
import {isPublic} from "../../../helpers/packsHelper";
import {scenarioTags} from "../../../helpers/scenariosHelper";
import {listItemDateLabel} from "../../../helpers/listItemsHelper";

import ContextMenu from "../../../components/shared/context_menu/ContextMenu";
import ContextMenuItem from "../../../components/shared/context_menu/ContextMenuItem";
import SvgIcon from "../../../components/SvgIcon";

const UserScenario = ({userScenario}) => {
  const packIcon = isPublic(userScenario.pack) ? "pack-public" : "pack-private";
  const cPackIcon = isPublic(userScenario.pack)
    ? "scenario-list-item__pack-icon--public"
    : "scenario-list-item__pack-icon--private";

  const showPackPath = packPath(userScenario.pack.id, {back_path: currentPath()});
  const showScenarioPath = userScenarioPath(userScenario.id, {back_path: currentPath()});
  const editScenarioPath = editUserScenarioPath(userScenario.id, {back_path: currentPath()});

  return (
    <Link className="scenario-list-item" to={showScenarioPath}>
      <div className="scenario-list-item__icon scenario-list-item__icon--scenario" />
      <div className="scenario-list-item__content">
        <div className="scenario-list-item__title" title={userScenario.title}>
          {userScenario.title}
        </div>
        <div className="scenario-list-item__secondary-content">
          <div className="d-flex align-items-center">
            <SvgIcon className={`scenario-list-item__pack-icon ${cPackIcon}`} icon={packIcon} />
            <div className="scenario-list-item__pack-title">{userScenario.pack.title}</div>
          </div>
          <div className="scenario-list-item__tags-content">
            <div className="scenario-list-item__tags">{scenarioTags(userScenario)}</div>
          </div>
          <div className="scenario-list-item__date">
            {listItemDateLabel(userScenario.updated_at)}
          </div>
        </div>
      </div>
      <div className="scenario-list-item__actions">
        <ContextMenu className="scenario-list-item__context-menu">
          <ContextMenuItem buttonLinkTo={showPackPath}>
            {I18n.t("user_scenarios.search.actions.view_pack")}
          </ContextMenuItem>
          <ContextMenuItem buttonLinkTo={showScenarioPath}>
            {I18n.t("user_scenarios.search.actions.view_scenario")}
          </ContextMenuItem>
          <VisibleIf action="Update" model={userScenario} type="UserScenario">
            <ContextMenuItem buttonLinkTo={editScenarioPath}>
              {I18n.t("user_scenarios.search.actions.edit_scenario")}
            </ContextMenuItem>
          </VisibleIf>
        </ContextMenu>
      </div>
    </Link>
  );
};

export default memo(UserScenario);
