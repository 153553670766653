import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import queryString from "query-string";
import classnames from "classnames";
import _ from "lodash";

class Pagination extends Component {
  getPathObject = page => location => {
    const searchObject = queryString.parse(location.search);

    if (page > 1) {
      return {...location, search: queryString.stringify({...searchObject, page})};
    }

    return {...location, search: queryString.stringify(_.omit(searchObject, "page"))};
  };

  renderFirstPage(currentPage) {
    return (
      <span className={classnames("first", {disabled: currentPage === 1})}>
        {currentPage > 1 && <Link to={this.getPathObject()} />}
      </span>
    );
  }

  renderPreviousPage(currentPage) {
    return (
      <span className={classnames("prev", {disabled: currentPage === 1})}>
        {currentPage > 1 && <Link rel="prev" to={this.getPathObject(currentPage - 1)} />}
      </span>
    );
  }

  renderGap() {
    return <span className="page gap">…</span>;
  }

  renderPreviousPreviousPageNumber(currentPage) {
    if (currentPage === 3) {
      return (
        <span className="page">
          <Link rel="next" to={this.getPathObject()}>
            1
          </Link>
        </span>
      );
    }

    if (currentPage > 3) {
      return this.renderGap();
    }

    return null;
  }

  renderPreviousPageNumber(currentPage) {
    if (currentPage > 1) {
      return (
        <span className="page">
          <Link rel="next" to={this.getPathObject(currentPage - 1)}>
            {currentPage - 1}
          </Link>
        </span>
      );
    }

    return null;
  }

  renderCurrentPageNumber(currentPage) {
    return <span className="page current">{currentPage}</span>;
  }

  renderNextPageNumber(currentPage, totalPages) {
    if (currentPage < totalPages) {
      return (
        <span className="page">
          <Link rel="next" to={this.getPathObject(currentPage + 1)}>
            {currentPage + 1}
          </Link>
        </span>
      );
    }

    return null;
  }

  renderNextNextPageNumber(currentPage, totalPages) {
    if (currentPage === totalPages - 2) {
      return (
        <span className="page">
          <Link to={this.getPathObject(totalPages)}>{totalPages}</Link>
        </span>
      );
    }

    if (totalPages - 2 > 1 && currentPage < totalPages - 2) {
      return this.renderGap();
    }

    return null;
  }

  renderNextPage(currentPage, totalPages) {
    return (
      <span className={classnames("next", {disabled: currentPage === totalPages})}>
        {currentPage < totalPages && <Link rel="next" to={this.getPathObject(currentPage + 1)} />}
      </span>
    );
  }

  renderLastPage(currentPage, totalPages) {
    return (
      <span className={classnames("last", {disabled: currentPage === totalPages})}>
        {currentPage < totalPages && <Link to={this.getPathObject(totalPages)} />}
      </span>
    );
  }

  render() {
    const {pagination} = this.props;
    const currentPage = pagination.current_page;
    const totalPages = pagination.total_pages;

    if (!totalPages || totalPages === 1) {
      return null;
    }

    return (
      <nav className="pagination">
        {this.renderFirstPage(currentPage)}
        {this.renderPreviousPage(currentPage)}
        {this.renderPreviousPreviousPageNumber(currentPage)}
        {this.renderPreviousPageNumber(currentPage)}
        {this.renderCurrentPageNumber(currentPage)}
        {this.renderNextPageNumber(currentPage, totalPages)}
        {this.renderNextNextPageNumber(currentPage, totalPages)}
        {this.renderNextPage(currentPage, totalPages)}
        {this.renderLastPage(currentPage, totalPages)}
      </nav>
    );
  }
}

Pagination.propTypes = {
  pagination: PropTypes.object.isRequired
};

export default Pagination;
