import React from "react";
import classnames from "classnames";

import {METRIC_MEASURES} from "../../../../../../constants";

import {isNumber} from "../../../../../../helpers/numbersHelper";

import DefaultModal from "../../../../../../components/shared/modals/DefaultModal";

const MODAL_NAME = "SCENARIO_CONTENT_KEY_POINTS_REVIEW_MODAL";

const KeyPointsReviewModal = ({id, keyPointsValueResult = {}, keyPoints, userKeyPoints}) => {
  const modalName = MODAL_NAME + id;
  const keyPointsValue = keyPointsValueResult.raw_value || "-";
  const isPassed = Boolean(keyPointsValueResult.passed);
  const buttonText =
    _.isEmpty(keyPoints) ? "No KPs" : `${keyPointsValue}${METRIC_MEASURES.key_points_value}`;

  return (
    <DefaultModal
      buttonClass={classnames(
        "scenario-content-lines__key-points-button button button_color_black button_size_xs",
        {
          "scenario-review-lines__failure": !isPassed && keyPoints.length !== 0,
          button_color_grey: keyPoints.length === 0
        }
      )}
      buttonDisabled={_.isEmpty(keyPoints)}
      buttonText={buttonText}
      modalName={modalName}
      modalTitle={I18n.t("dashboard.scenario_review.key_points_title", {value: keyPointsValue})}
    >
      <div className="bem-popup__content">
        <div className="mt-3">
          <div className="key-points-popup-subtitle">
            {I18n.t("dashboard.scenario_review.user_key_points_subtitle")}
          </div>
          {userKeyPoints.map((userKeyPoint, index) => (
            <span
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={classnames("key-point key-point_user", {
                "key-point_mismatched": !userKeyPoint.is_matched
              })}
            >
              {userKeyPoint.title}
            </span>
          ))}
          <div className="mt-4">
            <div className="key-points-popup-subtitle">
              {I18n.t("dashboard.scenario_review.key_points_subtitle")}
            </div>
            {keyPoints.map((keyPoint, index) => (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={classnames("key-point", {"key-point_mismatched": !keyPoint.is_matched})}
              >
                {keyPoint.title}
              </span>
            ))}
          </div>
        </div>
      </div>
    </DefaultModal>
  );
};

export default KeyPointsReviewModal;
