import React, {Component} from "react";
import jstz from "jstz";

class TimezoneDetect extends Component {
  componentDidMount() {
    document.cookie = "timezone=" + jstz.determine().name() + ";";
  }

  render() {
    return null;
  }
}

export default TimezoneDetect;
