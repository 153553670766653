import {toPolicyObject} from "react-pundit";

import ProgressDashboardPolicy from "./ProgressDashboardsPolicy";
import UserPolicy from "./UserPolicy";
import CompanyPolicy from "./CompanyPolicy";
import UserScenarioPolicy from "./UserScenarioPolicy";
import PackPolicy from "./PackPolicy";
import PackCategoryPolicy from "./PackCategoryPolicy";
import CoursePolicy from "./CoursePolicy";
import TagPolicy from "./TagPolicy";

export default toPolicyObject([
  new ProgressDashboardPolicy(),
  new UserPolicy(),
  new CompanyPolicy(),
  new UserScenarioPolicy(),
  new PackPolicy(),
  new PackCategoryPolicy(),
  new CoursePolicy(),
  new TagPolicy()
]);
