import React, {Fragment} from "react";

import KeyPointsModal from "../../scenario_content/KeyPointsModal";
import PlayAudioButton from "../../scenario_content/PlayAudioButton";
import AudioPlayer from "../../scenario_content/AudioPlayer";
import Participant from "./Participant";
import ParticipantFocuses from "./ParticipantFocuses";
import FormattedText from "./FormattedText";

const Lines = ({scenario: {lines, participants}}) => {
  return (
    <table className="scenario-edit__table scenario-edit__table--lines scenario-edit__table--lines-show">
      <thead>
        <tr>
          <th aria-label="Index" />
          <th>{I18n.t("mongoid.attributes.line.participant")}</th>
          <th>{I18n.t("mongoid.attributes.line.text")}</th>
          <th>{I18n.t("mongoid.attributes.line.translation")}</th>
          <th colSpan={participants.length}>
            {I18n.t("mongoid.attributes.line.participant_focuses")}
          </th>
          <th>{I18n.t("mongoid.attributes.line.key_points")}</th>
          <th>{I18n.t("mongoid.attributes.line.audio_track")}</th>
          <th aria-label="player" />
        </tr>
        <tr>
          <th aria-label="Empty space" colSpan={4} />
          {participants.map(participant => (
            <th key={participant.id}>
              <div className="participant-avatar participant-avatar--focus avatar">
                <img alt="" src={participant.avatar_url} />
              </div>
            </th>
          ))}
          <th aria-label="Empty space" />
        </tr>
      </thead>
      <tbody>
        {lines.map((line, index) => (
          <Fragment key={line.id}>
            <tr className="scenario-line">
              <td>
                <div className="scenario-line__line-number">{index + 1}</div>
              </td>

              <td>
                <Participant
                  avatarUrl={line.participant.avatar_url}
                  isBold
                  name={line.participant.role}
                />
              </td>

              <td>
                <FormattedText className="scenario-edit__form-value" text={line.text} />
              </td>

              <td>
                <div className="scenario-edit__form-value">{line.translation}</div>
              </td>

              <ParticipantFocuses line={line} participants={participants} />

              <td>
                <KeyPointsModal keyPoints={line.key_points} lineId={line.id} />
              </td>

              <td>
                <PlayAudioButton
                  audioTrackUrl={line.audio_track_url}
                  disabled={!line.audio_track_url}
                  id={line.id}
                  title={
                    line.audio_track_url ? null : I18n.t("scenario_contents.audio_not_generated")
                  }
                  type="ear"
                />
              </td>
            </tr>
            <tr>
              <td colSpan={4} />
              <td colSpan={participants.length + 2}>
                <AudioPlayer className="audio-player-mf_full-width" id={line.id} />
              </td>
            </tr>
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default Lines;
