import moment from "moment-timezone";

export function formatMetricTime(time) {
  const format = "L (ddd) HH:mm";
  const date = moment(time).format(format);

  if (date === moment().format(format)) {
    return `${I18n.t("today")} ${moment(time).format("HH:mm")}`;
  } else if (date === moment().subtract(1, "days").format("L")) {
    return `${I18n.t("yesterday")} ${moment(time).format("HH:mm")}`;
  } else {
    return date;
  }
}
