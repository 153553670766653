import React from "react";
import PropTypes from "prop-types";

import ActivityStats from "./ActivityStats";

const Overall = ({isLoaded, ...restProps}) => {
  if (!isLoaded) {
    return null;
  }

  return <ActivityStats {...restProps} />;
};

Overall.propTypes = {
  changeUserActivitiesOrder: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  isLoaded: PropTypes.bool,
  userActivities: PropTypes.object.isRequired
};

export default Overall;
