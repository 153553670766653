import React from "react";
import {SortableElement} from "react-sortable-hoc";

import TagEdit from "./TagEdit";

const SortableTag = SortableElement(({tagId, openEditModal, formAttributes, assignTagAttributes}) => {
  return (
    <TagEdit id={tagId} formAttributes={formAttributes} assignTagAttributes={assignTagAttributes} openEditModal={openEditModal}/>
  );
});

export default SortableTag;
