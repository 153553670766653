/* eslint-disable */
import React, {memo} from "react";

const fn = ({className}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path fill="none" fillRule="evenodd" stroke="#C6C1BB" strokeWidth="1.5" d="M4 3.75c-.69 0-1.25.56-1.25 1.25v8c0 1.243 1.007 2.25 2.25 2.25h10c1.243 0 2.25-1.007 2.25-2.25V8c0-1.243-1.007-2.25-2.25-2.25H9.708l-2.11-1.934c-.046-.043-.106-.066-.169-.066H4z"/>
  </svg>
);

export default memo(fn);

