import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchMetricsError} from "../../helpers/errorsHelpers";

import {fetchMetrics} from "../../store/actions/metricsActions";

class FetchMetrics extends Component {
  componentDidMount() {
    this.fetchMetrics(true);
  }

  componentDidUpdate() {
    this.fetchMetrics(false);
  }

  fetchMetrics(isComponentMount) {
    const {metrics, userId, userScenarioId, page} = this.props;
    const isWrongCollection = metrics.userId !== userId || metrics.userScenarioId !== userScenarioId;
    const isReasonToFetch = isComponentMount || !metrics.isCollectionLoaded || isWrongCollection;

    if (isReasonToFetch && !metrics.isLoading && !isFetchMetricsError(metrics)) {
      this.props.fetchMetrics(userId, {userScenarioId, page: page});
    }
  }

  render() {
    return null;
  }
}

FetchMetrics.propTypes = {
  userId: PropTypes.string.isRequired,
  userScenarioId: PropTypes.string,
  page: PropTypes.number.isRequired,
  metrics: PropTypes.object.isRequired,
  fetchMetrics: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    metrics: state.metrics
  }
}

const mapActionsToProps = {
  fetchMetrics
};

export default connect(mapStateToProps, mapActionsToProps)(FetchMetrics);
