import React from "react";
import {newUserSessionPath} from "helpers/routesHelper";

import {Link} from "react-router-dom";
import SvgIcon from "components/SvgIcon";
import UnauthenticatedLayout from "views/layouts/UnauthenticatedLayout";

const EmailSent = () => (
  <UnauthenticatedLayout>
    <div className="login-section">
      <SvgIcon className="login-section__title" icon="smart-tutor-logo" />
      <div className="login-section__subtitle">{I18n.t("passwords.password_updated")}</div>
      <div className="pt-3">
        <Link
          className="login-section__submit button d-flex justify-content-center align-items-center"
          to={newUserSessionPath()}
        >
          {I18n.t("log_in")}
        </Link>
      </div>
    </div>
  </UnauthenticatedLayout>
);

export default EmailSent;
