import {ACTIONS} from "../reducers/packsReducer";

import webToolApi from "../../helpers/webToolApiHelper";

import {notifyResponseError, notifySuccess} from "./notificationsActions";
import {defaultErrorAction} from "./errorsActions";
import {fetchPackCategory} from "./packCategoriesActions";
import {fetchProfile} from "./profilesActions";

export function changePacksLoading(isLoading) {
  return {type: ACTIONS.CHANGE_PACKS_LOADING, isLoading};
}

export function fetchPacks(categoryId, {page} = {}) {
  return (dispatch) => {
    dispatch(changePacksLoading(true));
    dispatch({type: ACTIONS.FETCH_PACKS});

    webToolApi().get(Routes.pack_category_packs_path(categoryId, {page}))
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_PACKS_FULFILLED, payload: response.data, categoryId, page});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_PACKS_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("packs.messages.index.error"), response));
      })
      .finally(() => {
        dispatch(changePacksLoading(false));
      });
  }
}

export function fetchPack(id) {
  return (dispatch) => {
    dispatch(changePacksLoading(true));
    dispatch({type: ACTIONS.FETCH_PACK});

    webToolApi().get(Routes.pack_path(id))
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_PACK_FULFILLED, pack: response.data.pack});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_PACK_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("packs.messages.show.error"), response));
      })
      .finally(() => {
        dispatch(changePacksLoading(false));
      });
  }
}

export function assignPackAttributes(attributes) {
  return {type: ACTIONS.ASSIGN_PACK_ATTRIBUTES, attributes};
}

export function clearPackAttributes() {
  return {type: ACTIONS.CLEAR_PACK_ATTRIBUTES};
}

export function createPack(pack, {onSuccess} = {}) {
  return (dispatch) => {
    dispatch(changePacksLoading(true));

    webToolApi().post(Routes.packs_path(), {pack})
      .then((response) => {
        const pack = response.data.pack;
        dispatch({type: ACTIONS.CREATE_PACK_FULFILLED, pack});
        dispatch(notifySuccess(I18n.t("packs.messages.create.success")));
        dispatch(clearPackAttributes());
        dispatch(fetchPackCategory(pack.category_id));
        dispatch(fetchProfile());
        dispatch(changePacksLoading(false));
        onSuccess && onSuccess();
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.CREATE_PACK_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("packs.messages.create.error"), response));
        dispatch(changePacksLoading(false));
      });
  }
}

export function updatePack(pack, {onSuccess} = {}) {
  return (dispatch) => {
    dispatch(changePacksLoading(true));

    webToolApi().put(Routes.pack_path(pack.id), {pack})
      .then((response) => {
        dispatch({type: ACTIONS.UPDATE_PACK_FULFILLED, pack: response.data.pack});
        dispatch(notifySuccess(I18n.t("packs.messages.update.success")));
        dispatch(clearPackAttributes());
        dispatch(fetchProfile());
        dispatch(changePacksLoading(false));
        onSuccess && onSuccess();
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.UPDATE_PACK_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("packs.messages.update.error"), response));
        dispatch(changePacksLoading(false));
      });
  }
}

export function destroyPack(categoryId, packId) {
  return (dispatch) => {
    dispatch(changePacksLoading(true));

    webToolApi().delete(Routes.pack_path(packId))
      .then(() => {
        dispatch({type: ACTIONS.DESTROY_PACK_FULFILLED, packId});
        dispatch(notifySuccess(I18n.t("packs.messages.destroy.success")));
        dispatch(fetchPackCategory(categoryId));
        dispatch(fetchProfile());
        dispatch(changePacksLoading(false));
      })
      .catch(({response}) => {
        dispatch(notifyResponseError(I18n.t("packs.messages.destroy.error"), response));
        dispatch(changePacksLoading(false));
      });
  }
}

export function setPackRemovingState(packId, removingState) {
  return {type: ACTIONS.SET_PACK_REMOVING_STATE, packId, removingState};
}

export function selectPack(packId) {
  return {type: ACTIONS.SELECT_PACK, packId};
}

export function deselectPack(packId) {
  return {type: ACTIONS.DESELECT_PACK, packId};
}

export function deselectAllPacks() {
  return {type: ACTIONS.DESELECT_ALL_PACKS};
}

export function importPacks(file) {
  return (dispatch) => {
    dispatch(changePacksLoading(true));

    const formData = new FormData();
    formData.append("file", file);

    webToolApi().post(Routes.packs_imports_path(), formData, {headers: {"Content-Type": "multipart/form-data"}})
      .then(() => {
        dispatch({type: ACTIONS.IMPORT_PACKS_FULFILLED});
        dispatch(notifySuccess(I18n.t("packs.messages.import.success")));
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.IMPORT_PACKS_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("packs.messages.import.error"), response));
      })
      .finally(() => {
        dispatch(changePacksLoading(false));
      });
  }
}
