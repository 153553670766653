import React, {Component} from "react";
import PropTypes from "prop-types";
import Select2 from "react-select2-wrapper";

import {LIMITED_PACK_VISIBILITY} from "../../constants";

import {enumOptionsForSelect} from "../../helpers/enumI18nHelper";
import {isLimited} from "../../helpers/packsHelper";

class VisibilitySelect extends Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
  }

  renderVisibilitySelect2Template = (state) => {
    if (!state.loading && state.element.attributes.value.value === LIMITED_PACK_VISIBILITY) {
      return `${state.text} (${this.props.formAttributes.allowed_user_ids.length})`;
    }
    return state.text;
  };

  rerenderVisibilitySelect = () => {
    const attributes = this.props.formAttributes;

    if (isLimited(attributes)) {
      const visibilitySelect2 = this.selectRef.current;
      // Rerender visibility select2 value (change limited visibility counter)
      visibilitySelect2 && visibilitySelect2.updateSelect2Value(attributes.visibility);
    }
  };

  get selectOptions() {
    return this.props.isShowLimited ? ["public", "private", "limited"] : ["public", "private"];
  }

  render() {
    const {onChange} = this.props;
    const attributes = this.props.formAttributes;

    this.rerenderVisibilitySelect();

    return (
      <div className="form-group form-group_size_sm align-items-baseline">
        <label htmlFor="visibility" className="form-group__label form-group__label_width_sm">
          {I18n.t("mongoid.attributes.pack.visibility")}
        </label>
        <div className="form-group__input-wrapper">
          <Select2
            id="visibility"
            name="visibility"
            ref={this.selectRef}
            value={attributes.visibility}
            onChange={onChange}
            className="select2-in select2_size_sm form-group__input"
            data={enumOptionsForSelect("pack", "visibility", {only: this.selectOptions})}
            options={{
              minimumResultsForSearch: -1,
              templateSelection: this.renderVisibilitySelect2Template,
              theme: "size--sm"
            }}
          />
        </div>
      </div>
    );
  }
}

VisibilitySelect.propTypes = {
  formAttributes: PropTypes.object.isRequired,
  isShowLimited: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default VisibilitySelect;
