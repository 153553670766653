export function isNumber(val) {
  return typeof val == "number" || Object.prototype.toString.call(val) === "[object Number]";
}

export default function numberToPercentage(number) {
  if (!isNumber(number)) {
    return null;
  }
  return `${Math.round(number)}%`;
}
