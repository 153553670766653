import React, {Fragment} from "react";
import classnames from "classnames";
import _ from "lodash";

import KeyPoints from "./KeyPoints";
import WrappedText from "./WrappedText";

const LineText = ({line, results}) => {
  if (!line.summary) return line.filtered_text;

  const keyPointsValueResult = _.find(results, {metric: "key_points_value"});
  const isKeyPointsPassed = keyPointsValueResult && keyPointsValueResult.passed;

  return (
    <Fragment>
      <span className="scenario-review-lines__text-label">
        {I18n.t("dashboard.scenario_review.summary_label")}
      </span>
      <div className={classnames("mt-2", {"scenario-review-lines__failure": !isKeyPointsPassed})}>
        <WrappedText text={line.user_text} type="warning" words={line.words} />
      </div>
    </Fragment>
  );
};

const ListeningColumns = ({isListeningSummaryLine, isUserLine, line, metric}) => (
  <Fragment>
    <td>
      <LineText line={line} results={metric.results} />
    </td>
    <td>
      <KeyPoints
        isListeningSummaryLine={isListeningSummaryLine}
        isUserLine={isUserLine}
        line={line}
        metric={metric}
      />
    </td>
  </Fragment>
);

export default ListeningColumns;
