/* eslint-disable */
import React, {memo} from "react";

const fn = ({className}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31">
    <g fill="#56BE71" fillRule="evenodd">
      <path fillRule="nonzero" d="M6.642 10.396L4.861 8.39 4.267 9.059 6.642 11.733 11.733 6.002 11.139 5.333z" transform="translate(8 8)"/>
      <path d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 1C4.134 1 1 4.134 1 8s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7z" transform="translate(8 8)"/>
    </g>
  </svg>

);

export default memo(fn);

