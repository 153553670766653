export const ACTIONS = {
  SET_FLASH: "SET_FLASH",
  CLEAR_FLASH: "CLEAR_FLASH"
};

export default function reducer(state = {
  flash: [],
  isCollectionLoaded: false
}, action) {
  switch (action.type) {
    case ACTIONS.SET_FLASH: {
      return {...state, flash: action.flash, isCollectionLoaded: true};
    }
    case ACTIONS.CLEAR_FLASH: {
      return {...state, flash: []};
    }
  }
  return state;
}
