import _ from "lodash";

import {SUCCESS_STATUS_CODE, PRIVATE_PACK_VISIBILITY} from "../../constants";

export const ACTIONS = {
  CHANGE_PACKS_LOADING: "CHANGE_PACKS_LOADING",
  FETCH_PACKS: "FETCH_PACKS",
  FETCH_PACKS_FULFILLED: "FETCH_PACKS_FULFILLED",
  FETCH_PACKS_REJECTED: "FETCH_PACKS_REJECTED",
  FETCH_PACK: "FETCH_PACK",
  FETCH_PACK_FULFILLED: "FETCH_PACK_FULFILLED",
  FETCH_PACK_REJECTED: "FETCH_PACK_REJECTED",
  ASSIGN_PACK_ATTRIBUTES: "ASSIGN_PACK_ATTRIBUTES",
  CLEAR_PACK_ATTRIBUTES: "CLEAR_PACK_ATTRIBUTES",
  CREATE_PACK_FULFILLED: "CREATE_PACK_FULFILLED",
  CREATE_PACK_REJECTED: "CREATE_PACK_REJECTED",
  UPDATE_PACK_FULFILLED: "UPDATE_PACK_FULFILLED",
  UPDATE_PACK_REJECTED: "UPDATE_PACK_REJECTED",
  SET_PACK_REMOVING_STATE: "SET_PACK_REMOVING_STATE",
  DESTROY_PACK_FULFILLED: "DESTROY_PACK_FULFILLED",
  DESTROY_PACK_REJECTED: "DESTROY_PACK_REJECTED",
  IMPORT_PACKS_FULFILLED: "IMPORT_PACKS_FULFILLED",
  IMPORT_PACKS_REJECTED: "IMPORT_PACKS_REJECTED",
  SELECT_PACK: "SELECT_PACK",
  DESELECT_PACK: "DESELECT_PACK",
  DESELECT_ALL_PACKS: "DESELECT_ALL_PACKS"
};

const DEFAULT_SUCCESS_STATUS = {errors: {}, status: SUCCESS_STATUS_CODE};
const DEFAULT_STATUSES = {
  [ACTIONS.FETCH_PACKS]: {errors: {}, status: null},
  [ACTIONS.FETCH_PACK]: {errors: {}, status: null}
};

const INITIAL_FORM_ATTRIBUTES = {
  title: "",
  visibility: PRIVATE_PACK_VISIBILITY,
  allowed_user_ids: [],
  errors: {}
};

export default function reducer(state = {
  collection: [],
  current: {},
  formAttributes: INITIAL_FORM_ATTRIBUTES,
  selectedIds: [],
  categoryId: "",
  isCollectionLoaded: false,
  isLoading: false,
  loadingCount: 0,
  pagination: {},
  page: 0,
  statuses: DEFAULT_STATUSES
}, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_PACKS_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);
      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.FETCH_PACKS: {
      return {...state, categoryId: "", collection: [], pagination: {}, page: 0, isCollectionLoaded: false};
    }
    case ACTIONS.FETCH_PACKS_FULFILLED: {
      return {...state,
        categoryId: action.categoryId,
        collection: action.payload.packs,
        pagination: action.payload.pagination,
        page: action.page,
        isCollectionLoaded: true,
        statuses: {...state.statuses, [ACTIONS.FETCH_PACKS]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_PACKS_REJECTED: {
      return {
        ...state,
        statuses: {...state.statuses, [ACTIONS.FETCH_PACKS]: {errors: action.errors, status: action.status}}
      };
    }

    case ACTIONS.FETCH_PACK: {
      return {...state, current: {}};
    }
    case ACTIONS.FETCH_PACK_FULFILLED: {
      const clonedCollection = [...state.collection];
      const packIndex = _.findIndex(clonedCollection, {id: action.pack.id});
      if (packIndex >= 0) {
        clonedCollection[packIndex] = action.pack;
        return {
          ...state,
          current: action.pack,
          collection: clonedCollection,
          statuses: {...state.statuses, [ACTIONS.FETCH_PACK]: DEFAULT_SUCCESS_STATUS}
        };
      }
      return {
        ...state,
        current: action.pack,
        statuses: {...state.statuses, [ACTIONS.FETCH_PACK]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_PACK_REJECTED: {
      return {
        ...state,
        statuses: {...state.statuses, [ACTIONS.FETCH_PACK]: {errors: action.errors, status: action.status}}
      };
    }

    case ACTIONS.ASSIGN_PACK_ATTRIBUTES: {
      const newFormAttributes = {errors: state.formAttributes.errors, ...action.attributes};
      return {...state, formAttributes: newFormAttributes};
    }
    case ACTIONS.CLEAR_PACK_ATTRIBUTES: {
      return {...state, formAttributes: {...INITIAL_FORM_ATTRIBUTES}};
    }

    case ACTIONS.CREATE_PACK_FULFILLED: {
      return {...state, isCollectionLoaded: false, collection: [], current: action.pack};
    }
    case ACTIONS.CREATE_PACK_REJECTED: {
      return {...state, formAttributes: {...state.formAttributes, errors: action.errors}};
    }

    case ACTIONS.UPDATE_PACK_FULFILLED: {
      return {...state, isCollectionLoaded: false, collection: [], current: action.pack};
    }
    case ACTIONS.UPDATE_PACK_REJECTED: {
      return {...state, formAttributes: {...state.formAttributes, errors: action.errors}};
    }

    case ACTIONS.SET_PACK_REMOVING_STATE: {
      const clonedCollection = [...state.collection];
      const packIndex = _.findIndex(clonedCollection, {id: action.packId});
      clonedCollection[packIndex] = {...clonedCollection[packIndex], removingState: action.removingState};
      return {...state, collection: clonedCollection};
    }
    case ACTIONS.DESTROY_PACK_FULFILLED: {
      return {...state, isCollectionLoaded: false, collection: []};
    }

    case ACTIONS.IMPORT_PACKS_FULFILLED: {
      return {...state, isCollectionLoaded: false, collection: []};
    }

    case ACTIONS.SELECT_PACK: {
      return {...state, selectedIds: [...state.selectedIds, action.packId]};
    }

    case ACTIONS.DESELECT_PACK: {
      return {...state, selectedIds: _.without(state.selectedIds, action.packId)};
    }

    case ACTIONS.DESELECT_ALL_PACKS: {
      return {...state, selectedIds: []};
    }
  }
  return state;
}
