import React, {Component, Fragment, cloneElement} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Modal from "react-modal";

import {DEFAULT_MODEL_CONTENT_STYLE} from "./defaultContentStyle";

import {closeModal, openModal} from "../../../store/actions/modalsActions";

class BaseModal extends Component {
  isOpened = () => {
    return this.props.modals.current === this.props.modalName;
  };

  openModal = (event) => {
    event && event.preventDefault();
    this.props.openModal(this.props.modalName);
  };

  closeModal = (event) => {
    event && event.preventDefault();
    this.props.onClose && this.props.onClose();
    this.props.closeModal(this.props.modalName);
  };

  renderButton() {
    const {button, isRenderButton} = this.props;

    if (button) {
      return cloneElement(button, {onClick: this.openModal});
    }

    if (isRenderButton) {
      const {buttonClass, buttonText, buttonDisabled, buttonTitle} = this.props;

      return (
        <button className={buttonClass} onClick={this.openModal} disabled={buttonDisabled} title={buttonTitle}>
          {buttonText}
        </button>
      );
    }

    return null;
  }

  render() {
    const modalStyle = {
      content: {...DEFAULT_MODEL_CONTENT_STYLE, ...this.props.contentStyle}
    };

    const {onOpen} = this.props;

    return (
      <Fragment>
        {this.renderButton()}
        <Modal isOpen={this.isOpened()}
               onAfterOpen={onOpen}
               onRequestClose={this.closeModal}
               style={modalStyle}
               ariaHideApp={false}>
          {this.props.children}
        </Modal>
      </Fragment>
    )
  }
}

BaseModal.defaultProps = {
  isRenderButton: true
};

BaseModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  isRenderButton: PropTypes.bool,
  button: PropTypes.object,
  buttonClass: PropTypes.string,
  buttonText: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  buttonTitle: PropTypes.string,
  contentStyle: PropTypes.object,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  modals: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    modals: state.modals
  }
}

const mapActionsToProps = {
  openModal,
  closeModal
};

export default connect(mapStateToProps, mapActionsToProps)(BaseModal);
