import {PunditPolicy} from "react-pundit";

import {isManager, isStudent} from "../helpers/userRolesHelper";

class ProgressDashboardPolicy extends PunditPolicy {
  constructor() {
    super("ProgressDashboard");
  }

  Index(model, user) {
    return isManager(user) || isStudent(user);
  }
}

export default ProgressDashboardPolicy;
