import BaseRecaptcha from "react-recaptcha";
import React, {useEffect, useRef} from "react";

const Recaptcha = ({disableResetCaptcha, isReset, verifyCallback}) => {
  const ref = useRef();

  const resetCaptcha = () => {
    if (isReset) {
      disableResetCaptcha();
      ref.current.reset();
    }
  };

  useEffect(resetCaptcha);

  return (
    <BaseRecaptcha
      ref={ref}
      onloadCallback={() => {}}
      render="explicit"
      sitekey={window.recaptcha_site_key}
      verifyCallback={verifyCallback}
    />
  );
};

export default Recaptcha;
