import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import classnames from "classnames";

import {REMOVING_STATES} from "../../constants";

import {coursePath} from "../../helpers/routesHelper";
import {listItemDateLabel} from "../../helpers/listItemsHelper";

import {destroyCourse, setCourseRemovingState} from "../../store/actions/coursesActions";
import ContextMenu from "../../components/shared/context_menu/ContextMenu";
import ContextMenuItem from "../../components/shared/context_menu/ContextMenuItem";
import DeleteListItem from "../../components/shared/DeleteListItem";

class Course extends Component {
  showRemoveDialog = (event) => {
    event && event.preventDefault();
    this.props.setCourseRemovingState(this.props.course.id, REMOVING_STATES.REMOVING);
  };

  onDestroy = () => {
    const {course, destroyCourse} = this.props;
    destroyCourse(course.id);
  };

  render() {
    const {course, setCourseRemovingState} = this.props;

    return (
      <Link to={coursePath(course.id)} className="scenario-list-item">
        <div className="scenario-list-item__icon scenario-list-item__icon--course"/>
        <div className="scenario-list-item__content">
          <div className="scenario-list-item__title" title={course.title}>
            {course.title}
          </div>
          <div className="scenario-list-item__secondary-content">
            <div className="scenario-list-item__date ml-0">
              {listItemDateLabel(course.updated_at)}
            </div>
          </div>
        </div>
        <div className="scenario-list-item__actions">
          <ContextMenu className="scenario-list-item__context-menu">
            <ContextMenuItem onClick={this.showRemoveDialog} color="red">
              {I18n.t("delete")}
            </ContextMenuItem>
          </ContextMenu>
        </div>
        <DeleteListItem objectWithState={course} onDestroy={this.onDestroy} setRemovingState={setCourseRemovingState}/>
      </Link>
    );
  }
}

Course.propTypes = {
  setCourseRemovingState: PropTypes.func.isRequired,
  destroyCourse: PropTypes.func.isRequired
};

const mapActionsToProps = {
  setCourseRemovingState,
  destroyCourse
};

export default connect(null, mapActionsToProps)(Course);
