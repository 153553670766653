import React, {Component} from "react";
import moment from "moment-timezone";

import {JAPAN_TIMEZONE} from '../../constants';

class UseTimezone extends Component {
  constructor(props) {
    super(props);

    moment.tz.setDefault(JAPAN_TIMEZONE);
  }

  render() {
    return null;
  }
}

export default UseTimezone;
