import React, {Component} from "react";
import PropTypes from "prop-types";
import moment from "moment";

import {MODAL_NAME as UPDATE_SCORE_MODAL_NAME} from "./_UpdateScoreModal";
import {MODAL_NAME as DELETE_SCORE_MODAL_NAME} from "./_DeleteScoreModal";

import valueOrDash from "../../../helpers/valueOrDash";

import ContextMenu from "../../../components/shared/context_menu/ContextMenu";
import ContextMenuItem from "../../../components/shared/context_menu/ContextMenuItem";

class Row extends Component {
  handleUpdate = () => {
    const {englishScore, openModal} = this.props;
    openModal(UPDATE_SCORE_MODAL_NAME, {englishScore});
  };

  handleDelete = () => {
    const {englishScore, openModal} = this.props;
    openModal(DELETE_SCORE_MODAL_NAME, {id: englishScore.id});
  };

  render() {
    const {englishScore} = this.props;

    return (
      <div className="score-section__block">
        <div className="score-section__field">
          <div className="score-section__label">
            {I18n.t(`mongoid.attributes.english_score.score`)}
          </div>
          <div className="score-section__value">
            {valueOrDash(englishScore.score)}
          </div>
          <div className="score-section__date">
            {moment(englishScore.date).format(I18n.t("date.moment_formats.activity_date"))}
          </div>
          <ContextMenu className="score-section__context-menu">
            <ContextMenuItem onClick={this.handleUpdate}>
              {I18n.t("edit")}
            </ContextMenuItem>
            <ContextMenuItem onClick={this.handleDelete}>
              {I18n.t("delete")}
            </ContextMenuItem>
          </ContextMenu>
        </div>
      </div>
    );
  }
}

Row.propTypes = {
  englishScore: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired
};

export default Row;
