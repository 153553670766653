import {ACTIONS} from "../reducers/sessionsReducer";
import {ACTIONS as PROFILE_ACTIONS} from "../reducers/profilesReducer";
import {SHOW_RECAPTCHA_HEADER_KEY} from "../../constants";

import webToolApi from "../../helpers/webToolApiHelper";
import {updateCsrfToken} from "../../helpers/csrfHelper";
import {newUserSessionPath, rootPath} from "../../helpers/routesHelper";
import {isAdmin} from "../../helpers/userRolesHelper";

import {fetchCompany} from "./companiesActions";
import {unloadData} from "./unloadDataActions";
import {notifyError, notifyServiceUnavailableError} from "./notificationsActions";

export function changeSessionsLoading(isLoading) {
  return {type: ACTIONS.CHANGE_SESSIONS_LOADING, isLoading};
}

export function changeSessionsShowCaptcha(isShowCaptcha) {
  return {type: ACTIONS.CHANGE_SESSIONS_SHOW_CAPTCHA, isShowCaptcha};
}

export function enableSessionsSubmit() {
  return {type: ACTIONS.ENABLE_SESSIONS_SUBMIT};
}

export function disableSessionsResetCaptcha() {
  return {type: ACTIONS.DISABLE_SESSIONS_RESET_CAPTCHA};
}

export function assignSessionAttributes(attributes) {
  return {type: ACTIONS.ASSIGN_SESSION_ATTRIBUTES, attributes};
}

export function clearSessionAttributes() {
  return {type: ACTIONS.CLEAR_SESSION_ATTRIBUTES};
}

export function createSession(session, history) {
  return (dispatch) => {
    dispatch(changeSessionsLoading(true));

    webToolApi().post(Routes.user_session_path(), {user: session})
      .then((response) => {
        const {csrf_token, user} = response.data;
        if (isAdmin(user)) {
          window.location = Routes.admin_root_path({format: ""});
        } else {
          updateCsrfToken(csrf_token);
          dispatch(changeSessionsShowCaptcha(false));
          dispatch({type: ACTIONS.CREATE_SESSION_FULFILLED, user});
          dispatch(clearSessionAttributes());
          dispatch(fetchCompany());
          dispatch({type: PROFILE_ACTIONS.FETCH_PROFILE_FULFILLED, profile: user});
          dispatch(changeSessionsLoading(false));
          history.push(rootPath());
        }
      })
      .catch(({response}) => {
        dispatch({type: ACTIONS.CREATE_SESSION_REJECTED});
        if (response) {
          if (response.data.error) {
            dispatch(notifyError(response.data.error));
          } else {
            response.data.errors.forEach((message) => dispatch(notifyError(message)));
          }
          if (response.headers[SHOW_RECAPTCHA_HEADER_KEY]) {
            dispatch(changeSessionsShowCaptcha(true));
          }
        } else {
          dispatch(notifyServiceUnavailableError());
        }
        dispatch(changeSessionsLoading(false));
      });
  }
}

export function destroySession(history) {
  return (dispatch) => {
    dispatch(changeSessionsLoading(true));

    webToolApi().delete(Routes.destroy_user_session_path())
      .then((response) => {
        updateCsrfToken(response.data.csrf_token);
        dispatch({type: ACTIONS.DESTROY_SESSION_FULFILLED});
        history.push(newUserSessionPath());
        dispatch(changeSessionsLoading(false));
        unloadData(dispatch)
      })
      .catch(({response}) => {
        if (response) {
          if (response.status === 401) {
            window.location = newUserSessionPath();
          } else if (response.data.error) {
            dispatch(notifyError(response.data.error));
          } else {
            dispatch(notifyError(I18n.t("messages.logout_failed")))
          }
        } else {
          dispatch(notifyServiceUnavailableError());
        }
        dispatch(changeSessionsLoading(false));
      });
  }
}
