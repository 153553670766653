import React, {Component} from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import ConfirmationModal from "../../components/shared/modals/ConfirmationModal";
import ContextMenuItem from "../../components/shared/context_menu/ContextMenuItem";

const MODAL_NAME = "COURSE_DELETE_SCENARIO_MODAL";

class DeleteScenarioModal extends Component {
  get modalName() {
    return `${MODAL_NAME}_${this.props.scenarioId}`;
  };

  onSubmit = (closeModal) => {
    const attributes = this.props.formAttributes;
    const changedScenarios = _.reject(attributes.user_scenarios, {id: this.props.scenarioId});
    this.props.assignCourseAttributes({...attributes, user_scenarios: changedScenarios});
    closeModal();
  };

  get button() {
    return (
      <ContextMenuItem color="red" hideContextMenu={this.props.hideContextMenu}>
        {I18n.t("delete")}
      </ContextMenuItem>
    );
  }

  render() {
    return (
      <ConfirmationModal
        modalName={this.modalName}
        button={this.button}
        message={I18n.t("courses.popups.delete_scenario")}
        onSubmit={this.onSubmit}
      />
    );
  }
}

DeleteScenarioModal.propTypes = {
  scenarioId: PropTypes.string.isRequired,
  formAttributes: PropTypes.object.isRequired,
  assignCourseAttributes: PropTypes.func.isRequired,
  hideContextMenu: PropTypes.func // Injected by the ContextMenu component
};

export default DeleteScenarioModal;
