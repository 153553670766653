/* eslint-disable */
import React, {memo} from "react";

const fn = ({className}) => (
  <svg className={className} width="268" height="211" viewBox="0 0 268 211" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="88" height="88">
      <circle cx="44" cy="44" r="44" fill="#D71414"/>
    </mask>
    <g mask="url(#mask0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M32 0H56V25H32V0ZM56 32H32H0V56H32V88H56V56H88V32H56Z" fill="#D81414"/>
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M178 211C227.706 211 268 170.706 268 121C268 71.2944 227.706 31 178 31C128.294 31 88 71.2944 88 121C88 170.706 128.294 211 178 211ZM178 179C210.033 179 236 153.033 236 121C236 88.9675 210.033 63 178 63C145.967 63 120 88.9675 120 121C120 153.033 145.967 179 178 179Z" fill="#333333"/>
  </svg>
);

export default memo(fn);

