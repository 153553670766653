import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {userScenarioPath} from "../../helpers/routesHelper";
import {scenarioTags} from "../../helpers/scenariosHelper";
import {listItemDateLabel} from "../../helpers/listItemsHelper";

class UserScenario extends Component {
  render() {
    const {userScenario} = this.props;

    return (
      <div className="list-item-counter">
        <Link to={userScenarioPath(userScenario.id)} className="scenario-list-item">
          <div className="scenario-list-item__icon scenario-list-item__icon--scenario"/>
          <div className="scenario-list-item__content">
            <div className="scenario-list-item__title" title={userScenario.title}>
              {userScenario.title}
            </div>
            <div className="scenario-list-item__secondary-content">
              <div className="scenario-list-item__tags-content">
                <div className="scenario-list-item__tags">
                  {scenarioTags(userScenario)}
                </div>
              </div>
              <div className="scenario-list-item__date">
                {listItemDateLabel(userScenario.updated_at)}
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

UserScenario.propTypes = {
  userScenario: PropTypes.object.isRequired,
  userScenarios: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    userScenarios: state.userScenarios
  }
}

export default connect(mapStateToProps)(UserScenario);
