import React, {useCallback, useState} from "react";
import {connect} from "react-redux";
import Select2 from "react-select2-wrapper";
import {closeModals} from "store/actions/modalsActions";
import {fetchDashboardUser} from "store/actions/dashboardUsersActions";
import {updateCoursesUser} from "store/actions/coursesUsersActions";

import ContextMenuItem from "components/shared/context_menu/ContextMenuItem";
import DefaultModal from "components/shared/modals/DefaultModal";

import {LEVELS_COUNT} from "../../../../../constants";

const MODAL_NAME = "COURSE_SETTINGS_MODAL";

const Button = props => <ContextMenuItem {...props}>{I18n.t("settings")}</ContextMenuItem>;

const SettingsModal = ({
  closeModals,
  course,
  dashboardUsers,
  fetchDashboardUser,
  updateCoursesUser
}) => {
  const [level, setLevel] = useState(course.level);
  const [isLoading, setLoading] = useState(false);
  const modalName = `${MODAL_NAME}_${course.id}`;

  const options = Array.from({length: LEVELS_COUNT}, (_, i) => ({
    id: i + 1,
    text: `${i + 1} ${I18n.t("dashboard.popups.course_settings.star", {count: i + 1})}`
  }));

  const handleChange = useCallback(
    event => {
      setLevel(event.target.value);
    },
    [setLevel]
  );

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();
      setLoading(true);
      const attributes = {id: course.id, level};
      updateCoursesUser(attributes, {
        onError: () => {
          setLoading(false);
        },
        onSuccess: () => {
          setLoading(false);
          fetchDashboardUser(
            dashboardUsers.current.id,
            {groupId: dashboardUsers.groupId},
            {silentReload: true}
          );
          closeModals();
        }
      });
    },
    [closeModals, course.id, dashboardUsers, fetchDashboardUser, level, updateCoursesUser]
  );

  return (
    <DefaultModal
      button={<Button />}
      modalName={modalName}
      modalTitle={I18n.t("dashboard.course_settings")}
    >
      <div className="bem-popup__content bem-popup__content_margin_scroll">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-7">
            <form onSubmit={handleSubmit}>
              <div className="form-group form-group_size_sm">
                <label className="form-group__label form-group__label_width_sm" htmlFor="level">
                  {I18n.t("mongoid.attributes.courses_user.level")}
                </label>

                <div className="form-group__input-wrapper">
                  <Select2
                    className="select2-in select2_size_sm form-group__input"
                    data={options}
                    id="level"
                    name="level"
                    onChange={handleChange}
                    options={{
                      minimumResultsForSearch: -1,
                      theme: "size--sm"
                    }}
                    value={level}
                  />
                </div>
              </div>

              <div className="mt-5">
                <div className="pt-5 text-center">
                  <button className="button" disabled={isLoading} type="submit">
                    {I18n.t("save")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </DefaultModal>
  );
};

function mapStateToProps(state) {
  return {
    dashboardUsers: state.dashboardUsers
  };
}

const mapActionsToProps = {
  closeModals,
  fetchDashboardUser,
  updateCoursesUser
};

export default connect(mapStateToProps, mapActionsToProps)(SettingsModal);
