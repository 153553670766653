import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {notify} from "reapop";
import {withRouter} from "react-router-dom";

import {isFetchUserActivitiesError} from "../../../helpers/errorsHelpers";

import {assignGroupAttributes, updateGroup} from "../../../store/actions/groupsActions";
import {closeModal, openModal} from "../../../store/actions/modalsActions";
import {
  changeUserActivitiesOrder,
  changeUserActivitiesWeek,
  refreshUserActivities
} from "../../../store/actions/userActivitiesActions";

import DashboardLayout from "../../layouts/DashboardLayout";
import FetchGroup from "../../../components/data_loaders/FetchGroup";
import FetchUserActivities from "../../../components/data_loaders/FetchUserActivities";
import GroupSettingsModal from "./_GroupSettingsModal";
import ManageUsersModal from "./ManageUsersModal";
import Overview from "./Overview";
import TopPanel from "./TopPanel";
import SearchBar from "../../../components/shared/_SearchBar";
import queryString from "query-string";
import { debounce } from "lodash";
import { INITIAL_NUM_OF_USERS_TO_LOAD } from "../../../components/data_loaders/FetchUserActivities"

class Show extends Component {
  constructor(props) {
    super(props);
    const parsedQs = queryString.parse(location.search)
    const query = parsedQs.query;
    this.state = {
      query: query,
    };
    this.changeUsersQuery = this.changeUsersQuery.bind(this);
    this.resetUsersQuery = this.resetUsersQuery.bind(this);
  }

  refreshData = async () => {
    const parsedQs = queryString.parse(location.search)
    const query = parsedQs.query;
    const loadAllUsers = parsedQs.loadAllUsers;
    await this.props.refreshUserActivities({
      groupId: this.props.groupId,
      weekStartsAt: this.props.userActivities.inputWeekStartsAt.format(),
      query,
      perPage: loadAllUsers === 'true' ? undefined : INITIAL_NUM_OF_USERS_TO_LOAD,
    });
    location.reload();
  };

  changeUsersQuery(query) {
    this.setState({
      query,
    });

    const parsedQs = queryString.parse(location.search)
    const stringifiedQs = queryString.stringify({
      ...parsedQs, // Preserve other query keys
      query: query !== "" ? query : undefined, // We want `undefined` for empty query so that the URL is cleaned up
    })
    
    this.props.history.push({
      search: `?${stringifiedQs}`
    });
  }

  debouncedChangeUsersQuery = debounce((query) => this.changeUsersQuery(query), 1500)

  resetUsersQuery() {
    this.changeUsersQuery('')
  }

  render() {
    const {
      groupId,
      groups,
      userActivities,
      assignGroupAttributes,
      updateGroup,
      openModal,
      closeModal,
      changeUserActivitiesWeek,
      changeUserActivitiesOrder,
      notify,
      lastUpdatedAt,
    } = this.props;
    const group = groups.current;

    const isLoaded = group.id && userActivities.isCollectionLoaded;
    const isLoadingError = isFetchUserActivitiesError(userActivities);
    const isLoading =
      (!isLoaded && !isLoadingError) || groups.isLoading || userActivities.isLoading;

    return (
      <DashboardLayout
        groupId={groupId}
        headerTitle={I18n.t("dashboard.titles.index")}
        isLoading={isLoading}
      >
        <FetchGroup id={groupId} />
        {group.id && (
          <Fragment>
            <TopPanel
              group={group}
              openModal={openModal}
              weekStartsAt={userActivities.inputWeekStartsAt}
            />
            <SearchBar changeUsersQuery={this.debouncedChangeUsersQuery} query={this.state.query} uncontrolled />
            <GroupSettingsModal
              assignGroupAttributes={assignGroupAttributes}
              closeModal={closeModal}
              groups={groups}
              isLoading={isLoading}
              notify={notify}
              updateGroup={updateGroup}
            />
            {!group.default && <ManageUsersModal group={group} groupUsers={userActivities.collection}/>}
          </Fragment>
        )}
        <Overview
          changeUserActivitiesOrder={changeUserActivitiesOrder}
          changeUserActivitiesWeek={changeUserActivitiesWeek}
          group={group}
          isLoaded={isLoaded}
          userActivities={userActivities}
          refreshData={this.refreshData}
          lastUpdatedAt={lastUpdatedAt}
        />
        {group.id && 
          <FetchUserActivities 
            key={groupId} 
            groupId={groupId} 
            query={this.state.query} 
            resetUsersQuery={this.resetUsersQuery} 
            userCount={group.users_count} 
          />
        }
      </DashboardLayout>
    );
  }
}

Show.propTypes = {
  assignGroupAttributes: PropTypes.func.isRequired,
  changeUserActivitiesOrder: PropTypes.func.isRequired,
  changeUserActivitiesWeek: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  groups: PropTypes.object.isRequired,
  notify: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  userActivities: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    groups: state.groups,
    userActivities: state.userActivities
  };
}

const mapActionsToProps = {
  assignGroupAttributes,
  changeUserActivitiesOrder,
  changeUserActivitiesWeek,
  refreshUserActivities,
  closeModal,
  notify,
  openModal,
  updateGroup
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(Show));
