import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchUsersError} from "../../helpers/errorsHelpers";

import {changeUsersQuery, fetchUsers} from "../../store/actions/usersActions";

class FetchUsers extends Component {
  componentDidMount() {
    this.clearUsersQuery();
    this.fetchUsers(true);
  }

  componentDidUpdate() {
    this.fetchUsers(false);
  }

  clearUsersQuery() {
    if (this.props.clearQuery) {
      this.props.changeUsersQuery("");
    }
  }

  fetchUsers(isComponentMount) {
    const {users, page} = this.props;
    const isWrongCollection = users.page !== page || users.inputQuery !== users.query;
    const isReasonToFetch = isComponentMount || !users.isCollectionLoaded || isWrongCollection;

    if (isReasonToFetch && !users.isLoading && !isFetchUsersError(users)) {
      this.props.fetchUsers({page, query: users.inputQuery});
    }
  }

  render() {
    return null;
  }
}

FetchUsers.propTypes = {
  page: PropTypes.number,
  users: PropTypes.object.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  clearQuery: PropTypes.bool,
  changeUsersQuery: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    users: state.users
  }
}

const mapActionsToProps = {
  fetchUsers,
  changeUsersQuery
};

export default connect(mapStateToProps, mapActionsToProps)(FetchUsers);
