import React, {useCallback} from "react";
import classnames from "classnames";

import styles from "./styles.module.css";

const PasteInput = ({className, dataTypes = ["text/plain"], onPaste}) => {
  const handlePaste = useCallback(
    event => {
      event.stopPropagation();
      event.preventDefault();

      const {clipboardData} = event.originalEvent || event;
      const existingDataType = dataTypes.find(dataType => clipboardData.getData(dataType));
      const rawData = clipboardData.getData(existingDataType);

      onPaste(rawData);
    },
    [dataTypes, onPaste]
  );

  return (
    <input
      aria-label={I18n.t("user_scenarios.paste_input_placeholder")}
      className={classnames(className, styles.input)}
      onPaste={handlePaste}
      placeholder={I18n.t("user_scenarios.paste_input_placeholder")}
      readOnly
      type="text"
    />
  );
};

export default PasteInput;
