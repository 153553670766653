import React, {Component} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import {isLimited} from "../../helpers/packsHelper";

class UserPermissions extends Component {
  isAllowedUser(user) {
    return this.props.packAttributes.allowed_user_ids.includes(user.id)
  }

  render() {
    const {packAttributes, users, onAllowedUserChange} = this.props;

    if (!isLimited(packAttributes)) {
      return null;
    }

    return (
      <ul className="user-permissions">
        {users.collection.map((user) => (
          <li key={user.id}
              className={
                classnames("user-permissions__item", {"user-permissions__item--active": this.isAllowedUser(user)})
              }>
            <div className="user-permissions__avatar avatar">
              <img src={user.avatar.thumb_url} alt=""/>
            </div>
            <div className="user-permissions__name">
              {user.full_name}
            </div>
            <div className="user-permissions__switch custom-control custom-switch">
              <input
                type="checkbox"
                id={`allowed_user_ids.${user.id}`}
                name={`allowed_user_ids.${user.id}`}
                className="custom-control-input"
                checked={this.isAllowedUser(user)}
                onChange={() => onAllowedUserChange(user.id)}
              />
              <label
                className="user-permissions__switch-label custom-control-label"
                htmlFor={`allowed_user_ids.${user.id}`}
              />
            </div>
          </li>
        ))}
      </ul>
    );
  }
}

UserPermissions.propTypes = {
  packAttributes: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  onAllowedUserChange: PropTypes.func.isRequired
};

export default UserPermissions;
