import {ACTIONS as CHARACTER_ACTIONS} from "../store/reducers/charactersReducer";
import {ACTIONS as COMPANIES_ACTIONS} from "../store/reducers/companiesReducer";
import {ACTIONS as COURSE_ACTIONS} from "../store/reducers/coursesReducer";
import {ACTIONS as COURSE_CATEGORIES_ACTIONS} from "../store/reducers/courseCategoriesReducer";
import {ACTIONS as DASHBOARD_USER_ACTIONS} from "../store/reducers/dashboardUsersReducer";
import {ACTIONS as DIALOGFLOW_AGENT_ACTIONS} from "../store/reducers/dialogflowAgentsReducer";
import {ACTIONS as ENVIRONMENT_ACTIONS} from "../store/reducers/environmentsReducer";
import {ACTIONS as GROUPS_ACTIONS} from "../store/reducers/groupsReducer";
import {ACTIONS as METRIC_ACTIONS} from "../store/reducers/metricsReducer";
import {ACTIONS as PACK_ACTIONS} from "../store/reducers/packsReducer";
import {ACTIONS as PACK_CATEGORIES_ACTIONS} from "../store/reducers/packCategoriesReducer";
import {ACTIONS as PACK_SHARE_ACTIONS} from "../store/reducers/packSharesReducer";
import {ACTIONS as PROFILE_ACTIONS} from "../store/reducers/profilesReducer";
import {ACTIONS as TAG_ACTIONS} from "../store/reducers/tagsReducer";
import {ACTIONS as USER_ACTIONS} from "../store/reducers/usersReducer";
import {ACTIONS as USER_ACTIVITY_ACTIONS} from "../store/reducers/userActivitiesReducer";
import {ACTIONS as USER_SCENARIO_ACTIONS} from "../store/reducers/userScenariosReducer";
import {ACTIONS as USER_SCENARIO_HEADER_ACTIONS} from "../store/reducers/userScenarioHeadersReducer";

function isError(status) {
  return status >= 400;
}

function isActionError(state, action) {
  return isError(state.statuses[action].status);
}

export function isFetchCharactersError(characters) {
  return isActionError(characters, CHARACTER_ACTIONS.FETCH_CHARACTERS);
}

export function isFetchDashboardUserError(users) {
  return isActionError(users, DASHBOARD_USER_ACTIONS.FETCH_DASHBOARD_USER);
}

export function isFetchEnvironmentsError(environments) {
  return isActionError(environments, ENVIRONMENT_ACTIONS.FETCH_ENVIRONMENTS);
}

export function isFetchDialogflowAgentsError(dialogflowAgents) {
  return isActionError(dialogflowAgents, DIALOGFLOW_AGENT_ACTIONS.FETCH_DIALOGFLOW_AGENTS);
}

export function isFetchUserScenariosError(userScenarios) {
  return isActionError(userScenarios, USER_SCENARIO_ACTIONS.FETCH_USER_SCENARIOS);
}

export function isFetchUserScenarioError(userScenarios) {
  return isActionError(userScenarios, USER_SCENARIO_ACTIONS.FETCH_USER_SCENARIO);
}

export function isFetchUserScenarioHeaderError(userScenarioHeaders) {
  return isActionError(
    userScenarioHeaders,
    USER_SCENARIO_HEADER_ACTIONS.FETCH_USER_SCENARIO_HEADER
  );
}

export function isFetchUsersError(users) {
  return isActionError(users, USER_ACTIONS.FETCH_USERS);
}

export function isFetchUserError(users) {
  return isActionError(users, USER_ACTIONS.FETCH_USER);
}

export function isFetchCoursesError(courses) {
  return isActionError(courses, COURSE_ACTIONS.FETCH_COURSES);
}

export function isFetchCourseCategoriesError(courseCategories) {
  return isActionError(courseCategories, COURSE_CATEGORIES_ACTIONS.FETCH_COURSE_CATEGORIES);
}

export function isFetchCourseCategoryError(courseCategories) {
  return isActionError(courseCategories, COURSE_CATEGORIES_ACTIONS.FETCH_COURSE_CATEGORY);
}

export function isFetchCourseError(courses) {
  return isActionError(courses, COURSE_ACTIONS.FETCH_COURSE);
}

export function isFetchUserActivitiesError(userActivities) {
  return isActionError(userActivities, USER_ACTIVITY_ACTIONS.FETCH_USER_ACTIVITIES);
}

export function isFetchPacksError(packs) {
  return isActionError(packs, PACK_ACTIONS.FETCH_PACKS);
}

export function isFetchPackError(packs) {
  return isActionError(packs, PACK_ACTIONS.FETCH_PACK);
}

export function isFetchPackSharesError(packShares) {
  return isActionError(packShares, PACK_SHARE_ACTIONS.FETCH_PACK_SHARES);
}

export function isFetchPackCategoriesError(packCategories) {
  return isActionError(packCategories, PACK_CATEGORIES_ACTIONS.FETCH_PACK_CATEGORIES);
}

export function isFetchPackCategoryError(packCategories) {
  return isActionError(packCategories, PACK_CATEGORIES_ACTIONS.FETCH_PACK_CATEGORY);
}

export function isFetchMetricsError(metrics) {
  return isActionError(metrics, METRIC_ACTIONS.FETCH_METRICS);
}

export function isFetchMetricError(metrics) {
  return isActionError(metrics, METRIC_ACTIONS.FETCH_METRIC);
}

export function isFetchCompanyError(companies) {
  return isActionError(companies, COMPANIES_ACTIONS.FETCH_COMPANY);
}

export function isFetchGroupsError(groups) {
  return isActionError(groups, GROUPS_ACTIONS.FETCH_GROUPS);
}

export function isFetchGroupError(groups) {
  return isActionError(groups, GROUPS_ACTIONS.FETCH_GROUP);
}

export function isFetchTagsError(tags) {
  return isActionError(tags, TAG_ACTIONS.FETCH_TAGS);
}

export function isFetchTagError(tags) {
  return isActionError(tags, TAG_ACTIONS.FETCH_TAG);
}

export function isFetchProfileError(profiles) {
  return isActionError(profiles, PROFILE_ACTIONS.FETCH_PROFILE);
}

export function isAuthenticated(profiles) {
  const {status} = profiles.statuses[PROFILE_ACTIONS.FETCH_PROFILE];

  return status && !isError(status);
}

export function isUnauthenticated(profiles) {
  return isActionError(profiles, PROFILE_ACTIONS.FETCH_PROFILE);
}
