import React, {Fragment, memo} from "react";

import Participant from "./Participant";

const Participants = ({participants}) => {
  return (
    <Fragment>
      <table className="scenario-edit__table scenario-edit__table--roles">
        <thead>
          <tr>
            <th>{I18n.t("mongoid.attributes.participant.role")}</th>
            <th>{I18n.t("mongoid.attributes.participant.name")}</th>
            <th>{I18n.t("mongoid.attributes.participant.character")}</th>
            <th>{I18n.t("mongoid.attributes.participant.description")}</th>
            <th>{I18n.t("mongoid.attributes.participant.position")}</th>
            <th>{I18n.t("mongoid.attributes.participant.kind")}</th>
          </tr>
        </thead>

        <tbody>
          {participants.map(participant => (
            <tr key={participant.id}>
              <td>
                <div className="scenario-edit__form-value font-weight-bold">{participant.role}</div>
              </td>

              <td>
                <div className="scenario-edit__form-value">{participant.name}</div>
              </td>

              <td>
                <Participant
                  avatarUrl={participant.avatar_url}
                  isBold
                  name={participant.character_name}
                />
              </td>

              <td>
                <div className="scenario-edit__form-value">{participant.description}</div>
              </td>

              <td>
                <div className="scenario-edit__form-value font-weight-bold">
                  {participant.position}
                </div>
              </td>

              <td>
                <div className="scenario-edit__form-value">
                  {I18n.t(`mongoid.attributes.participant.kinds.${participant.kind}`)}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default memo(Participants);
