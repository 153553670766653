import React, {Fragment} from "react";
import {connect} from "react-redux";

import {dashboardUserPath} from "../../../helpers/routesHelper";
import {isFetchMetricError} from "../../../helpers/errorsHelpers";

import DashboardLayout from "../../layouts/DashboardLayout";
import FetchMetric from "../../../components/data_loaders/FetchMetric";
import Header from "./Header";
import Results from "./Results";
import ScenarioContent from "./ScenarioContent";
import TopPanel from "./TopPanel";

const Show = ({dashboardUsers, groupId = "", metricId, metrics}) => {
  const metric = metrics.current;
  const user = dashboardUsers.current;

  const isLoaded = metric.id && metric.id === metricId;
  const isLoadingError = isFetchMetricError(metrics);
  const isLoading = (!isLoaded && !isLoadingError) || metrics.isLoading;

  return (
    <Fragment>
      <FetchMetric id={metricId} />
      {isLoaded && (
        <DashboardLayout
          groupId={groupId}
          headerTitle={I18n.t("dashboard.scenario_review.title")}
          isLoading={isLoading}
          userId={metric.user_id}
        >
          <Fragment>
            <TopPanel backPath={dashboardUserPath(user.id, groupId)} user={user} />
            <Header metric={metric} />
            <Results metric={metric} />
            <ScenarioContent metric={metric} user={user} />
          </Fragment>
        </DashboardLayout>
      )}
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    dashboardUsers: state.dashboardUsers,
    metrics: state.metrics
  };
}

export default connect(mapStateToProps)(Show);
