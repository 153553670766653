import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import {userPath} from "../../helpers/routesHelper";
import {isFetchUserError} from "../../helpers/errorsHelpers";

import {assignUserAttributes, updateUser} from "../../store/actions/usersActions";
import ApplicationLayout from "../layouts/ApplicationLayout";
import UserForm from "./Form";
import FetchUser from "../../components/data_loaders/FetchUser";

class Edit extends Component {
  componentDidMount() {
    this.assignUserAttributes(true);
  }

  componentDidUpdate() {
    this.assignUserAttributes(false);
  }

  assignUserAttributes = isComponentMount => {
    const {id, users, assignUserAttributes} = this.props;
    const user = users.current;
    const {formAttributes} = users;

    if (user.id === id && (isComponentMount || formAttributes.id !== id)) {
      assignUserAttributes({...user, errors: {}, password: "", password_confirmation: ""});
    }
  };

  onSubmit = attributes => {
    this.props.updateUser(attributes, this.props.history);
  };

  notifyNotSaved = () => {
    this.props.notify({
      message: I18n.t("users.messages.update.error"),
      status: "error",
      title: I18n.t("notifications.titles.error")
    });
  };

  render() {
    const {id, users, companies} = this.props;
    const user = users.current;
    const company = companies.current;

    const isLoaded = company.id && user.id && user.id === id;
    const isLoadingError = isFetchUserError(users);
    const isLoading = (!isLoaded && !isLoadingError) || users.isLoading || companies.isLoading;

    return (
      <ApplicationLayout
        headerTitle={I18n.t("header_titles.users.edit")}
        isLoading={!isLoaded || isLoading}
      >
        <FetchUser id={id} />
        {isLoaded && (
          <div className="container">
            <div className="row py-4">
              <div className="col-12 col-md-10 col-lg-7 col-xl-6 mx-auto">
                <div className="profile-section">
                  <UserForm
                    assignUserAttributes={this.props.assignUserAttributes}
                    backPath={userPath(id)}
                    company={company}
                    formAttributes={users.formAttributes}
                    isLoading={isLoading}
                    notifyNotSaved={this.notifyNotSaved}
                    onSubmit={this.onSubmit}
                    submitButtonLabel={I18n.t("btn_save")}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </ApplicationLayout>
    );
  }
}

Edit.propTypes = {
  assignUserAttributes: PropTypes.func.isRequired,
  companies: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  updateUser: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    companies: state.companies,
    users: state.users
  };
}

const mapActionsToProps = {
  assignUserAttributes,
  updateUser
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Edit));
