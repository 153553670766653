/* eslint-disable */
import React, {memo} from "react";

const fn = ({className}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <path fill="#C6C1BB" d="M21.545 9.182h-7.272L13.545 7h-5.09C7.655 7 7 7.655 7 8.455v10.909c0 .8.655 1.454 1.455 1.454h5.818L15 23h6.545c.8 0 1.455-.655 1.455-1.455V10.636c0-.8-.655-1.454-1.455-1.454zm-10.181 8.727c-2.008 0-3.637-1.629-3.637-3.636 0-2.008 1.63-3.637 3.637-3.637.981 0 1.803.364 2.436.946l-.96.923c-.276-.261-.756-.567-1.476-.567-1.266 0-2.291 1.047-2.291 2.335 0 1.287 1.025 2.334 2.29 2.334 1.462 0 2.066-1.047 2.124-1.752h-2.123V13.61h3.403c.051.225.088.444.088.742 0 2.08-1.39 3.556-3.491 3.556zm4.487-3.942h2.69c-.312.91-.806 1.768-1.49 2.524-.226-.255-.436-.524-.626-.8l-.574-1.724zm6.058 7.215c0 .4-.327.727-.727.727h-4.727l1.454-1.818-.756-2.255 2.254 2.255.67-.67-2.4-2.363.014-.014c.822-.91 1.404-1.957 1.745-3.07h1.382v-.945h-3.294v-.938h-.939v.938h-1.047l-.93-2.756h6.574c.4 0 .727.327.727.727v10.182z"/>
  </svg>
);

export default memo(fn);
