import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchCourseCategoriesError} from "../../helpers/errorsHelpers";

import {fetchCourseCategories} from "../../store/actions/courseCategoriesActions";

class FetchCourseCategories extends Component {
  componentDidMount() {
    this.fetchCourseCategories(true);
  }

  componentDidUpdate() {
    this.fetchCourseCategories(false);
  }

  fetchCourseCategories(isComponentMount) {
    const {courseCategories} = this.props;
    const isReasonToFetch = isComponentMount || !courseCategories.isCollectionLoaded;

    if (isReasonToFetch && !courseCategories.isLoading && !isFetchCourseCategoriesError(courseCategories)) {
      this.props.fetchCourseCategories();
    }
  }

  render() {
    return null;
  }
}

FetchCourseCategories.propTypes = {
  courseCategories: PropTypes.object.isRequired,
  fetchCourseCategories: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    courseCategories: state.courseCategories
  }
}

const mapActionsToProps = {
  fetchCourseCategories
};

export default connect(mapStateToProps, mapActionsToProps)(FetchCourseCategories);
