/* eslint-disable */
import React, {memo} from "react";

const fn = ({className}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <path fill="#C6C1BB" d="M21.5 7h-12c-.825 0-1.492.675-1.492 1.5L8 22l3-3h10.5c.825 0 1.5-.675 1.5-1.5v-9c0-.825-.675-1.5-1.5-1.5zM11 12.25h9v1.5h-9v-1.5zM17 16h-6v-1.5h6V16zm3-4.5h-9V10h9v1.5z"/>
  </svg>
);

export default memo(fn);

