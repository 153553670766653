import React, {useCallback, useState} from "react";
import {SortableHandle} from "react-sortable-hoc";
import classnames from "classnames";
import {VisibleIf} from "react-pundit";

import ContextMenu from "components/shared/context_menu/ContextMenu";
import Progress from "components/shared/Progress";
import Scenarios from "./Scenarios";
import UnassignCourseModal from "./UnassignCourseModal";
import Level from "./Level";
import SettingsModal from "./SettingsModal";

const DragHandle = SortableHandle(() => <div className="pd-course__drag" />);

const Course = ({course, groupId}) => {
  const [areScenariosVisible, setScenariosVisible] = useState(false);
  const unlockedPercentage =
    course.user_scenarios_count > 0
      ? (course.unlocked_scenario_ids.length * 100) / course.user_scenarios_count
      : 0;

  const toggleScenarios = useCallback(() => {
    setScenariosVisible(!areScenariosVisible);
  }, [areScenariosVisible, setScenariosVisible]);

  return (
    <div key={course.id} className="pd-course">
      <div className="pd-course__header">
        <VisibleIf action="Update" model={course} type="Course">
          <DragHandle />
        </VisibleIf>
        <div className="pd-course__item">
          <div className="pd-course__icon" />
          <div className="pd-course__title">{course.title}</div>
        </div>
        <Level className="ml-auto mr-4" level={course.level} />
        <div className="pd-course__progress">
          <Progress percentage={course.progress} secondaryPercentage={unlockedPercentage} />
        </div>
        <div
          className={classnames("pd-course__toggle", {
            "pd-course__toggle--active": areScenariosVisible
          })}
          onClick={toggleScenarios}
        />
        <VisibleIf action="Update" model={course} type="Course">
          <ContextMenu className="pd-course__context-menu">
            <SettingsModal course={course} />
            <UnassignCourseModal course={course} groupId={groupId} />
          </ContextMenu>
        </VisibleIf>
      </div>
      {areScenariosVisible && <Scenarios coursesUsersId={course.id} />}
    </div>
  );
};

export default Course;
