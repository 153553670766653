import {SUCCESS_STATUS_CODE} from "../../constants";
import _ from "lodash";

export const ACTIONS = {
  CHANGE_TAGS_LOADING: "CHANGE_TAGS_LOADING",
  FETCH_TAGS: "FETCH_TAGS",
  FETCH_TAGS_FULFILLED: "FETCH_TAGS_FULFILLED",
  FETCH_TAGS_REJECTED: "FETCH_TAGS_REJECTED",
  FETCH_TAG: "FETCH_TAG",
  FETCH_TAG_FULFILLED: "FETCH_TAG_FULFILLED",
  FETCH_TAG_REJECTED: "FETCH_TAG_REJECTED",
  ASSIGN_TAG_ATTRIBUTES: "ASSIGN_TAG_ATTRIBUTES",
  CLEAR_TAG_ATTRIBUTES: "CLEAR_TAG_ATTRIBUTES",
  CREATE_TAG_FULFILLED: "CREATE_TAG_FULFILLED",
  CREATE_TAG_REJECTED: "CREATE_TAG_REJECTED",
  UPDATE_TAG_FULFILLED: "UPDATE_TAG_FULFILLED",
  UPDATE_TAG_REJECTED: "UPDATE_TAG_REJECTED",
  SET_TAG_REMOVING_STATE: "SET_TAG_REMOVING_STATE",
  DESTROY_TAG_FULFILLED: "DESTROY_TAG_FULFILLED",
  DESTROY_TAG_REJECTED: "DESTROY_TAG_REJECTED",
  REORDER_TAGS: "REORDER_TAGS",
  REORDER_TAGS_FULFILLED: "REORDER_TAGS_FULFILLED",
  REORDER_TAGS_REJECTED: "REORDER_TAGS_REJECTED"
};

const DEFAULT_SUCCESS_STATUS = {errors: {}, status: SUCCESS_STATUS_CODE};
const DEFAULT_STATUSES = {
  [ACTIONS.FETCH_TAGS]: {errors: {}, status: null},
  [ACTIONS.FETCH_TAG]: {errors: {}, status: null}
};

const INITIAL_FORM_ATTRIBUTES = {
  english_name: "",
  japanese_name: "",
  order: 0,
  errors: {}
};

export default function reducer(state = {
  collection: [],
  current: {},
  formAttributes: INITIAL_FORM_ATTRIBUTES,
  isCollectionLoaded: false,
  isLoading: false,
  loadingCount: 0,
  pagination: {},
  page: 0,
  statuses: DEFAULT_STATUSES
}, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_TAGS_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);
      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.FETCH_TAGS: {
      return {...state, collection: [], pagination: {}, page: 0, isCollectionLoaded: false};
    }
    case ACTIONS.FETCH_TAGS_FULFILLED: {
      return {...state,
        collection: action.payload.tags,
        pagination: action.payload.pagination,
        page: action.page,
        isCollectionLoaded: true,
        statuses: {...state.statuses, [ACTIONS.FETCH_TAGS]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_TAGS_REJECTED: {
      return {
        ...state,
        statuses: {...state.statuses, [ACTIONS.FETCH_TAGS]: {errors: action.errors, status: action.status}}
      };
    }
    case ACTIONS.REORDER_TAGS_FULFILLED: {
      return {...state,
        isCollectionLoaded: true,
        statuses: {...state.statuses, [ACTIONS.REORDER_TAGS]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.REORDER_TAGS_REJECTED: {
      return {
        ...state,
        statuses: {...state.statuses, [ACTIONS.REORDER_TAGS]: {errors: action.errors, status: action.status}}
      };
    }
    case ACTIONS.FETCH_TAG: {
      return {...state, current: {}};
    }
    case ACTIONS.FETCH_TAG_FULFILLED: {
      return {
        ...state,
        current: action.tag,
        statuses: {...state.statuses, [ACTIONS.FETCH_TAG]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_TAG_REJECTED: {
      return {
        ...state,
        statuses: {...state.statuses, [ACTIONS.FETCH_TAG]: {errors: action.errors, status: action.status}}
      };
    }
    case ACTIONS.REORDER_TAGS: {
      return {
        ...state, tags: action.tags
      };
    }
    case ACTIONS.ASSIGN_TAG_ATTRIBUTES: {
      return {...state, formAttributes: {errors: state.formAttributes.errors, ...action.attributes}};
    }
    case ACTIONS.CLEAR_TAG_ATTRIBUTES: {
      return {...state, formAttributes: INITIAL_FORM_ATTRIBUTES};
    }

    case ACTIONS.CREATE_TAG_FULFILLED: {
      return {...state, isCollectionLoaded: false, collection: [], current: action.tag};
    }
    case ACTIONS.CREATE_TAG_REJECTED: {
      return {...state, formAttributes: {...state.formAttributes, errors: action.errors}};
    }

    case ACTIONS.UPDATE_TAG_FULFILLED: {
      return {...state, isCollectionLoaded: false, collection: [], current: action.tag};
    }
    case ACTIONS.UPDATE_TAG_REJECTED: {
      return {...state, formAttributes: {...state.formAttributes, errors: action.errors}};
    }

    case ACTIONS.SET_TAG_REMOVING_STATE: {
      const clonedCollection = [...state.collection];
      const tagIndex = _.findIndex(clonedCollection, {id: action.tagId});
      clonedCollection[tagIndex] = {...clonedCollection[tagIndex], removingState: action.removingState};
      return {...state, collection: clonedCollection};
    }
    case ACTIONS.DESTROY_TAG_FULFILLED: {
      let newState = {...state};

      if (state.current.id === action.tagId) {
        newState = {...newState, current: {}};
      }

      return {...newState, isCollectionLoaded: false, collection: []};
    }
  }
  return state;
}
