import {ACTIONS} from "../reducers/coursesReducer";

import webToolApi from "../../helpers/webToolApiHelper";
import {coursePath} from "../../helpers/routesHelper";

import {defaultErrorAction} from "./errorsActions";
import {notifyResponseError, notifySuccess} from "./notificationsActions";

export function changeCoursesLoading(isLoading) {
  return {type: ACTIONS.CHANGE_COURSES_LOADING, isLoading};
}

export function fetchCourses({page} = {}) {
  return (dispatch) => {
    dispatch(changeCoursesLoading(true));
    dispatch({type: ACTIONS.FETCH_COURSES});

    webToolApi().get(Routes.courses_path({page}))
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_COURSES_FULFILLED, payload: response.data, page});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_COURSES_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("courses.messages.index.error"), response));
      })
      .finally(() => {
        dispatch(changeCoursesLoading(false));
      });
  }
}

export function fetchCourse(id) {
  return (dispatch) => {
    dispatch(changeCoursesLoading(true));
    dispatch({type: ACTIONS.FETCH_COURSE});

    webToolApi().get(Routes.course_path(id))
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_COURSE_FULFILLED, course: response.data.course});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_COURSE_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("courses.messages.show.error"), response));
      })
      .finally(() => {
        dispatch(changeCoursesLoading(false));
      });
  }
}

export function assignCourseAttributes(attributes) {
  return {type: ACTIONS.ASSIGN_COURSE_ATTRIBUTES, attributes};
}

export function clearCourseAttributes(defaultAttributes = {}) {
  return {type: ACTIONS.CLEAR_COURSE_ATTRIBUTES, defaultAttributes};
}

export function createCourse(course, history) {
  return (dispatch) => {
    dispatch(changeCoursesLoading(true));

    webToolApi().post(Routes.courses_path(), {course})
      .then((response) => {
        dispatch({type: ACTIONS.CREATE_COURSE_FULFILLED, course: response.data.course});
        dispatch(notifySuccess(I18n.t("courses.messages.create.success")));
        dispatch(clearCourseAttributes());
        dispatch(changeCoursesLoading(false));
        history.push(coursePath(response.data.course.id));
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.CREATE_COURSE_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("courses.messages.create.error"), response));
        dispatch(changeCoursesLoading(false));
      });
  }
}

export function updateCourse(course, {onSuccess} = {}) {
  return (dispatch) => {
    dispatch(changeCoursesLoading(true));

    webToolApi().put(Routes.course_path(course.id), {course})
      .then((response) => {
        dispatch({type: ACTIONS.UPDATE_COURSE_FULFILLED, course: response.data.course});
        dispatch(notifySuccess(I18n.t("courses.messages.update.success")));
        dispatch(clearCourseAttributes());
        dispatch(changeCoursesLoading(false));
        onSuccess && onSuccess();
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.UPDATE_COURSE_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("courses.messages.update.error"), response));
        dispatch(changeCoursesLoading(false));
      });
  }
}

export function destroyCourse(courseId) {
  return (dispatch) => {
    dispatch(changeCoursesLoading(true));

    webToolApi().delete(Routes.course_path(courseId))
      .then(() => {
        dispatch({type: ACTIONS.DESTROY_COURSE_FULFILLED, courseId});
        dispatch(notifySuccess(I18n.t("courses.messages.destroy.success")));
      })
      .catch(({response}) => {
        dispatch(notifyResponseError(I18n.t("courses.messages.destroy.error"), response));
      })
      .finally(() => {
        dispatch(changeCoursesLoading(false));
      });
  }
}

export function setCourseRemovingState(courseId, removingState) {
  return {type: ACTIONS.SET_COURSE_REMOVING_STATE, courseId, removingState};
}
