import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

import {isAdmin} from "../../helpers/userRolesHelper";

import {rootPath, profilePath} from "../../helpers/routesHelper";

import LogoUrl from "../../images/logo_white_with_padding.png";
import DropdownMenu from "./DropdownMenu";

class Header extends Component {
  logoImage() {
    const {company} = this.props;
    return company.logo_url ? <img src={company.logo_url} alt=""/> : <img src={LogoUrl} alt=""/>;
  }

  render() {
    const {currentUser, title} = this.props;

    return (
      <header className="header">
        <Link to={rootPath()} className="header__logo">
          {this.logoImage()}
        </Link>
        <div className="header__left">
          <div className="header__company-name hide-on-sm-and-down font-weight-bold">
            {title}
          </div>
        </div>
        <div className="header__right">
          <Link to={isAdmin(currentUser) ? '' : profilePath()} className="header__profile hide-on-md-and-down">
            <div className="d-flex align-items-center">
              <div className='header-name d-flex flex-column align-items-end'>
                <span className="header__greeting hide-on-md-and-down font-weight-bold">
                  {currentUser.first_name}
                </span>
                <span className="header__user-role hide-on-md-and-down">
                  {I18n.t(`mongoid.attributes.user.role_titles.${currentUser.role}`)}
                </span>
              </div>
              <div className="avatar header__avatar">
                <img src={currentUser.avatar.thumb_url} alt=""/>
              </div>
            </div>
          </Link>
          <DropdownMenu currentUser={currentUser}/>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  currentUser: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  title: PropTypes.string
};

export default Header;
