import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {notify} from "reapop";

import {tagPath, tagsPath} from "../../helpers/routesHelper";
import {isFetchTagError} from "../../helpers/errorsHelpers";

import {assignTagAttributes, clearTagAttributes, updateTag} from "../../store/actions/tagsActions";

import ApplicationLayout from "../layouts/ApplicationLayout";
import TagForm from "./_Form";
import FetchTag from "../../components/data_loaders/FetchTag";

class Edit extends Component {
  componentDidMount() {
    this.assignTagAttributes(true);
  }

  componentDidUpdate() {
    this.assignTagAttributes(false);
  }

  assignTagAttributes = (isComponentMount) => {
    const {id, tags} = this.props;
    const tag = tags.current;
    const {formAttributes} = tags;

    if (tag.id !== id || (!isComponentMount && formAttributes.id === id)) {
      return;
    }

    this.props.assignTagAttributes({...tag, errors: {}});
  };

  onSubmit = (tag) => {
    this.props.updateTag(tag, {
      onSuccess: () => this.props.history.push(tagsPath)
    });
  };

  render() {
    const {id, tags} = this.props;

    const isLoaded = tags.formAttributes.id === id;
    const isLoadingError = isFetchTagError(tags);
    const isLoading = (!isLoaded && !isLoadingError) || tags.isLoading;

    return (
      <ApplicationLayout headerTitle={I18n.t("tags.titles.edit")} isLoading={isLoading}>
        <FetchTag id={id}/>
        {
          isLoaded &&
          <div className="container">
            <div className="row py-4">
              <div className="col-12">
                <TagForm
                  backPath={tagPath(id)}
                  formAttributes={this.props.tags.formAttributes}
                  assignTagAttributes={this.props.assignTagAttributes}
                  onSubmit={this.onSubmit}
                  notify={this.props.notify}
                />
              </div>
            </div>
          </div>
        }
      </ApplicationLayout>
    );
  }
}

Edit.propTypes = {
  id: PropTypes.string.isRequired,
  tags: PropTypes.object.isRequired,
  assignTagAttributes: PropTypes.func.isRequired,
  updateTag: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    tags: state.tags
  }
}

const mapActionsToProps = {
  assignTagAttributes,
  clearTagAttributes,
  updateTag,
  notify
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Edit));
