import {ACTIONS} from "../reducers/environmentsReducer";

import webToolApi from "../../helpers/webToolApiHelper";

import {defaultErrorAction} from "./errorsActions";
import {notifyResponseError} from "./notificationsActions";

export function changeEnvironmentsLoading(isLoading) {
  return {type: ACTIONS.CHANGE_ENVIRONMENTS_LOADING, isLoading};
}

export function fetchEnvironments() {
  return (dispatch) => {
    dispatch(changeEnvironmentsLoading(true));
    dispatch({type: ACTIONS.FETCH_ENVIRONMENTS});

    webToolApi().get(Routes.environments_path())
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_ENVIRONMENTS_FULFILLED, payload: response.data});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_ENVIRONMENTS_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("user_scenarios.messages.index.error"), response));
      })
      .finally(() => {
        dispatch(changeEnvironmentsLoading(false));
      });
  }
}
