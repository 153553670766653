import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchTagsError} from "../../helpers/errorsHelpers";

import {fetchTags} from "../../store/actions/tagsActions";

class FetchTags extends Component {
  componentDidMount() {
    this.fetchTags(true);
  }

  componentDidUpdate() {
    this.fetchTags(false);
  }

  fetchTags(isComponentMount) {
    const {tags, page} = this.props;
    const isWrongCollection = tags.page !== page;
    const isReasonToFetch = isComponentMount || !tags.isCollectionLoaded || isWrongCollection;

    if (isReasonToFetch && !tags.isLoading && !isFetchTagsError(tags)) {
      this.props.fetchTags({page});
    }
  }

  render() {
    return null;
  }
}

FetchTags.propTypes = {
  page: PropTypes.number,
  tags: PropTypes.object.isRequired,
  fetchTags: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    tags: state.tags
  }
}

const mapActionsToProps = {
  fetchTags
};

export default connect(mapStateToProps, mapActionsToProps)(FetchTags);
