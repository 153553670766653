import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";

import {groupPath} from "../../helpers/routesHelper";

import DashboardLayout from "../layouts/DashboardLayout";

class Show extends Component {
  render() {
    const {groups} = this.props;
    const isLoaded = groups.isCollectionLoaded;

    return (
      <DashboardLayout headerTitle={I18n.t("dashboard.titles.index")}>
        {isLoaded && <Redirect to={groupPath(groups.collection[0].id)}/>}
      </DashboardLayout>
    )
  }
}

Show.propTypes = {
  groups: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    groups: state.groups
  }
}

export default connect(mapStateToProps)(Show);
