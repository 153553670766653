import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import classnames from "classnames";

class ChoiceItem extends Component {
  render() {
    const {path, title, type} = this.props;

    return (
      <div className="col-6 col-md">
        <Link to={path} className="choice-item">
          <div className={classnames("choice-item__circle choice-item__circle--icon", `choice-item__circle--${type}`)}/>
          <h3 className="choice-item__label">{title}</h3>
        </Link>
      </div>
    )
  }
}

ChoiceItem.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["progress", "tags", "courses", "scenarios", "accounts", "scenario_category"]).isRequired
};

export default ChoiceItem;
