import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";

import {isManager} from "../../helpers/userRolesHelper";
import {isFetchDashboardUserError, isFetchGroupsError} from "../../helpers/errorsHelpers";

import ApplicationLayout from "./ApplicationLayout";
import FetchDashboardUser from "../../components/data_loaders/FetchDashboardUser";
import FetchGroups from "../../components/data_loaders/FetchGroups";
import Sidebar from "../../components/shared/Sidebar";

class DashboardLayout extends Component {
  get isShowSidebar() {
    return isManager(this.props.currentUser);
  }

  render() {
    const {dashboardUsers, groupId, userId, currentUser, groups, headerTitle} = this.props;
    const user = dashboardUsers.current;

    const isLoaded =
      (!userId || user.id === userId) && (!isManager(currentUser) || groups.isCollectionLoaded);

    const isLoading = this.props.isLoading || dashboardUsers.isLoading || groups.isLoading;

    const isLoadingError =
      isFetchDashboardUserError(dashboardUsers) ||
      (isManager(currentUser) && isFetchGroupsError(groups));

    return (
      <ApplicationLayout
        headerTitle={headerTitle}
        isLoading={(!isLoaded && !isLoadingError) || isLoading}
      >
        {isManager(currentUser) && <FetchGroups />}
        {userId && <FetchDashboardUser groupId={groupId} id={userId} />}
        {isLoaded && (
          <div className={classnames("container", {container_full_width: this.isShowSidebar})}>
            <div className="row no-gutters">
              {this.isShowSidebar && (
                <div className="col-4 col-md-3 col-xl-2">
                  <Sidebar groupId={groupId} groups={groups} />
                </div>
              )}
              <div className={this.isShowSidebar ? "col-8 col-md-9 col-xl-10" : "col-12"}>
                <div className="container container_padding_vertical">{this.props.children}</div>
              </div>
            </div>
          </div>
        )}
      </ApplicationLayout>
    );
  }
}

DashboardLayout.propTypes = {
  currentUser: PropTypes.object.isRequired,
  dashboardUsers: PropTypes.object.isRequired,
  groupId: PropTypes.string,
  headerTitle: PropTypes.string,
  isLoading: PropTypes.bool,
  userId: PropTypes.string
};

function mapStateToProps(state) {
  return {
    currentUser: state.profiles.current,
    dashboardUsers: state.dashboardUsers,
    groups: state.groups
  };
}
export default connect(mapStateToProps)(DashboardLayout);
