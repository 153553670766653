import React from "react";
import sanitizeHtml from "sanitize-html";

const allowedTags = ["blur", "se", "we"];

const FormattedText = ({text, ...restProps}) => {
  const html = sanitizeHtml(text, {allowedTags})
    .replace(/<se[^>]*>/g, "<span class='strong-enunciation'>")
    .replace(/<\/se>/g, "</span>")
    .replace(/<we[^>]*>/g, "<span class='weak-enunciation'>")
    .replace(/<\/we>/g, "</span>")
    .replace(/<blur[^>]*>/g, "<u>")
    .replace(/<\/blur>/g, "</u>");

  // eslint-disable-next-line react/no-danger
  return <div {...restProps} dangerouslySetInnerHTML={{__html: html}} />;
};

export default FormattedText;
