import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchPacksError} from "../../helpers/errorsHelpers";

import {fetchPacks} from "../../store/actions/packsActions";

class FetchPacks extends Component {
  componentDidMount() {
    this.fetchPacks(true);
  }

  componentDidUpdate() {
    this.fetchPacks(false);
  }

  fetchPacks(isComponentMount) {
    const {categoryId, packs, page} = this.props;
    const isWrongCollection = packs.page !== page || packs.categoryId !== categoryId;
    const isReasonToFetch = isComponentMount || !packs.isCollectionLoaded || isWrongCollection;

    if (isReasonToFetch && !packs.isLoading && !isFetchPacksError(packs)) {
      this.props.fetchPacks(categoryId, {page: page});
    }
  }

  render() {
    return null;
  }
}

FetchPacks.propTypes = {
  categoryId: PropTypes.string.isRequired,
  page: PropTypes.number,
  packs: PropTypes.object.isRequired,
  fetchPacks: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    packs: state.packs
  }
}

const mapActionsToProps = {
  fetchPacks
};

export default connect(mapStateToProps, mapActionsToProps)(FetchPacks);
