import generateObjectId from "./generateObjectId";

export function buildLine(currentParticipant, participants) {
  return {
    id: generateObjectId(),
    text: "",
    translation: "",
    participant: currentParticipant,
    participant_id: currentParticipant.id,
    participant_focuses: participants.map(() => 0)
  };
}
