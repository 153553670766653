import React, {useCallback, useRef} from "react";

const Slide = ({assignUserScenarioAttributes, index, slide, slides}) => {
  const handleRemove = useCallback(() => {
    const newSlides = slides.splice(index, 1);
    assignUserScenarioAttributes({slides: newSlides});
  }, [assignUserScenarioAttributes, slides, index]);

  return (
    <div key={index} className="d-flex align-items-center mt-1">
      <span>{slide.filename}</span>
      <button
        aria-label="Remove slide"
        className="button button_shape_circle button_icon_trash button_size_xs ml-2"
        onClick={handleRemove}
      />
    </div>
  );
};

const Slides = ({assignUserScenarioAttributes, formAttributes}) => {
  const fileInputRef = useRef(null);

  const handleUploadClick = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  const handleFileChange = useCallback(
    event => {
      event.preventDefault();

      const file = event.target.files[0];

      if (!file) return;

      const reader = new FileReader();

      reader.onloadend = () => {
        const slide = {file_base64: reader.result, filename: file.name};
        const newSlides = [...formAttributes.slides, slide];
        assignUserScenarioAttributes({...formAttributes, slides: newSlides});
      };

      reader.readAsDataURL(file);
    },
    [assignUserScenarioAttributes, formAttributes]
  );

  return (
    <div>
      {formAttributes.slides.map((slide, index) => (
        <Slide
          key={index}
          assignUserScenarioAttributes={assignUserScenarioAttributes}
          index={index}
          slide={slide}
          slides={formAttributes.slides}
        />
      ))}
      <button
        aria-label="Upload"
        className="button button_theme_plus-grey mt-4"
        onClick={handleUploadClick}
      />
      <form>
        <input
          ref={fileInputRef}
          aria-label="Upload input"
          className="hide"
          onChange={handleFileChange}
          type="file"
        />
      </form>
    </div>
  );
};

export default Slides;
