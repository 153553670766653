import React from "react";
import {Link} from "react-router-dom";

import ScenarioOwner from "./ScenarioOwner";

const TopPanel = ({backPath, user}) => (
  <div className="top-panel">
    <div className="top-panel__left">
      <Link
        className="top-panel__button top-panel__button_back hide-on-sm-and-down mr-2"
        to={backPath}
      />
      <ScenarioOwner avatarUrl={user.avatar.thumb_url} name={user.full_name} />
    </div>
  </div>
);

export default TopPanel;
