import {ADMIN_ROLE, MANAGER_ROLE, STUDENT_ROLE} from "../constants";

export function isStudent(user) {
  return user.role === STUDENT_ROLE;
}

export function isManager(user) {
  return user.role === MANAGER_ROLE;
}

export function isAdmin(user) {
  return user.role === ADMIN_ROLE;
}
