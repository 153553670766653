import React, {Component} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

class ActivityDateSlider extends Component {
  canMove = (date) => {
    const {from, maxFrom} = this.props;
    return date && moment(date) < moment(maxFrom).add(1, "week") &&
      (moment(date) < moment(from) || moment(from).add(1, "week") < moment(date));
  };

  get canMoveForward() {
    const {from, maxFrom} = this.props;
    return moment(from) < moment(maxFrom);
  }

  get canMoveBack() {
    const {from, minFrom} = this.props;
    return moment(minFrom) < moment(from);
  }

  formatDate(date) {
    return moment(date).format(I18n.t("date.moment_formats.activity_date"));
  }

  moveTo = (date) => () => {
    const {from, onChange} = this.props;
    const weekDifference = Math.ceil(moment(from).diff(date, "weeks", true));
    const time = moment(from).subtract(weekDifference, "weeks");
    onChange(time);
  };

  moveForward = () => {
    const {from, onChange} = this.props;
    onChange(moment(from).add(1, "week"));
  };

  moveBack = () => {
    const {from, onChange} = this.props;
    onChange(moment(from).subtract(1, "week"));
  };

  render() {
    const {from, to, first, last} = this.props;

    return (
      <div className="activity-date-slider">
        <div>
          <button
            onClick={this.moveTo(first)}
            disabled={!this.canMove(first)}
            className={classnames(
              "activity-date-slider__arrow",
              "activity-date-slider__arrow--first",
              {"activity-date-slider__arrow--disabled": !this.canMove(first)}
            )}
            title={I18n.t("dashboard.groups.activity_date_slider.first")}
          />
          <button
            onClick={this.moveBack}
            disabled={!this.canMoveBack}
            className={classnames(
              "activity-date-slider__arrow",
              "activity-date-slider__arrow--left",
              {"activity-date-slider__arrow--disabled": !this.canMoveBack}
            )}
            title={I18n.t("dashboard.groups.activity_date_slider.previous")}
          />
        </div>

        <span className="activity-date-slider__range">
          {`${this.formatDate(from)} - ${this.formatDate(to)}`}
        </span>

        <div>
          <button
            onClick={this.moveForward}
            disabled={!this.canMoveForward}
            className={classnames(
              "activity-date-slider__arrow",
              "activity-date-slider__arrow--right",
              {"activity-date-slider__arrow--disabled": !this.canMoveForward}
            )}
            title={I18n.t("dashboard.groups.activity_date_slider.next")}
          />
          <button
            onClick={this.moveTo(last)}
            disabled={!this.canMove(last)}
            className={classnames(
              "activity-date-slider__arrow",
              "activity-date-slider__arrow--last",
              {"activity-date-slider__arrow--disabled": !this.canMove(last)}
            )}
            title={I18n.t("dashboard.groups.activity_date_slider.last")}
          />
        </div>
      </div>
    );
  }
}

ActivityDateSlider.propTypes = {
  first: PropTypes.any,
  last: PropTypes.any,
  from: PropTypes.any.isRequired,
  to: PropTypes.any.isRequired,
  minFrom: PropTypes.any.isRequired,
  maxFrom: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ActivityDateSlider;
