import {connect} from "react-redux";
import PropTypes from "prop-types";
import React, {Fragment} from "react";

import Header from "components/shared/Header";
import MainLoader from "components/shared/MainLoader";
import ScrollUp from "components/components/ScrollUp";

const ApplicationLayout = ({children, company, currentUser, headerTitle, isLoading}) => {
  return (
    <Fragment>
      <ScrollUp /> {/* Scroll to the top after page change */}
      <Header company={company} currentUser={currentUser} title={headerTitle} />
      {children}
      <MainLoader isShow={isLoading} />
    </Fragment>
  );
};

ApplicationLayout.propTypes = {
  company: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  headerTitle: PropTypes.string,
  isLoading: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    company: state.companies.current,
    currentUser: state.profiles.current
  };
}

export default connect(mapStateToProps)(ApplicationLayout);
