import React, {memo} from "react";
import {Link} from "react-router-dom";

import {isPublic} from "../../../../helpers/packsHelper";

import BaseContextMenu from "../../context_menu/ContextMenu";
import ContextMenuItem from "../../context_menu/ContextMenuItem";
import DeleteUserScenarioModal from "./DeleteUserScenarioModal";
import ScenarioOwner from "../../ScenarioOwner";

const ContextMenu = ({backPath, editPath, scenario}) => {
  if (!editPath) return null;

  return (
    <BaseContextMenu className="top-panel__context-menu">
      <ContextMenuItem linkTo={editPath}>
        {I18n.t("user_scenarios.scenario_actions.edit")}
      </ContextMenuItem>
      <DeleteUserScenarioModal redirectPath={backPath} userScenario={scenario} />
    </BaseContextMenu>
  );
};

const TopPanel = ({backPath, editPath = "", scenario, user}) => {
  return (
    <div className="top-panel">
      <div className="top-panel__left">
        <Link
          className="top-panel__button top-panel__button_back hide-on-sm-and-down"
          to={backPath}
        />
        <div className="top-panel__title">
          <div className="top-panel__title-top">
            <span className="top-panel__title-top-label">
              {I18n.t("mongoid.models.pack.one")}
            </span>
            <span className="top-panel__title-top-text">{scenario.pack.title}</span>
            {!isPublic(scenario.pack) && (
              <span className="top-panel__title-top-icon top-panel__title-top-icon_lock" />
            )}
          </div>
          <div className="top-panel__title-bottom">{scenario.title}</div>
        </div>
      </div>
      <div className="top-panel__right">
        <ScenarioOwner avatarUrl={user.avatar.thumb_url} name={user.full_name} />
        <ContextMenu backPath={backPath} editPath={editPath} scenario={scenario} />
      </div>
    </div>
  );
};

export default memo(TopPanel);
