import generateObjectId from "./generateObjectId";

import {DEFAULT_PARTICIPANT_KIND} from "../constants";

export function buildParticipant(character, initialPosition) {
  return {
    avatar_url: character.avatar_url,
    character_id: character.id,
    description: "",
    id: generateObjectId(),
    identification: character.identification,
    kind: DEFAULT_PARTICIPANT_KIND,
    name: character.name,
    position: initialPosition,
    role: "",
    static_id: generateObjectId()
  };
}

export function renderSelect2Participant(state) {
  if (state.loading) {
    return state.text;
  }

  const avatarUrl = state.element.attributes.avatar_url.value;

  /* eslint-disable prettier/prettier,no-undef */
  return $(
    "<span class='select_edit_wp'>" +
    "<div class='participant-avatar avatar'>" +
    `<img src="${avatarUrl}" alt=""/>` +
    "</div>" +
    `<span class='select_edit_text font-weight-bold'>${state.text}</span>` +
    "</span>"
  );
  /* eslint-enable prettier/prettier,no-undef */
}

export function updateParticipant(participant, character) {
  return {
    ...participant,
    avatar_url: character.avatar_url,
    character_id: character.id,
    identification: character.identification,
    name: character.name
  };
}
