import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchUserError} from "../../helpers/errorsHelpers";

import {fetchUser} from "../../store/actions/usersActions";

class FetchUser extends Component {
  componentDidMount() {
    this.fetchUser(true);
  }

  componentDidUpdate() {
    this.fetchUser(false);
  }

  fetchUser(isComponentMount) {
    const {id, groupId, users} = this.props;
    const isReasonToFetch = isComponentMount || users.current.id !== id || users.groupId !== groupId;

    if (isReasonToFetch && !users.isLoading && !isFetchUserError(users)) {
      this.props.fetchUser(id, {groupId});
    }
  }

  render() {
    return null;
  }
}

FetchUser.propTypes = {
  id: PropTypes.string.isRequired,
  groupId: PropTypes.string,
  users: PropTypes.object.isRequired,
  fetchUser: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    users: state.users
  }
}

const mapActionsToProps = {
  fetchUser
};

export default connect(mapStateToProps, mapActionsToProps)(FetchUser);
