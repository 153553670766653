import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";

import {openModal} from "../../../store/actions/modalsActions";

import Row from './_Row';
import CreateScoreModal from "./_CreateScoreModal";
import UpdateScoreModal from "./_UpdateScoreModal";
import DeleteScoreModal from "./_DeleteScoreModal";

class Show extends Component {
  getScoresByKind = (kind) => {
    return _.sortBy(this.props.englishScores.filter(score => score.kind === kind), "date").reverse();
  };

  renderNoScoresSection() {
    if (this.props.englishScores.length) {
      return null;
    }

    return (
      <div className="text-brown-dark pt-3">
        {I18n.t("profiles.scores.no_scores")}
      </div>
    );
  }

  render() {
    const {userId, openModal} = this.props;

    return (
      <div className="scores">
        <div className="scores-title">
          <div className="scores-title__text">
            {I18n.t("profiles.scores.title")}
          </div>
          <CreateScoreModal userId={userId}/>
        </div>

        <div className="pt-2">
          {_.map(I18n.t("mongoid.attributes.english_score.kinds"), (title, kind) => {
            const scoresByKind = this.getScoresByKind(kind);

            if (!scoresByKind.length) return null;

            return (
              <div key={kind} className="score-section">
                <div className="score-section__title">
                  {title}
                </div>
                {this.getScoresByKind(kind).map(englishScore => (
                  <Row key={englishScore.id} englishScore={englishScore} openModal={openModal}/>
                ))}
              </div>
            )
          })}
          {this.renderNoScoresSection()}
        </div>

        <UpdateScoreModal userId={userId}/>
        <DeleteScoreModal userId={userId}/>
      </div>
    );
  }
}

Show.propTypes = {
  userId: PropTypes.string.isRequired,
  englishScores: PropTypes.array.isRequired,
  openModal: PropTypes.func.isRequired
};

const mapActionsToProps = {
  openModal
};

export default connect(null, mapActionsToProps)(Show);
