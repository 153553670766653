import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchPackSharesError} from "../../helpers/errorsHelpers";

import {fetchPackShares} from "../../store/actions/packSharesActions";

class FetchPackShares extends Component {
  componentDidMount() {
    this.fetchPackShares(true);
  }

  componentDidUpdate() {
    this.fetchPackShares(false);
  }

  fetchPackShares(isComponentMount) {
    const {packShares} = this.props;
    const isReasonToFetch = isComponentMount || !packShares.isCollectionLoaded;

    if (isReasonToFetch && !packShares.isLoading && !isFetchPackSharesError(packShares)) {
      this.props.fetchPackShares();
    }
  }

  render() {
    return null;
  }
}

FetchPackShares.propTypes = {
  packShares: PropTypes.object.isRequired,
  fetchPackShares: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    packShares: state.packShares
  }
}

const mapActionsToProps = {
  fetchPackShares
};

export default connect(mapStateToProps, mapActionsToProps)(FetchPackShares);
