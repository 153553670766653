export const ACTIONS = {
  ASSIGN_TARGET_SCORE_ATTRIBUTES: "ASSIGN_TARGET_SCORE_ATTRIBUTES",
  CLEAR_TARGET_SCORE_ATTRIBUTES: "CLEAR_TARGET_SCORE_ATTRIBUTES"
};

const INITIAL_FORM_ATTRIBUTES = {
  targetScore: {},
  enabledScore: {},
  errors: {}
};

export default function reducer(state = {
  formAttributes: INITIAL_FORM_ATTRIBUTES
}, action) {
  switch (action.type) {
    case ACTIONS.ASSIGN_TARGET_SCORE_ATTRIBUTES: {
      return {...state, formAttributes: {errors: state.formAttributes.errors, ...action.attributes}};
    }
  }
  return state;
}
