import React from "react";
import PropTypes from "prop-types";
import {CartesianGrid, Line, LineChart} from 'recharts';

const BASE_VALUE = 180;

const Chart = ({weeks}) => {
  const data = weeks.map(seconds => ({minutes: seconds / 60, base: BASE_VALUE}));

  if (!data.length) {
    return null;
  }

  return (
    <LineChart width={100} height={50} data={data} margin={{top: 5, right: 10, left: 10, bottom: 5}}>
      <Line
        dataKey="minutes"
        dot={false}
        isAnimationActive={false}
        stroke="#525252"
        strokeWidth={2}
        type="linear"
      />
      <Line
        dataKey="base"
        dot={false}
        isAnimationActive={false}
        stroke="#A19C94"
        strokeDasharray="3 2"
        type="linear"
      />
    </LineChart>
  );
}

Chart.propTypes = {
  weeks: PropTypes.array.isRequired
};

export default Chart;
