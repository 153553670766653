import React, {Component} from "react";
import PropTypes from "prop-types";
import Select2 from "react-select2-wrapper";

import {USER_ACTIVITIES_ORDERS} from "../../../../constants";

class UserActivityOrderSelect extends Component {
  onOrderChange = event => {
    this.props.changeUserActivitiesOrder(event.target.value);
  };

  get orderSelect2Data() {
    return [
      USER_ACTIVITIES_ORDERS.WEEK_TOTAL,
      USER_ACTIVITIES_ORDERS.USER_NAME,
      USER_ACTIVITIES_ORDERS.WEEKLY_AVERAGE_DURATION,
      USER_ACTIVITIES_ORDERS.WEEKLY_AVERAGE_DAYS,
      USER_ACTIVITIES_ORDERS.TIER,
      USER_ACTIVITIES_ORDERS.COURSE_PROGRESS
    ].map(order => ({
      id: order,
      text: I18n.t(`dashboard.activity_orders.${order}`)
    }));
  }

  render() {
    const {userActivities} = this.props;

    return (
      <Select2
        className="select2-in select2_size_sm form-group__input"
        data={this.orderSelect2Data}
        onChange={this.onOrderChange}
        options={{
          minimumResultsForSearch: -1,
          theme: "activity-order"
        }}
        value={userActivities.order}
      />
    );
  }
}

UserActivityOrderSelect.propTypes = {
  changeUserActivitiesOrder: PropTypes.func.isRequired,
  userActivities: PropTypes.object.isRequired
};

export default UserActivityOrderSelect;
