/* eslint-disable */
import React, {memo} from "react";

const fn = ({className}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <path fill="#C6C1BB" d="M18.941 21.113c-.206 0-.398-.042-.54-.105-.504-.258-.859-.614-1.214-1.661-.362-1.09-1.044-1.6-1.697-2.095-.56-.426-1.143-.866-1.647-1.767-.377-.67-.582-1.403-.582-2.053 0-1.955 1.562-3.49 3.55-3.49 1.988 0 3.55 1.535 3.55 3.49h1.42c0-2.744-2.18-4.887-4.97-4.887-2.79 0-4.97 2.143-4.97 4.887 0 .88.27 1.85.76 2.724.645 1.152 1.405 1.731 2.023 2.199.575.433.987.747 1.214 1.431.426 1.271.973 1.983 1.939 2.48.362.16.76.244 1.164.244 1.57 0 2.84-1.25 2.84-2.793h-1.42c0 .768-.639 1.396-1.42 1.396zM12.295 8.992L11.286 8C9.873 9.39 9 11.31 9 13.432c0 2.123.873 4.043 2.286 5.433l1.002-.985c-1.15-1.138-1.868-2.709-1.868-4.448 0-1.738.717-3.31 1.875-4.44zm2.74 4.44c0 .964.796 1.746 1.776 1.746s1.775-.782 1.775-1.746c0-.963-.795-1.745-1.775-1.745-.98 0-1.775.782-1.775 1.745z"/>
  </svg>
);

export default memo(fn);

