import React, {Component} from "react"
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";

import {closeModal} from "../../../store/actions/modalsActions";
import {
  assignEnglishScoreAttributes,
  clearEnglishScoreAttributes,
  createEnglishScore
} from "../../../store/actions/englishScoresActions";

import DefaultModal from "../../../components/shared/modals/DefaultModal";
import Form from './_Form';

const MODAL_NAME = "CREATE_SCORE_MODAL";

class createEnglishScoreModal extends Component {
  handleOpen = () => {
    this.props.clearEnglishScoreAttributes();
  };

  handleSubmit = score => {
    const {userId, createEnglishScore, closeModal} = this.props;
    createEnglishScore(userId, {...score}, {
      onSuccess: () => closeModal(MODAL_NAME)
    });
  };

  openForm = kind => () => {
    const {englishScores, assignEnglishScoreAttributes} = this.props;
    assignEnglishScoreAttributes({...englishScores.formAttributes, kind, date: new Date()});
  };

  get modalTitle() {
    if (this.isFormOpen) {
      return I18n.t("profiles.scores.add_score");
    }
    return I18n.t("profiles.scores.select_test");
  }

  get onBack() {
    if (this.isFormOpen) {
      return () => this.props.clearEnglishScoreAttributes()
    }
    return null;
  }

  get isFormOpen() {
    return !!this.props.englishScores.formAttributes.kind;
  }

  renderForm() {
    const {englishScores, assignEnglishScoreAttributes} = this.props;
    const {formAttributes, isLoading} = englishScores;

    return (
      <Form
        formAttributes={formAttributes}
        assignEnglishScoreAttributes={assignEnglishScoreAttributes}
        onSubmit={this.handleSubmit}
        isLoading={isLoading}
      />
    );
  }

  render() {
    return (
      <DefaultModal modalName={MODAL_NAME}
                    modalTitle={this.modalTitle}
                    buttonClass="button button_shape_circle button_icon_plus button_size_xs"
                    onBack={this.onBack}
                    onOpen={this.handleOpen}>
        <div className="bem-popup__content">
          {
            !this.isFormOpen &&
            <ul className="simple-list">
              {_.map(I18n.t("mongoid.attributes.english_score.kinds"), (title, kind) => (
                <li key={kind} className="simple-list__item">
                  <a className="simple-list__link" onClick={this.openForm(kind)}>
                    {title}
                  </a>
                </li>
              ))}
            </ul>
          }
          {
            this.isFormOpen &&
            <div className="row no-gutters">
              <div className="col-12 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                {this.renderForm()}
              </div>
            </div>
          }
        </div>
      </DefaultModal>
    );
  }
}

createEnglishScoreModal.propTypes = {
  englishScores: PropTypes.object.isRequired,
  createEnglishScore: PropTypes.func.isRequired,
  assignEnglishScoreAttributes: PropTypes.func.isRequired,
  clearEnglishScoreAttributes: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    englishScores: state.englishScores
  }
}

const mapActionsToProps = {
  closeModal,
  createEnglishScore,
  assignEnglishScoreAttributes,
  clearEnglishScoreAttributes
};

export default connect(mapStateToProps, mapActionsToProps)(createEnglishScoreModal);
