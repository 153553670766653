import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import classnames from "classnames";
import _ from "lodash";

import {dashboardScenarioReviewPath} from "helpers/routesHelper";
import {formatMetricTime} from "helpers/dateTimeHelper";
import {isFetchMetricsError} from "helpers/errorsHelpers";
import {modeToBemModifier} from "helpers/modesHelper";

import {fetchMoreMetrics} from "store/actions/metricsActions";

import FetchMetrics from "components/data_loaders/FetchMetrics";
import Loader from "components/shared/Loader";
import Participant from "./Participant";

class Metrics extends Component {
  fetchMoreMetrics = () => {
    const {user, metrics} = this.props;

    if (!metrics.isLoading && !metrics.pagination.is_last_page) {
      this.props.fetchMoreMetrics(user.id, {page: metrics.pagination.next_page});
    }
  };

  renderUserParticipant(completedScenario) {
    if (!completedScenario.user_participant) {
      return null;
    }

    const participant = completedScenario.user_participant;

    return <Participant avatarUrl={participant.avatar_url} name={participant.role} />;
  }

  renderMode(mode) {
    const modeClass = `pd-metrics__mode--${modeToBemModifier(mode)}`;

    return <div className={classnames("pd-metrics__mode", modeClass)}>{mode.title}</div>;
  }

  renderStatus(completedScenario) {
    const status = completedScenario.passed ? "success" : "failure";
    const statusClass = `pd-metrics__status--${status}`;

    return (
      <div className={classnames("pd-metrics__status", statusClass)}>
        {completedScenario.passed ? I18n.t("success") : I18n.t("fail")}
      </div>
    );
  }

  renderSubmode(completedScenario) {
    if (!completedScenario.submode) {
      return null;
    }

    return (
      <div className="pd-metrics__submode">
        {I18n.t(`dashboard.submodes.${completedScenario.submode}`)}
      </div>
    );
  }

  renderReviewButton = metric => {
    const {groupId} = this.props;

    return (
      <Link
        className="button pd-metrics__review-button"
        to={dashboardScenarioReviewPath(metric.id, groupId)}
      >
        {I18n.t("buttons.review")}
      </Link>
    );
  };

  render() {
    const {user, metrics} = this.props;

    const isLoaded = metrics.isCollectionLoaded && metrics.userId === user.id;
    const isLoadingError = isFetchMetricsError(metrics);
    const isLoading = (!isLoaded && !isLoadingError) || metrics.isLoading;

    return (
      <div className="pd-metrics">
        <FetchMetrics page={1} userId={user.id} />
        <Loader isShow={isLoading} />
        {isLoaded && (
          <Fragment>
            <div className="pd-metrics__label">{I18n.t("mongoid.models.scenario.other")}</div>
            {_.isEmpty(metrics.collection) && (
              <div className="pd-metrics__empty">{I18n.t("dashboard.no_scenario_activity")}</div>
            )}
            {!_.isEmpty(metrics.collection) && (
              <Fragment>
                <table className="pd-metrics__table">
                  <thead>
                    <tr>
                      <th>{I18n.t("dashboard.scenario")}</th>
                      <th>{I18n.t("dashboard.role")}</th>
                      <th>{I18n.t("dashboard.mode")}</th>
                      <th>{I18n.t("dashboard.result")}</th>
                      <th>{I18n.t("dashboard.submode")}</th>
                      <th aria-label="Actions" />
                    </tr>
                  </thead>

                  <tbody>
                    {metrics.collection.map(metric => {
                      const completedScenario = metric.completed_scenario;

                      return (
                        <tr key={metric.id}>
                          <td>
                            <div className="pd-metrics__title">{completedScenario.title}</div>
                            <div className="pd-metrics__date">
                              {formatMetricTime(metric.created_at)}
                            </div>
                          </td>
                          <td>{this.renderUserParticipant(completedScenario)}</td>
                          <td>{this.renderMode(completedScenario.mode)}</td>
                          <td>{this.renderStatus(completedScenario)}</td>
                          <td>{this.renderSubmode(completedScenario)}</td>
                          <td>{this.renderReviewButton(metric)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <div className="pd-show-more">
                  {!metrics.pagination.is_last_page && (
                    <button
                      className="button button_color_grey"
                      disabled={metrics.isLoading}
                      onClick={this.fetchMoreMetrics}
                    >
                      {I18n.t("buttons.show_more")}
                    </button>
                  )}
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

Metrics.propTypes = {
  groupId: PropTypes.string,
  metrics: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    metrics: state.metrics
  };
}

const mapActionsToProps = {
  fetchMoreMetrics
};

export default connect(mapStateToProps, mapActionsToProps)(Metrics);
