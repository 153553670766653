import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchPackCategoryError} from "../../helpers/errorsHelpers";

import {fetchPackCategory} from "../../store/actions/packCategoriesActions";

class FetchPackCategory extends Component {
  componentDidMount() {
    this.fetchPackCategory(true);
  }

  componentDidUpdate() {
    this.fetchPackCategory(false);
  }

  fetchPackCategory(isComponentMount) {
    const {id, packCategories} = this.props;
    const isReasonToFetch = isComponentMount || packCategories.current.id !== id;

    if (isReasonToFetch && !packCategories.isLoading && !isFetchPackCategoryError(packCategories)) {
      this.props.fetchPackCategory(id, packCategories.collection);
    }
  }

  render() {
    return null;
  }
}

FetchPackCategory.propTypes = {
  id: PropTypes.string.isRequired,
  packCategories: PropTypes.object.isRequired,
  fetchPackCategory: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    packCategories: state.packCategories
  }
}

const mapActionsToProps = {
  fetchPackCategory
};

export default connect(mapStateToProps, mapActionsToProps)(FetchPackCategory);
