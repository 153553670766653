import React, {Component} from "react"
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

import {tagsPath, editTagPath} from "../../helpers/routesHelper";

class TopPanelShow extends Component {
  render() {
    const {tag} = this.props;

    return (
      <div className="top-panel">
        <div className="top-panel__left">
          <Link to={tagsPath()} className="top-panel__button top-panel__button_back hide-on-sm-and-down"/>
          <div className="top-panel__owner-icon top-panel__owner-icon_course"/>
          <div className="top-panel__title">
            {tag.english_title}
          </div>
        </div>
        <div className="top-panel__right">
          <Link
            to={editTagPath(tag.id)}
            className="top-panel__button top-panel__button_edit hide-on-sm-and-down"
          />
        </div>
      </div>
    )
  }
}

TopPanelShow.propTypes = {
  tag: PropTypes.object.isRequired
};

export default TopPanelShow;
