import React, {Component} from "react";
import PropTypes from "prop-types";
import {Handle, Range} from "rc-slider";
import Tooltip from "rc-tooltip";

import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import "../../stylesheets/rc_slider/rc_slider.css";

class RangeSlider extends Component {
  handle = (props) => {
    const {value, dragging, index, ...restProps} = props;
    const formattedValue = this.props.tipFormatter(value);
    return (
      <Tooltip prefixCls="rc-slider-tooltip" overlay={formattedValue} visible={dragging} placement="top" key={index}>
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
  };

  render() {
    const {min, max, value, tipFormatter} = this.props;

    return (
      <Range
        min={min}
        max={max}
        value={value}
        tipFormatter={tipFormatter}
        handle={this.handle}
        onChange={this.props.onChange}
      />
    );
  }
}

RangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.array,
  tipFormatter: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default RangeSlider;
