import {useEffect, useState} from "react";

import {LOADING_STATUSES} from "../../../constants";

import webToolApi from "../../../helpers/webToolApiHelper";

const fetchScenarios = ({category, page, query}) =>
  webToolApi().get(Routes.user_scenarios_search_path({category, page, query}));

export default function useScenariosLoader({category, notifyResponseError, page, query}) {
  const [status, setStatus] = useState(LOADING_STATUSES.INITIAL);
  const [data, setData] = useState({});

  useEffect(() => {
    setStatus(LOADING_STATUSES.LOADING);
    fetchScenarios({category, page, query})
      .then(({data}) => {
        setData(data);
        setStatus(LOADING_STATUSES.LOADED);
      })
      .catch(({response}) => {
        notifyResponseError(I18n.t("user_scenarios.messages.index.error"), response);
        setStatus(LOADING_STATUSES.FAILED);
      });
  }, [category, notifyResponseError, page, query]);

  return [data, status];
}
