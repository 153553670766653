export const ACTIONS = {
  ASSIGN_PASSWORD_ATTRIBUTES: "ASSIGN_PASSWORD_ATTRIBUTES",
  CHANGE_PASSWORDS_LOADING: "CHANGE_PASSWORDS_LOADING",
  CLEAR_PASSWORD_ATTRIBUTES: "CLEAR_PASSWORD_ATTRIBUTES",
  CREATE_PASSWORD_FULFILLED: "CREATE_PASSWORD_FULFILLED",
  CREATE_PASSWORD_REJECTED: "CREATE_PASSWORD_REJECTED",
  DISABLE_PASSWORDS_RESET_CAPTCHA: "DISABLE_PASSWORDS_RESET_CAPTCHA",
  ENABLE_PASSWORDS_SUBMIT: "ENABLE_PASSWORDS_SUBMIT",
  RESET_PASSWORDS_CAPTCHA: "RESET_PASSWORDS_CAPTCHA",
  UPDATE_PASSWORD_FULFILLED: "UPDATE_PASSWORD_FULFILLED",
  UPDATE_PASSWORD_REJECTED: "UPDATE_PASSWORD_REJECTED"
};

const INITIAL_FORM_ATTRIBUTES = {
  email: "",
  errors: {},
  password: "",
  password_confirmation: ""
};

export default function reducer(
  state = {
    errors: {},
    formAttributes: INITIAL_FORM_ATTRIBUTES,
    isLoading: false,
    isResetCaptcha: true,
    isSubmitDisabled: true,
    loadingCount: 0
  },
  action
) {
  switch (action.type) {
    case ACTIONS.CHANGE_PASSWORDS_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);

      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.ENABLE_PASSWORDS_SUBMIT: {
      return {...state, isSubmitDisabled: false};
    }

    case ACTIONS.ASSIGN_PASSWORD_ATTRIBUTES: {
      return {
        ...state,
        formAttributes: {errors: state.formAttributes.errors, ...action.attributes}
      };
    }
    case ACTIONS.CLEAR_PASSWORD_ATTRIBUTES: {
      return {...state, formAttributes: INITIAL_FORM_ATTRIBUTES};
    }

    case ACTIONS.CREATE_PASSWORD_REJECTED: {
      const attributesWithNewErrors = {...state.formAttributes, errors: action.errors};

      return {...state, formAttributes: attributesWithNewErrors};
    }

    case ACTIONS.DISABLE_PASSWORDS_RESET_CAPTCHA: {
      return {...state, isResetCaptcha: false};
    }

    case ACTIONS.RESET_PASSWORDS_CAPTCHA: {
      return {...state, isResetCaptcha: true, isSubmitDisabled: true};
    }

    case ACTIONS.UPDATE_PASSWORD_REJECTED: {
      const attributesWithNewErrors = {...state.formAttributes, errors: action.errors};

      return {...state, formAttributes: attributesWithNewErrors};
    }

    default: {
      return state;
    }
  }
}
