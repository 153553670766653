import React, {useCallback, useState} from "react";
import {connect} from "react-redux";
import {fetchDashboardUser} from "store/actions/dashboardUsersActions";
import {updateCoursesUser} from "store/actions/coursesUsersActions";
import _ from "lodash";

import Switch from "components/shared/Switch";

const SwitchUnlocked = ({
  course,
  dashboardUsers,
  fetchDashboardUser,
  scenario,
  updateCoursesUser
}) => {
  const [isLoading, setLoading] = useState(false);

  const handleChange = useCallback(
    event => {
      setLoading(true);

      const unlockedScenarioIds = course.unlocked_scenario_ids;

      if (event.target.checked) {
        unlockedScenarioIds.push(scenario.id);
      } else {
        _.remove(unlockedScenarioIds, x => x === scenario.id);
      }

      const attributes = {
        id: course.id,
        unlocked_scenario_ids: unlockedScenarioIds
      };

      updateCoursesUser(attributes, {
        onError: () => {
          setLoading(false);
        },
        onSuccess: () => {
          setLoading(false);
          fetchDashboardUser(
            dashboardUsers.current.id,
            {groupId: dashboardUsers.groupId},
            {silentReload: true}
          );
        }
      });
    },
    [
      course.id,
      course.unlocked_scenario_ids,
      dashboardUsers,
      fetchDashboardUser,
      scenario.id,
      updateCoursesUser
    ]
  );

  return (
    <Switch
      disabled={isLoading}
      name={`courses_user[${course.id}][scenario_locked]${scenario.id}`}
      onChange={handleChange}
      value={scenario.unlocked}
    />
  );
};

function mapStateToProps(state) {
  return {
    dashboardUsers: state.dashboardUsers
  };
}

const mapActionsToProps = {
  fetchDashboardUser,
  updateCoursesUser
};

export default connect(mapStateToProps, mapActionsToProps)(SwitchUnlocked);
