import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {closeModal} from "../../../store/actions/modalsActions";
import BaseModal from "./BaseModal";

class ConfirmationModal extends Component {
  closeModal = (event) => {
    event && event.preventDefault();
    this.props.onClose && this.props.onClose();
    this.props.closeModal(this.props.modalName);
  };

  submitModal = (event) => {
    event.preventDefault();
    this.props.onSubmit && this.props.onSubmit(this.closeModal);
  };

  renderActions() {
    const {actionsStyle} = this.props;
    let cancelClass, submitClass, cancelCaption, submitCaption;
    switch (actionsStyle) {
      case "YES_NO": {
        cancelClass = "button button_color_grey w-100";
        submitClass = "button w-100 ml-3";
        cancelCaption = I18n.t("can");
        submitCaption = I18n.t("del");
        break;
      }
      case "DELETE_CANCEL": {
        cancelClass = "button button_color_grey w-100";
        submitClass = "button button_color_red w-100 ml-3";
        cancelCaption = I18n.t("cancel");
        submitCaption = I18n.t("delete");
        break;
      }
    }

    return (
      <Fragment>
        <button className={cancelClass} onClick={this.closeModal}>{cancelCaption}</button>
        <button className={submitClass} onClick={this.submitModal} autoFocus>{submitCaption}</button>
      </Fragment>
    );
  }

  render() {
    const {message} = this.props;

    return (
      <BaseModal modalName={this.props.modalName}
                 isRenderButton={this.props.isRenderButton}
                 button={this.props.button}
                 buttonClass={this.props.buttonClass}
                 buttonText={this.props.buttonText}
                 buttonDisabled={this.props.buttonDisabled}
                 buttonTitle={this.props.buttonTitle}
                 contentStyle={this.props.contentStyle}
                 onOpen={this.props.onOpen}
                 onClose={this.props.onClose}>
        <div className="bem-popup__content">
          <div className="bem-popup__message">
            {message}
          </div>
          <div className="bem-popup__confirmation_actions">
            {this.renderActions()}
          </div>
        </div>
      </BaseModal>
    );
  }
}

ConfirmationModal.defaultProps = {
  actionsStyle: "YES_NO"
};

ConfirmationModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  isRenderButton: PropTypes.bool,
  button: PropTypes.object,
  buttonClass: PropTypes.string,
  buttonText: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  buttonTitle: PropTypes.string,
  contentStyle: PropTypes.object,
  actionsStyle: PropTypes.oneOf(["YES_NO", "DELETE_CANCEL"]),
  message: PropTypes.string.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  closeModal: PropTypes.func.isRequired
};

const mapActionsToProps = {
  closeModal
};

export default connect(null, mapActionsToProps)(ConfirmationModal);
