import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchCoursesError} from "../../helpers/errorsHelpers";

import {fetchCourses} from "../../store/actions/coursesActions";

class FetchCourses extends Component {
  componentDidMount() {
    this.fetchCourses(true);
  }

  componentDidUpdate() {
    this.fetchCourses(false);
  }

  fetchCourses(isComponentMount) {
    const {courses, page} = this.props;
    const isWrongCollection = courses.page !== page;
    const isReasonToFetch = isComponentMount || !courses.isCollectionLoaded || isWrongCollection;

    if (isReasonToFetch && !courses.isLoading && !isFetchCoursesError(courses)) {
      this.props.fetchCourses({page});
    }
  }

  render() {
    return null;
  }
}

FetchCourses.propTypes = {
  page: PropTypes.number,
  courses: PropTypes.object.isRequired,
  fetchCourses: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    courses: state.courses
  }
}

const mapActionsToProps = {
  fetchCourses
};

export default connect(mapStateToProps, mapActionsToProps)(FetchCourses);
