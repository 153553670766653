import React, {Component} from "react"
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {closeModal} from "../../../store/actions/modalsActions";
import {assignEnglishScoreAttributes, updateEnglishScore} from "../../../store/actions/englishScoresActions";

import DefaultModal from "../../../components/shared/modals/DefaultModal";
import Form from './_Form';

export const MODAL_NAME = "UPDATE_SCORE_MODAL";

class UpdateScoreModal extends Component {
  handleOpen = () => {
    const {modals, assignEnglishScoreAttributes} = this.props;
    assignEnglishScoreAttributes(modals.options.englishScore);
  };

  handleSubmit = (englishScore) => {
    const {userId, updateEnglishScore, closeModal} = this.props;
    updateEnglishScore(userId, englishScore, {
      onSuccess: () => closeModal(MODAL_NAME)
    });
  };

  get isOpen() {
    return this.props.modals.current === MODAL_NAME;
  }

  render() {
    if (!this.isOpen) {
      return null;
    }

    const {englishScores, assignEnglishScoreAttributes} = this.props;
    const {formAttributes, isLoading} = englishScores;

    return (
      <DefaultModal modalName={MODAL_NAME}
                    modalTitle={I18n.t("profiles.scores.edit_score")}
                    onOpen={this.handleOpen}
                    isRenderButton={false}>
        <div className="bem-popup__content">
          <div className="row no-gutters">
            <div className="col-12 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
              <Form
                formAttributes={formAttributes}
                assignEnglishScoreAttributes={assignEnglishScoreAttributes}
                onSubmit={this.handleSubmit}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </DefaultModal>
    );
  }
}

UpdateScoreModal.propTypes = {
  englishScores: PropTypes.object.isRequired,
  modals: PropTypes.shape({
    options: PropTypes.shape({
      englishScore: PropTypes.object,
    })
  }),
  updateEnglishScore: PropTypes.func.isRequired,
  assignEnglishScoreAttributes: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    englishScores: state.englishScores,
    modals: state.modals
  }
}

const mapActionsToProps = {
  closeModal,
  updateEnglishScore,
  assignEnglishScoreAttributes
};

export default connect(mapStateToProps, mapActionsToProps)(UpdateScoreModal);
