import React, {Fragment, useCallback} from "react";
import TextareaAutosize from "react-autosize-textarea";
import Select2 from "react-select2-wrapper";
import _ from "lodash";

import {
  buildParticipant,
  renderSelect2Participant,
  updateParticipant
} from "../../../../helpers/participantsHelper";

import ContextMenu from "../../context_menu/ContextMenu";
import DeleteParticipantModel from "./DeleteParticipantModal";
import Errors from "./Errors";

const Participants = ({
  assignUserScenarioAttributes,
  characters,
  formAttributes,
  onChange,
  positions
}) => {
  const kindsSelect2Data = _.map(I18n.t("mongoid.attributes.participant.kinds"), (value, key) => ({
    id: key,
    text: value
  }));

  const participantSelect2Data = characters.collection.map(character => ({
    avatar_url: character.avatar_url,
    id: character.id,
    text: character.name
  }));

  const positionsSelect2Data = positions.map(position => ({
    id: position,
    text: position
  }));

  const isMaxParticipants = formAttributes.participants.length >= positions.length;

  const handleCharacterChange = useCallback(
    (event, participantIndex) => {
      const {participants} = formAttributes;
      const characterId = event.target.value;
      const character = _.find(characters.collection, {id: characterId});
      const updatedParticipants = [...participants];
      updatedParticipants[participantIndex] = updateParticipant(
        participants[participantIndex],
        character
      );
      assignUserScenarioAttributes({...formAttributes, participants: updatedParticipants});
    },
    [assignUserScenarioAttributes, characters.collection, formAttributes]
  );

  const addParticipant = useCallback(() => {
    const {participants} = formAttributes;

    const newParticipant = buildParticipant(characters.collection[0], positions[0]);
    const updatedParticipants = [...participants, newParticipant];

    const updatedLines = formAttributes.lines.map(line => ({
      ...line,
      participant_focuses: [...line.participant_focuses, 0]
    }));

    assignUserScenarioAttributes({
      ...formAttributes,
      lines: updatedLines,
      participants: updatedParticipants
    });
  }, [assignUserScenarioAttributes, characters.collection, formAttributes, positions]);

  return (
    <Fragment>
      <table className="scenario-edit__table scenario-edit__table--roles">
        <thead>
          <tr>
            <th>{I18n.t("mongoid.attributes.participant.role")}</th>
            <th>{I18n.t("mongoid.attributes.participant.name")}</th>
            <th>{I18n.t("mongoid.attributes.participant.character")}</th>
            <th>{I18n.t("mongoid.attributes.participant.description")}</th>
            <th>{I18n.t("mongoid.attributes.participant.position")}</th>
            <th aria-label="Actions" />
          </tr>
        </thead>

        <tbody>
          {formAttributes.participants.map((participant, index) => (
            <tr key={participant.id}>
              <td>
                <input
                  aria-label="Role"
                  className="transparent-form-group__input transparent-form-group__input--role"
                  defaultValue={participant.role}
                  id={`participants.${index}.role`}
                  name={`participants.${index}.role`}
                  onBlur={onChange}
                  placeholder={I18n.t("user_scenarios.role_placeholder")}
                  type="text"
                />
                <Errors formAttributes={formAttributes} name={`participants[${index}].role`} />
              </td>

              <td>
                <input
                  aria-label="Name"
                  className="transparent-form-group__input"
                  id={`participants.${index}.name`}
                  name={`participants.${index}.name`}
                  onChange={onChange}
                  placeholder={I18n.t("user_scenarios.participant_name_placeholder")}
                  type="text"
                  value={participant.name}
                />
                <Errors formAttributes={formAttributes} name={`participants[${index}].name`} />
              </td>

              <td>
                <Select2
                  className="select2-participant select2-transparent"
                  data={participantSelect2Data}
                  name={`participants.${index}.character_id`}
                  onChange={event => handleCharacterChange(event, index)}
                  options={{
                    minimumResultsForSearch: -1,
                    templateResult: renderSelect2Participant,
                    templateSelection: renderSelect2Participant
                  }}
                  value={participant.character_id}
                />
              </td>

              <td>
                <TextareaAutosize
                  className="transparent-form-group__input transparent-form-group__input--text-area"
                  data-gramm_editor={false}
                  id={`participants.${index}.description`}
                  name={`participants.${index}.description`}
                  onChange={onChange}
                  placeholder={I18n.t("user_scenarios.participant_description_placeholder")}
                  rows={2}
                  value={participant.description}
                />
                <Errors
                  formAttributes={formAttributes}
                  name={`participants[${index}].description`}
                />
              </td>

              <td>
                <Select2
                  className="select2-transparent select2-position"
                  data={positionsSelect2Data}
                  name={`participants.${index}.position`}
                  onChange={onChange}
                  options={{
                    minimumResultsForSearch: -1,
                    theme: "size--sm"
                  }}
                  value={participant.position}
                />
                <Errors formAttributes={formAttributes} name={`participants[${index}].position`} />
              </td>

              <td>
                <Select2
                  className="select2-transparent"
                  data={kindsSelect2Data}
                  name={`participants.${index}.kind`}
                  onChange={onChange}
                  options={{
                    minimumResultsForSearch: -1,
                    theme: "size--sm"
                  }}
                  value={participant.kind}
                />
                <Errors formAttributes={formAttributes} name={`participants[${index}].position`} />
              </td>

              <td>
                <div className="scenario-edit__table-actions">
                  <ContextMenu className="scenario-edit__actions-context-menu">
                    <DeleteParticipantModel
                      assignUserScenarioAttributes={assignUserScenarioAttributes}
                      formAttributes={formAttributes}
                      participantId={participant.id}
                    />
                  </ContextMenu>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="text-center mt-4">
        <button
          aria-label="Add participant"
          className="button button_theme_plus-grey"
          disabled={isMaxParticipants}
          onClick={addParticipant}
        />
      </div>
    </Fragment>
  );
};

export default Participants;
