export const ACTIONS = {
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  CLOSE_MODALS: "CLOSE_MODALS"
};

export default function reducer(state = {
  current: "",
  options: {}
}, action) {
  switch (action.type) {
    case ACTIONS.OPEN_MODAL: {
      return {...state, current: action.modal, options: action.options};
    }

    case ACTIONS.CLOSE_MODAL: {
      if (state.current !== action.modal) {
        return state;
      }
      return {...state, current: ""};
    }

    case ACTIONS.CLOSE_MODALS: {
      return {...state, current: ""};
    }
  }
  return state;
}
