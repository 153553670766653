import React, {Component} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";
import _ from "lodash";

import UserRow from "./UserRow";
import UserActivityOrderSelect from "../UserActivityOrderSelect";

class ActivityStats extends Component {
  render() {
    const {group, userActivities, sortedActivities, changeUserActivitiesOrder} = this.props;
    const {inputWeekStartsAt} = userActivities;

    return (
      <div className="activity-stats mt-5">

        <div className="activity-stats__header">
          <div className="activity-stats__label">
            {I18n.t("dashboard.student_activity")}
          </div>
          
          <div className="activity-stats__settings">
            <UserActivityOrderSelect
              userActivities={userActivities}
              changeUserActivitiesOrder={changeUserActivitiesOrder}
            />
          </div>
        </div>

        <table className="activity-stats__table" cellSpacing="0">

          <thead>
            <tr className="activity-stats__header-row">

              <th className="activity-stats__header-cell activity-stats__header-cell--name">
                {I18n.t("dashboard.student")}
              </th>

              {_.range(7).map((dayOfWeek) => {
                const date = moment(inputWeekStartsAt).add(dayOfWeek, "days");
                return (
                  <th key={dayOfWeek}
                      className={
                        classnames("activity-stats__header-cell",
                        {"activity-stats__header-cell--active": moment(date).isSame(moment(), "day")})
                      }>
                    {date.format("YYYY/M/D")}
                    <br/>
                    {date.format("(ddd)").toUpperCase()}
                  </th>
                );
              })}

              <th className="activity-stats__header-cell">
                {I18n.t("dashboard.week_total")}
              </th>

              <th className="activity-stats__header-cell">
                {I18n.t("dashboard.weekly_average_duration")}
              </th>

              <th className="activity-stats__header-cell">
                {I18n.t("dashboard.total_overall")}
              </th>

            </tr>
          </thead>

          <tbody>
            {sortedActivities.map((userActivity) => (
              <UserRow
                key={userActivity.id}
                group={group}
                userActivity={userActivity}
                inputWeekStartsAt={inputWeekStartsAt}
              />
            ))}
          </tbody>

        </table>

      </div>
    );
  }
}

ActivityStats.propTypes = {
  group: PropTypes.object.isRequired,
  userActivities: PropTypes.object.isRequired,
  changeUserActivitiesOrder: PropTypes.func.isRequired
};

export default ActivityStats;
