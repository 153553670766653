import React, {useCallback, useState} from "react";
import classnames from "classnames";
import {withRouter} from "react-router-dom";

import {userScenariosSearchPath} from "../../../helpers/routesHelper";

const SearchBar = ({category, history, query = ""}) => {
  const [value, setValue] = useState(query);

  const handleChange = useCallback(
    event => {
      setValue(event.target.value);
    },
    [setValue]
  );

  const handleClear = useCallback(() => {
    setValue("");
  }, [setValue]);

  const handleKeyDown = useCallback(
    event => {
      if (event.key !== "Enter") return;

      history.push({
        pathname: userScenariosSearchPath(),
        search: `category=${category}&query=${value}`
      });
    },
    [category, history, value]
  );

  return (
    <div className={classnames("search-bar mr-4", {"search-bar--filled": value})}>
      <input
        className="search-bar__input"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={I18n.t("search_scenarios")}
        type="search"
        value={value}
      />
      <i className="search-bar__icon" onClick={handleClear} />
    </div>
  );
};

export default withRouter(SearchBar);
