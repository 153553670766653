import React, {Component} from "react";
import PropTypes from "prop-types";

class ScenarioOwner extends Component {
  render() {
    const {name, avatarUrl} = this.props;

    return (
      <div className="scenario-owner">
        <div className="scenario-owner__title">
          <div className="scenario-owner__label">{I18n.t("mongoid.attributes.user_scenario.owner")}</div>
          <div className="scenario-owner__name">{name}</div>
        </div>
        <div className="avatar scenario-owner__avatar">
          <img src={avatarUrl} alt=""/>
        </div>
      </div>
    )
  }
}

ScenarioOwner.propTypes = {
  name: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired
};

export default ScenarioOwner;
