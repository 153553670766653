import React from "react";
import {SortableElement} from "react-sortable-hoc";

import ScenarioEdit from "./ScenarioEdit";

const SortableScenario = SortableElement(({scenarioId, formAttributes, assignCourseAttributes}) => {
  return (
    <ScenarioEdit id={scenarioId} formAttributes={formAttributes} assignCourseAttributes={assignCourseAttributes}/>
  );
});

export default SortableScenario;
