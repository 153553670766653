import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import {usersPath} from "../../helpers/routesHelper";

import {
  assignUserAttributes,
  clearUserAttributes,
  createUser
} from "../../store/actions/usersActions";
import ApplicationLayout from "../layouts/ApplicationLayout";
import UserForm from "./Form";

class New extends Component {
  componentDidMount() {
    this.props.clearUserAttributes({language_code: this.props.currentUser.language_code});
  }

  onSubmit = attributes => {
    this.props.createUser(attributes, this.props.history);
  };

  notifyNotSaved = () => {
    this.props.notify({
      message: I18n.t("users.messages.create.error"),
      status: "error",
      title: I18n.t("notifications.titles.error")
    });
  };

  render() {
    const {users, companies} = this.props;
    const company = companies.current;

    const isLoaded = company.id;
    const isLoading = users.isLoading || companies.isLoading;

    return (
      <ApplicationLayout
        headerTitle={I18n.t("header_titles.users.new")}
        isLoading={!isLoaded || isLoading}
      >
        {isLoaded && (
          <div className="container">
            <div className="row py-4">
              <div className="col-12 col-md-10 col-lg-7 col-xl-7 mx-auto">
                <div className="profile-section">
                  <UserForm
                    assignUserAttributes={this.props.assignUserAttributes}
                    backPath={usersPath()}
                    company={company}
                    formAttributes={users.formAttributes}
                    isLoading={isLoading}
                    notifyNotSaved={this.notifyNotSaved}
                    onSubmit={this.onSubmit}
                    submitButtonLabel={I18n.t("btn_create")}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </ApplicationLayout>
    );
  }
}

New.propTypes = {
  assignUserAttributes: PropTypes.func.isRequired,
  clearUserAttributes: PropTypes.func.isRequired,
  companies: PropTypes.object.isRequired,
  createUser: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    companies: state.companies,
    currentUser: state.profiles.current,
    users: state.users
  };
}

const mapActionsToProps = {
  assignUserAttributes,
  clearUserAttributes,
  createUser
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(New));
