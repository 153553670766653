import React from "react";
import {connect} from "react-redux";

import {isFetchCoursesError} from "../../helpers/errorsHelpers";

import FetchCourses from "../../components/data_loaders/FetchCourses";
import ApplicationLayout from "../layouts/ApplicationLayout";
import Pagination from "../../components/shared/Pagination";
import Course from "./_Course";
import TopPanel from "./_TopPanelIndex";

const Index = ({courses, page = 1}) => {
  const isLoaded = courses.isCollectionLoaded;
  const isLoadingError = isFetchCoursesError(courses);
  const isLoading = (!isLoaded && !isLoadingError) || courses.isLoading;

  return (
    <ApplicationLayout headerTitle={I18n.t("courses.titles.index")} isLoading={isLoading}>
      <FetchCourses page={page} />
      <div className="container">
        <div className="row py-4">
          <div className="col-12">
            <TopPanel />
            <div className="pt-3">
              {courses.collection.map(course => (
                <Course key={course.id} course={course} />
              ))}
              <div className="page-index-bottom">
                <Pagination pagination={courses.pagination} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ApplicationLayout>
  );
};

function mapStateToProps(state) {
  return {
    courses: state.courses
  };
}

export default connect(mapStateToProps)(Index);
