import {ACTIONS} from "../reducers/usersReducer";

import webToolApi from "../../helpers/webToolApiHelper";
import {userPath, usersPath} from "../../helpers/routesHelper";

import {fetchCompany} from "./companiesActions";
import {defaultErrorAction} from "./errorsActions";
import {notifyResponseError, notifySuccess} from "./notificationsActions";

export function changeUsersLoading(isLoading) {
  return {type: ACTIONS.CHANGE_USERS_LOADING, isLoading};
}

export function changeUsersQuery(query) {
  return {type: ACTIONS.CHANGE_USERS_QUERY, query};
}

export function fetchUsers({page, query} = {}) {
  return (dispatch) => {
    dispatch(changeUsersLoading(true));
    dispatch({type: ACTIONS.FETCH_USERS});

    webToolApi().get(Routes.users_index_path({page, query}))
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_USERS_FULFILLED, payload: response.data, page, query});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_USERS_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("users.messages.index.error"), response));
      })
      .finally(() => {
        dispatch(changeUsersLoading(false));
      });
  }
}

export function fetchUser(id, {groupId} = {}) {
  return (dispatch) => {
    dispatch(changeUsersLoading(true));

    webToolApi().get(Routes.user_path(id, {group_id: groupId}))
      .then((response) => {
        dispatch({groupId, type: ACTIONS.FETCH_USER_FULFILLED, user: response.data.user});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_USER_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("users.messages.show.error"), response));
      })
      .finally(() => {
        dispatch(changeUsersLoading(false));
      });
  }
}

export function assignUserAttributes(attributes) {
  return {type: ACTIONS.ASSIGN_USER_ATTRIBUTES, attributes};
}

export function clearUserAttributes(defaultAttributes = {}) {
  return {type: ACTIONS.CLEAR_USER_ATTRIBUTES, defaultAttributes};
}

export function createUser(user, history) {
  return (dispatch) => {
    dispatch(changeUsersLoading(true));

    webToolApi().post(Routes.users_index_path(), {user})
      .then((response) => {
        dispatch({type: ACTIONS.CREATE_USER_FULFILLED, user: response.data.user});
        dispatch(notifySuccess(I18n.t("users.messages.create.success")));
        dispatch(clearUserAttributes());
        dispatch(changeUsersLoading(false));
        dispatch(fetchCompany());
        history.push(userPath(response.data.user.id));
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.CREATE_USER_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("users.messages.create.error"), response));
        dispatch(changeUsersLoading(false));
      });
  }
}

export function updateUser(user, history) {
  return (dispatch) => {
    dispatch(changeUsersLoading(true));

    webToolApi().put(Routes.user_path(user.id), {user})
      .then((response) => {
        dispatch({type: ACTIONS.UPDATE_USER_FULFILLED, user: response.data.user});
        dispatch(notifySuccess(I18n.t("users.messages.update.success")));
        dispatch(clearUserAttributes());
        dispatch(changeUsersLoading(false));
        dispatch(fetchCompany());
        history.push(userPath(user.id));
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.UPDATE_USER_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("users.messages.update.error"), response));
        dispatch(changeUsersLoading(false));
      });
  }
}

export function destroyUser(userId, history) {
  return (dispatch) => {
    dispatch(changeUsersLoading(true));

    webToolApi().delete(Routes.user_path(userId))
      .then(() => {
        dispatch({type: ACTIONS.DESTROY_USER_FULFILLED, userId});
        dispatch(notifySuccess(I18n.t("users.messages.destroy.success")));
        history.push(usersPath());
        dispatch(changeUsersLoading(false));
        dispatch(fetchCompany());
      })
      .catch(({response}) => {
        dispatch(notifyResponseError(I18n.t("users.messages.destroy.error"), response));
        dispatch(changeUsersLoading(false));
      });
  }
}
