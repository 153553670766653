import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import moment from "moment-timezone";

import {enumI18n} from "helpers/enumI18nHelper";
import {isAdmin, isStudent} from "helpers/userRolesHelper";
import {editProfilePath} from "helpers/routesHelper";

import ApplicationLayout from "views/layouts/ApplicationLayout";
import Switch from "components/shared/Switch";
import Avatar from "./_Avatar";
import EnglishScores from "./english_scores/Show";

class Show extends Component {
  render() {
    const {companies, currentUser} = this.props;
    const company = companies.current;

    const isLoaded = isAdmin(currentUser) || company.id;
    const {isLoading} = companies;

    return (
      <ApplicationLayout
        headerTitle={I18n.t("profiles.titles.show")}
        isLoading={!isLoaded || isLoading}
      >
        {isLoaded && (
          <div className="container">
            <div className="row py-4">
              <div className="col-12 col-md-10 col-lg-4 mx-auto">
                <div className="profile-section px-3 px-md-0">
                  <div className="profile-section__top">
                    <Avatar user={currentUser} />
                  </div>
                  <Link className="button button_color_grey my-3" to={editProfilePath()}>{I18n.t("btn_edit")}</Link>
                  <div className="profile-section__title">{I18n.t("users.sections.general_information")}</div>
                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.id")}
                    </label>
                    <span className="textright">{currentUser.id}</span>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.company")}
                    </label>
                    <span className="textright">{company.title}</span>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.type")}
                    </label>
                    <span className="textright">{enumI18n("user", "role", currentUser.role)}</span>
                  </div>

                  <div className="profile-section__title">{I18n.t("users.sections.login_info")}</div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.email")}
                    </label>
                    <span className="textright">{currentUser.email}</span>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.username")}
                    </label>
                    <span className="textright">{currentUser.username}</span>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label" htmlFor="password">
                      {I18n.t("mongoid.attributes.user.password")}
                    </label>
                    <span className="textright">*******</span>
                  </div>

                  {isStudent(currentUser) && (
                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.pin_code")}
                    </label>
                    <span className="textright">{currentUser.pin_code}</span>
                  </div>
                  )}

                  <div className="profile-section__title">{I18n.t("users.sections.user_info")}</div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.first_name")}
                    </label>
                    <span className="textright">{currentUser.first_name}</span>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.last_name")}
                    </label>
                    <span className="textright">{currentUser.last_name}</span>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.first_name_ja")}
                    </label>
                    <span className="textright">{currentUser.first_name_ja}</span>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.last_name_ja")}
                    </label>
                    <span className="textright">{currentUser.last_name_ja}</span>
                  </div>

                  {isStudent(currentUser) && (
                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.show_initials_only")}
                    </label>
                    {currentUser.show_initials_only ? I18n.t("mongoid.attributes.user.initials_on") : I18n.t("mongoid.attributes.user.initials_off")}
                  </div>
                  )}

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.language_code")}
                    </label>
                    <span className="textright">{enumI18n("user", "language_code", currentUser.language_code)}</span>
                  </div>

                  <div className="profile-section__title">{I18n.t("users.sections.other_info")}</div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.gender")}
                    </label>
                    <span className="textright">{enumI18n("user", "gender", currentUser.gender)}</span>
                  </div>

                  <div className="profile-section__field">
                    <label className="profile-section__label">
                      {I18n.t("mongoid.attributes.user.birthday")}
                    </label>
                    <span className="textright">{currentUser.birthday && moment(currentUser.birthday).format("YYYY/MM/DD")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ApplicationLayout>
    );
  }
}

Show.propTypes = {
  companies: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    companies: state.companies,
    currentUser: state.profiles.current
  };
}

export default connect(mapStateToProps)(Show);
