import React from "react";

const formatDuration = durationInSeconds => {
  return `${Math.floor(durationInSeconds / 60)}${I18n.t("minutes_sign")}`;
};

const LevelSummary = ({user}) => (
  <div className="pd-levels">
    <div className="level-summary">
      <div className="level-summary__title">{I18n.t("dashboard.total_activity_time")}</div>
      <div className="performance-progress">
        <div className="performance-progress__value">{formatDuration(user.activity_duration)}</div>
      </div>
    </div>
  </div>
);

export default LevelSummary;
