import React from "react";
import classnames from "classnames";

const Status = ({isPassed}) => {
  const status = isPassed ? "success" : "failure";
  const cStatus = `scenario-review-results__status--${status}`;

  return (
    <div className={classnames("scenario-review-results__status", cStatus)}>
      {isPassed ? I18n.t("success") : I18n.t("fail")}
    </div>
  );
};

export default Status;
