import {ACTIONS} from "../reducers/targetScoresReducer";
import {ACTIONS as USER_SCENARIO_HEADER_ACTIONS} from "../reducers/userScenarioHeadersReducer";
import {ACTIONS as USER_SCENARIO_ACTIONS} from "../reducers/userScenariosReducer";

import webToolApi from "../../helpers/webToolApiHelper";

import {changeUserScenariosLoading} from "./userScenariosActions";
import {notifyResponseError, notifySuccess} from "./notificationsActions";
import {reloadMetrics} from "./metricsActions";

export function assignTargetScoreAttributes(attributes) {
  return {type: ACTIONS.ASSIGN_TARGET_SCORE_ATTRIBUTES, attributes};
}

export function updateTargetScore(userScenarioId, targetScore, enabledScore) {
  return (dispatch) => {
    dispatch(changeUserScenariosLoading(true));

    webToolApi().put(
      Routes.user_scenario_target_score_path(userScenarioId), {target_score: targetScore, enabled_score: enabledScore}
    )
      .then((response) => {
        dispatch({
          type: USER_SCENARIO_HEADER_ACTIONS.FETCH_USER_SCENARIO_HEADER_FULFILLED,
          userScenario: response.data.user_scenario
        });
        dispatch({
          type: USER_SCENARIO_ACTIONS.FETCH_USER_SCENARIO_FULFILLED,
          userScenario: response.data.user_scenario
        });
        dispatch(reloadMetrics());
        dispatch(notifySuccess(I18n.t("target_scores.messages.update.success")));
      })
      .catch(({response}) => {
        dispatch(notifyResponseError(I18n.t("target_scores.messages.update.error"), response));
      })
      .finally(() => {
        dispatch(changeUserScenariosLoading(false));
      });
  }
}
