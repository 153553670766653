import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchPackCategoriesError} from "../../helpers/errorsHelpers";

import {fetchPackCategories} from "../../store/actions/packCategoriesActions";

class FetchPackCategories extends Component {
  componentDidMount() {
    this.fetchPackCategories(true);
  }

  componentDidUpdate() {
    this.fetchPackCategories(false);
  }

  fetchPackCategories(isComponentMount) {
    const {packCategories} = this.props;
    const isReasonToFetch = isComponentMount || !packCategories.isCollectionLoaded;

    if (isReasonToFetch && !packCategories.isLoading && !isFetchPackCategoriesError(packCategories)) {
      this.props.fetchPackCategories();
    }
  }

  render() {
    return null;
  }
}

FetchPackCategories.propTypes = {
  packCategories: PropTypes.object.isRequired,
  fetchPackCategories: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    packCategories: state.packCategories
  }
}

const mapActionsToProps = {
  fetchPackCategories
};

export default connect(mapStateToProps, mapActionsToProps)(FetchPackCategories);
