import {ACTIONS} from "../reducers/englishScoresReducer";
import {ACTIONS as PROFILE_ACTIONS} from "../reducers/profilesReducer";

import webToolApi from "../../helpers/webToolApiHelper";
import {isAdmin} from "../../helpers/userRolesHelper";

import {fetchCompany} from "./companiesActions";
import {notifyResponseError, notifySuccess} from "./notificationsActions";
import {defaultErrorAction} from "./errorsActions";

export function changeEnglishScoresLoading(isLoading) {
  return {type: ACTIONS.CHANGE_ENGLISH_SCORES_LOADING, isLoading};
}

export function assignEnglishScoreAttributes(attributes) {
  return {type: ACTIONS.ASSIGN_ENGLISH_SCORE_ATTRIBUTES, attributes};
}

export function clearEnglishScoreAttributes() {
  return {type: ACTIONS.CLEAR_ENGLISH_SCORE_ATTRIBUTES};
}

export function createEnglishScore(userId, englishScore, {onSuccess} = {}) {
  return (dispatch) => {
    dispatch(changeEnglishScoresLoading(true));

    webToolApi().post(Routes.user_english_scores_path(userId), {english_score: englishScore})
      .then((response) => {
        const user = response.data.user;
        dispatch({type: PROFILE_ACTIONS.UPDATE_PROFILE_FULFILLED, profile: user});
        dispatch(notifySuccess(I18n.t("profiles.scores.messages.create.success")));
        !isAdmin(user) && dispatch(fetchCompany());
        onSuccess && onSuccess();
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.CREATE_ENGLISH_SCORE_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("profiles.scores.messages.create.error"), response));
      })
      .finally(() => {
        dispatch(changeEnglishScoresLoading(false));
      });
  }
}

export function updateEnglishScore(userId, englishScore, {onSuccess} = {}) {
  return (dispatch) => {
    dispatch(changeEnglishScoresLoading(true));

    webToolApi().put(Routes.user_english_score_path(userId, englishScore.id), {english_score: englishScore})
      .then((response) => {
        const user = response.data.user;
        dispatch({type: PROFILE_ACTIONS.UPDATE_PROFILE_FULFILLED, profile: user});
        dispatch(notifySuccess(I18n.t("profiles.scores.messages.update.success")));
        !isAdmin(user) && dispatch(fetchCompany());
        onSuccess && onSuccess();
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.UPDATE_ENGLISH_SCORE_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("profiles.scores.messages.update.error"), response));
      })
      .finally(() => {
        dispatch(changeEnglishScoresLoading(false));
      });
  }
}

export function destroyEnglishScore(userId, englishScoreId, {onSuccess} = {}) {
  return (dispatch) => {
    dispatch(changeEnglishScoresLoading(true));

    webToolApi().delete(Routes.user_english_score_path(userId, englishScoreId))
      .then((response) => {
        const user = response.data.user;
        dispatch({type: PROFILE_ACTIONS.UPDATE_PROFILE_FULFILLED, profile: user});
        dispatch(notifySuccess(I18n.t("profiles.scores.messages.destroy.success")));
        !isAdmin(user) && dispatch(fetchCompany());
        onSuccess && onSuccess();
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.DESTROY_ENGLISH_SCORE_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("profiles.scores.messages.destroy.error"), response));
      })
      .finally(() => {
        dispatch(changeEnglishScoresLoading(false));
      });
  }
}
