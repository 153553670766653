export const DEFAULT_CONTENT_STYLE = {
  position: "relative",
  display: "flex",
  flexFlow: "column nowrap",
  backgroundColor: "#f6f5f1",
  borderRadius: "2.5em",
  border: "none",
  width: "800px",
  maxWidth: "95%",
  minHeight: "300px",
  maxHeight: "95%",
  padding: "1.25em 1.9em 1.9em",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: "0 0 45px 2px rgba(68, 68, 68, 0.2)",
  zIndex: 13
};

export const DEFAULT_MODEL_CONTENT_STYLE = {
  ...DEFAULT_CONTENT_STYLE,
  overflow: "initial"
};
