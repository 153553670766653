import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import { MODAL_NAME as GROUP_SETTINGS_MODAL_NAME } from './_GroupSettingsModal';
import { MODAL_NAME as MANAGE_USERS_MODAL_NAME } from './ManageUsersModal';

import ContextMenu from '../../../components/shared/context_menu/ContextMenu';
import ContextMenuItem from '../../../components/shared/context_menu/ContextMenuItem';
import DeleteGroupModal from './_DeleteGroupModal';

const DATE_FORMAT = I18n.t("date.moment_formats.activity_date");

class TopPanel extends Component {
  get groupDatesRange() {
    const {group} = this.props;
    const startsOn = moment(group.starts_on).format(DATE_FORMAT);
    const endsOn = moment(group.ends_on).format(DATE_FORMAT);

    return `${startsOn} - ${endsOn} (JST)`;
  }

  render() {
    const {group} = this.props;

    return (
      <div className="activity-top-panel">
        <div className="activity-top-panel__title">
          {group.title}
        </div>
        <div className="d-flex align-items-center">
          <div className="mr-3">
            <div className="activity-top-panel__dates-label">
              {I18n.t("dashboard.groups.group_dates_label")}
            </div>
            <div className="activity-top-panel__dates-range">
              {this.groupDatesRange}
            </div>
          </div>

          <ContextMenu>
            <ContextMenuItem onClick={() => this.props.openModal(GROUP_SETTINGS_MODAL_NAME)}>
              {I18n.t("dashboard.groups.settings.title")}
            </ContextMenuItem>
            {
              !group.default &&
              <ContextMenuItem onClick={() => this.props.openModal(MANAGE_USERS_MODAL_NAME)}>
                {I18n.t("dashboard.groups.manage_students")}
              </ContextMenuItem>
            }
            <ContextMenuItem
              href={Routes.group_user_activities_export_path(group.id, {format: "csv", type: "weekly-minutes"})}
            >
              {I18n.t("dashboard.export_activities.weekly_minutes")}
            </ContextMenuItem>
            <ContextMenuItem
              href={Routes.group_user_activities_export_path(group.id, {format: "csv", type: "weekly-days"})}
            >
              {I18n.t("dashboard.export_activities.weekly_days")}
            </ContextMenuItem>
            {!group.default && <DeleteGroupModal groupId={group.id}/>}
          </ContextMenu>
        </div>
      </div>
    );
  }
}

TopPanel.propTypes = {
  group: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired
};

export default TopPanel;
