import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import classnames from "classnames";

import {packPath} from "../../helpers/routesHelper";

import {closeFilter, openFilter} from "../../store/actions/filterActions";
import FetchEnvironments from "../../components/data_loaders/FetchEnvironment";

class UserScenariosFilter extends Component {
  toggleFilter = () => {
    const {filter, openFilter, closeFilter} = this.props;
    if (filter.isOpened) {
      closeFilter();
    } else {
      openFilter();
    }
  };

  render() {
    const {packId, filter, environments, closeFilter} = this.props;

    return (
      <div className="filter-top">
        <FetchEnvironments/>
        {filter.isOpened && <div className="dimmer" onClick={closeFilter}/>}
        <div className={classnames("filter-top__choice", {"filter-top__choice_open": filter.isOpened})}>
          <div className="filter-top__toggle" onClick={this.toggleFilter}/>
          <div className="filter-top__items">
            {environments.collection.map((environment) => (
              <Link key={environment.id}
                    to={{pathname: packPath(packId), search: `environment_id=${environment.id}`}}
                    onClick={closeFilter}
                    className="filter-top__item">
                <span>{environment.title}</span>
                <div className="filter-top__item-icon"/>
              </Link>
            ))}
            <Link to={packPath(packId)} onClick={closeFilter} className="filter-top__item">
              <span>{I18n.t("all")}</span>
              <div className="filter-top__item-icon"/>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

UserScenariosFilter.propTypes = {
  packId: PropTypes.string.isRequired,
  environments: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  openFilter: PropTypes.func.isRequired,
  closeFilter: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    environments: state.environments,
    filter: state.filter
  }
}

const mapActionsToState = {
  openFilter,
  closeFilter
};

export default connect(mapStateToProps, mapActionsToState)(UserScenariosFilter);
