import React, {Component} from "react"
import {Link} from "react-router-dom";

import {newTagPath, rootPath} from "../../helpers/routesHelper";

class TopPanelIndex extends Component {
  render() {
    return (
      <div className="top-panel">
        <div className="top-panel__left">
          <Link to={rootPath()} className="top-panel__button top-panel__button_back hide-on-sm-and-down"/>
        </div>
      </div>
    )
  }
}

export default TopPanelIndex;
