import React, {Component} from "react";
import PropTypes from "prop-types";
import DatePicker from 'react-datepicker';
import NumericInput from 'react-numeric-input';
import _ from 'lodash';

import handleFieldChange from "../../../helpers/handleFieldChange";

import DateInput from '../../../components/shared/DateInput';

class Form extends Component {
  handleDateChange = date => {
    const {formAttributes, assignEnglishScoreAttributes} = this.props;
    assignEnglishScoreAttributes({...formAttributes, date});
  };

  handleChange = event => {
    const {formAttributes, assignEnglishScoreAttributes} = this.props;
    assignEnglishScoreAttributes({...formAttributes, ...handleFieldChange(event, formAttributes)});
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.onSubmit(this.props.formAttributes);
  };

  renderErrors = (name) => {
    const errors = this.props.formAttributes.errors[name];
    if (_.isEmpty(errors)) return null;
    return <div className="form-group__error">{errors.join(", ")}</div>;
  };

  render() {
    const attributes = this.props.formAttributes;
    const {kind} = attributes;
    const {isLoading} = this.props;

    return (
      <form className="mb-3 mt-3" onSubmit={this.handleSubmit}>
        <div className="form-section-title form-section-title_size_sm">
          {I18n.t(`mongoid.attributes.english_score.kinds.${kind}`)}
        </div>

        <div className="form-group form-group_size_sm">
          <label className="form-group__label">
            {I18n.t("mongoid.attributes.english_score.score")}
          </label>
          <div className="form-group__input-wrapper">
            <NumericInput
              style={false}
              min={0}
              id="score"
              name="score"
              className="form-group__input form-group__input_size_sm"
              type="text"
              value={attributes.score}
              onBlur={this.handleChange}
            />
            {this.renderErrors("score")}
          </div>
        </div>

        <div className="form-group form-group_size_sm mt-2">
          <label className="form-group__label">
            {I18n.t("mongoid.attributes.english_score.date")}
          </label>
          <div className="form-group__input-wrapper">
            <DatePicker
              name="date"
              customInput={<DateInput/>}
              dateFormat="yyyy/M/d"
              onChange={this.handleDateChange}
              popperModifiers={{
                preventOverflow: {
                  enabled: true
                }
              }}
              selected={attributes.date ? Date.parse(attributes.date) : ""}
              showPopperArrow={false}
            />
            {this.renderErrors("date")}
          </div>
        </div>

        <div className="text-center mt-5">
          <button className="button" type="submit" disabled={isLoading}>
            {attributes.id ? I18n.t("update") : I18n.t("add")}
          </button>
        </div>
      </form>
    );
  }
}

Form.propTypes = {
  formAttributes: PropTypes.object.isRequired,
  assignEnglishScoreAttributes: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default Form;
