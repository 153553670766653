import React from "react";
import {newUserSessionPath} from "helpers/routesHelper";

import {Link} from "react-router-dom";
import SvgIcon from "components/SvgIcon";
import UnauthenticatedLayout from "views/layouts/UnauthenticatedLayout";

const EmailSent = ({email}) => (
  <UnauthenticatedLayout>
    <div className="login-section">
      <SvgIcon className="login-section__title" icon="smart-tutor-logo" />
      <div className="login-section__subtitle">{I18n.t("passwords.email_sent")}</div>
      <div className="mt-4">{I18n.t("passwords.email_sent_explanation", {email})}</div>
      <Link
        className="login-section__submit button d-flex justify-content-center align-items-center mt-5"
        to={newUserSessionPath()}
      >
        {I18n.t("passwords.return_to_login")}
      </Link>
    </div>
  </UnauthenticatedLayout>
);

export default EmailSent;
