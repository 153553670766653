import React, {Fragment, useCallback, useRef} from "react";
import {SortableContainer} from "react-sortable-hoc";
import arrayMove from "array-move";
import _ from "lodash";

import {buildLine} from "../../../../helpers/buildLine";

import SortableLine from "./SortableLine";

const SortableLineListContainer = SortableContainer(
  ({formAttributes, assignUserScenarioAttributes}) => (
    <tbody>
      {formAttributes.lines.map((line, index) => (
        <SortableLine
          key={line.id}
          assignUserScenarioAttributes={assignUserScenarioAttributes}
          formAttributes={formAttributes}
          index={index}
          lineId={line.id}
        />
      ))}
    </tbody>
  )
);

const SortableLineList = ({assignUserScenarioAttributes, formAttributes}) => {
  const tableRef = useRef(null);
  const {participants} = formAttributes;

  const addLine = useCallback(() => {
    const participant = participants[0];
    const newLine = buildLine(participant, participants);
    const changedLines = [...formAttributes.lines, newLine];
    assignUserScenarioAttributes({...formAttributes, lines: changedLines});
  }, [assignUserScenarioAttributes, formAttributes, participants]);

  const handleLinesReorderingEnd = useCallback(
    ({oldIndex, newIndex}) => {
      const newErrors = _.mapKeys(formAttributes.errors, (_value, key) => {
        return key.replace(`lines[${oldIndex}]`, `lines[${newIndex}]`);
      });

      const changedAttributes = {
        ...formAttributes,
        errors: newErrors,
        lines: arrayMove(formAttributes.lines, oldIndex, newIndex)
      };

      assignUserScenarioAttributes(changedAttributes);
    },
    [assignUserScenarioAttributes, formAttributes]
  );

  return (
    <Fragment>
      <table
        ref={tableRef}
        className="scenario-edit__table scenario-edit__table--lines scenario-edit__table--lines-edit"
      >
        <thead>
          <tr>
            <th />
            <th>{I18n.t("mongoid.attributes.line.participant")}</th>
            <th>{I18n.t("mongoid.attributes.line.text")}</th>
            <th>{I18n.t("mongoid.attributes.line.translation")}</th>
            <th colSpan={participants.length}>
              {I18n.t("mongoid.attributes.line.participant_focuses")}
            </th>
            <th />
          </tr>
          <tr>
            <th colSpan={4} />
            {participants.map(participant => (
              <th key={participant.id}>
                <div className="participant-avatar participant-avatar--focus avatar">
                  <img alt="" src={participant.avatar_url} />
                </div>
              </th>
            ))}
            <th />
          </tr>
        </thead>
        <SortableLineListContainer
          assignUserScenarioAttributes={assignUserScenarioAttributes}
          formAttributes={formAttributes}
          helperClass="scenario-line_dragging"
          helperContainer={tableRef.current}
          onSortEnd={handleLinesReorderingEnd}
          useDragHandle
          useWindowAsScrollContainer
        />
      </table>
      <div className="text-center mt-4">
        <button aria-label="Add line" className="button button_theme_plus-grey" onClick={addLine} />
      </div>
    </Fragment>
  );
};

export default SortableLineList;
