import {SUCCESS_STATUS_CODE} from "../../constants";

export const ACTIONS = {
  CHANGE_DIALOGFLOW_AGENTS_LOADING: "CHANGE_DIALOGFLOW_AGENTS_LOADING",
  FETCH_DIALOGFLOW_AGENTS: "FETCH_DIALOGFLOW_AGENTS",
  FETCH_DIALOGFLOW_AGENTS_FULFILLED: "FETCH_DIALOGFLOW_AGENTS_FULFILLED",
  FETCH_DIALOGFLOW_AGENTS_REJECTED: "FETCH_DIALOGFLOW_AGENTS_REJECTED"
};

const DEFAULT_SUCCESS_STATUS = {errors: {}, status: SUCCESS_STATUS_CODE};
const DEFAULT_STATUSES = {
  [ACTIONS.FETCH_DIALOGFLOW_AGENTS]: {errors: {}, status: null}
};

export default function reducer(state = {
  collection: [],
  isLoading: false,
  loadingCount: 0,
  isCollectionLoaded: false,
  statuses: DEFAULT_STATUSES
}, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_DIALOGFLOW_AGENTS_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);
      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.FETCH_DIALOGFLOW_AGENTS: {
      return {...state, collection: [], isCollectionLoaded: false};
    }
    case ACTIONS.FETCH_DIALOGFLOW_AGENTS_FULFILLED: {
      return {
        ...state,
        collection: action.payload.dialogflow_agents,
        isCollectionLoaded: true,
        statuses: {...state.statuses, [ACTIONS.FETCH_DIALOGFLOW_AGENTS]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_DIALOGFLOW_AGENTS_REJECTED: {
      return {
        ...state,
        statuses: {
          ...state.statuses,
          [ACTIONS.FETCH_DIALOGFLOW_AGENTS]: {errors: action.errors, status: action.status}
        }
      };
    }
  }
  return state;
}
