import {ACTIONS} from "../reducers/profilesReducer";

import webToolApi from "../../helpers/webToolApiHelper";
import {profilePath} from "../../helpers/routesHelper";

import {fetchCompany} from "./companiesActions";
import {defaultErrorAction} from "./errorsActions";
import {notifyResponseError, notifySuccess} from "./notificationsActions";

export function changeProfilesLoading(isLoading) {
  return {type: ACTIONS.CHANGE_PROFILES_LOADING, isLoading};
}

export function fetchProfile() {
  return (dispatch) => {
    dispatch(changeProfilesLoading(true));
    dispatch({type: ACTIONS.FETCH_PROFILE});

    webToolApi().get(Routes.profile_path())
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_PROFILE_FULFILLED, profile: response.data.user});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_PROFILE_REJECTED, response, {redirect: false}));
      })
      .finally(() => {
        dispatch(changeProfilesLoading(false));
      });
  }
}

export function assignProfileAttributes(attributes) {
  return {type: ACTIONS.ASSIGN_PROFILE_ATTRIBUTES, attributes};
}

export function clearProfileAttributes() {
  return {type: ACTIONS.CLEAR_PROFILE_ATTRIBUTES};
}

export function updateProfile(profile, history) {
  return (dispatch) => {
    dispatch(changeProfilesLoading(true));

    webToolApi().put(Routes.profile_path(), {profile})
      .then((response) => {
        const user = response.data.user;
        dispatch({type: ACTIONS.UPDATE_PROFILE_FULFILLED, profile: user});
        dispatch(notifySuccess(I18n.t("profiles.messages.update.success")));
        dispatch(clearProfileAttributes());
        dispatch(fetchCompany());
        dispatch(changeProfilesLoading(false));
        history && history.push(profilePath());
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.UPDATE_PROFILE_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("profiles.messages.update.error"), response));
        dispatch(changeProfilesLoading(false));
      });
  }
}
