/* eslint-disable */
import React, {memo} from "react";

const fn = ({className}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31">
    <g fill="#DC6468" fillRule="evenodd">
      <path d="M7.263 8L4.317 10.946 5.054 11.683 8 8.737 10.946 11.683 11.683 10.946 8.737 8 11.683 5.054 10.946 4.317 8 7.263 5.054 4.317 4.317 5.054z" transform="translate(8 8)"/>
      <path d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 1C4.134 1 1 4.134 1 8s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7z" transform="translate(8 8)"/>
    </g>
  </svg>
);

export default memo(fn);

