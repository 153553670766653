import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {notify} from "reapop";

import {tagsPath} from "../../helpers/routesHelper";

import {assignTagAttributes, clearTagAttributes, createTag} from "../../store/actions/tagsActions";

import ApplicationLayout from "../layouts/ApplicationLayout";
import TagForm from "./_Form";

class New extends Component {
  componentDidMount() {
    this.props.clearTagAttributes();
  }

  onSubmit = (tag) => {
    this.props.createTag(tag, this.props.history);
  };

  render() {
    return (
      <ApplicationLayout headerTitle={I18n.t("tags.titles.edit")}>
        <div className="container">
          <div className="row py-4">
            <div className="col-12">
              <TagForm
                backPath={tagsPath()}
                formAttributes={this.props.tags.formAttributes}
                assignTagAttributes={this.props.assignTagAttributes}
                onSubmit={this.onSubmit}
                notify={this.props.notify}
              />
            </div>
          </div>
        </div>
      </ApplicationLayout>
    );
  }
}

New.propTypes = {
  tags: PropTypes.object.isRequired,
  assignTagAttributes: PropTypes.func.isRequired,
  createTag: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    tags: state.tags
  }
}

const mapActionsToProps = {
  assignTagAttributes,
  clearTagAttributes,
  createTag,
  notify
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(New));
