import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";

import handleFieldChange from "../../helpers/handleFieldChange";
import {isLimited} from "../../helpers/packsHelper";
import {isFetchUsersError} from "../../helpers/errorsHelpers";
import {isManager} from "../../helpers/userRolesHelper";

import FetchUsers from "../../components/data_loaders/FetchUsers";
import UserPermissions from "../pack_categories/_UserPermissions";
import VisibilitySelect from "../pack_categories/_VisibilitySelect";
import Loader from "../../components/shared/Loader";

class Form extends Component {
  componentDidMount() {
    this.props.clearPackAttributes();
  }

  onChange = (event) => {
    const {formAttributes, assignPackAttributes} = this.props;
    assignPackAttributes({...formAttributes, ...handleFieldChange(event, formAttributes)});
  };

  onAllowedUserChange = (userId) => {
    const attributes = this.props.formAttributes;
    const allowedUserIds = attributes.allowed_user_ids;
    const newAllowedUserIds =
      allowedUserIds.includes(userId) ? _.without(allowedUserIds, userId) : [...allowedUserIds, userId];

    this.props.assignPackAttributes({...attributes, allowed_user_ids: newAllowedUserIds});
  };

  isValid() {
    const attributes = this.props.formAttributes;
    let errors = {};

    if (!attributes.title.trim()) {
      errors.title = [I18n.t("errors.messages.blank")];
    }

    const attributesWithNewErrors = {...attributes, errors: errors};
    this.props.assignPackAttributes(attributesWithNewErrors);

    return _.isEmpty(errors);
  }

  renderErrors(name) {
    const errors = this.props.formAttributes.errors[name];
    if (_.isEmpty(errors)) return null;
    return <div className="form-group__error">{errors.join(", ")}</div>;
  }

  onSubmit = (event) => {
    event.preventDefault();

    if (this.isValid()) {
      let attributes = _.omit(this.props.formAttributes, "errors");
      if (!isLimited(attributes)) {
        attributes = {...attributes, allowed_user_ids: []};
      }
      this.props.onSubmit(attributes);
    }
  };

  get isPackPersisted() {
    return this.props.formAttributes.id;
  }

  render() {
    const {users, currentUser} = this.props;
    const attributes = this.props.formAttributes;

    const isLoaded = !isManager(currentUser) || (users.isCollectionLoaded && !users.page);
    const isLoadingError = isFetchUsersError(users);
    const isLoading = this.props.isLoading || (!isLoaded && !isLoadingError) || users.isLoading;

    return (
      <Fragment>
        {isManager(currentUser) && <FetchUsers clearQuery/>}
        {
          isLoaded &&
          <form>
            <div className="row no-gutters">
              <div className="col-xl-6 offset-xl-3 col-sm-8 offset-sm-2 col-12">
                {
                  !attributes.is_default &&
                  <div className="form-group form-group_size_sm align-items-baseline">
                    <label htmlFor="title" className="form-group__label form-group__label_width_sm">
                      {I18n.t("mongoid.attributes.pack.title")}
                    </label>
                    <div className="form-group__input-wrapper">
                      <input
                        id="title"
                        name="title"
                        className="form-group__input form-group__input_size_sm"
                        type="text"
                        value={attributes.title}
                        onChange={this.onChange}
                      />
                      {this.renderErrors("title")}
                    </div>
                  </div>
                }

                <VisibilitySelect
                  formAttributes={attributes}
                  onChange={this.onChange}
                  isShowLimited={isManager(currentUser)}
                />
              </div>
            </div>

            <UserPermissions packAttributes={attributes} users={users} onAllowedUserChange={this.onAllowedUserChange}/>

            <div className="mt-1">
              <div className="pt-4 text-center">
                <button className="button" type="submit" onClick={this.onSubmit} disabled={isLoading}>
                  {this.isPackPersisted ? I18n.t("save") : I18n.t("add")}
                </button>
              </div>
            </div>
          </form>
        }
        <Loader isShow={isLoading}/>
      </Fragment>
    )
  }
}

Form.propTypes = {
  formAttributes: PropTypes.object.isRequired,
  assignPackAttributes: PropTypes.func.isRequired,
  clearPackAttributes: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  users: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired
};


function mapStateToProps(state) {
  return {
    users: state.users,
    currentUser: state.profiles.current
  }
}

export default connect(mapStateToProps)(Form);
