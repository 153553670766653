import React from "react";
import classnames from "classnames";
import _ from "lodash";

import {METRIC_MEASURES} from "../../../../constants";
import valueOrDash from "../../../../helpers/valueOrDash";

const Score = ({completedScenario, score}) => {
  const measure = METRIC_MEASURES[score.metric];
  const targetRange = completedScenario.target_score[score.metric];

  let valueString;
  let targetString;
  if (score.metric === "missions_value") {
    const dialogflowMissions = completedScenario.dialogflow_missions;
    const totalMissions = dialogflowMissions.length;
    const passedMissions = _.filter(dialogflowMissions, {done: true}).length;
    const minRequiredMissions = Math.ceil((targetRange[0] / 100.0) * totalMissions);

    valueString = `${passedMissions}/${totalMissions}`;
    targetString = `${minRequiredMissions}/${totalMissions}`;
  } else {
    valueString = `${valueOrDash(score.raw_value)}${measure}`;
    targetString = `${targetRange[0]}${measure} - ${targetRange[1]}${measure}`;
  }

  return (
    <div
      key={score.metric}
      className={classnames("scenario-review-results__metric", {
        "scenario-review-results__metric--failure": !score.passed
      })}
    >
      <div className="scenario-review-results__metric-name">
        {I18n.t(`metrics_attr.${score.metric}`)}
      </div>
      <div className="scenario-review-results__metric-score">{valueString}</div>
      <div className="scenario-review-results__metric-target-score">
        {I18n.t("dashboard.scenario_review.target").toUpperCase()}: {targetString}
      </div>
    </div>
  );
};

export default Score;
