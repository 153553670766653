/* eslint-disable */
import React, {memo} from "react";

const fn = ({className}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="8 8 30 30">
    <defs>
      <path id="a" d="M26 10.753A9.457 9.457 0 0 0 20.5 9a9.5 9.5 0 0 0-9.168 12H0V0h26v10.753z"/>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(8 9)">
      <path fill="#56BEA4" d="M20.5 26a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15zM17 18h7v4a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-4zm5 0v-2.5a1.5 1.5 0 0 0-3 0V18h-1v-2.5a2.5 2.5 0 1 1 5 0V18h-1z"/>
      <mask id="b" fill="#fff">
        <use xlinkHref="#a"/>
      </mask>
      <path fill="#56BEA4" d="M2 4V3a2 2 0 0 1 2-2h5.586a1 1 0 0 1 .707.293L13 4h8a3 3 0 0 1 3 3v10a2 2 0 0 1-2 2H5a3 3 0 0 1-3-3V4z" mask="url(#b)"/>
    </g>
  </svg>

);

export default memo(fn);

