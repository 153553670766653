import React from "react";

const Errors = ({formAttributes, name}) => {
  const errors = formAttributes.errors[name] || {};
  if (!Object.keys(errors).length) return null;

  return <div className="form-group__error">{errors.join(", ")}</div>;
};

export default Errors;
