import React, {useCallback} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import {destroyUserScenario} from "../../../../store/actions/userScenariosActions";

import ConfirmationModal from "../../modals/ConfirmationModal";
import ContextMenuItem from "../../context_menu/ContextMenuItem";

const MODAL_NAME = "USER_SCENARIO_DELETE_MODAL";

const DeleteUserScenarioModal = ({
  destroyUserScenario,
  history,
  onSuccess,
  redirectPath,
  userScenario
}) => {
  const modalName = `${MODAL_NAME}_${userScenario.id}`;

  const handleSubmit = useCallback(
    closeModal => {
      destroyUserScenario(userScenario.pack.id, userScenario.id, history, redirectPath, {
        onSuccess
      });
      closeModal();
    },
    [destroyUserScenario, history, onSuccess, redirectPath, userScenario.id, userScenario.pack.id]
  );

  return (
    <ConfirmationModal
      button={
        <ContextMenuItem color="red">
          {I18n.t("user_scenarios.scenario_actions.destroy")}
        </ContextMenuItem>
      }
      message={I18n.t("user_scenarios.popups.delete_scenario")}
      modalName={modalName}
      onSubmit={handleSubmit}
    />
  );
};

const mapActionsToProps = {
  destroyUserScenario
};

export default connect(null, mapActionsToProps)(withRouter(DeleteUserScenarioModal));
