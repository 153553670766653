import React from "react";

import BaseParticipant from "../../../../../components/shared/user_scenarios/Show/Participant";

const getAvatarUrl = ({isUserLine, line, user}) => {
  if (isUserLine) return user.avatar.thumb_url;

  return line.participant.avatar_url;
};

const getRole = ({line}) => {
  if (line.summary) return I18n.t("mongoid.models.user.one");

  return line.participant.role;
};

const Participant = ({isUserLine, line, user}) => {
  const avatarUrl = getAvatarUrl({isUserLine, line, user});
  const role = getRole({line});

  return <BaseParticipant avatarUrl={avatarUrl} isBold name={role} />;
};

export default Participant;
