import _ from "lodash";

import {SUCCESS_STATUS_CODE} from "../../constants";

export const ACTIONS = {
  CHANGE_PACK_CATEGORIES_LOADING: "CHANGE_PACK_CATEGORIES_LOADING",
  FETCH_PACK_CATEGORIES: "FETCH_PACK_CATEGORIES",
  FETCH_PACK_CATEGORIES_FULFILLED: "FETCH_PACK_CATEGORIES_FULFILLED",
  FETCH_PACK_CATEGORIES_REJECTED: "FETCH_PACK_CATEGORIES_REJECTED",
  FETCH_PACK_CATEGORY: "FETCH_PACK_CATEGORY",
  FETCH_PACK_CATEGORY_FULFILLED: "FETCH_PACK_CATEGORY_FULFILLED",
  FETCH_PACK_CATEGORY_REJECTED: "FETCH_PACK_CATEGORY_REJECTED",
};

const DEFAULT_SUCCESS_STATUS = {errors: {}, status: SUCCESS_STATUS_CODE};
const DEFAULT_STATUSES = {
  [ACTIONS.FETCH_PACK_CATEGORIES]: {errors: {}, status: null},
  [ACTIONS.FETCH_PACK_CATEGORY]: {errors: {}, status: null}
};


export default function reducer(state = {
  collection: [],
  current: {},
  isCollectionLoaded: false,
  isLoading: false,
  loadingCount: 0,
  statuses: DEFAULT_STATUSES
}, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_PACK_CATEGORIES_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);
      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.FETCH_PACK_CATEGORIES: {
      return {...state, collection: [], isCollectionLoaded: false};
    }
    case ACTIONS.FETCH_PACK_CATEGORIES_FULFILLED: {
      return {
        ...state,
        collection: action.payload.pack_categories,
        isCollectionLoaded: true,
        statuses: {...state.statuses, [ACTIONS.FETCH_PACK_CATEGORIES]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_PACK_CATEGORIES_REJECTED: {
      return {
        ...state,
        statuses: {...state.statuses, [ACTIONS.FETCH_PACK_CATEGORIES]: {errors: action.errors, status: action.status}}
      };
    }

    case ACTIONS.FETCH_PACK_CATEGORY: {
      return {...state, current: {}};
    }
    case ACTIONS.FETCH_PACK_CATEGORY_FULFILLED: {
      const clonedCollection = [...state.collection];
      const packCategoryIndex = _.findIndex(clonedCollection, {id: action.packCategory.id});
      if (packCategoryIndex >= 0) {
        clonedCollection[packCategoryIndex] = action.packCategory;
        return {
          ...state,
          current: action.packCategory,
          collection: clonedCollection,
          statuses: {...state.statuses, [ACTIONS.FETCH_PACK_CATEGORY]: DEFAULT_SUCCESS_STATUS}
        };
      }
      return {
        ...state,
        current: action.packCategory,
        statuses: {...state.statuses, [ACTIONS.FETCH_PACK_CATEGORY]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_PACK_CATEGORY_REJECTED: {
      return {
        ...state,
        errors: action.errors,
        statuses: {...state.statuses, [ACTIONS.FETCH_PACK_CATEGORY]: {errors: action.errors, status: action.status}}
      };
    }
  }
  return state;
}
