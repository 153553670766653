import React from "react";

import AudioPlayer from "../../../../../components/shared/scenario_content/AudioPlayer";
import ModeSpecificColumns from "./ModeSpecificColumns";
import Participant from "./Participant";
import PlayAudioButton from "../../../../../components/shared/scenario_content/PlayAudioButton";

const Line = ({index, isListeningSummaryLine, isUserLine, line, metric, user}) => {
  return (
    <tr className="scenario-line">
      <td>{index + 1}</td>

      <td>
        <Participant isUserLine={isUserLine} line={line} user={user} />
      </td>

      <ModeSpecificColumns
        isListeningSummaryLine={isListeningSummaryLine}
        isUserLine={isUserLine}
        line={line}
        metric={metric}
      />

      <td>
        {!isListeningSummaryLine && (
          <PlayAudioButton
            audioTrackUrl={line.audio_track_url}
            disabled={!line.audio_track_url}
            id={line.id}
            title={line.audio_track_url ? null : I18n.t("scenario_contents.audio_not_generated")}
            type="ear"
          />
        )}
      </td>

      <td>
        {isUserLine && (
          <PlayAudioButton
            audioTrackUrl={line.user_audio_track_url}
            disabled={!line.user_audio_track_url}
            id={line.id}
            title={
              line.user_audio_track_url ? null : I18n.t("scenario_contents.audio_not_generated")
            }
            type="play"
          />
        )}
      </td>

      <td>
        <AudioPlayer className="audio-player-mf_full-width" id={line.id} />
      </td>
    </tr>
  );
};

export default Line;
