import _ from "lodash";

export const ACTIONS = {
  SHOW_CONTEXT_MENU: "SHOW_CONTEXT_MENU",
  HIDE_CONTEXT_MENU: "HIDE_CONTEXT_MENU"
};

export default function reducer(state = {
  active: []
}, action) {
  switch (action.type) {
    case ACTIONS.SHOW_CONTEXT_MENU: {
      return {...state, active: [...state.active, action.id]};
    }

    case ACTIONS.HIDE_CONTEXT_MENU: {
      return {...state, active: _.without(state.active, action.id)};
    }
  }
  return state;
}
