import {PunditPolicy} from "react-pundit";

import {isManager} from "../helpers/userRolesHelper";

class UserPolicy extends PunditPolicy {
  constructor() {
    super("User");
  }

  Index(model, user) {
    return isManager(user);
  }

  Show(model, user) {
    return this.Index(model, user);
  }

  Create(model, user) {
    return this.Index(model, user);
  }

  Update(model, user) {
    return this.Index(model, user);
  }

  Destroy(model, user) {
    return this.Index(model, user);
  }
}

export default UserPolicy;
