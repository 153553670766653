import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Papa from "papaparse";

import {extractScenarioData} from "../../../helpers/importScenarioHelper";

import {notifyError} from "../../../store/actions/notificationsActions";
import {assignUserScenarioAttributes} from "../../../store/actions/userScenariosActions";

// TODO: Component is not used
class ScenarioImport extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
  }

  onImport = () => {
    this.fileInputRef.current.click();
  };

  importScenario = (event) => {
    const file = event.target.files[0];

    if (file && this.isValid(file)) {
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: this.importScenarioData,
        error: (error) => this.props.notifyError(error)
      });
    }
  };

  isValid(file) {
    const TYPES = "csv";
    const TYPES_REGEX = /([./])(csv)$/i;
    let error;

    if (!TYPES_REGEX.test(file.type) && !TYPES_REGEX.test(file.name)) {
      const extension = file.name.split('.').pop();
      error = I18n.t("errors.messages.extension_whitelist_error", {extension: extension, allowed_types: TYPES});
    }

    if (error) {
      this.props.notifyError(error);
      return false;
    }

    return true;
  }

  importScenarioData = (csv, file) => {
    const {formAttributes} = this.props.userScenarios;

    try {
      const scenarioData = extractScenarioData(csv.data, file, formAttributes.participants);

      const userScenario = {
        ...formAttributes,
        ...scenarioData,
        errors: {}
      };

      this.props.assignUserScenarioAttributes(userScenario);
    } catch(exception) {
      this.props.notifyError(exception);
    }
  };

  render() {
    const {userScenarios} = this.props;

    return (
      <div>
        <button
          className="top-panel__button top-panel__button_upload"
          onClick={this.onImport}
          disabled={userScenarios.isLoading}
        />
        <form>
          <input type="file" className="hide" ref={this.fileInputRef} onChange={this.importScenario}/>
        </form>
      </div>
    );
  }
}

ScenarioImport.propTypes = {
  userScenarios: PropTypes.object.isRequired,
  assignUserScenarioAttributes: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    userScenarios: state.userScenarios
  }
}

const mapActionsToProps = {
  assignUserScenarioAttributes,
  notifyError
};

export default connect(mapStateToProps, mapActionsToProps)(ScenarioImport);
