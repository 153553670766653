import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import classnames from "classnames";

import {REMOVING_STATES} from "../../constants";

import {packPath} from "../../helpers/routesHelper";
import {isPublic} from "../../helpers/packsHelper";
import {listItemDateLabel} from "../../helpers/listItemsHelper";
import {isManager} from "../../helpers/userRolesHelper";

import {destroyPack, setPackRemovingState} from "../../store/actions/packsActions";
import {deselectPack, selectPack} from "../../store/actions/packsActions";

import ContextMenu from "../../components/shared/context_menu/ContextMenu";
import ContextMenuItem from "../../components/shared/context_menu/ContextMenuItem";
import DeleteListItem from "../../components/shared/DeleteListItem";
import PackPolicy from "../../policies/PackPolicy";

class Pack extends Component {
  get canShare() {
    const {pack, currentUser} = this.props;
    return isManager(currentUser) && pack.user_id === currentUser.id;
  }

  get isSelected() {
    const {packs, pack} = this.props;
    return packs.selectedIds.includes(pack.id);
  }

  toggleSelect = (event) => {
    event.preventDefault();
    const {pack, selectPack, deselectPack} = this.props;
    this.isSelected ? deselectPack(pack.id) : selectPack(pack.id);
  };

  showRemoveDialog = (event) => {
    event && event.preventDefault();
    this.props.setPackRemovingState(this.props.pack.id, REMOVING_STATES.REMOVING);
  };

  onDestroy = () => {
    const {pack, destroyPack} = this.props;
    destroyPack(pack.category_id, pack.id);
  };

  render() {
    const {pack, currentUser, setPackRemovingState} = this.props;
    const visibilityClass =
      isPublic(pack) ? "scenario-list-item__icon--pack-public" : "scenario-list-item__icon--pack-private";
    const canDestroy = new PackPolicy().Destroy(pack, currentUser);

    return (
      <Link to={packPath(pack.id)}
            className={classnames("scenario-list-item", {"scenario-list-item--selected": this.isSelected})}>
        <div className={classnames("scenario-list-item__icon", visibilityClass)}/>
        <div className="scenario-list-item__content">
          <div className="scenario-list-item__title" title={pack.title}>
            {pack.title}
          </div>
          <div className="scenario-list-item__secondary-content">
            <div className="scenario-list-item__subtitle">
              {I18n.t("packs.scenarios", {scenarios_count: pack.user_scenarios_count})}
            </div>
            <div className="scenario-list-item__date">
              {listItemDateLabel(pack.updated_at)}
            </div>
          </div>
        </div>
        <div className="scenario-list-item__actions">
          <ContextMenu className="scenario-list-item__context-menu">
            <ContextMenuItem buttonHrefTo={Routes.pack_export_path(pack.id, {format: ""})}>
              {I18n.t("download")}
            </ContextMenuItem>
            {
              canDestroy &&
              <ContextMenuItem onClick={this.showRemoveDialog} color="red">
                {I18n.t("delete")}
              </ContextMenuItem>
            }
          </ContextMenu>
          {
            this.canShare &&
            <div className="scenario-list-item__icon scenario-list-item__icon--check-box" onClick={this.toggleSelect}/>
          }
        </div>
        <DeleteListItem
          objectWithState={pack}
          onDestroy={this.onDestroy}
          setRemovingState={setPackRemovingState}
        />
      </Link>
    );
  }
}

Pack.propTypes = {
  pack: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  setPackRemovingState: PropTypes.func.isRequired,
  destroyPack: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    packs: state.packs,
    currentUser: state.profiles.current
  }
}

const mapActionsToProps = {
  selectPack,
  deselectPack,
  setPackRemovingState,
  destroyPack
};

export default connect(mapStateToProps, mapActionsToProps)(Pack);
