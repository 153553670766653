import React from "react";

const VRVersion = ({vrVersion = ""}) => {
  if (!vrVersion) return null;

  return (
    <div className="vr-version">
      {I18n.t("dashboard.scenario_review.vr_version", {version: vrVersion})}
    </div>
  );
};

export default VRVersion;
