import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import ContextMenu from "./context_menu/ContextMenu";
import ContextMenuItem from "./context_menu/ContextMenuItem";
import ContextMenuItemSeparator from "./context_menu/ContextMenuItemSeparator";

class Image extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
  }

  onUpload = () => {
    this.fileInputRef.current.click();
  };

  onFileInputChange = (event) => {
    event.preventDefault();

    const file = event.target.files[0];

    if (!file) {
      return;
    }

    const {onLoaded} = this.props;

    const reader = new FileReader();
    reader.onloadend = () => {
      const fileBase64 = reader.result;
      onLoaded(file.name, fileBase64);
    };
    reader.readAsDataURL(file);
  };

  render() {
    const {imageUrl, imageBase64, isUploadable, theme, onDelete} = this.props;
    const isImagePresent = imageUrl || imageBase64;

    return (
      <div className={classnames(
        "image-uploader", `image-uploader--${theme}`, {"image-uploader--uploadable": isUploadable}
      )}>
        <div className="image-uploader__image-holder">
          {imageUrl && <img src={imageUrl} alt=""/>}
          {
            imageBase64 &&
            <div className="image-uploader__uploaded-image" style={{backgroundImage: `url(${imageBase64})`}}/>
          }
        </div>
        {
          isUploadable &&
          <Fragment>
            <ContextMenu className="image-uploader__context-menu">
              <ContextMenuItem onClick={this.onUpload}>
                {I18n.t("upload")}
              </ContextMenuItem>
              {isImagePresent && <ContextMenuItemSeparator/>}
              {
                isImagePresent &&
                <ContextMenuItem onClick={onDelete}>
                  {I18n.t("remove")}
                </ContextMenuItem>
              }
            </ContextMenu>
            <input type="file" className="hide" ref={this.fileInputRef} onChange={this.onFileInputChange}/>
          </Fragment>
        }
      </div>
    );
  }
}

Image.propTypes = {
  imageUrl: PropTypes.string,
  imageBase64: PropTypes.string,
  isUploadable: PropTypes.bool,
  theme: PropTypes.oneOf(["avatar", "course-image", "scenario-image"]).isRequired,
  onDelete: PropTypes.func.isRequired,
  onLoaded: PropTypes.func.isRequired,
};

export default Image;
