import React, {Component} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";
import _ from "lodash";

import {OVERVIEW_WEEKS_COUNT} from "../../../../../constants";

import UserRow from "./UserRow";
import UserActivityOrderSelect from "../UserActivityOrderSelect";

class ActivityStats extends Component {
  getWeekNumber = date => {
    const {group} = this.props;
    const firstWeekStartsAt = moment(group.first_week_starts_at);
    const week = Math.ceil(moment(date).diff(firstWeekStartsAt, "weeks", true)) + 1;

    return I18n.t("dashboard.week_number", {week});
  };

  getWeekRange = date => {
    return `${date.format("M/D")}-${moment(date).add(6, "days").format("M/D")}`;
  };

  render() {
    const {group, userActivities, sortedActivities, changeUserActivitiesOrder} = this.props;
    const {inputWeekStartsAt} = userActivities;
    const latestWeekStartsAt = group.overview_first_week_starts_at;

    return (
      <div className="activity-stats">
        <div className="activity-stats__header">
          <div className="activity-stats__label">{I18n.t("dashboard.student_activity")}</div>

          <div className="activity-stats__settings">
            <UserActivityOrderSelect
              changeUserActivitiesOrder={changeUserActivitiesOrder}
              userActivities={userActivities}
            />
          </div>
        </div>

        <table cellSpacing="0" className="activity-stats__table">
          <thead>
            <tr className="activity-stats__header-row">
              <th className="activity-stats__header-cell activity-stats__header-cell--name">
                {I18n.t("dashboard.student")}
              </th>

              <th className="activity-stats__header-cell activity-stats__header-cell--tier">
                {I18n.t("dashboard.tier")}
              </th>

              <th className="activity-stats__header-cell activity-stats__header-cell--progress">
                {I18n.t("dashboard.course_progress")}
              </th>

              {_.range(OVERVIEW_WEEKS_COUNT).map(week => {
                const date = moment(latestWeekStartsAt).add(week, "weeks");

                if (moment() < date || moment(group.last_week_starts_at) < date) {
                  return <th key={week} className="activity-stats__header-cell" />;
                }

                return (
                  <th
                    key={week}
                    className={classnames("activity-stats__header-cell", {
                      "activity-stats__header-cell--active": moment(date).isSame(moment(), "week")
                    })}
                  >
                    {this.getWeekNumber(date)}
                    <br />
                    {this.getWeekRange(date)}
                  </th>
                );
              })}

              <th className="activity-stats__header-cell" />

              <th className="activity-stats__header-cell">
                {I18n.t("dashboard.weekly_average_duration")}
              </th>

              <th className="activity-stats__header-cell">
                {I18n.t("dashboard.weekly_average_days")}
              </th>
            </tr>
          </thead>

          <tbody>
            {sortedActivities.map(userActivity => (
              <UserRow
                key={userActivity.id}
                group={group}
                inputWeekStartsAt={inputWeekStartsAt}
                userActivity={userActivity}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

ActivityStats.propTypes = {
  changeUserActivitiesOrder: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  userActivities: PropTypes.object.isRequired
};

export default ActivityStats;
