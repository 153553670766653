import React, {Fragment} from "react";
import classnames from "classnames";
import _ from "lodash";

import {METRIC_MEASURES} from "../../../../../../constants";

import WrappedText from "./WrappedText";

const Pronunciation = ({isUserLine, line}) => {
  if (!isUserLine) return null;

  const pronunciationResult = _.find(line.results, {metric: "pronunciation"});
  const measure = METRIC_MEASURES.pronunciation;
  const value = pronunciationResult ? pronunciationResult.raw_value : "-";
  const passed = pronunciationResult && pronunciationResult.passed;

  return (
    <div className={classnames({"scenario-review-lines__failure": !passed})}>
      {`${value}${measure}`}
    </div>
  );
};

const ReadingColumns = ({isUserLine, line}) => (
  <Fragment>
    <td>
      <WrappedText text={line.filtered_text} type="failure" words={line.words} />
    </td>
    <td>
      <Pronunciation isUserLine={isUserLine} line={line} />
    </td>
  </Fragment>
);

export default ReadingColumns;
