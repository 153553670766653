import React, {useCallback} from "react";
import _ from "lodash";

import ConfirmationModal from "../../modals/ConfirmationModal";
import ContextMenuItem from "../../context_menu/ContextMenuItem";
import NotificationModal from "../../modals/NotificationModal";

const MODAL_NAME = "USER_SCENARIO_DELETE_PARTICIPANT_MODAL";

const Button = props => (
  <ContextMenuItem color="red" {...props}>
    {I18n.t("delete")}
  </ContextMenuItem>
);

const DeleteParticipantModel = ({assignUserScenarioAttributes, formAttributes, participantId}) => {
  const modalName = `${MODAL_NAME}_${participantId}`;

  const handleSubmit = useCallback(
    closeModal => {
      const {lines, participants} = formAttributes;
      const participantIndex = _.findIndex(participants, {id: participantId});
      const changedParticipants = _.reject(participants, {id: participantId});

      const changedLines = lines.map(line => {
        const changedParticipantFocuses = [...line.participant_focuses];
        changedParticipantFocuses.splice(participantIndex, 1);
        let newParticipantId = line.participant_id;
        if (line.participant_id === participantId) {
          newParticipantId = changedParticipants[0].id;
          changedParticipantFocuses[0] = 0;
        }

        return {
          ...line,
          participant_focuses: changedParticipantFocuses,
          participant_id: newParticipantId
        };
      });

      const changedDialogflowAgents = formAttributes.dialogflow_agents.map(agent => {
        const userParticipantId =
          agent.user_participant_id === participantId
            ? changedParticipants[0].id
            : agent.user_participant_id;
        const chatbotParticipantId =
          agent.chatbot_participant_id === participantId
            ? changedParticipants[0].id
            : agent.chatbot_participant_id;

        return {
          ...agent,
          chatbot_participant_id: chatbotParticipantId,
          user_participant_id: userParticipantId
        };
      });

      assignUserScenarioAttributes({
        ...formAttributes,
        dialogflow_agents: changedDialogflowAgents,
        lines: changedLines,
        participants: changedParticipants
      });
      closeModal();
    },
    [assignUserScenarioAttributes, formAttributes, participantId]
  );

  const isLastLine = formAttributes.participants.length === 1;

  if (isLastLine) {
    return (
      <NotificationModal
        button={<Button />}
        message={I18n.t("user_scenarios.popups.last_participant_message")}
        modalName={MODAL_NAME}
      />
    );
  }

  return (
    <ConfirmationModal
      button={<Button />}
      message={I18n.t("user_scenarios.popups.confirm_delete_participant")}
      modalName={modalName}
      onSubmit={handleSubmit}
    />
  );
};

export default DeleteParticipantModel;
