import React, {Component} from "react"
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";

import {COMPANY_PACK_CATEGORY, GENERAL_PACK_CATEGORY, OWN_PACK_CATEGORY} from "../../constants";

import {packCategoryPath} from "../../helpers/routesHelper";
import {isFetchPackCategoriesError} from "../../helpers/errorsHelpers";

import ApplicationLayout from "../layouts/ApplicationLayout";
import ChoiceItem from "../../components/shared/ChoiceItem";
import FetchPackCategories from "../../components/data_loaders/FetchPackCategories";

class Show extends Component {
  get companyTitle() {
    return this.props.company ? this.props.company.title : "";
  }

  render() {
    const {packCategories} = this.props;
    const generalCategory = _.find(packCategories.collection, {id: GENERAL_PACK_CATEGORY});
    const companyCategory = _.find(packCategories.collection, {id: COMPANY_PACK_CATEGORY});
    const ownCategory = _.find(packCategories.collection, {id: OWN_PACK_CATEGORY});

    const isLoaded = packCategories.isCollectionLoaded;
    const isLoadingError = isFetchPackCategoriesError(packCategories);
    const isLoading = (!isLoaded && !isLoadingError) || packCategories.isLoading;

    return (
      <ApplicationLayout isLoading={isLoading}>
        <FetchPackCategories/>
        {
          packCategories.isCollectionLoaded &&
          <div className="container container_full_height">
            <div className="row h-100 align-content-center justify-content-md-center pl-4 pr-4">
              {
                generalCategory &&
                <ChoiceItem
                  path={packCategoryPath(GENERAL_PACK_CATEGORY)}
                  title={I18n.t("packs.categories.with_count.general", {packs_count: generalCategory.packs_count})}
                  type="scenario_category"
                />
              }
              {
                companyCategory &&
                <ChoiceItem
                  path={packCategoryPath(COMPANY_PACK_CATEGORY)}
                  title={
                    I18n.t("packs.categories.with_count.company",
                      {company_title: this.companyTitle, packs_count: companyCategory.packs_count})
                  }
                  type="scenario_category"
                />
              }
              {
                ownCategory &&
                <ChoiceItem
                  path={packCategoryPath(OWN_PACK_CATEGORY)}
                  title={I18n.t("packs.categories.with_count.own", {packs_count: ownCategory.packs_count})}
                  type="scenario_category"
                />
              }
              <div className="col-12">
                {/* Adds a row to allow using align-content */}
              </div>
            </div>
          </div>
        }
      </ApplicationLayout>
    )
  }
}

Show.propTypes = {
  company: PropTypes.object,
  packCategories: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    company: state.companies.current,
    packCategories: state.packCategories
  }
}

export default connect(mapStateToProps)(Show);
