import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchGroupError} from "../../helpers/errorsHelpers";

import {fetchGroup} from "../../store/actions/groupsActions";

class FetchGroup extends Component {
  componentDidMount() {
    this.fetchGroup(true);
  }

  componentDidUpdate() {
    this.fetchGroup(false);
  }

  fetchGroup(isComponentMount) {
    const {id, groups} = this.props;
    const isReasonToFetch = isComponentMount || groups.current.id !== id;

    if (isReasonToFetch && !groups.isLoading && !isFetchGroupError(groups)) {
      this.props.fetchGroup(id);
    }
  }

  render() {
    return null;
  }
}

FetchGroup.propTypes = {
  id: PropTypes.string.isRequired,
  groups: PropTypes.object.isRequired,
  fetchGroup: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    groups: state.groups
  }
}

const mapActionsToProps = {
  fetchGroup
};

export default connect(mapStateToProps, mapActionsToProps)(FetchGroup);
