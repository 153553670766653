import React, {Fragment} from "react";
import PropTypes from "prop-types";
import moment from 'moment-timezone';

import ActiveStudentsCounter from './ActiveStudentsCounter';
import ActivityStats from './ActivityStats';
import ActivityDateSlider from './ActivityDateSlider';

const Weekly = ({
  changeUserActivitiesOrder,
  changeUserActivitiesWeek,
  group,
  isLoaded,
  sortedActivities,
  userActivities
}) => {
  if (!group.id) {
    return null;
  }

  const weekStartsAt = moment(userActivities.inputWeekStartsAt);
  const weekEndsAt = moment(weekStartsAt).add(7, "days")

  return (
    <Fragment>
      <ActivityDateSlider
        first={group.starts_on}
        last={group.ends_on}
        from={weekStartsAt}
        to={weekEndsAt}
        minFrom={group.first_week_starts_at}
        maxFrom={group.week_starts_at}
        onChange={changeUserActivitiesWeek}
      />
      <div className="horizontal-line mb-4"/>
      {isLoaded && (
        <Fragment>
          <ActiveStudentsCounter userActivities={userActivities}/>
          <ActivityStats
            group={group}
            sortedActivities={sortedActivities}
            userActivities={userActivities}
            changeUserActivitiesOrder={changeUserActivitiesOrder}
          />
        </Fragment>
      )}
    </Fragment>
  );
}

Weekly.propTypes = {
  changeUserActivitiesOrder: PropTypes.func.isRequired,
  changeUserActivitiesWeek: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  isLoaded: PropTypes.bool,
  userActivities: PropTypes.object.isRequired
};

export default Weekly;
