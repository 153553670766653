import React, {Fragment} from "react";

import {
  isFreeTalkMode,
  isListeningMode,
  isReadingMode,
  isTranslatingMode
} from "../../../../../helpers/modesHelper";

const ListeningHeaders = () => (
  <Fragment>
    <th>{I18n.t("dashboard.scenario_review.line_text")}</th>
    <th>{I18n.t("metrics_attr.key_points_value")}</th>
  </Fragment>
);

const ReadingHeaders = () => (
  <Fragment>
    <th>{I18n.t("dashboard.scenario_review.line_text")}</th>
    <th>{I18n.t("metrics_attr.pronunciation")}</th>
  </Fragment>
);

const TranslatingHeaders = () => (
  <Fragment>
    <th>{I18n.t("dashboard.scenario_review.line_text")}</th>
    <th>{I18n.t("metrics_attr.key_points_value")}</th>
  </Fragment>
);

const FreeTalkHeaders = () => <th>{I18n.t("dashboard.scenario_review.line_text")}</th>;

const ModeSpecificHeaders = ({mode}) => {
  if (isListeningMode(mode)) return <ListeningHeaders />;
  if (isReadingMode(mode)) return <ReadingHeaders />;
  if (isTranslatingMode(mode)) return <TranslatingHeaders />;
  if (isFreeTalkMode(mode)) return <FreeTalkHeaders />;

  return null;
};

export default ModeSpecificHeaders;
