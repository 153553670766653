import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import Image from './Image';

class Cover extends Component {
  handleDelete = () => {
    const {formAttributes, assignAttributes} = this.props;
    assignAttributes({...formAttributes, image: {remove_image: true}});
  };

  handleLoaded = (filename, fileBase64) => {
    const {formAttributes, assignAttributes} = this.props;
    assignAttributes({...formAttributes, image: {filename, file_base64: fileBase64}});
  };

  renderImageErrors = () => {
    if (!this.props.isUploadable) return;

    const errors = this.props.formAttributes.errors;
    const imageErrors = [];
    ["width", "height"].forEach((name) => {
      const error = errors[`image_uploaded_${name}`];
      if (error) {
        imageErrors.push(I18n.t(`mongoid.errors.messages.wrong_image_${name}`, {error}));
      }
    });
    if (_.isEmpty(imageErrors)) return null;
    return <div className="form-group__error">{imageErrors.join(", ")}</div>;
  };

  render() {
    const {formAttributes, isUploadable, theme} = this.props;
    const {image} = formAttributes;

    return (
      <Fragment>
        <Image
          imageUrl={image.url}
          imageBase64={image.file_base64}
          isUploadable={isUploadable}
          theme={theme}
          onDelete={this.handleDelete}
          onLoaded={this.handleLoaded}
        />
        {this.renderImageErrors()}
      </Fragment>
    );
  }
}

Cover.propTypes = {
  formAttributes: PropTypes.object.isRequired,
  isUploadable: PropTypes.bool,
  theme: PropTypes.oneOf(["course-image", "scenario-image"]).isRequired,
  assignAttributes: PropTypes.func
};

export default Cover;
