import React from "react";
import PropTypes from "prop-types";

import ModeSpecificHeaders from "./ModeSpecificHeaders";

const Header = ({mode}) => {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th>{I18n.t("mongoid.attributes.line.participant")}</th>
        <ModeSpecificHeaders mode={mode} />
        <th>{I18n.t("mongoid.attributes.line.audio_track")}</th>
        <th>{I18n.t("mongoid.attributes.line.user_audio_track")}</th>
        <th aria-label="Audio player" />
      </tr>
    </thead>
  );
};

Header.propTypes = {
  mode: PropTypes.object.isRequired
};

export default Header;
