import {ACTIONS} from "../reducers/companiesReducer";

import webToolApi from "../../helpers/webToolApiHelper";

import {defaultErrorAction} from "./errorsActions";
import {notifyResponseError, notifySuccess} from "./notificationsActions";

export function changeCompaniesLoading(isLoading) {
  return {type: ACTIONS.CHANGE_COMPANIES_LOADING, isLoading};
}

export function fetchCompany() {
  return (dispatch) => {
    dispatch(changeCompaniesLoading(true));
    dispatch({type: ACTIONS.FETCH_COMPANY});

    webToolApi().get(Routes.company_path())
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_COMPANY_FULFILLED, company: response.data.company});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_COMPANY_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("companies.messages.show.error"), response));
      })
      .finally(() => {
        dispatch(changeCompaniesLoading(false));
      });
  }
}

export function assignCompanyAttributes(attributes) {
  return {type: ACTIONS.ASSIGN_COMPANY_ATTRIBUTES, attributes};
}

export function clearCompanyAttributes() {
  return {type: ACTIONS.CLEAR_COMPANY_ATTRIBUTES};
}

export function updateCompany(company, {onSuccess} = {}) {
  return (dispatch) => {
    dispatch(changeCompaniesLoading(true));

    webToolApi().put(Routes.company_path(), {company})
      .then((response) => {
        dispatch({type: ACTIONS.UPDATE_COMPANY_FULFILLED, company: response.data.company});
        dispatch(notifySuccess(I18n.t("companies.messages.update.success")));
        dispatch(clearCompanyAttributes());
        onSuccess && onSuccess();
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.UPDATE_COMPANY_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("companies.messages.update.error"), response));
      })
      .finally(() => {
        dispatch(changeCompaniesLoading(false));
      });
  }
}
