import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {importPacks} from "../../../store/actions/packsActions";
import {notifyError} from "../../../store/actions/notificationsActions";

class PacksImport extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
  }

  onImport = () => {
    this.fileInputRef.current.click();
  };

  importPacks = event => {
    const file = event.target.files[0];

    if (file && this.isValid(file)) {
      this.props.importPacks(file);
    }
  };

  isValid(file) {
    const TYPES = "stp";
    const TYPES_REGEX = /([./])(stp)$/i;
    let error;

    if (!TYPES_REGEX.test(file.type) && !TYPES_REGEX.test(file.name)) {
      const extension = file.name.split(".").pop();
      error = I18n.t("errors.messages.extension_whitelist_error", {
        allowed_types: TYPES,
        extension
      });
    }

    if (error) {
      this.props.notifyError(error);

      return false;
    }

    return true;
  }

  render() {
    const {packs} = this.props;

    return (
      <Fragment>
        <button
          aria-label="upload"
          className="top-panel__button top-panel__button_upload"
          disabled={packs.isLoading}
          onClick={this.onImport}
        />
        <form>
          <input ref={this.fileInputRef} className="hide" onChange={this.importPacks} type="file" />
        </form>
      </Fragment>
    );
  }
}

PacksImport.propTypes = {
  importPacks: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  packs: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    packs: state.packs
  };
}

const mapActionsToProps = {
  importPacks,
  notifyError
};

export default connect(mapStateToProps, mapActionsToProps)(PacksImport);
