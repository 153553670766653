import React, {Fragment} from "react";
import PropTypes from "prop-types";

import MainLoader from "components/shared/MainLoader";
import ScrollUp from "components/components/ScrollUp";
import SvgIcon from "../../components/SvgIcon";

const UnauthenticatedLayout = ({children, isLoading}) => (
  <Fragment>
    <ScrollUp /> {/* Scroll to the top after page change */}
    <a href="https://www.plusone.space">
      <SvgIcon className="plus-one-logo" icon="plus-one-logo" />
    </a>
    <div className="container container_full_height container_without_header">
      <div className="row h-100 align-items-center">{children}</div>
    </div>
    <MainLoader isShow={isLoading} />
  </Fragment>
);

UnauthenticatedLayout.propTypes = {
  isLoading: PropTypes.bool
};

export default UnauthenticatedLayout;
