import React from "react";
import {SortableElement} from "react-sortable-hoc";

import Line from "./Line";

const SortableLine = SortableElement(({lineId, formAttributes, assignUserScenarioAttributes}) => (
  <Line
    assignUserScenarioAttributes={assignUserScenarioAttributes}
    formAttributes={formAttributes}
    id={lineId}
  />
));

export default SortableLine;
