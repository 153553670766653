import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import moment from "moment-timezone";

import DeleteActivationDateModal, {
  MODAL_NAME as DELETE_ACTIVATION_DATE_MODAL_NAME
} from "./DeleteActivationDateModal";

import "react-datepicker/dist/react-datepicker.css";

import {enumI18n} from "helpers/enumI18nHelper";

import {openModal} from "store/actions/modalsActions";

import ToggleableDatePicker from "components/shared/ToggleableDatePicker";

class EditStatusesSection extends Component {
  onStatusChange = () => {
    const attributes = this.props.formAttributes;
    const status = attributes.status === "active" ? "inactive" : "active";
    let activationStartsOn = attributes.activation_starts_on;
    if (status === "active") {
      activationStartsOn = "";
    }
    this.props.assignUserAttributes({
      ...attributes,
      activation_starts_on: activationStartsOn,
      status
    });
  };

  onActivationDateChange = (attribute, value) => {
    const attributes = this.props.formAttributes;
    let date;
    let {status} = attributes;
    if (attributes[attribute] && value) {
      if (value instanceof Date){
        date = value;
      }else{
        this.props.openModal(DELETE_ACTIVATION_DATE_MODAL_NAME, {attribute});

        return;
      }
    } else if (!attributes[attribute]) {
      date = this.initialDate;
      if (attribute === "activation_starts_on") {
        status = "inactive";
      }
    }
    this.props.assignUserAttributes({...attributes, [attribute]: date, status});
  };

  get initialDate() {
    return moment().add(1, "days").toDate();
  }

  render() {
    const attributes = this.props.formAttributes;
    const {assignUserAttributes} = this.props;

    return (
      <Fragment>

        <ToggleableDatePicker
          className="profile-section__field"
          dateFormat="yyyy/M/d"
          label={I18n.t("mongoid.attributes.user.activation_starts_on")}
          minDate={this.initialDate}
          name="activation_starts_on"
          onChange={value => this.onActivationDateChange("activation_starts_on", value)}
          value={attributes.activation_starts_on}
        />

        <ToggleableDatePicker
          className="profile-section__field"
          dateFormat="yyyy/M/d"
          label={I18n.t("mongoid.attributes.user.activation_ends_on")}
          minDate={this.initialDate}
          name="activation_ends_on"
          onChange={value => this.onActivationDateChange("activation_ends_on", value)}
          value={attributes.activation_ends_on}
        />

        <DeleteActivationDateModal
          assignUserAttributes={assignUserAttributes}
          formAttributes={attributes}
        />
      </Fragment>
    );
  }
}

EditStatusesSection.propTypes = {
  assignUserAttributes: PropTypes.func.isRequired,
  formAttributes: PropTypes.object.isRequired
};

const mapActionsToProps = {
  openModal
};

export default connect(null, mapActionsToProps)(EditStatusesSection);
