import React, {useCallback} from "react";
import {connect} from "react-redux";
import classnames from "classnames";
import _ from "lodash";

import {closeModal} from "../../../../store/actions/modalsActions";
import {assignUserScenarioAttributes} from "../../../../store/actions/userScenariosActions";

import DefaultModal from "../../modals/DefaultModal";

const MODAL_NAME = "USER_SCENARIO_DIALOGFLOW_AGENTS_MODAL";

const DialogflowAgentItem = ({
  assignUserScenarioAttributes,
  closeModal,
  dialogflowAgent,
  formAttributes,
  scenarioDialogflowAgent
}) => {
  const selectDialogflowAgent = useCallback(
    (event, dialogflowAgentId) => {
      event.preventDefault();

      const updatedDialogflowAgents = [...formAttributes.dialogflow_agents];
      const dialogflowAgentIndex = _.findIndex(updatedDialogflowAgents, {
        id: scenarioDialogflowAgent.id
      });
      updatedDialogflowAgents[dialogflowAgentIndex] = {
        ...updatedDialogflowAgents[dialogflowAgentIndex],
        dialogflow_agent_id: dialogflowAgentId
      };
      assignUserScenarioAttributes({...formAttributes, dialogflow_agents: updatedDialogflowAgents});
      closeModal(MODAL_NAME);
    },
    [assignUserScenarioAttributes, closeModal, formAttributes, scenarioDialogflowAgent.id]
  );

  const isCurrentAgent = dialogflowAgent.id === scenarioDialogflowAgent.dialogflow_agent_id;
  const cButton = classnames("scenario-list-item scenario-list-item--agent", {
    "scenario-list-item--non-hoverable": isCurrentAgent
  });

  return (
    <button
      key={dialogflowAgent.id}
      className={cButton}
      disabled={isCurrentAgent}
      onClick={event => selectDialogflowAgent(event, dialogflowAgent.id)}
    >
      <div className="scenario-list-item__content">
        <div className="scenario-list-item__title" title={dialogflowAgent.name}>
          {dialogflowAgent.name}
        </div>
      </div>
    </button>
  );
};

const DialogflowAgentsModal = ({
  assignUserScenarioAttributes,
  closeModal,
  dialogflowAgents,
  formAttributes,
  scenarioDialogflowAgent
}) => {
  const sortedAgents = _.sortBy(dialogflowAgents.collection, agent => agent.name.toLowerCase());

  return (
    <DefaultModal
      buttonClass="button button_theme_select-agent button_color_grey ml-2"
      buttonText={I18n.t("select")}
      modalName={MODAL_NAME}
      modalTitle={I18n.t("user_scenarios.popups.dialogflow_agents.title")}
    >
      <div className="bem-popup__content">
        {sortedAgents.map(dialogflowAgent => (
          <DialogflowAgentItem
            key={dialogflowAgent.id}
            assignUserScenarioAttributes={assignUserScenarioAttributes}
            closeModal={closeModal}
            dialogflowAgent={dialogflowAgent}
            formAttributes={formAttributes}
            scenarioDialogflowAgent={scenarioDialogflowAgent}
          />
        ))}
      </div>
    </DefaultModal>
  );
};

const mapActionsToProps = {
  assignUserScenarioAttributes,
  closeModal
};

export default connect(null, mapActionsToProps)(DialogflowAgentsModal);
