import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchEnvironmentsError} from "../../helpers/errorsHelpers";

import {fetchEnvironments} from "../../store/actions/environmentsActions";

class FetchEnvironments extends Component {
  componentDidMount() {
    this.fetchEnvironments(true);
  }

  componentDidUpdate() {
    this.fetchEnvironments(false);
  }

  fetchEnvironments(isComponentMount) {
    const {environments} = this.props;
    const isReasonToFetch = isComponentMount || !environments.isCollectionLoaded;

    if (isReasonToFetch && !environments.isLoading && !isFetchEnvironmentsError(environments)) {
      this.props.fetchEnvironments();
    }
  }

  render() {
    return null;
  }
}

FetchEnvironments.propTypes = {
  environments: PropTypes.object.isRequired,
  fetchEnvironments: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    environments: state.environments
  }
}

const mapActionsToProps = {
  fetchEnvironments
};

export default connect(mapStateToProps, mapActionsToProps)(FetchEnvironments);
