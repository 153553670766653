import React, {useCallback, useEffect, useState} from "react";
import Switch from "components/shared/Switch";
import {connect} from "react-redux";
import {updateGroup} from "store/actions/groupsActions";

import styles from "./styles.module.css";

const InactiveUsersSwitch = ({groups, updateGroup}) => {
  const {current: group, isLoading} = groups;
  const [inactiveVisible, setInactiveVisible] = useState(group.show_inactive_users || false);

  useEffect(() => {
    setInactiveVisible(group.show_inactive_users || false);
  }, [group.show_inactive_users]);

  const handleChange = useCallback(() => {
    setInactiveVisible(!inactiveVisible);
    updateGroup({...group, show_inactive_users: !inactiveVisible});
  }, [group, inactiveVisible, updateGroup]);

  if (!group.id) return null;

  return (
    <div className="d-flex mt-2">
      <label className={styles.label}>
        {I18n.t("dashboard.groups.show_inactive_users", {count: group.inactive_users_count})}
      </label>
      <Switch
        disabled={isLoading}
        name="show_inactive_users"
        onChange={handleChange}
        value={inactiveVisible}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    groups: state.groups
  };
}

const mapActionsToProps = {
  updateGroup
};

export default connect(mapStateToProps, mapActionsToProps)(InactiveUsersSwitch);
