import {PunditPolicy} from "react-pundit";
import {isAdmin, isManager, isStudent} from "../helpers/userRolesHelper";
import {isPublic} from "../helpers/packsHelper";

class UserScenarioPolicy extends PunditPolicy {
  constructor() {
    super("UserScenario");
    this.Show = this.Show.bind(this);
    this.Update = this.Update.bind(this);
    this.Destroy = this.Destroy.bind(this);
    this.Duplicate = this.Duplicate.bind(this);
    this.Copy = this.Copy.bind(this);
  }

  Index(model, user) {
    return isAdmin(user) || isManager(user);
  }

  Show(model, user) {
    return this.isScenarioOwner(model, user) || this.isManagerOfUser(model, user) ||
      (((isStudent(user) && this.isCompanyScenario(model, user)) || isAdmin(model.user)) && isPublic(model.pack));
  }

  Update(model, user) {
    return isAdmin(user) || this.isScenarioOwner(model, user) || this.isManagerOfUser(model, user);
  }

  Destroy(model, user) {
    return isAdmin(user) || this.isScenarioOwner(model, user) ||
      (this.isManagerOfUser(model, user) && !model.has_metrics);
  }

  Duplicate(model, user) {
    return this.isScenarioOwner(model, user);
  }

  Copy(model, user) {
    if (this.isManagerOfUser(model, user)) {
      return true;
    }
    return isPublic(model.pack) && isStudent(user) && this.isCompanyScenario(model, user);
  }

  CreateGeneral(model, user) {
    return isAdmin(user);
  }

  CreateCompany(model, user) {
    return isManager(user);
  }

  CreateOwn(model, user) {
    return isStudent(user);
  }

  isScenarioOwner(model, user) {
    return model.user.id === user.id;
  }

  isManagerOfUser(model, user) {
    return isManager(user) && isStudent(model.user) && user.company_id === model.user.company_id;
  }

  isCompanyScenario(model, user) {
    return model.user.id === user.manager_id;
  }
}

export default UserScenarioPolicy;
