import React from "react";
import classnames from "classnames";

const Switch = ({disabled, name, onChange, value, wrapperClass}) => (
  <div className={classnames("custom-control custom-switch", wrapperClass)}>
    <input
      checked={value}
      className="custom-control-input"
      disabled={disabled}
      id={name}
      name={name}
      onChange={onChange}
      type="checkbox"
    />
    <label className="custom-control-label" htmlFor={name} />
  </div>
);

export default Switch;
