import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import _ from "lodash";

import {buildLine} from "../../helpers/buildLine";
import {buildParticipant} from "../../helpers/participantsHelper";
import {isAdmin} from "../../helpers/userRolesHelper";
import {
  isFetchCharactersError,
  isFetchDialogflowAgentsError,
  isFetchEnvironmentsError
} from "../../helpers/errorsHelpers";
import {packPath} from "../../helpers/routesHelper";

import {
  assignUserScenarioAttributes,
  clearUserScenarioAttributes,
  createUserScenario
} from "../../store/actions/userScenariosActions";
import {notifyError} from "../../store/actions/notificationsActions";

import FetchCharacters from "../../components/data_loaders/FetchCharacters";
import FetchDialogflowAgents from "../../components/data_loaders/FetchDialogflowAgents";
import FetchEnvironments from "../../components/data_loaders/FetchEnvironment";
import UserScenarioForm from "../../components/shared/user_scenarios/Form";

class New extends Component {
  componentDidMount() {
    this.assignUserScenarioAttributes(true);
  }

  componentDidUpdate() {
    this.assignUserScenarioAttributes(false);
  }

  assignUserScenarioAttributes = isComponentMount => {
    const {packId, characters, environments, userScenarios, currentUser} = this.props;

    const isLoaded = characters.isCollectionLoaded && environments.isCollectionLoaded;

    if (isComponentMount) {
      this.props.clearUserScenarioAttributes();

      return;
    }

    if (!isLoaded || userScenarios.formAttributes.pack_id === packId) {
      return;
    }

    const environment = environments.collection[0];
    const participants = [buildParticipant(characters.collection[0], environment.positions[0])];
    const lines = _.map(_.range(10), () => buildLine(participants[0], participants));

    const userScenario = {
      description: "",
      dialogflow_agents: [],
      environment_id: environment.id,
      errors: {},
      image: {},
      lines,
      pack_id: packId,
      participants,
      scenarioable: currentUser,
      slides: [],
      title: ""
    };

    this.props.assignUserScenarioAttributes(userScenario);
  };

  onSubmit = userScenario => {
    this.props.createUserScenario(this.props.packId, userScenario, this.props.history);
  };

  render() {
    const {
      packId,
      currentUser,
      characters,
      environments,
      dialogflowAgents,
      userScenarios
    } = this.props;

    const isLoaded =
      userScenarios.formAttributes.pack_id === packId &&
      characters.isCollectionLoaded &&
      environments.isCollectionLoaded &&
      (!isAdmin(currentUser) || dialogflowAgents.isCollectionLoaded);

    const isLoadingError =
      isFetchCharactersError(characters) ||
      isFetchEnvironmentsError(environments) ||
      isFetchDialogflowAgentsError(dialogflowAgents);

    const isLoading =
      (!isLoaded && !isLoadingError) ||
      characters.isLoading ||
      environments.isLoading ||
      dialogflowAgents.isLoading ||
      userScenarios.isLoading;

    return (
      <Fragment>
        <FetchCharacters />
        <FetchEnvironments />
        {isAdmin(currentUser) && <FetchDialogflowAgents />}
        <UserScenarioForm
          assignUserScenarioAttributes={this.props.assignUserScenarioAttributes}
          backPath={packPath(this.props.packId)}
          characters={characters}
          currentUser={currentUser}
          dialogflowAgents={dialogflowAgents}
          environments={environments}
          formAttributes={userScenarios.formAttributes}
          isLoaded={isLoaded}
          isLoading={isLoading}
          notifyError={this.props.notifyError}
          onSubmit={this.onSubmit}
          user={this.props.currentUser}
        />
      </Fragment>
    );
  }
}

New.propTypes = {
  assignUserScenarioAttributes: PropTypes.func.isRequired,
  characters: PropTypes.object.isRequired,
  createUserScenario: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  dialogflowAgents: PropTypes.object.isRequired,
  environments: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  notifyError: PropTypes.func.isRequired,
  packId: PropTypes.string.isRequired,
  userScenarios: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    characters: state.characters,
    currentUser: state.profiles.current,
    dialogflowAgents: state.dialogflowAgents,
    environments: state.environments,
    userScenarios: state.userScenarios
  };
}

const mapActionsToProps = {
  assignUserScenarioAttributes,
  clearUserScenarioAttributes,
  createUserScenario,
  notifyError
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(New));
