import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchPackError} from "../../helpers/errorsHelpers";

import {fetchPack} from "../../store/actions/packsActions";

class FetchPack extends Component {
  componentDidMount() {
    this.fetchPack(true);
  }

  componentDidUpdate() {
    this.fetchPack(false);
  }

  fetchPack(isComponentMount) {
    const {id, packs} = this.props;
    const isReasonToFetch = isComponentMount || packs.current.id !== id;

    if (isReasonToFetch && !packs.isLoading && !isFetchPackError(packs)) {
      this.props.fetchPack(id);
    }
  }

  render() {
    return null;
  }
}

FetchPack.propTypes = {
  id: PropTypes.string.isRequired,
  packs: PropTypes.object.isRequired,
  fetchPack: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    packs: state.packs
  }
}

const mapActionsToProps = {
  fetchPack
};

export default connect(mapStateToProps, mapActionsToProps)(FetchPack);
