import {SUCCESS_STATUS_CODE} from "../../constants";

export const ACTIONS = {
  ASSIGN_GROUP_ATTRIBUTES: "ASSIGN_GROUP_ATTRIBUTES",
  CHANGE_GROUPS_LOADING: "CHANGE_GROUPS_LOADING",
  CLEAR_GROUP_ATTRIBUTES: "CLEAR_GROUP_ATTRIBUTES",
  CREATE_GROUP_FULFILLED: "CREATE_GROUP_FULFILLED",
  CREATE_GROUP_REJECTED: "CREATE_GROUP_REJECTED",
  DESTROY_GROUP_FULFILLED: "DESTROY_GROUP_FULFILLED",
  DESTROY_GROUP_REJECTED: "DESTROY_GROUP_REJECTED",
  FETCH_GROUP: "FETCH_GROUP",
  FETCH_GROUPS: "FETCH_GROUPS",
  FETCH_GROUPS_FULFILLED: "FETCH_GROUPS_FULFILLED",
  FETCH_GROUPS_REJECTED: "FETCH_GROUPS_REJECTED",
  FETCH_GROUP_FULFILLED: "FETCH_GROUP_FULFILLED",
  FETCH_GROUP_REJECTED: "FETCH_GROUP_REJECTED",
  UPDATE_GROUP_FULFILLED: "UPDATE_GROUP_FULFILLED",
  UPDATE_GROUP_REJECTED: "UPDATE_GROUP_REJECTED"
};

const DEFAULT_SUCCESS_STATUS = {errors: {}, status: SUCCESS_STATUS_CODE};
const DEFAULT_STATUSES = {
  [ACTIONS.FETCH_GROUPS]: {errors: {}, status: null},
  [ACTIONS.FETCH_GROUP]: {errors: {}, status: null}
};

const INITIAL_FORM_ATTRIBUTES = {
  errors: {},
  title: "",
  user_ids: []
};

export default function reducer(
  state = {
    collection: [],
    current: {},
    formAttributes: INITIAL_FORM_ATTRIBUTES,
    isCollectionLoaded: false,
    isLoading: false,
    loadingCount: 0,
    statuses: DEFAULT_STATUSES
  },
  action
) {
  switch (action.type) {
    case ACTIONS.CHANGE_GROUPS_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);

      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.FETCH_GROUPS: {
      return {...state, collection: [], isCollectionLoaded: false};
    }
    case ACTIONS.FETCH_GROUPS_FULFILLED: {
      return {
        ...state,
        collection: action.payload.groups,
        isCollectionLoaded: true,
        statuses: {...state.statuses, [ACTIONS.FETCH_GROUPS]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_GROUPS_REJECTED: {
      return {
        ...state,
        statuses: {
          ...state.statuses,
          [ACTIONS.FETCH_GROUPS]: {errors: action.errors, status: action.status}
        }
      };
    }

    case ACTIONS.FETCH_GROUP: {
      return {...state, current: {}};
    }
    case ACTIONS.FETCH_GROUP_FULFILLED: {
      return {
        ...state,
        current: action.group,
        statuses: {...state.statuses, [ACTIONS.FETCH_GROUP]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_GROUP_REJECTED: {
      return {
        ...state,
        statuses: {
          ...state.statuses,
          [ACTIONS.FETCH_GROUP]: {errors: action.errors, status: action.status}
        }
      };
    }

    case ACTIONS.ASSIGN_GROUP_ATTRIBUTES: {
      return {
        ...state,
        formAttributes: {errors: state.formAttributes.errors, ...action.attributes}
      };
    }
    case ACTIONS.CLEAR_GROUP_ATTRIBUTES: {
      return {...state, formAttributes: INITIAL_FORM_ATTRIBUTES};
    }

    case ACTIONS.CREATE_GROUP_FULFILLED: {
      return {...state, collection: [], current: action.group, isCollectionLoaded: false};
    }
    case ACTIONS.CREATE_GROUP_REJECTED: {
      return {...state, formAttributes: {...state.formAttributes, errors: action.errors}};
    }

    case ACTIONS.UPDATE_GROUP_FULFILLED: {
      return {...state, collection: [], current: action.group, isCollectionLoaded: false};
    }
    case ACTIONS.UPDATE_GROUP_REJECTED: {
      return {...state, formAttributes: {...state.formAttributes, errors: action.errors}};
    }

    case ACTIONS.DESTROY_GROUP_FULFILLED: {
      let newState = {...state};

      if (state.current.id === action.groupId) {
        newState = {...newState, current: {}};
      }

      return {...newState, collection: [], isCollectionLoaded: false};
    }

    default: {
      return state;
    }
  }
}
