import React from "react";
import {connect} from "react-redux";

import {isFetchPackCategoryError} from "../../../helpers/errorsHelpers";
import {isStatusLoaded, isStatusLoading} from "../../../helpers/loadingStatusesHelper";
import {notifyResponseError} from "../../../store/actions/notificationsActions";
import useScenariosLoader from "./useScenariosLoader";

import ApplicationLayout from "../../layouts/ApplicationLayout";
import Pagination from "../../../components/shared/Pagination";
import TopPanel from "../../pack_categories/TopPanel";
import UserScenario from "./UserScenario";
import FetchPackCategory from "../../../components/data_loaders/FetchPackCategory";

const Search = ({category, notifyResponseError, packCategories, page, query}) => {
  const [data, status] = useScenariosLoader({category, notifyResponseError, page, query});

  const packCategory = packCategories.current;
  const isCategoryLoaded = packCategory.id;
  const isCategoryLoadingError = isFetchPackCategoryError(packCategories);
  const isLoading = (!isCategoryLoaded && !isCategoryLoadingError) || isStatusLoading(status);

  return (
    <ApplicationLayout headerTitle={I18n.t("packs.titles.index")} isLoading={isLoading}>
      <FetchPackCategory id={category} />
      <div className="container">
        <div className="row py-4">
          <div className="col-12">
            {isCategoryLoaded && <TopPanel categoryId={category} query={query} />}
            {isStatusLoaded(status) && (
              <div className="pt-3">
                {data.user_scenarios.map(userScenario => (
                  <UserScenario key={userScenario.id} userScenario={userScenario} />
                ))}
                <div className="page-index-bottom">
                  <Pagination pagination={data.pagination} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ApplicationLayout>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.profiles.current,
    packCategories: state.packCategories
  };
}

const mapActionsToProps = {
  notifyResponseError
};

export default connect(mapStateToProps, mapActionsToProps)(Search);
