import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";

import {
  editDashboardUserScenarioPath,
  dashboardScenarioDetailPath
} from "../../../helpers/routesHelper";

import UserScenarioShow from "../../../components/shared/user_scenarios/Show";
import UserScenarioPolicy from "../../../policies/UserScenarioPolicy";

class Show extends Component {
  get editPath() {
    const {userScenarios, currentUser} = this.props;
    const userScenario = userScenarios.current;
    if (!_.isEmpty(userScenario)) {
      const canUpdate = new UserScenarioPolicy().Update(userScenario, currentUser);
      if (canUpdate) {
        return editDashboardUserScenarioPath(this.props.userId, this.props.id);
      }
    }
    return null;
  }

  render() {
    return (
      <UserScenarioShow
        id={this.props.id}
        userScenarios={this.props.userScenarios}
        company={this.props.company}
        backPath={dashboardScenarioDetailPath(this.props.userId, this.props.id)}
        editPath={this.editPath}
      />
    );
  }
}

Show.propTypes = {
  id: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userScenarios: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    userScenarios: state.userScenarios,
    company: state.companies.current,
    currentUser: state.profiles.current
  }
}

export default connect(mapStateToProps)(Show);
