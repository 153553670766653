import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";

const METRIC_RAW_ATTRIBUTES = ["pronunciation", "fluency", "language_difficulty", "eye_contact", "speech_pace",
  "energy_level_value", "attitude_value", "key_points_value"];

import {METRIC_MEASURES} from "../../../constants";
import {MODAL_NAME as TARGET_SCORE_MODAL_NAME} from "../../../views/dashboard/scenario_details/_TargetScoreModal";

import {targetScoreButtonText} from "../../../helpers/targetScoresHelper";

import {openModal} from "../../../store/actions/modalsActions";
import UserScenarioPolicy from "../../../policies/UserScenarioPolicy";
import TargetScoreModal from "../../../views/dashboard/scenario_details/_TargetScoreModal";

// TODO: This component should be updated or removed
class TargetRanges extends Component {
  get canUpdateUserScenario() {
    const {userScenario, currentUser} = this.props;
    return new UserScenarioPolicy().Update(userScenario, currentUser);
  }

  openTargetScoreModal = (scoreAttribute) => {
    const {userScenario, openModal} = this.props;
    const userScenarioId = userScenario.id;
    const isUpdateAllowed = this.canUpdateUserScenario;
    const targetRange = userScenario.target_score[scoreAttribute];
    const scoreEnabled = userScenario.enabled_score[scoreAttribute];

    openModal(TARGET_SCORE_MODAL_NAME, {userScenarioId, scoreAttribute, targetRange, isUpdateAllowed, scoreEnabled});
  };

  renderRangeButton = (attribute) => {
    const {userScenario} = this.props;
    const targetRange = userScenario.target_score[attribute];
    const isScoreEnabled = userScenario.enabled_score[attribute];

    if (this.canUpdateUserScenario) {
      if (isScoreEnabled) {
        return (
          <button className="pd-sd-metrics__range-button" onClick={() => this.openTargetScoreModal(attribute)}>
            {targetScoreButtonText(targetRange, METRIC_MEASURES[attribute])}
          </button>
        );
      }
      return (
        <button className="pd-sd-metrics__range-button pd-sd-metrics__range-button_muted"
                onClick={() => this.openTargetScoreModal(attribute)}>
          {I18n.t("off")}
        </button>
      );
    }
    return (
      <span>
        {targetScoreButtonText(targetRange, METRIC_MEASURES[attribute])}
      </span>
    );
  };

  render() {
    return (
      <Fragment>
        <table className="pd-sd-metrics scenario-content-header__target-ranges">
          <thead>
          <tr>
            <th>
              <div
                className="lesson-icon lesson-icon--pronunciation"
                title={I18n.t("metrics_attr.pronunciation")}
              />
            </th>
            <th>
              <div className="lesson-icon lesson-icon--fluency" title={I18n.t("metrics_attr.fluency")}/>
            </th>
            <th>
              <div
                className="lesson-icon lesson-icon--language"
                title={I18n.t("metrics_attr.language_difficulty")}
              />
            </th>
            <th>
              <div className="lesson-icon lesson-icon--eye" title={I18n.t("metrics_attr.eye_contact")}/>
            </th>
            <th>
              <div className="lesson-icon lesson-icon--pace" title={I18n.t("metrics_attr.speech_pace")}/>
            </th>
            <th>
              <div className="lesson-icon lesson-icon--energy" title={I18n.t("metrics_attr.energy")}/>
            </th>
            <th>
              <div
                className="lesson-icon lesson-icon--attitude"
                title={I18n.t("metrics_attr.attitude")}
              />
            </th>
            <th>
              <div
                className="lesson-icon lesson-icon--key-points"
                title={I18n.t("metrics_attr.key_points_value")}
              />
            </th>
          </tr>
          <tr className="pd-sd-metrics__secondary-header">
            {_.map(METRIC_RAW_ATTRIBUTES, (attribute) => (
              <td key={attribute}>
                {this.renderRangeButton(attribute)}
              </td>
            ))}
          </tr>
          </thead>
        </table>
        <TargetScoreModal/>
      </Fragment>
    );
  }
}

TargetRanges.propTypes = {
  userScenario: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    currentUser: state.profiles.current
  }
}

const mapActionsToProps = {
  openModal
};

export default connect(mapStateToProps, mapActionsToProps)(TargetRanges);
