export const ACTIONS = {
  OPEN_FILTER: "OPEN_FILTER",
  CLOSE_FILTER: "CLOSE_FILTER"
};

export default function reducer(state = {
  isOpened: false
}, action) {
  switch (action.type) {
    case ACTIONS.OPEN_FILTER: {
      return {...state, isOpened: true};
    }
    case ACTIONS.CLOSE_FILTER: {
      return {...state, isOpened: false};
    }
  }
  return state;
}
