import React, {memo} from "react";

const Slides = ({slides}) => {
  return (
    <div className="mt-2">
      {slides.map(slide => (
        <img key={slide.id} alt="" className="slide" src={slide.url} />
      ))}
    </div>
  );
};

export default memo(Slides);
