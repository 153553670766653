import React, {Component} from "react"
import PropTypes from "prop-types";
import _ from "lodash";

import DefaultModal from "../modals/DefaultModal";

const MODAL_NAME = "SCENARIO_CONTENT_KEY_POINTS_MODAL";

class KeyPointsModal extends Component {
  get buttonTitle() {
    return _.isEmpty(this.props.keyPoints) ? I18n.t("scenario_contents.key_points_not_generated") : null;
  }

  get modalName() {
    return MODAL_NAME + this.props.lineId;
  }

  render() {
    const {keyPoints} = this.props;

    return (
      <DefaultModal modalName={this.modalName}
                    modalTitle={I18n.t("user_scenarios.key_points_popup.title")}
                    buttonClass="scenario-content-lines__key-points-button button button_color_grey button_size_xs w-100"
                    buttonText={_.isEmpty(keyPoints) ? "No KPs" : I18n.t("mongoid.attributes.line.key_points")}
                    buttonDisabled={_.isEmpty(keyPoints)}
                    buttonTitle={this.buttonTitle}>
        <div className="bem-popup__content">
          {_.map(keyPoints, (keyPoint, index) => {
            return <span key={index} className="key-point">{keyPoint.title}</span>;
          })}
        </div>
      </DefaultModal>
    );
  }
}

KeyPointsModal.propTypes = {
  lineId: PropTypes.string.isRequired,
  keyPoints: PropTypes.array.isRequired
};

export default KeyPointsModal;
