import React from "react";

const ParticipantFocuses = ({formAttributes, line, lineIndex, onChange}) =>
  line.participant_focuses.map((percentage, index) => {
    const participant = formAttributes.participants[index];
    const isLineParticipant = participant.id === line.participant_id;

    if (isLineParticipant) {
      return (
        <td key={participant.id}>
          <input
            aria-label="Percentage"
            className="transparent-form-group__input"
            disabled
            value="-"
          />
        </td>
      );
    }

    return (
      <td key={participant.id}>
        <input
          aria-label="Percentage"
          className="transparent-form-group__input"
          name={`lines.${lineIndex}.participant_focuses.${index}`}
          onChange={onChange}
          value={percentage}
        />
      </td>
    );
  });

export default ParticipantFocuses;
