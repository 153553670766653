import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import queryString from "query-string";
import {isFetchUserActivitiesError} from "../../helpers/errorsHelpers";
import {fetchUserActivities} from "../../store/actions/userActivitiesActions";
import {withRouter} from "react-router-dom";
import { isEmpty } from "lodash";

export const INITIAL_NUM_OF_USERS_TO_LOAD = 20;
class FetchUserActivities extends Component {
  constructor(props) {
    super(props);
    const loadAllUsers = queryString.parse(location.search).loadAllUsers;
    this.state = {
      loadAllUsersButtonWasClickedOrInitialQueryWasToLoadAllUser: loadAllUsers == 'true',
    };
  }

  componentDidMount() {
    this.fetchUserActivities(true);

    this.syncLoadAllUsersParamWithUserCount();
  }

  syncLoadAllUsersParamWithUserCount() {
    const {userCount, history} = this.props;

    if (userCount > INITIAL_NUM_OF_USERS_TO_LOAD) {
      // Behave as normal
      return;
    }

    // UX Note: When the `userCount <= INITIAL_NUM_OF_USERS_TO_LOAD` we can just set `loadAllUsers` to `true
    // So that the user knows we've already loaded them all in, this will also hide the button

    const parsedQs = queryString.parse(location.search)

    const stringifiedQs = queryString.stringify({
      ...parsedQs, // Preserve other query keys
      loadAllUsers: true,
    })
    
    history.push({
      search: `?${stringifiedQs}`
    });
  }

  componentWillUnmount() {
    // UX Note: give the next render instance a clean slate
    this.resetLoadAllUsersQuery();
  }

  componentDidUpdate(prevProps) {
    this.fetchUserActivities(false, prevProps);
  }

  fetchAllUsersActivities() {
    const {groupId, userActivities, query} = this.props;
    const parsedQs = queryString.parse(location.search)

    this.props.fetchUserActivities({groupId, weekStartsAt: userActivities.inputWeekStartsAt.format(), query});

    const stringifiedQs = queryString.stringify({
      ...parsedQs, // Preserve other query keys
      loadAllUsers: true,
    })
    
    this.props.history.push({
      search: `?${stringifiedQs}`
    });
  }
  
  resetLoadAllUsersQuery() {
    const parsedQs = queryString.parse(location.search)

    const stringifiedQs = queryString.stringify({
      ...parsedQs, // Preserve other query keys
      loadAllUsers: false,
    })

    this.props.history.push({
      search: `?${stringifiedQs}`
    });
  }

  fetchUserActivities = (isComponentMount, prevProps) => {
    const {groupId, userActivities, query} = this.props;
    const {loadAllUsersButtonWasClickedOrInitialQueryWasToLoadAllUser} = this.state;
    const groupHasChanged = groupId !== userActivities.groupId;

    const isWrongCollection =
      groupHasChanged || !userActivities.inputWeekStartsAt.isSame(userActivities.weekStartsAt);
    const queryChanged = prevProps?.query !== query;
    const isReasonToFetch = isComponentMount || !userActivities.isCollectionLoaded || isWrongCollection || queryChanged;

    if (isReasonToFetch && !userActivities.isLoading && !isFetchUserActivitiesError(userActivities)) {
      if (loadAllUsersButtonWasClickedOrInitialQueryWasToLoadAllUser || !isEmpty(query)) {
        this.fetchAllUsersActivities();
      } else {
        if (isEmpty(query)) {
          this.resetLoadAllUsersQuery();
        }

        this.props.fetchUserActivities({
          groupId,
          weekStartsAt: userActivities.inputWeekStartsAt.format(),
          perPage: INITIAL_NUM_OF_USERS_TO_LOAD,
          query,
        });
      }
    }
  }

  render() {
    const {userActivities} = this.props;
    if (userActivities.isLoading) return null;

    const loadAllUsers = queryString.parse(location.search).loadAllUsers;

    const hasLoadedAllUsers = loadAllUsers == 'true';

    if (hasLoadedAllUsers) {
      return null;
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          justifyContent: "center",
          marginTop: "3em",
        }}
      >
        <button
          className="button button_color_grey"
          style={{
            fontSize: "1em",
          }}
          onClick={() => {
            this.setState({
              loadAllUsersButtonWasClickedOrInitialQueryWasToLoadAllUser: true,
            })
            this.fetchAllUsersActivities()
          }}
        >
          {I18n.t("dashboard.groups.user_activities.load_all_users")}
        </button>
      </div>
    );
  }
}

FetchUserActivities.propTypes = {
  groupId: PropTypes.string.isRequired,
  userActivities: PropTypes.object.isRequired,
  fetchUserActivities: PropTypes.func.isRequired,
  userCount: PropTypes.number.isRequired,
  query: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    userActivities: state.userActivities
  }
}

const mapActionsToProps = {
  fetchUserActivities
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(FetchUserActivities));
