import _ from "lodash";

export const ACTIONS = {
  CHANGE_COURSES_USERS_LOADING: "CHANGE_COURSES_USERS_LOADING",
  CREATE_COURSES_USER_FULFILLED: "CREATE_COURSES_USER_FULFILLED",
  DESTROY_COURSES_USER_FULFILLED: "DESTROY_COURSES_USER_FULFILLED",
  FETCH_COURSES_USER: "FETCH_COURSES_USERS",
  FETCH_COURSES_USER_FULFILLED: "FETCH_COURSES_USERS_FULFILLED",
  UPDATE_COURSES_USER_FULFILLED: "UPDATE_COURSES_USERS_FULFILLED"
};

export default function reducer(
  state = {
    collections: {},
    loadingState: {}
  },
  action
) {
  switch (action.type) {
    case ACTIONS.CHANGE_COURSES_USERS_LOADING: {
      const loadingState = state.loadingState[action.id] || {loadingCount: 0};
      const newLoadingCount = loadingState.loadingCount + (action.isLoading ? 1 : -1);

      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          [action.id]: {isLoading: newLoadingCount > 0, loadingCount: newLoadingCount}
        }
      };
    }

    case ACTIONS.CREATE_COURSES_USER_FULFILLED: {
      return {
        ...state,
        collections: {...state.collections, [action.coursesUser.id]: action.coursesUser}
      };
    }

    case ACTIONS.DESTROY_COURSES_USER_FULFILLED: {
      return {
        ...state,
        collections: _.omit(state.collections, action.id)
      };
    }

    case ACTIONS.FETCH_COURSES_USER: {
      return {
        ...state,
        collections: _.omit(state.collections, action.id)
      };
    }
    case ACTIONS.FETCH_COURSES_USER_FULFILLED: {
      return {
        ...state,
        collections: {...state.collections, [action.coursesUser.id]: action.coursesUser}
      };
    }

    case ACTIONS.UPDATE_COURSES_USER_FULFILLED: {
      return {
        ...state,
        collections: {...state.collections, [action.coursesUser.id]: action.coursesUser}
      };
    }

    default: {
      return state;
    }
  }
}
