import {ACTIONS} from "../reducers/coursesUsersReducer";

import webToolApi from "../../helpers/webToolApiHelper";

import {notifyResponseError, notifySuccess} from "./notificationsActions";

export function changeCoursesUsersLoading(id, isLoading) {
  return {id, isLoading, type: ACTIONS.CHANGE_COURSES_USERS_LOADING};
}

export function fetchCoursesUser(id) {
  return dispatch => {
    dispatch(changeCoursesUsersLoading(id, true));
    dispatch({type: ACTIONS.FETCH_COURSES_USER});

    webToolApi()
      .get(Routes.courses_user_path(id))
      .then(response => {
        dispatch({
          coursesUser: response.data.courses_user,
          type: ACTIONS.FETCH_COURSES_USER_FULFILLED
        });
      })
      .catch(({response}) => {
        dispatch(notifyResponseError(I18n.t("courses.messages.index.error"), response));
      })
      .finally(() => {
        dispatch(changeCoursesUsersLoading(id, false));
      });
  };
}

export function createCoursesUser(coursesUser, {onSuccess} = {}) {
  return dispatch => {
    const id = "new-course";
    dispatch(changeCoursesUsersLoading(id, true));

    webToolApi()
      .post(Routes.courses_users_path(), {courses_user: coursesUser})
      .then(response => {
        dispatch({
          coursesUser: response.data.courses_user,
          type: ACTIONS.CREATE_COURSES_USER_FULFILLED
        });
        dispatch(changeCoursesUsersLoading(id, false));
        if (onSuccess) onSuccess();
      })
      .catch(({response}) => {
        dispatch(notifyResponseError(I18n.t("courses.messages.create.error"), response));
        dispatch(changeCoursesUsersLoading(id, false));
      });
  };
}

export function updateCoursesUser(coursesUser, {onError, onSuccess} = {}) {
  return dispatch => {
    const {id} = coursesUser;
    dispatch(changeCoursesUsersLoading(id, true));

    webToolApi()
      .put(Routes.courses_user_path(id), {courses_user: coursesUser})
      .then(response => {
        dispatch({
          coursesUser: response.data.courses_user,
          type: ACTIONS.UPDATE_COURSES_USER_FULFILLED
        });
        dispatch(notifySuccess(I18n.t("courses.messages.update.success")));
        dispatch(changeCoursesUsersLoading(id, false));
        if (onSuccess) onSuccess();
      })
      .catch(({response}) => {
        dispatch(notifyResponseError(I18n.t("courses.messages.update.error"), response));
        dispatch(changeCoursesUsersLoading(id, false));
        if (onError) onError();
      });
  };
}

export function destroyCoursesUser(id, {onSuccess} = {}) {
  return dispatch => {
    dispatch(changeCoursesUsersLoading(true));

    webToolApi()
      .delete(Routes.courses_user_path(id))
      .then(() => {
        dispatch({id, type: ACTIONS.DESTROY_COURSES_USER_FULFILLED});
        dispatch(notifySuccess(I18n.t("courses.messages.destroy.success")));
        if (onSuccess) onSuccess();
      })
      .catch(({response}) => {
        dispatch(notifyResponseError(I18n.t("courses.messages.destroy.error"), response));
      })
      .finally(() => {
        dispatch(changeCoursesUsersLoading(id, false));
      });
  };
}
