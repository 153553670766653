import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {fetchTags} from "../../store/actions/tagsActions";
import _ from "lodash";

const animatedComponents = makeAnimated();

class TagSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: []
    };
    this.onChange = this.onChange.bind(this);
  }

  

  componentDidMount() {
    const tags = this.props.tags.collection;
    this.setState({
      tags: tags
    });
  }


  onChange = (event, action_type) => {
    const attributes = this.props.formAttributes;
    const changedAttributes = {
      ...attributes,
      course_tags: event
    };
    this.props.assignCourseAttributes(changedAttributes);
  }

  render () {
    const filtered_tags = this.props.tags.collection.map((_tag) => {
      return {
        value: _tag.id,
        label: _tag.english_name
      }
    });
    const tags_assigned_to_course = this.props.formAttributes.course_tags.map((_tag) => _tag.value.$oid ? _tag.value.$oid : _tag.value);
    const default_selected_tags = filtered_tags.filter((_tag) => {
      return _.includes(tags_assigned_to_course, _tag.value);
    });
    return (
      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        value={default_selected_tags}
        isMulti
        options={filtered_tags}
        onChange={this.onChange}
      />
    );
  }
}

TagSelection.propTypes = {
  formAttributes: PropTypes.object.isRequired,
  assignCourseAttributes: PropTypes.func.isRequired,
  tags: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    tags: state.tags
  };
};

const mapActionsToProps = {
  fetchTags
};

export default connect(mapStateToProps, mapActionsToProps)(TagSelection);
