import React, {Component} from "react";
import PropTypes from "prop-types";

import ConfirmationModal from "../../components/shared/modals/ConfirmationModal";
import ContextMenuItem from "../../components/shared/context_menu/ContextMenuItem";

const MODAL_NAME = "COPY_USER_SCENARIO_MODAL";

class CopyUserScenarioModal extends Component {
  get modalName() {
    return `${MODAL_NAME}_${this.props.userScenario.id}`;
  };

  onSubmit = (closeModal) => {
    this.props.copyUserScenario(this.props.userScenario.id);
    closeModal();
  };

  get button() {
    return (
      <ContextMenuItem hideContextMenu={this.props.hideContextMenu}>
        {I18n.t("copy")}
      </ContextMenuItem>
    );
  }

  get message() {
    return I18n.t("user_scenarios.popups.copy_scenario_message", {scenario_title: this.props.userScenario.title});
  }

  render() {
    return (
      <ConfirmationModal
        modalName={this.modalName}
        button={this.button}
        message={this.message}
        onSubmit={this.onSubmit}
      />
    );
  }
}

CopyUserScenarioModal.propTypes = {
  userScenario: PropTypes.object.isRequired,
  copyUserScenario: PropTypes.func.isRequired,
  hideContextMenu: PropTypes.func // Injected by the ContextMenu component
};

export default CopyUserScenarioModal;
