import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import ConfirmationModal from "components/shared/modals/ConfirmationModal";

export const MODAL_NAME = "USERS_DELETE_ACTIVATION_DATE_MODAL";

class DeleteActivationDateModal extends Component {
  onSubmit = closeModal => {
    const attributes = this.props.formAttributes;
    const {attribute} = this.props.modals.options;
    this.props.assignUserAttributes({...attributes, [attribute]: ""});
    closeModal();
  };

  render() {
    const {attribute} = this.props.modals.options;

    return (
      <ConfirmationModal
        actionsStyle="DELETE_CANCEL"
        contentStyle={{width: "550px"}}
        isRenderButton={false}
        message={I18n.t(`users.remove_activation_date.${attribute}`)}
        modalName={MODAL_NAME}
        onSubmit={this.onSubmit}
      />
    );
  }
}

DeleteActivationDateModal.propTypes = {
  assignUserAttributes: PropTypes.func.isRequired,
  formAttributes: PropTypes.object.isRequired,
  modals: PropTypes.shape({
    options: PropTypes.shape({
      attribute: PropTypes.string
    })
  })
};

function mapStateToProps(state) {
  return {
    modals: state.modals
  };
}

export default connect(mapStateToProps)(withRouter(DeleteActivationDateModal));
