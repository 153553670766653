import React, {Component} from "react";
import PropTypes from "prop-types";

import Image from "../../components/shared/Image";

class Avatar extends Component {
  onDelete = () => {
    const {user, assignAttributes} = this.props;
    assignAttributes({...user, avatar: {remove_avatar: true}});
  };

  onLoaded = (filename, fileBase64) => {
    const {user, assignAttributes} = this.props;
    assignAttributes({...user, avatar: {filename, file_base64: fileBase64}});
  };

  render() {
    const {user, isUploadable} = this.props;
    const {avatar} = user;

    return (
      <Image
        imageUrl={avatar.profile_url}
        imageBase64={avatar.file_base64}
        isUploadable={isUploadable}
        theme="avatar"
        onDelete={this.onDelete}
        onLoaded={this.onLoaded}
      />
    );
  }
}

Avatar.propTypes = {
  user: PropTypes.object.isRequired,
  isUploadable: PropTypes.bool,
  assignAttributes: PropTypes.func
};

export default Avatar;
