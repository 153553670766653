import moment from "moment-timezone";

import {SUCCESS_STATUS_CODE, USER_ACTIVITIES_ORDERS} from "../../constants";
import {ACTIONS as GROUP_ACTIONS} from "./groupsReducer";

export const ACTIONS = {
  CHANGE_USER_ACTIVITIES_LOADING: "CHANGE_USER_ACTIVITIES_LOADING",
  CHANGE_USER_ACTIVITIES_WEEK: "CHANGE_USER_ACTIVITIES_WEEK",
  FETCH_USER_ACTIVITIES: "FETCH_USER_ACTIVITIES",
  FETCH_USER_ACTIVITIES_FULFILLED: "FETCH_USER_ACTIVITIES_FULFILLED",
  FETCH_USER_ACTIVITIES_REJECTED: "FETCH_USER_ACTIVITIES_REJECTED",
  REFRESH_USER_ACTIVITIES: "REFRESH_USER_ACTIVITIES",
  CHANGE_USER_ACTIVITIES_ORDER: "CHANGE_USER_ACTIVITIES_ORDER"
};

const DEFAULT_SUCCESS_STATUS = {errors: {}, status: SUCCESS_STATUS_CODE};
const DEFAULT_STATUSES = {
  [ACTIONS.FETCH_USER_ACTIVITIES]: {errors: {}, status: null}
};

export default function reducer(state = {
  collection: [],
  isCollectionLoaded: false,
  isLoading: false,
  groupId: "",
  loadingCount: 0,
  weekStartsAt: null,
  order: USER_ACTIVITIES_ORDERS.WEEK_TOTAL,
  inputWeekStartsAt: null,
  statuses: DEFAULT_STATUSES
}, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_USER_ACTIVITIES_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);
      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.CHANGE_USER_ACTIVITIES_WEEK: {
      const inputWeekStartsAt = moment(action.weekStartsAt);
      return {...state, inputWeekStartsAt};
    }

    case ACTIONS.FETCH_USER_ACTIVITIES: {
      return {
        ...state,
        collection: [],
        groupId: "",
        weekStartsAt: moment(action.weekStartsAt),
        isCollectionLoaded: false
      };
    }
    case ACTIONS.FETCH_USER_ACTIVITIES_FULFILLED: {
      const weekStartsAtParam = moment(action.weekStartsAt);
      if (state.weekStartsAt.isSame(weekStartsAtParam)) {
        return {...state,
          collection: action.payload.user_activities,
          groupId: action.groupId,
          weekStartsAt: weekStartsAtParam,
          isCollectionLoaded: true,
          statuses: {...state.statuses, [ACTIONS.FETCH_USER_ACTIVITIES]: DEFAULT_SUCCESS_STATUS},
          lastUpdatedAt: action.payload.last_updated_at,
        };
      }
      return state;
    }
    case ACTIONS.REFRESH_USER_ACTIVITIES: {
      return {
        ...state
      };
    }
    case ACTIONS.FETCH_USER_ACTIVITIES_REJECTED: {
      return {
        ...state,
        statuses: {...state.statuses, [ACTIONS.FETCH_USER_ACTIVITIES]: {errors: action.errors, status: action.status}}
      };
    }

    case GROUP_ACTIONS.FETCH_GROUP_FULFILLED: {
      const weekStartsAt = moment(action.group.week_starts_at);
      return {...state, inputWeekStartsAt: weekStartsAt};
    }

    case GROUP_ACTIONS.UPDATE_GROUP_FULFILLED: {
      const weekStartsAt = moment(action.group.week_starts_at);
      return {...state, inputWeekStartsAt: weekStartsAt};
    }

    case ACTIONS.CHANGE_USER_ACTIVITIES_ORDER: {
      return {...state, order: action.order};
    }
  }
  return state;
}
