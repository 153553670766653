import React, {Component} from "react";
import PropTypes from "prop-types";

class DateInput extends Component {
  render() {
    return (
      <button type="button" className="form-date" onClick={this.props.onClick}>
        {this.props.value}
      </button>
    );
  }
}

DateInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func
};

export default DateInput;
