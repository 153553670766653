import React, {Fragment} from "react";
import classnames from "classnames";
import _ from "lodash";

import KeyPoints from "./KeyPoints";
import WrappedText from "./WrappedText";

const LineText = ({isUserLine, line}) => {
  const keyPointsValueResult = _.find(line.results, {metric: "key_points_value"});
  const isKeyPointsPassed = keyPointsValueResult && keyPointsValueResult.passed;

  if (!isUserLine) return line.text;

  return (
    <Fragment>
      <div className={classnames({"scenario-review-lines__failure": !isKeyPointsPassed})}>
        <WrappedText text={line.user_text} type="warning" words={line.words} />
      </div>
      <div className="scenario-review-lines__translation">
        <span className="scenario-review-lines__text-label">
          {I18n.t("dashboard.scenario_review.translation_label")}
        </span>
        {line.translation}
      </div>
      <div className="scenario-review-lines__translation">
        <span className="scenario-review-lines__text-label">
          {I18n.t("dashboard.scenario_review.text_label")}
        </span>
        {line.filtered_text}
      </div>
    </Fragment>
  );
};

const TranslatingColumns = ({isListeningSummaryLine, isUserLine, line, metric}) => (
  <Fragment>
    <td>
      <LineText isUserLine={isUserLine} line={line} />
    </td>
    <td>
      <KeyPoints
        isListeningSummaryLine={isListeningSummaryLine}
        isUserLine={isUserLine}
        line={line}
        metric={metric}
      />
    </td>
  </Fragment>
);

export default TranslatingColumns;
