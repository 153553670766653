import {PunditPolicy} from "react-pundit";

import {isManager} from "../helpers/userRolesHelper";

class CompanyPolicy extends PunditPolicy {
  constructor() {
    super("Company");
  }

  CreateUser(model, user) {
    return isManager(user) && model.users_count < model.max_users_count;
  }
}

export default CompanyPolicy;
