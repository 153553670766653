import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Select2 from "react-select2-wrapper";
import _ from "lodash";

import handleFieldChange from "../../../helpers/handleFieldChange";

import DefaultModal from "../modals/DefaultModal";
import {closeModal} from "../../../store/actions/modalsActions";

const MODAL_NAME = "USER_SCENARIO_FREE_SPEECH_SETTINGS_MODAL";

// TODO: Component is not used
class FreeSpeechSettingsModal extends Component {
  get line() {
    return _.find(this.props.formAttributes.lines, {id: this.props.lineId});
  };

  get lineIndex() {
    return _.findIndex(this.props.formAttributes.lines, {id: this.props.lineId});
  };

  get modalName() {
    return `${MODAL_NAME}_${this.props.lineId}`;
  };

  get lineTypeSelect2Data() {
    // return enumOptionsForSelect("line", "type", {only: FREE_SPEECH_TYPES});
  }

  setDefaultTemporaryType = () => {
    const line = this.line;
    const attributes = this.props.formAttributes;
    const changedLines = _.clone(attributes.lines);
    changedLines[this.lineIndex] = {...line, temporaryType: line.type};
    this.props.assignUserScenarioAttributes({...attributes, lines: changedLines});
  };

  onChange = (event) => {
    const attributes = this.props.formAttributes;
    const changedAttributes = _.extend({}, attributes, handleFieldChange(event, attributes));
    this.props.assignUserScenarioAttributes(changedAttributes);
  };

  onSubmit = () => {
    const line = this.line;
    if (!line.temporaryType) {
      this.closeModal();
      return;
    }
    const attributes = this.props.formAttributes;
    const changedLines = _.clone(attributes.lines);
    changedLines[this.lineIndex] = {...line, type: line.temporaryType, temporaryType: null};
    this.props.assignUserScenarioAttributes({...attributes, lines: changedLines});
    this.closeModal();
  };

  closeModal() {
    this.props.closeModal(this.modalName);
  };

  render() {
    const {line, lineIndex} = this;

    const contentStyle = {
      minHeight: "200px"
    };

    return (
      <DefaultModal modalName={this.modalName}
                    modalTitle={I18n.t('user_scenarios.free_speech_settings_popup.title')}
                    buttonClass="button button_color_grey scenario-line__type-button"
                    buttonText={I18n.t(`mongoid.attributes.line.types.${line.type}`)}
                    contentStyle={contentStyle}
                    onOpen={this.setDefaultTemporaryType}>
        <div className="bem-popup__content">
          <div className="row no-gutters justify-content-center">
            <div className="col-md-7 col-sm-8 col-12">
              <div className="form-group form-group_size_sm">
                <label className="form-group__label form-group__label_width_sm text-weight-normal">
                  {I18n.t("mongoid.attributes.line.type")}
                </label>
                <Select2
                  name={`lines.${lineIndex}.temporaryType`}
                  value={line.temporaryType}
                  onChange={this.onChange}
                  className="select2-in select2_size_sm form-group__input"
                  data={this.lineTypeSelect2Data}
                  options={{
                    minimumResultsForSearch: -1,
                    theme: "size--sm"
                  }}
                />
              </div>
              <div className="col-12 mt-5 text-center">
                <button className="button" onClick={this.onSubmit}>{I18n.t("save")}</button>
              </div>
            </div>
          </div>
        </div>
      </DefaultModal>
    );
  }
}

FreeSpeechSettingsModal.propTypes = {
  lineId: PropTypes.string.isRequired,
  formAttributes: PropTypes.object.isRequired,
  assignUserScenarioAttributes: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

const mapActionsToProps = {
  closeModal
};

export default connect(null, mapActionsToProps)(FreeSpeechSettingsModal);
