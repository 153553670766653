import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {notify} from "reapop";

import {profilePath} from "../../helpers/routesHelper";
import {isAdmin} from "../../helpers/userRolesHelper";

import {assignProfileAttributes, updateProfile} from "../../store/actions/profilesActions";

import ApplicationLayout from "../layouts/ApplicationLayout";
import ProfileForm from "./_Form";

class Edit extends Component {
  componentDidMount() {
    this.assignProfileAttributes(true);
  }

  componentDidUpdate() {
    this.assignProfileAttributes(false);
  }

  assignProfileAttributes = (isComponentMount) => {
    const {profiles, assignProfileAttributes} = this.props;
    const user = profiles.current;
    const {formAttributes} = profiles;

    if (isComponentMount || !formAttributes.id) {
      assignProfileAttributes({...user, password: "", password_confirmation: "", errors: {}});
    }
  };

  onSubmit = (attributes) => {
    this.props.updateProfile(attributes, this.props.history);
  };

  notifyNotSaved = () => {
    this.props.notify({
      title: I18n.t("notifications.titles.error"),
      message: I18n.t("profiles.messages.update.error"),
      status: "error"
    });
  };

  render() {
    const {profiles, companies, assignProfileAttributes} = this.props;
    const company = companies.current;
    const profile = profiles.current;

    const isLoaded = profile.id && (isAdmin(profile) || company.id);
    const isLoading = profiles.isLoading || companies.isLoading;

    return (
      <ApplicationLayout headerTitle={I18n.t("profiles.titles.edit")} isLoading={!isLoaded || isLoading}>
        {
          isLoaded &&
          <div className="container">
            <div className="row py-4">
              <div className="col-12 col-md-10 col-lg-7 col-xl-6 mx-auto">
                <div className="profile-section px-3 px-md-0">
                  <ProfileForm
                    assignProfileAttributes={assignProfileAttributes}
                    formAttributes={profiles.formAttributes}
                    company={company}
                    backPath={profilePath()}
                    onSubmit={this.onSubmit}
                    notifyNotSaved={this.notifyNotSaved}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      </ApplicationLayout>
    )
  }
}

Edit.propTypes = {
  profiles: PropTypes.object.isRequired,
  companies: PropTypes.object.isRequired,
  assignProfileAttributes: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    profiles: state.profiles,
    companies: state.companies
  }
}

const mapActionsToProps = {
  assignProfileAttributes,
  updateProfile,
  notify
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Edit));
