import {ACTIONS} from "../reducers/packSharesReducer";

import webToolApi from "../../helpers/webToolApiHelper";

import {notifyError, notifyResponseError, notifySuccess} from "./notificationsActions";
import {defaultErrorAction} from "./errorsActions";

export function changePackSharesLoading(isLoading) {
  return {type: ACTIONS.CHANGE_PACK_SHARES_LOADING, isLoading};
}

export function fetchPackShares() {
  return (dispatch) => {
    dispatch(changePackSharesLoading(true));
    dispatch({type: ACTIONS.FETCH_PACK_SHARES});

    webToolApi().get(Routes.pack_shares_path())
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_PACK_SHARES_FULFILLED, payload: response.data});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_PACK_SHARES_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("pack_shares.messages.index.error"), response));
      })
      .finally(() => {
        dispatch(changePackSharesLoading(false));
      });
  }
}

export function createPackShare(packShare, username, {onSuccess} = {}) {
  return (dispatch) => {
    dispatch(changePackSharesLoading(true));

    webToolApi().post(Routes.pack_shares_path(), {pack_share: packShare})
      .then(() => {
        dispatch({type: ACTIONS.CREATE_PACK_SHARE_FULFILLED});
        dispatch(notifySuccess(I18n.t("pack_shares.messages.create.success", {name: username})));
        dispatch(fetchPackShares());
        dispatch(changePackSharesLoading(false));
        onSuccess && onSuccess();
      })
      .catch(({response}) => {
        dispatch(notifyResponseError(I18n.t("pack_shares.messages.create.error"), response));
        dispatch(changePackSharesLoading(false));
      });
  }
}

export function confirmPackShare(packShare, company, {onSuccess, onError} = {}) {
  return (dispatch) => {
    dispatch(changePackSharesLoading(true));

    webToolApi().post(Routes.pack_share_confirmation_path(packShare.id))
      .then((response) => {
        const pack = response.data.pack;
        dispatch({type: ACTIONS.CONFIRM_PACK_SHARE_FULFILLED});
        dispatch(notifySuccess(I18n.t("pack_shares.messages.confirm.success", {pack: pack.title})));
        dispatch(changePackSharesLoading(false));
        onSuccess && onSuccess();
      })
      .catch(({response}) => {
        if (response && response.status === 404) {
          dispatch(fetchPackShares());
          dispatch(notifyError(I18n.t("pack_shares.messages.confirm.not_found", {company: company.title})));
        } else {
          dispatch(notifyResponseError(I18n.t("pack_shares.messages.confirm.error"), response));
        }
        dispatch(changePackSharesLoading(false));
        onError && onError();
      });
  }
}

export function destroyPackShare(packShareIds, username, {onError} = {}) {
  return (dispatch) => {
    dispatch(changePackSharesLoading(true));

    webToolApi().delete(Routes.pack_shares_path(), {data: {pack_share_ids: packShareIds}})
      .then(() => {
        dispatch({type: ACTIONS.DESTROY_PACK_SHARE_FULFILLED});
        dispatch(notifySuccess(I18n.t("pack_shares.messages.destroy.success", {name: username})));
        dispatch(fetchPackShares());
        dispatch(changePackSharesLoading(false));
      })
      .catch(({response}) => {
        if (response && response.status === 404) {
          dispatch(fetchPackShares());
          dispatch(notifyError(I18n.t("pack_shares.messages.destroy.not_found")));
        } else {
          dispatch(notifyResponseError(I18n.t("pack_shares.messages.destroy.error"), response));
        }
        dispatch(changePackSharesLoading(false));
        onError && onError();
      })
  }
}
