import {SUCCESS_STATUS_CODE} from "../../constants";

export const ACTIONS = {
  CHANGE_DASHBOARD_USERS_LOADING: "CHANGE_DASHBOARD_USERS_LOADING",
  FETCH_DASHBOARD_USER: "FETCH_DASHBOARD_USER",
  FETCH_DASHBOARD_USER_FULFILLED: "FETCH_DASHBOARD_USER_FULFILLED",
  FETCH_DASHBOARD_USER_REJECTED: "FETCH_DASHBOARD_USER_REJECTED",
  ORDER_COURSES: "ORDER_COURSES",
  ORDER_COURSES_FULFILLED: "ORDER_COURSES_FULFILLED",
  ORDER_COURSES_REJECTED: "ORDER_COURSES_REJECTED"
};

const DEFAULT_SUCCESS_STATUS = {errors: {}, status: SUCCESS_STATUS_CODE};
const DEFAULT_STATUSES = {
  [ACTIONS.FETCH_DASHBOARD_USER]: {errors: {}, status: null}
};

export default function reducer(
  state = {
    current: {},
    groupId: "",
    isLoading: false,
    loadingCount: 0,
    statuses: DEFAULT_STATUSES
  },
  action
) {
  switch (action.type) {
    case ACTIONS.CHANGE_DASHBOARD_USERS_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);

      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.FETCH_DASHBOARD_USER: {
      return {...state, current: {}};
    }
    case ACTIONS.FETCH_DASHBOARD_USER_FULFILLED: {
      return {
        ...state,
        current: action.user,
        groupId: action.groupId,
        statuses: {...state.statuses, [ACTIONS.FETCH_DASHBOARD_USER]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_DASHBOARD_USER_REJECTED: {
      return {
        ...state,
        statuses: {
          ...state.statuses,
          [ACTIONS.FETCH_DASHBOARD_USER]: {errors: action.errors, status: action.status}
        }
      };
    }

    case ACTIONS.ORDER_COURSES: {
      if (state.current.id === action.userId) {
        return {...state, current: {...state.current, courses: action.courses}};
      }

      return state;
    }

    case ACTIONS.ORDER_COURSES_FULFILLED: {
      if (state.current.id === action.userId) {
        return {...state, current: {...state.current, courses: action.courses}};
      }

      return state;
    }

    default: {
      return state;
    }
  }
}
