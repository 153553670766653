import React from "react";
import classnames from "classnames";

import {modeToBemModifier} from "../../../../helpers/modesHelper";

const Submode = ({submode}) => {
  if (!submode) return null;

  return (
    <div className="scenario-review-header__submode">
      {I18n.t(`dashboard.submodes.${submode}`)}
    </div>
  );
};

const ScenarioMode = ({completedScenario}) => {
  const {mode, submode} = completedScenario;
  const modeClass = `scenario-review-header__mode--${modeToBemModifier(mode)}`;

  return (
    <div className={classnames("scenario-review-header__mode", modeClass)}>
      {I18n.t("dashboard.scenario_review.mode", {title: mode.title})}
      <Submode submode={submode} />
    </div>
  );
};

export default ScenarioMode;
