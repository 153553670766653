import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import classnames from "classnames";

import {adminRootPath} from "../../helpers/routesHelper";
import {isAdmin, isManager, isStudent} from "../../helpers/userRolesHelper";

import {closeDropdownMenu, openDropdownMenu} from "../../store/actions/dropdownMenuActions";
import {destroySession} from "../../store/actions/sessionsActions";
import {Link} from "react-router-dom";
import {rootPath, profilePath} from "../../helpers/routesHelper";
import DorpDownImgUrl from "../../images/logo_header.svg";

class DropdownMenu extends Component {
  toggleStatus = () => {
    this.status === "opened" ? this.props.closeDropdownMenu() : this.props.openDropdownMenu();
  };

  onBlur = () => {
    this.props.closeDropdownMenu();
  };

  onSignOut = (event) => {
    event.preventDefault();

    const {history, closeDropdownMenu, destroySession} = this.props;
    closeDropdownMenu();
    destroySession(history);
  };

  get dropdownMenuStatusClass() {
    return this.status === "opened" ? "dropdown-menu_open" : "";
  }

  get status() {
    return this.props.dropdownMenu.status;
  }

  render() {
    const {currentUser, closeDropdownMenu} = this.props;

    return (
      <div className={classnames("dropdown-menu", this.dropdownMenuStatusClass)}>
        <div className="d-flex align-items-center" onClick={this.toggleStatus} onBlur={this.onBlur} tabIndex={0}>
          <img src={DorpDownImgUrl} alt=""/>
          <div className="dropdown-menu__icon">
          </div>
        </div>
        <div className="dropdown-menu__wrapper p-0 align-items-start text-left">
          {
            isAdmin(currentUser) &&
            <a href={adminRootPath()} className="dropdown-menu__item font-weight-bold">
              {I18n.t("main_menu.navigation_panel.admin_panel")}
            </a>
          }
          {
            (isManager(currentUser) || isStudent(currentUser)) &&
            <Link to={rootPath()} className="dropdown-menu__item font-weight-bold">
              {I18n.t("header.drop_down.menu")}
            </Link>
          }
          {
            (isManager(currentUser) || isStudent(currentUser)) &&
            <Link to={profilePath()} className="dropdown-menu__item font-weight-bold">
              {I18n.t("header.drop_down.manage_account")}
            </Link>
          }
          <a href="#" className="dropdown-menu__item font-weight-bold" onMouseDown={this.onSignOut}>
            {I18n.t("log_out")}
          </a>
        </div>
      </div>
    )
  }
}

DropdownMenu.propTypes = {
  currentUser: PropTypes.object.isRequired,
  dropdownMenu: PropTypes.object.isRequired,
  openDropdownMenu: PropTypes.func.isRequired,
  closeDropdownMenu: PropTypes.func.isRequired,
  destroySession: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    dropdownMenu: state.dropdownMenu
  }
}

const mapActionsToProps = {
  openDropdownMenu,
  closeDropdownMenu,
  destroySession
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(DropdownMenu));
