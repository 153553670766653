import React, {useCallback, useState} from "react";
import classnames from "classnames";
import {connect} from "react-redux";
import _ from "lodash";

import {hideContextMenu, showContextMenu} from "../../../store/actions/contextMenusActions";
import CallbacksContext from "./CallbacksContext";

const ContextMenu = ({children, className, contextMenus, hideContextMenu, showContextMenu}) => {
  const [id] = useState(_.uniqueId);
  const isActive = contextMenus.active.includes(id);

  const handleHideContextMenu = useCallback(() => {
    hideContextMenu(id);
  }, [hideContextMenu, id]);

  const handleShowContextMenu = useCallback(() => {
    showContextMenu(id);
  }, [id, showContextMenu]);

  return (
    <div
      className={classnames("context-menu", className, {"context-menu__active": isActive})}
      onMouseEnter={handleShowContextMenu}
      onMouseLeave={handleHideContextMenu}
    >
      <div className="context-menu__wrapper">
        <div className="context-menu__items">
          <CallbacksContext.Provider value={{hideContextMenu: handleHideContextMenu}}>
            {children}
          </CallbacksContext.Provider>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    contextMenus: state.contextMenus
  };
}

const mapActionsToProps = {
  hideContextMenu,
  showContextMenu
};

export default connect(mapStateToProps, mapActionsToProps)(ContextMenu);
