import React, {Component} from "react"
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";

import {isFetchTagError} from "../../helpers/errorsHelpers";

import ApplicationLayout from "../layouts/ApplicationLayout";
import Cover from '../../components/shared/_Cover';
import FetchTag from "../../components/data_loaders/FetchTag";
import TopPanel from "./_TopPanelShow";

class Show extends Component {
  render() {
    const {id, tags} = this.props;
    const tag = tags.current;

    const isLoaded = tag.id;
    const isLoadingError = isFetchTagError(tags);
    const isLoading = (!isLoaded && !isLoadingError) || tags.isLoading;

    return (
      <ApplicationLayout headerTitle={I18n.t("tags.titles.show")} isLoading={isLoading}>
        <FetchTag id={id}/>
        {
          isLoaded &&
          <div className="container">
            <div className="row py-4">
              <div className="col-12">
                <TopPanel tag={tag}/>

                <div className="course-image-wrapper mt-3">
                  <Cover formAttributes={tag} theme="course-image"/>
                </div>
              </div>
            </div>
          </div>
        }
      </ApplicationLayout>
    )
  }
}

Show.propTypes = {
  id: PropTypes.string.isRequired,
  tags: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    tags: state.tags
  }
}

export default connect(mapStateToProps)(Show);
