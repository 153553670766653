import {ACTIONS} from "../reducers/packCategoriesReducer";

import webToolApi from "../../helpers/webToolApiHelper";

import {defaultErrorAction} from "./errorsActions";
import {notifyResponseError} from "./notificationsActions";

export function changePackCategoriesLoading(isLoading) {
  return {type: ACTIONS.CHANGE_PACK_CATEGORIES_LOADING, isLoading};
}

export function fetchPackCategories() {
  return (dispatch) => {
    dispatch(changePackCategoriesLoading(true));
    dispatch({type: ACTIONS.FETCH_PACK_CATEGORIES});

    webToolApi().get(Routes.pack_categories_path())
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_PACK_CATEGORIES_FULFILLED, payload: response.data});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_PACK_CATEGORIES_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("packs.categories.messages.index.error"), response));
      })
      .finally(() => {
        dispatch(changePackCategoriesLoading(false));
      });
  }
}

export function fetchPackCategory(id, packCategories = []) {
  return (dispatch) => {
    const foundPackCategory = _.find(packCategories, {id});
    if (foundPackCategory) {
      return dispatch({type: ACTIONS.FETCH_PACK_CATEGORY_FULFILLED, packCategory: foundPackCategory});
    }

    dispatch(changePackCategoriesLoading(true));
    dispatch({type: ACTIONS.FETCH_PACK_CATEGORY});

    webToolApi().get(Routes.pack_category_path(id))
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_PACK_CATEGORY_FULFILLED, packCategory: response.data.pack_category});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_PACK_CATEGORY_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("packs.categories.messages.show.error"), response));
      })
      .finally(() => {
        dispatch(changePackCategoriesLoading(false));
      });
  }
}
