import pluralize from "pluralize";
import _ from "lodash";

export function enumOptionsForSelect(modelName, attribute, {only} = {}) {
  const options = only || Object.keys(I18n.t(`mongoid.attributes.${modelName}.${pluralize(attribute)}`));
  return _.map(options, (key) => new Object({id: key, text: enumI18n(modelName, attribute, key)}));
}

export function enumI18n(modelName, attribute, value) {
  return I18n.t(`mongoid.attributes.${modelName}.${pluralize(attribute)}.${value}`);
}
