import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";
import {closeModal, openModal} from "../../store/actions/modalsActions";
import {assignTagAttributes, destroyTag} from "../../store/actions/tagsActions";
import {notify} from "reapop";

import ConfirmationModal from "../../components/shared/modals/ConfirmationModal";
import ContextMenuItem from "../../components/shared/context_menu/ContextMenuItem";

const MODAL_NAME = "DELETE_TAG_MODAL";

class DeleteTagModal extends Component {
  get modalName() {
    return `${MODAL_NAME}_${this.props.tagId}`;
  };

  onSubmit = (closeModal) => {
    this.props.destroyTag(this.props.tagId);
    closeModal();
  };

  get button() {
    return (
      <ContextMenuItem color="red" hideContextMenu={this.props.hideContextMenu}>
        {I18n.t("delete")}
      </ContextMenuItem>
    );
  }

  render() {
    return (
      <ConfirmationModal
        modalName={this.modalName}
        button={this.button}
        message={"Delete tag with id: "+this.props.tagId+this.props.formAttributes}
        onSubmit={this.onSubmit}
      />
    );
  }
}

DeleteTagModal.propTypes = {
  tagId: PropTypes.string.isRequired,
  formAttributes: PropTypes.object.isRequired,
  assignTagAttributes: PropTypes.func.isRequired,
  hideContextMenu: PropTypes.func // Injected by the ContextMenu component
};

const mapActionsToProps = {
  destroyTag
}

export default connect(null, mapActionsToProps)(DeleteTagModal);
