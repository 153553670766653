import React, {useCallback, useContext} from "react";
import classnames from "classnames";
import {Link, withRouter} from "react-router-dom";
import CallbacksContext from "./CallbacksContext";

const ContextMenuItem = ({
  children,
  onClick = () => ({}),
  href,
  linkTo,
  buttonHrefTo,
  buttonLinkTo,
  color,
  history
}) => {
  const {hideContextMenu} = useContext(CallbacksContext);

  const handleClick = useCallback(
    event => {
      hideContextMenu();
      onClick(event);
    },
    [hideContextMenu, onClick]
  );

  const handleButtonLinkClick = useCallback(
    event => {
      event.preventDefault();
      handleClick(event);
      history.push(buttonLinkTo);
    },
    [buttonLinkTo, handleClick, history]
  );

  const handleButtonHrefClick = useCallback(
    event => {
      event.preventDefault();
      handleClick(event);
      window.location = buttonHrefTo;
    },
    [buttonHrefTo, handleClick]
  );

  const className = classnames("context-menu__item", {
    "context-menu__item_color_red": color === "red"
  });

  if (href) {
    return (
      <a className={className} href={href} onClick={handleClick}>
        {children}
      </a>
    );
  }

  if (linkTo) {
    return (
      <Link className={className} onClick={handleClick} to={linkTo}>
        {children}
      </Link>
    );
  }

  if (buttonHrefTo) {
    return (
      <button className={className} onClick={handleButtonHrefClick}>
        {children}
      </button>
    );
  }

  if (buttonLinkTo) {
    return (
      <button className={className} onClick={handleButtonLinkClick}>
        {children}
      </button>
    );
  }

  return (
    <div className={className} onClick={handleClick}>
      {children}
    </div>
  );
};

export default withRouter(ContextMenuItem);
