import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {createGroup} from "store/actions/groupsActions";
import {groupPath} from "helpers/routesHelper";
import classnames from "classnames";

import {Link, withRouter} from "react-router-dom";

class Sidebar extends Component {
  createGroup = () => {
    this.props.createGroup(this.props.history);
  };

  render() {
    const {groups, groupId} = this.props;

    return (
      <div className="sidebar">
        <div className="sidebar__group-actions">
          <div className="sidebar__group-label">{I18n.t("mongoid.models.group.other")}</div>
          <button
            aria-label="Create group"
            className="sidebar__group-add"
            onClick={this.createGroup}
          />
        </div>
        {groups.collection.map(group => (
          <Fragment key={group.id}>
            <Link
              className={classnames("sidebar__category", {
                "sidebar__category--active": group.id === groupId
              })}
              to={groupPath(group.id)}
            >
              {group.title} ({group.users_count})
            </Link>
          </Fragment>
        ))}
      </div>
    );
  }
}

Sidebar.propTypes = {
  createGroup: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  groups: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapActionsToProps = {
  createGroup
};

export default connect(null, mapActionsToProps)(withRouter(Sidebar));
