import {SUCCESS_STATUS_CODE} from "../../constants";

export const ACTIONS = {
  CHANGE_USER_SCENARIO_HEADERS_LOADING: "CHANGE_USER_SCENARIO_HEADERS_LOADING",
  FETCH_USER_SCENARIO_HEADER: "FETCH_USER_SCENARIO_HEADER",
  FETCH_USER_SCENARIO_HEADER_FULFILLED: "FETCH_USER_SCENARIO_HEADER_FULFILLED",
  FETCH_USER_SCENARIO_HEADER_REJECTED: "FETCH_USER_SCENARIO_HEADER_REJECTED"
};

const DEFAULT_SUCCESS_STATUS = {errors: {}, status: SUCCESS_STATUS_CODE};
const DEFAULT_STATUSES = {
  [ACTIONS.FETCH_USER_SCENARIO_HEADER]: {errors: {}, status: null}
};

export default function reducer(state = {
  current: {},
  isLoading: false,
  loadingCount: 0,
  statuses: DEFAULT_STATUSES
}, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_USER_SCENARIO_HEADERS_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);
      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.FETCH_USER_SCENARIO_HEADER: {
      return {...state, current: {}};
    }
    case ACTIONS.FETCH_USER_SCENARIO_HEADER_FULFILLED: {
      return {
        ...state,
        current: action.userScenario,
        statuses: {...state.statuses, [ACTIONS.FETCH_USER_SCENARIO_HEADER]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_USER_SCENARIO_HEADER_REJECTED: {
      return {
        ...state,
        statuses: {
          ...state.statuses, [ACTIONS.FETCH_USER_SCENARIO_HEADER]: {errors: action.errors, status: action.status}
        }
      };
    }
  }
  return state;
}
