import {sortUsersByName, sortUsersByStatus} from "helpers/sortUsersHelper";
import _ from "lodash";

import {USER_ACTIVITIES_ORDERS} from "../../../../constants";

export function courseProgress(userActivity) {
  return Math.floor(_.meanBy(userActivity.courses, "progress"));
}

export function sortActivities(userActivities) {
  const unsortedActivities = userActivities.collection;
  const {order} = userActivities;

  switch (order) {
    case USER_ACTIVITIES_ORDERS.COURSE_PROGRESS: {
      let preSortedActivities = sortUsersByName(unsortedActivities);

      preSortedActivities = _.sortBy(
        preSortedActivities,
        userActivity => -courseProgress(userActivity) || 0
      );

      return sortUsersByStatus(preSortedActivities);
    }

    case USER_ACTIVITIES_ORDERS.USER_NAME: {
      let preSortedActivities = _.sortBy(
        unsortedActivities,
        userActivity => -userActivity.activity_durations.week_total
      );

      preSortedActivities = sortUsersByName(preSortedActivities);

      return sortUsersByStatus(preSortedActivities);
    }

    default: {
      let preSortedActivities = sortUsersByName(unsortedActivities);
      const sign = order === USER_ACTIVITIES_ORDERS.TIER ? 1 : -1;

      preSortedActivities = _.sortBy(
        preSortedActivities,
        userActivity => sign * userActivity.activity_durations[order]
      );

      return sortUsersByStatus(preSortedActivities);
    }
  }
}
