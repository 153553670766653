import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchGroupsError} from "../../helpers/errorsHelpers";

import {fetchGroups} from "../../store/actions/groupsActions";

class FetchGroups extends Component {
  componentDidMount() {
    this.fetchGroups(true);
  }

  componentDidUpdate() {
    this.fetchGroups(false);
  }

  fetchGroups(isComponentMount) {
    const {groups} = this.props;
    const isReasonToFetch = isComponentMount || !groups.isCollectionLoaded;

    if (isReasonToFetch && !groups.isLoading && !isFetchGroupsError(groups)) {
      this.props.fetchGroups();
    }
  }

  render() {
    return null;
  }
}

FetchGroups.propTypes = {
  groups: PropTypes.object.isRequired,
  fetchGroups: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    groups: state.groups
  }
}

const mapActionsToProps = {
  fetchGroups
};

export default connect(mapStateToProps, mapActionsToProps)(FetchGroups);
