import {ACTIONS} from "../reducers/audioPlayersReducer";

export function registerAudioPlayer(id) {
  return (dispatch) => {
    const player = new OGVPlayer();

    dispatch({type: ACTIONS.REGISTER_AUDIO_PLAYER, id, player});

    player.ontimeupdate = function () {
      dispatch({type: ACTIONS.UPDATE_AUDIO_CURRENT_TIME, id, currentTime: player.currentTime});
    };
    player.onended = function () {
      dispatch({type: ACTIONS.UPDATE_AUDIO_CURRENT_TIME, id, currentTime: player.currentTime});
    };
    player.onloadedmetadata = function () {
      dispatch({type: ACTIONS.UPDATE_AUDIO_DURATION, id, duration: player.duration});
    };
  };
}

export function playAudio(id, url) {
  return {type: ACTIONS.PLAY_AUDIO, id, url};
}

export function pauseAudio(id) {
  return {type: ACTIONS.PAUSE_AUDIO, id};
}

export function moveAudioPlayHead(id, percentage) {
  return {type: ACTIONS.MOVE_AUDIO_PLAY_HEAD, id, percentage};
}

export function finishMovingAudioPlayHead(id, percentage) {
  return {type: ACTIONS.FINISH_MOVING_AUDIO_PLAY_HEAD, id, percentage};
}
