import React from "react";

import SvgIcon from "components/SvgIcon";

const StatusIcon = ({mode, scenario}) => {
  const {status} = scenario.modes.find(x => x.identification === mode);

  if (status === "optional") return null;

  return <SvgIcon icon={`mode-state-${status}`} />;
};

export default StatusIcon;
