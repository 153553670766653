import {PunditPolicy} from "react-pundit";

import {isAdmin, isManager, isStudent} from "../helpers/userRolesHelper";

class PackPolicy extends PunditPolicy {
  constructor() {
    super("Pack");
  }

  Update(model, user) {
    return model.user_id === user.id;
  }

  CreateGeneral(model, user) {
    return isAdmin(user);
  }

  CreateCompany(model, user) {
    return isManager(user);
  }

  CreateOwn(model, user) {
    return isStudent(user);
  }

  Destroy(model, user) {
    return model.user_id === user.id && !model.is_default;
  }
}

export default PackPolicy;
