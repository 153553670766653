import React, {Component} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import {REMOVING_STATES} from "../../constants";

class DeleteListItem extends Component {
  onCancel = (event) => {
    event && event.preventDefault();

    const {objectWithState, setRemovingState} = this.props;
    setRemovingState(objectWithState.id, REMOVING_STATES.HIDING);
    setTimeout(() => setRemovingState(objectWithState.id, REMOVING_STATES.DEFAULT), 500);
  };

  onSubmit = (event) => {
    event && event.preventDefault();
    this.props.onDestroy();
  };

  get isHiding() {
    return this.props.objectWithState.removingState === REMOVING_STATES.HIDING;
  }

  get isDefault() {
    const {objectWithState} = this.props;
    return !objectWithState.removingState || objectWithState.removingState === REMOVING_STATES.DEFAULT;
  }

  render() {
    if (this.isDefault) {
      return null;
    }

    return (
      <div className={classnames("list-item-delete", {"list-item-delete_hide": this.isHiding})}>
        <div className="list-item-delete__body">
          <div className="list-item-delete__headline">
            <div className="list-item-delete__icon"/>
            {I18n.t("delete")}
          </div>
          <div className="list-item-delete__choice">
            <button className="list-item-delete__button" onClick={this.onSubmit}>
              {I18n.t("yes")}
            </button>
            /
            <button className="list-item-delete__button" onClick={this.onCancel}>
              {I18n.t("no")}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

DeleteListItem.propTypes = {
  objectWithState: PropTypes.object.isRequired,
  setRemovingState: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired
};

export default DeleteListItem;
