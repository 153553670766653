import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {closeModal} from "../../../store/actions/modalsActions";

import BaseModal from "./BaseModal";

class DefaultModal extends Component {
  closeModal = (event) => {
    event && event.preventDefault();
    this.props.onClose && this.props.onClose();
    this.props.closeModal(this.props.modalName);
  };

  render() {
    const {modalTitle, onBack} = this.props;

    return (
      <BaseModal modalName={this.props.modalName}
                 isRenderButton={this.props.isRenderButton}
                 button={this.props.button}
                 buttonClass={this.props.buttonClass}
                 buttonText={this.props.buttonText}
                 buttonDisabled={this.props.buttonDisabled}
                 buttonTitle={this.props.buttonTitle}
                 contentStyle={this.props.contentStyle}
                 onOpen={this.props.onOpen}
                 onClose={this.props.onClose}>
        <div className="bem-popup__header">
          <div className="bem-popup__title">{modalTitle}</div>
          {
            onBack &&
            <button
              className="button bem-popup__button-header bem-popup__button-header_left button button_theme_back"
              onClick={onBack}
            />
          }
          <button
            className="bem-popup__button-header bem-popup__button-header_right button button_theme_close"
            onClick={this.closeModal}
          />
        </div>
        {this.props.children}
      </BaseModal>
    );
  }
}

DefaultModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  isRenderButton: PropTypes.bool,
  button: PropTypes.object,
  buttonClass: PropTypes.string,
  buttonText: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  buttonTitle: PropTypes.string,
  contentStyle: PropTypes.object,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  closeModal: PropTypes.func.isRequired
};

const mapActionsToProps = {
  closeModal
};

export default connect(null, mapActionsToProps)(DefaultModal);
