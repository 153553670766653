import {ACTIONS} from "../reducers/userScenarioHeadersReducer";

import webToolApi from "../../helpers/webToolApiHelper";

import {notifyResponseError} from "./notificationsActions";
import {defaultErrorAction} from "./errorsActions";

export function changeUserScenariosLoading(isLoading) {
  return {type: ACTIONS.CHANGE_USER_SCENARIO_HEADERS_LOADING, isLoading};
}

export function fetchUserScenarioHeader(id) {
  return (dispatch) => {
    dispatch(changeUserScenariosLoading(true));
    dispatch({type: ACTIONS.FETCH_USER_SCENARIO_HEADER});

    webToolApi().get(Routes.user_scenario_header_path(id))
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_USER_SCENARIO_HEADER_FULFILLED, userScenario: response.data.user_scenario});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_USER_SCENARIO_HEADER_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("user_scenarios.messages.show.error"), response));
      })
      .finally(() => {
        dispatch(changeUserScenariosLoading(false));
      });
  }
}
