import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchCourseCategoryError} from "../../helpers/errorsHelpers";

import {fetchCourseCategory} from "../../store/actions/courseCategoriesActions";

class FetchCourseCategory extends Component {
  componentDidMount() {
    this.fetchCourseCategory(true);
  }

  componentDidUpdate() {
    this.fetchCourseCategory(false);
  }

  fetchCourseCategory(isComponentMount) {
    const {id, courseCategories} = this.props;
    const isReasonToFetch = isComponentMount || courseCategories.current.id !== id;

    if (isReasonToFetch && !courseCategories.isLoading && !isFetchCourseCategoryError(courseCategories)) {
      this.props.fetchCourseCategory(id, courseCategories.collection);
    }
  }

  render() {
    return null;
  }
}

FetchCourseCategory.propTypes = {
  id: PropTypes.string.isRequired,
  courseCategories: PropTypes.object.isRequired,
  fetchCourseCategory: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    courseCategories: state.courseCategories
  }
}

const mapActionsToProps = {
  fetchCourseCategory
};

export default connect(mapStateToProps, mapActionsToProps)(FetchCourseCategory);
