import {LOADING_STATUSES} from "../constants";

export function isStatusFailed(status) {
  return status === LOADING_STATUSES.FAILED;
}

export function isStatusInitial(status) {
  return status === LOADING_STATUSES.INITIAL;
}

export function isStatusLoading(status) {
  return status === LOADING_STATUSES.LOADING;
}

export function isStatusLoaded(status) {
  return status === LOADING_STATUSES.LOADED;
}
