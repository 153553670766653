import React, {memo} from "react";
import classnames from "classnames";

const Participant = ({avatarUrl, isBold, name}) => {
  return (
    <div className="select_edit_wp">
      <div className="participant-avatar avatar">
        <img alt="avatar" src={avatarUrl} />
      </div>
      <span className={classnames("select_edit_text", {"font-weight-bold": isBold})}>
        {name}
      </span>
    </div>
  );
};

export default memo(Participant);
