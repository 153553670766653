import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Select2 from "react-select2-wrapper";
import TextareaAutosize from "react-autosize-textarea";
import _ from "lodash";

import {isAdmin} from "../../../../helpers/userRolesHelper";
import handleFieldChange from "../../../../helpers/handleFieldChange";

import ApplicationLayout from "../../../../views/layouts/ApplicationLayout";
import Cover from "../../_Cover";
import DialogflowAgents from "./DialogflowAgents";
import Participants from "./Participants";
import PasteLinesButton from "./PasteLinesButton";
import PasteParticipantsButton from "./PasteParticipantsButton";
import ScenarioOwner from "../../ScenarioOwner";
import Slides from "./Slides";
import SortableLineList from "./SortableLineList";

class Form extends Component {
  get environment() {
    const {formAttributes, environments} = this.props;

    return _.find(environments.collection, {id: formAttributes.environment_id});
  }

  get environmentsSelect2Data() {
    const environments = _.sortBy(this.props.environments.collection, "title");

    return _.map(environments, environment => ({
      id: environment.id,
      text: environment.title
    }));
  }

  handleChange = event => {
    const attributes = this.props.formAttributes;
    const changedAttributes = {...attributes, ...handleFieldChange(event, attributes)};
    this.props.assignUserScenarioAttributes(changedAttributes);
  };

  handleEnvironmentChange = event => {
    this.handleChange(event);

    const {formAttributes, assignUserScenarioAttributes} = this.props;
    const {positions} = this.environment;
    const changedParticipants = _.map(formAttributes.participants, participant => {
      if (positions.includes(parseInt(participant.position, 10))) {
        return participant;
      }

      return {...participant, position: positions[0]};
    });
    assignUserScenarioAttributes({...formAttributes, participants: changedParticipants});
  };

  handleSubmit = event => {
    event.preventDefault();

    const attributes = _.omit(this.props.formAttributes, "errors");

    if (this.isValid()) {
      this.props.onSubmit(attributes);
    } else {
      const action = attributes.id ? "update" : "create";
      this.props.notifyError(I18n.t(`user_scenarios.messages.${action}.error`));
    }
  };

  isValid() {
    const attributes = this.props.formAttributes;
    const errors = {};

    _.each(["title", "description"], field => {
      if (!attributes[field].trim()) {
        errors[field] = [I18n.t("errors.messages.blank")];
      }
    });

    attributes.dialogflow_agents.forEach((dialogflowAgent, index) => {
      if (dialogflowAgent.user_participant_id === dialogflowAgent.chatbot_participant_id) {
        errors[`dialogflow_agents.${index}.chatbot_participant_id`] = [
          I18n.t("mongoid.errors.messages.wrong_chatbot_participant")
        ];
      }
    });

    const attributesWithNewErrors = {...attributes, errors};
    this.props.assignUserScenarioAttributes(attributesWithNewErrors);

    return _.isEmpty(errors);
  }

  renderErrors = name => {
    const errors = this.props.formAttributes.errors[name];
    if (_.isEmpty(errors)) return null;

    return <div className="form-group__error">{errors.join(", ")}</div>;
  };

  render() {
    const {
      assignUserScenarioAttributes,
      backPath,
      characters,
      currentUser,
      dialogflowAgents,
      formAttributes: attributes,
      isLoaded,
      isLoading,
      user
    } = this.props;
    const title = attributes.id
      ? I18n.t("user_scenarios.titles.new")
      : I18n.t("user_scenarios.titles.edit");

    return (
      <ApplicationLayout headerTitle={title} isLoading={isLoading}>
        {isLoaded && (
          <div className="container">
            <div className="row py-4">
              <div className="col-12">
                <div className="scenario-edit">
                  <div className="d-flex justify-content-end">
                    <ScenarioOwner avatarUrl={user.avatar.thumb_url} name={user.full_name} />
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-xl-4 col-lg-5">
                          <Cover
                            assignAttributes={assignUserScenarioAttributes}
                            formAttributes={attributes}
                            isUploadable
                            theme="scenario-image"
                          />
                        </div>

                        <div className="col-xl-8 col-lg-7">
                          <div className="transparent-form-group">
                            <label className="transparent-form-group__label" htmlFor="title">
                              {I18n.t("mongoid.attributes.user_scenario.title")}
                            </label>
                            <input
                              className="transparent-form-group__input"
                              id="title"
                              name="title"
                              onChange={this.handleChange}
                              placeholder={I18n.t("user_scenarios.title_placeholder")}
                              type="text"
                              value={attributes.title}
                            />
                            {this.renderErrors("title")}
                          </div>

                          <div className="transparent-form-group">
                            <label className="transparent-form-group__label" htmlFor="description">
                              {I18n.t("mongoid.attributes.user_scenario.description")}
                            </label>
                            <TextareaAutosize
                              className="transparent-form-group__input"
                              data-gramm_editor={false}
                              id="description"
                              name="description"
                              onChange={this.handleChange}
                              placeholder={I18n.t("user_scenarios.description_placeholder")}
                              rows={3}
                              value={attributes.description}
                            />
                            {this.renderErrors("description")}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="transparent-form-group">
                        <label className="transparent-form-group__label" htmlFor="environment_id">
                          {I18n.t("mongoid.attributes.user_scenario.environment")}
                        </label>
                        <div className="row">
                          <div className="col-xl-7 col-lg-8">
                            <Select2
                              className="select2-transparent select2-environment"
                              data={this.environmentsSelect2Data}
                              name="environment_id"
                              onChange={this.handleEnvironmentChange}
                              options={{
                                minimumResultsForSearch: -1,
                                theme: "size--sm"
                              }}
                              value={attributes.environment_id}
                            />
                          </div>
                        </div>
                      </div>

                      {this.environment.image_url && (
                        <img alt="" src={this.environment.image_url} width="200" />
                      )}
                    </div>
                  </div>

                  <div className="transparent-form-group">
                    <label className="align-items-center d-flex transparent-form-group__label">
                      {I18n.t("user_scenarios.roles")}
                      <PasteParticipantsButton
                        assignAttributes={assignUserScenarioAttributes}
                        characters={characters.collection}
                        className="ml-3"
                        formAttributes={attributes}
                        notifyError={this.props.notifyError}
                        positions={this.environment.positions}
                      />
                    </label>
                    <Participants
                      assignUserScenarioAttributes={assignUserScenarioAttributes}
                      characters={characters}
                      formAttributes={attributes}
                      onChange={this.handleChange}
                      positions={this.environment.positions}
                      renderErrors={this.renderErrors}
                    />
                  </div>

                  {isAdmin(currentUser) && (
                    <div className="transparent-form-group">
                      <label className="transparent-form-group__label">
                        {I18n.t("user_scenarios.chatbot")}
                      </label>
                      <DialogflowAgents
                        assignUserScenarioAttributes={assignUserScenarioAttributes}
                        dialogflowAgents={dialogflowAgents}
                        formAttributes={attributes}
                        renderErrors={this.renderErrors}
                      />
                    </div>
                  )}

                  <div className="transparent-form-group">
                    <label className="transparent-form-group__label">
                      {I18n.t("mongoid.attributes.user_scenario.summary")}
                    </label>
                    <input
                        className="transparent-form-group__input"
                        id="summary"
                        name="summary"
                        onChange={this.handleChange}
                        placeholder={I18n.t("user_scenarios.summary_placeholder")}
                        type="text"
                        value={attributes.summary}
                    />
                    {this.renderErrors("summary")}
                  </div>

                  <div className="transparent-form-group">
                    <label className="align-items-center d-flex transparent-form-group__label">
                      {I18n.t("user_scenarios.script")}
                      <PasteLinesButton
                        assignAttributes={assignUserScenarioAttributes}
                        className="ml-3"
                        formAttributes={attributes}
                        notifyError={this.props.notifyError}
                      />
                    </label>
                    <SortableLineList
                      assignUserScenarioAttributes={assignUserScenarioAttributes}
                      formAttributes={attributes}
                    />
                  </div>

                  <div className="transparent-form-group">
                    <label className="transparent-form-group__label">
                      {I18n.t("mongoid.models.scenario_slide.other")}
                    </label>
                    <Slides
                      assignUserScenarioAttributes={assignUserScenarioAttributes}
                      formAttributes={attributes}
                    />
                  </div>

                  <div className="form-actions">
                    <Link className="form-actions__button button button_color_grey" to={backPath}>
                      {I18n.t("btn_back")}
                    </Link>
                    <button
                      className="form-actions__button button"
                      disabled={isLoading}
                      onClick={this.handleSubmit}
                      type="submit"
                    >
                      {attributes.id ? I18n.t("btn_update") : I18n.t("btn_create")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ApplicationLayout>
    );
  }
}

Form.defaultProps = {
  isLoaded: false,
  isLoading: false
};

Form.propTypes = {
  assignUserScenarioAttributes: PropTypes.func.isRequired,
  backPath: PropTypes.string.isRequired,
  characters: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  dialogflowAgents: PropTypes.object.isRequired,
  environments: PropTypes.object.isRequired,
  formAttributes: PropTypes.object.isRequired,
  isLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  notifyError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default Form;
