import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {SortableContainer} from "react-sortable-hoc";
import arrayMove from "array-move";

import SortableScenario from "./SortableScenario";
import AddScenarioModal from "./_AddScenarioModal";

const SortableScenarioListContainer = SortableContainer(({formAttributes, assignCourseAttributes}) => {
  return (
    <tbody>
      {formAttributes.user_scenarios.map((scenario, index) => (
        <SortableScenario
          key={scenario.id}
          index={index}
          scenarioId={scenario.id}
          formAttributes={formAttributes}
          assignCourseAttributes={assignCourseAttributes}
        />)
      )}
    </tbody>
  );
});

class SortableScenarioList extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  onLinesReorderingEnd = ({oldIndex, newIndex}) => {
    const attributes = this.props.formAttributes;
    const changedAttributes = {
      ...attributes,
      user_scenarios: arrayMove(attributes.user_scenarios, oldIndex, newIndex)
    };
    this.props.assignCourseAttributes(changedAttributes);
  };

  render() {
    const attributes = this.props.formAttributes;
    const {assignCourseAttributes} = this.props;

    return (
      <Fragment>

        <table className="course-edit__scenarios-table" ref={this.tableRef}>

          <thead>
          <tr>
            <th/>
            <th>{I18n.t("mongoid.models.scenario.one")}</th>
            <th colSpan={2}/>
          </tr>
          </thead>

          <SortableScenarioListContainer
            onSortEnd={this.onLinesReorderingEnd}
            helperClass="course-scenario--dragging"
            helperContainer={this.tableRef.current}
            scenarios={attributes.user_scenarios}
            formAttributes={attributes}
            assignCourseAttributes={assignCourseAttributes}
            useDragHandle
          />

        </table>

        <div className="text-center mt-4">
          <AddScenarioModal formAttributes={attributes}/>
        </div>

      </Fragment>
    );
  }
}

SortableScenarioList.propTypes = {
  formAttributes: PropTypes.object.isRequired,
  assignCourseAttributes: PropTypes.func.isRequired
};

export default SortableScenarioList;
