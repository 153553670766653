export const SERVICE_UNAVAILABLE_STATUS_CODE = 503;
export const UNAUTHENTICATED_STATUS_CODE = 401;
export const SUCCESS_STATUS_CODE = 200;

export const SHOW_RECAPTCHA_HEADER_KEY = "show-recaptcha";

export const DEFAULT_PARTICIPANT_KIND = "required";

export const DEFAULT_NOTIFICATION_PROPS = {
  allowHTML: true,
  closeButton: false,
  dismissAfter: 3000,
  dismissible: true,
  position: "tr",
  status: "info"
};

export const LEVELS_COUNT = 3;

export const STUDENT_ROLE = "user";
export const MANAGER_ROLE = "manager";
export const ADMIN_ROLE = "admin";

export const SCENARIO_LINE_MAX_SYMBOLS_COUNT = 150;

export const PRIVATE_PACK_VISIBILITY = "private";
export const PUBLIC_PACK_VISIBILITY = "public";
export const LIMITED_PACK_VISIBILITY = "limited";

export const GENERAL_PACK_CATEGORY = "general";
export const COMPANY_PACK_CATEGORY = "company";
export const OWN_PACK_CATEGORY = "own";

export const JAPAN_TIMEZONE = "Japan";

export const MAX_DIALOGFLOW_AGENTS_COUNT = 1;

export const OVERVIEW_WEEKS_COUNT = 6;

export const LOADING_STATUSES = {
  FAILED: "FAILED",
  INITIAL: "INITIAL",
  LOADED: "LOADED",
  LOADING: "LOADING"
};

export const USER_ACTIVITIES_ORDERS = {
  COURSE_PROGRESS: "course_progress",
  TIER: "tier",
  USER_NAME: "user_name",
  WEEKLY_AVERAGE_DAYS: "weekly_average_days",
  WEEKLY_AVERAGE_DURATION: "weekly_average_duration",
  WEEK_TOTAL: "week_total"
};

export const METRIC_MEASURES = {
  attitude_value: "%",
  clarity: "%",
  energy_level_value: "%",
  eye_contact: "%",
  fluency: "%",
  key_points_value: "%",
  language_difficulty: "",
  missions_value: "",
  pronunciation: "%",
  speech_pace: " wpm"
};

export const RAW_SCORE_RANGES = {
  attitude_value: [0, 100],
  clarity: [0, 100],
  energy_level_value: [0, 100],
  eye_contact: [0, 100],
  fluency: [0, 100],
  key_points_value: [0, 100],
  language_difficulty: [1, 10],
  missions_value: [0, 10],
  pronunciation: [0, 100],
  speech_pace: [0, 700]
};

export const REMOVING_STATES = {
  DEFAULT: "DEFAULT",
  HIDING: "HIDING",
  REMOVING: "REMOVING"
};
