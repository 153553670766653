import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import _ from "lodash";
import handleFieldChange from "helpers/handleFieldChange";

import {
  assignPasswordAttributes,
  clearPasswordAttributes,
  updatePassword
} from "store/actions/passwordsActions";

import SvgIcon from "components/SvgIcon";
import UnauthenticatedLayout from "views/layouts/UnauthenticatedLayout";

class Edit extends Component {
  componentDidMount() {
    this.props.clearPasswordAttributes();
  }

  onChange = event => {
    const {passwords, assignPasswordAttributes} = this.props;
    const attributes = passwords.formAttributes;
    assignPasswordAttributes({...attributes, ...handleFieldChange(event, attributes)});
  };

  onSubmit = event => {
    event.preventDefault();

    if (this.isValid) {
      const {passwords, resetPasswordToken, updatePassword, history} = this.props;
      updatePassword(
        {
          ..._.pick(passwords.formAttributes, "password", "password_confirmation"),
          reset_password_token: resetPasswordToken
        },
        history
      );
    }
  };

  get isValid() {
    const attributes = this.props.passwords.formAttributes;
    const errors = {};

    ["password", "password_confirmation"].forEach(field => {
      if (!attributes[field].trim()) {
        errors[field] = [I18n.t("errors.messages.blank")];
      }
    });

    if (attributes.password.trim() && attributes.password !== attributes.password_confirmation) {
      errors.password_confirmation = [
        I18n.t("mongoid.errors.messages.confirmation", {
          attribute: I18n.t("mongoid.attributes.user.password")
        })
      ];
    }

    const attributesWithNewErrors = {...attributes, errors};
    this.props.assignPasswordAttributes(attributesWithNewErrors);

    return _.isEmpty(errors);
  }

  renderErrors = name => {
    const errors = this.props.passwords.formAttributes.errors[name];
    if (_.isEmpty(errors)) return null;

    return <div className="form-group__error text-left">{errors.join(", ")}</div>;
  };

  render() {
    const {email, passwords, username} = this.props;
    const attributes = passwords.formAttributes;

    const {isLoading} = passwords;

    return (
      <UnauthenticatedLayout isLoading={isLoading}>
        <div className="login-section">
          <SvgIcon className="login-section__title" icon="smart-tutor-logo" />
          <div className="login-section__subtitle">{I18n.t("passwords.set_new_password")}</div>
          <div className="my-4 whitespace-pre-line">
            {I18n.t("passwords.set_new_password_explanation", {email, username})}
          </div>
          <form>
            <input
              autoComplete="new-password"
              autoFocus
              className="login-section__input mt-3"
              name="password"
              onChange={this.onChange}
              placeholder={I18n.t("passwords.new_password")}
              type="password"
              value={attributes.password}
            />
            {this.renderErrors("password")}

            <input
              autoComplete="new-password"
              className="login-section__input mt-3"
              name="password_confirmation"
              onChange={this.onChange}
              placeholder={I18n.t("passwords.new_password_confirmation")}
              type="password"
              value={attributes.password_confirmation}
            />
            {this.renderErrors("password_confirmation")}

            <button
              className="login-section__submit button mt-4"
              disabled={isLoading}
              onClick={this.onSubmit}
            >
              {I18n.t("passwords.change_password")}
            </button>
          </form>
        </div>
      </UnauthenticatedLayout>
    );
  }
}

Edit.propTypes = {
  assignPasswordAttributes: PropTypes.func.isRequired,
  clearPasswordAttributes: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  passwords: PropTypes.object.isRequired,
  resetPasswordToken: PropTypes.string.isRequired,
  updatePassword: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    passwords: state.passwords
  };
}

const mapActionsToProps = {
  assignPasswordAttributes,
  clearPasswordAttributes,
  updatePassword
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Edit));
