/* eslint-disable */
import React, {memo} from "react";

const fn = ({className}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <path fill="#C6C1BB" d="M21.5 7h-12C8.675 7 8 7.675 8 8.5V22l3-3h10.5c.825 0 1.5-.675 1.5-1.5v-9c0-.825-.675-1.5-1.5-1.5zm0 10.5H11L9.5 19V8.5h12v9z"/>
  </svg>
);

export default memo(fn);

