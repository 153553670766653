import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import {withRouter} from "react-router-dom";
import _ from "lodash";
import {notify} from "reapop";

import {COMPANY_PACK_CATEGORY, GENERAL_PACK_CATEGORY} from "../../constants";

import {isPublic} from "../../helpers/packsHelper";
import {isFetchPacksError, isFetchTagsError} from "../../helpers/errorsHelpers";
import {isAdmin} from "../../helpers/userRolesHelper";

import {closeModal, openModal} from "../../store/actions/modalsActions";
import {assignTagAttributes, createTag} from "../../store/actions/tagsActions";

import DefaultModal from "../../components/shared/modals/DefaultModal";
import FetchPacks from "../../components/data_loaders/FetchPacks";
import FetchTags from "../../components/data_loaders/FetchTags";
import Loader from "../../components/shared/Loader";
import TagForm from "./_Form";
import {tagsPath} from "../../helpers/routesHelper";

const PAGES = {
  PACKS: "PACKS",
  TAGS: "TAGS"
};
const MODAL_NAME = "ADD_TAG_MODAL";

class AddTagModal extends Component {
  setDefaultPage = () => {
    this.changePage(PAGES.PACKS);
  };

  get modalTitle() {
    const {options} = this.props.modals;
    const {currentUser, company, tags} = this.props;

    switch (options.page) {
      case PAGES.PACKS:
        return isAdmin(currentUser) ? I18n.t("packs.categories.general") : company.title;
      case PAGES.TAGS:
        const tag = _.find(tag.collection, {id: options.packId});
        return tag ? tag.title : "";
      default:
        return "";
    }
  }

  onBack() {
    const {page} = this.props.modals.options;

    if (page === PAGES.TAGS) {
      return () => this.changePage(PAGES.PACKS);
    }

    return null;
  }

  changePage = (page, packId) => {
    this.props.openModal(MODAL_NAME, {page, packId});
  };

  addTag = (tag) => {
    const {formAttributes, assignTagAttributes} = this.props;

    assignTagAttributes({...formAttributes, tags: [...formAttributes.tags, tag]});
  };

  isTagAdded = (tag) => {
    return _.find(this.props.formAttributes.tags, {id: tag.id});
  };

  get packCategoryId() {
    return isAdmin(this.props.currentUser) ? GENERAL_PACK_CATEGORY : COMPANY_PACK_CATEGORY;
  }

  renderPacks() {
    const {packs} = this.props;

    const isLoaded = packs.isCollectionLoaded && packs.categoryId === this.packCategoryId;
    const isLoadingError = isFetchPacksError(packs);
    const isLoading = !isLoaded && !isLoadingError;

    return (
      <Fragment>
        <FetchPacks categoryId={this.packCategoryId}/>
        {
          isLoaded && _.map(packs.collection, (pack) => {
            const iconClass =
              isPublic(pack) ? "scenario-list-item__icon--pack-public" : "scenario-list-item__icon--pack-private";
            return (
              <button key={pack.id} className="scenario-list-item"
                      onClick={() => this.changePage(PAGES.TAGS, pack.id)}>
                <div className={classnames("scenario-list-item__icon", iconClass)}/>
                <div className="scenario-list-item__content">
                  <div className="scenario-list-item__title" title={pack.title}>
                    {pack.title}
                  </div>
                  <div className="scenario-list-item__secondary-content">
                    <div className="scenario-list-item__subtitle">
                      {I18n.t("packs.scenarios", {tags_count: pack.tags_count})}
                    </div>
                  </div>
                </div>
                <div className="scenario-list-item__icon scenario-list-item__icon--arrow-right"/>
              </button>
            );
          })
        }
        <Loader isShow={isLoading}/>
      </Fragment>
    );
  }

  renderTags() {
    const {tags} = this.props;
    const {packId} = this.props.modals.options;

    const isLoaded = tags.isCollectionLoaded && tags.packId === packId;
    const isLoadingError = isFetchTagsError(tags);
    const isLoading = !isLoaded && !isLoadingError;

    return (
      <Fragment>
        <FetchTags packId={packId}/>
        {
          isLoaded && _.map(tags.collection, (tag) => {
            const isTagAdded = this.isTagAdded(tag);
            const itemClass = isTagAdded ? "scenario-list-item--selected scenario-list-item--non-hoverable" : "";

            return (
              <button key={tag.id}
                      onClick={() => this.addTag(tag)}
                      className={classnames("scenario-list-item", itemClass)}
                      disabled={isTag}>
                <div className="scenario-list-item__icon scenario-list-item__icon--scenario"/>
                <div className="scenario-list-item__content">
                  <div className="scenario-list-item__title" title={userScenario.title}>
                    {userScenario.title}
                  </div>
                </div>
              </button>
            );
          })
        }
        <Loader isShow={isLoading}/>
      </Fragment>
    );
  }

  renderCurrentPage() {
    switch (this.props.modals.options.page) {
      case PAGES.PACKS:
        return this.renderPacks();
      case PAGES.TAGS:
        return this.renderTags();
      default:
        return null;
    }
  }

  onSubmit = (tag) => {
    this.props.createTag(tag, this.props.history);
    this.props.closeModal(MODAL_NAME);
  };

  render() {
    return (
      <DefaultModal modalName={MODAL_NAME}
                    modalTitle={this.modalTitle}
                    buttonClass="button button_theme_plus-grey"
                    onOpen={this.setDefaultPage}
                    onBack={this.onBack()}>
        <div className="bem-popup__content">
          <TagForm
                backPath={tagsPath()}
                formAttributes={this.props.tags.formAttributes}
                assignTagAttributes={this.props.assignTagAttributes}
                onSubmit={this.onSubmit}
                notify={this.props.notify}
          />
        </div>
      </DefaultModal>
    );
  }
}

AddTagModal.propTypes = {
  formAttributes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  packs: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  modals: PropTypes.shape({
    options: PropTypes.shape({
      page: PropTypes.string,
      packId: PropTypes.string
    })
  }),
  assignTagAttributes: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    currentUser: state.profiles.current,
    company: state.companies.current,
    packs: state.packs,
    tags: state.tags,
    modals: state.modals
  }
}

const mapActionsToProps = {
  assignTagAttributes,
  openModal,
  closeModal,
  createTag,
  notify
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(AddTagModal));
