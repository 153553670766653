import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchCharactersError} from "../../helpers/errorsHelpers";

import {fetchCharacters} from "../../store/actions/charactersActions";

class FetchCharacters extends Component {
  componentDidMount() {
    this.fetchCharacters(true);
  }

  componentDidUpdate() {
    this.fetchCharacters(false);
  }

  fetchCharacters(isComponentMount) {
    const {characters} = this.props;
    const isReasonToFetch = isComponentMount || !characters.isCollectionLoaded;

    if (isReasonToFetch && !characters.isLoading && !isFetchCharactersError(characters)) {
      this.props.fetchCharacters();
    }
  }

  render() {
    return null;
  }
}

FetchCharacters.propTypes = {
  characters: PropTypes.object.isRequired,
  fetchCharacters: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    characters: state.characters
  }
}

const mapActionsToProps = {
  fetchCharacters
};

export default connect(mapStateToProps, mapActionsToProps)(FetchCharacters);
