import React, {Component} from "react";
import PropTypes from "prop-types";
import {Provider} from "react-redux"

import store from "../store"
import App from "../components/App";

class AppContainer extends Component {
  render() {
    const {flash} = this.props;

    return (
      <Provider store={store}>
        <App flash={flash}/>
      </Provider>
    )
  }
}

AppContainer.propTypes = {
  flash: PropTypes.array
};

export default AppContainer;
