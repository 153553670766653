import {ACTIONS} from "../reducers/userScenariosReducer";

import webToolApi from "../../helpers/webToolApiHelper";
import {packPath} from "../../helpers/routesHelper";

import {notifyError, notifyResponseError, notifyServiceUnavailableError, notifySuccess} from "./notificationsActions";
import {defaultErrorAction} from "./errorsActions";

export function changeUserScenariosLoading(isLoading) {
  return {type: ACTIONS.CHANGE_USER_SCENARIOS_LOADING, isLoading};
}

export function fetchUserScenarios(packId, {environmentId, page} = {}) {
  return (dispatch) => {
    dispatch(changeUserScenariosLoading(true));
    dispatch({type: ACTIONS.FETCH_USER_SCENARIOS});

    webToolApi().get(Routes.pack_user_scenarios_path(packId, {environment_id: environmentId, page}))
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_USER_SCENARIOS_FULFILLED, payload: response.data, packId, environmentId, page});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_USER_SCENARIOS_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("user_scenarios.messages.index.error"), response));
      })
      .finally(() => {
        dispatch(changeUserScenariosLoading(false));
      });
  }
}

export function fetchUserScenario(id) {
  return (dispatch) => {
    dispatch(changeUserScenariosLoading(true));
    dispatch({type: ACTIONS.FETCH_USER_SCENARIO});

    webToolApi().get(Routes.user_scenario_path(id))
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_USER_SCENARIO_FULFILLED, userScenario: response.data.user_scenario});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_USER_SCENARIO_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("user_scenarios.messages.show.error"), response));
      })
      .finally(() => {
        dispatch(changeUserScenariosLoading(false));
      });
  }
}

export function assignUserScenarioAttributes(attributes) {
  return {type: ACTIONS.ASSIGN_USER_SCENARIO_ATTRIBUTES, attributes};
}

export function clearUserScenarioAttributes() {
  return {type: ACTIONS.CLEAR_USER_SCENARIO_ATTRIBUTES};
}

export function createUserScenario(packId, userScenario, history) {
  return (dispatch) => {
    dispatch(changeUserScenariosLoading(true));

    webToolApi().post(Routes.pack_user_scenarios_path(packId), {user_scenario: userScenario})
      .then((response) => {
        const userScenario = response.data.user_scenario;
        dispatch({type: ACTIONS.CREATE_USER_SCENARIO_FULFILLED, userScenario});
        dispatch(notifySuccess(I18n.t("user_scenarios.messages.create.success")));
        dispatch(clearUserScenarioAttributes());
        dispatch(changeUserScenariosLoading(false));
        history.push(packPath(packId));
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.CREATE_USER_SCENARIO_REJECTED, response));
        if (response) {
          const {errors} = response.data;
          if (errors.base) {
            errors.base.forEach((message) => dispatch(notifyError(message)));
          } else {
            dispatch(notifyError(I18n.t("user_scenarios.messages.create.error")));
          }
        } else {
          dispatch(notifyServiceUnavailableError(I18n.t("user_scenarios.messages.create.error")));
        }
        dispatch(changeUserScenariosLoading(false));
      });
  }
}

export function updateUserScenario(userScenario, history, redirectPath) {
  return (dispatch) => {
    dispatch(changeUserScenariosLoading(true));

    webToolApi().put(Routes.user_scenario_path(userScenario.id), {user_scenario: userScenario})
      .then((response) => {
        const userScenario = response.data.user_scenario;
        dispatch({type: ACTIONS.UPDATE_USER_SCENARIO_FULFILLED, userScenario});
        dispatch(notifySuccess(I18n.t("user_scenarios.messages.update.success")));
        dispatch(clearUserScenarioAttributes());
        dispatch(changeUserScenariosLoading(false));
        history.push(redirectPath);
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.UPDATE_USER_SCENARIO_REJECTED, response));
        if (response) {
          const {errors} = response.data;
          if (errors.base) {
            errors.base.forEach((message) => dispatch(notifyError(message)));
          } else {
            dispatch(notifyError(I18n.t("user_scenarios.messages.update.error")));
          }
        } else {
          dispatch(notifyServiceUnavailableError(I18n.t("user_scenarios.messages.update.error")));
        }
        dispatch(changeUserScenariosLoading(false));
      });
  }
}

export function destroyUserScenario(packId, userScenarioId, history, redirectPath, {onSuccess} = {}) {
  return (dispatch) => {
    dispatch(changeUserScenariosLoading(true));

    webToolApi().delete(Routes.user_scenario_path(userScenarioId))
      .then(() => {
        dispatch({type: ACTIONS.DESTROY_USER_SCENARIO_FULFILLED, userScenarioId});
        dispatch(notifySuccess(I18n.t("user_scenarios.messages.destroy.success")));
        onSuccess && onSuccess();
        dispatch(changeUserScenariosLoading(false));
        redirectPath && history.push(redirectPath);
      })
      .catch(({response}) => {
        dispatch(notifyResponseError(I18n.t("user_scenarios.messages.destroy.error"), response));
        dispatch(changeUserScenariosLoading(false));
      })
  }
}

export function setUserScenarioRemovingState(userScenarioId, removingState) {
  return {type: ACTIONS.SET_USER_SCENARIO_REMOVING_STATE, userScenarioId, removingState};
}

export function duplicateUserScenario(userScenarioId) {
  return (dispatch) => {
    dispatch(changeUserScenariosLoading(true));

    webToolApi().post(Routes.user_scenario_duplicates_path(userScenarioId))
      .then(() => {
        dispatch({type: ACTIONS.DUPLICATE_USER_SCENARIO_FULFILLED});
        dispatch(notifySuccess(I18n.t("user_scenarios.messages.duplicate.success")));
      })
      .catch(({response}) => {
        dispatch(notifyResponseError(I18n.t("user_scenarios.messages.duplicate.error"), response));
      })
      .finally(() => {
        dispatch(changeUserScenariosLoading(false));
      });
  }
}

export function copyUserScenario(userScenarioId) {
  return (dispatch) => {
    dispatch(changeUserScenariosLoading(true));

    webToolApi().post(Routes.user_scenario_copies_path(userScenarioId))
      .then(() => {
        dispatch({type: ACTIONS.COPY_USER_SCENARIO_FULFILLED});
        dispatch(notifySuccess(I18n.t("user_scenarios.messages.copy.success")));
      })
      .catch(({response}) => {
        dispatch(notifyResponseError(I18n.t("user_scenarios.messages.copy.error"), response));
      })
      .finally(() => {
        dispatch(changeUserScenariosLoading(false));
      });
  }
}

export function selectUserScenario(userScenarioId) {
  return {type: ACTIONS.SELECT_USER_SCENARIO, userScenarioId};
}

export function deselectUserScenario(userScenarioId) {
  return {type: ACTIONS.DESELECT_USER_SCENARIO, userScenarioId};
}

export function deselectAllUserScenarios() {
  return {type: ACTIONS.DESELECT_ALL_USER_SCENARIOS};
}

export function moveUserScenarios(packId, userScenarioIds, {onSuccess} = {}) {
  return (dispatch) => {
    dispatch(changeUserScenariosLoading(true));

    webToolApi().post(Routes.pack_moves_path(packId), {user_scenario_ids: userScenarioIds})
      .then(() => {
        dispatch({type: ACTIONS.MOVE_USER_SCENARIOS_FULFILLED});
        dispatch(notifySuccess(I18n.t("user_scenarios.messages.move.success")));
        dispatch(changeUserScenariosLoading(false));
        onSuccess && onSuccess();
      })
      .catch(({response}) => {
        dispatch(notifyResponseError(I18n.t("user_scenarios.messages.move.error"), response));
        dispatch(changeUserScenariosLoading(false));
      });
  }
}
