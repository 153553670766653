import React, {Component} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  onQueryChange = (event) => {
    this.props.changeUsersQuery(event.target.value);
  };

  clearQuery = () => {
    if (!this.props.uncontrolled){
      if (this.props.query) {
        this.props.changeUsersQuery("");
      }
    }

    if (this.props.uncontrolled) {
      this.inputRef.current.value = "";
      this.props.changeUsersQuery("");
    }
  };

  render() {
    const {query, uncontrolled} = this.props;

    return (
      <div className={classnames("search-bar", {"search-bar--filled": query})}>
        <input
          ref={this.inputRef}
          type="search"
          className="search-bar__input"
          placeholder={I18n.t("search_students")}
          value={uncontrolled ? undefined : query}
          onChange={this.onQueryChange}
          defaultValue={uncontrolled ? query : undefined}
        />
        <i className="search-bar__icon" onClick={this.clearQuery}/>
      </div>
    );
  }
}

SearchBar.propTypes = {
  query: PropTypes.string.isRequired,
  changeUsersQuery: PropTypes.func.isRequired,
  // Set `uncontrolled` to `true` when recalculating the `query`
  // the causes lag from the re-rendering. When setting `uncontrolled` to `true`
  // It's best to set `changeUsersQuery` to be a `debounced` function
  uncontrolled: PropTypes.boolean,
};

export default SearchBar;
