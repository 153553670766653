import {ACTIONS} from "../reducers/metricsReducer";

import webToolApi from "../../helpers/webToolApiHelper";

import {defaultErrorAction} from "./errorsActions";
import {notifyResponseError, notifySuccess} from "./notificationsActions";

export function changeMetricsLoading(isLoading) {
  return {type: ACTIONS.CHANGE_METRICS_LOADING, isLoading};
}

export function reloadMetrics() {
  return {type: ACTIONS.FETCH_METRICS};
}

export function fetchMetrics(userId, {userScenarioId, page = 1} = {}) {
  return (dispatch) => {
    dispatch(changeMetricsLoading(true));
    dispatch({type: ACTIONS.FETCH_METRICS});

    webToolApi().get(Routes.user_metrics_path(userId, {page, user_scenario_id: userScenarioId}))
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_METRICS_FULFILLED, payload: response.data, userId, userScenarioId});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_METRICS_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("metrics.messages.index.error"), response));
      })
      .finally(() => {
        dispatch(changeMetricsLoading(false));
      });
  }
}

export function fetchMoreMetrics(userId, {userScenarioId, page} = {}) {
  return (dispatch) => {
    dispatch(changeMetricsLoading(true));

    webToolApi().get(Routes.user_metrics_path(userId, {page, user_scenario_id: userScenarioId}))
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_MORE_METRICS_FULFILLED, payload: response.data});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_MORE_METRICS_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("metrics.messages.index.error"), response));
      })
      .finally(() => {
        dispatch(changeMetricsLoading(false));
      });
  }
}

export function fetchMetric(id) {
  return (dispatch) => {
    dispatch(changeMetricsLoading(true));
    dispatch({type: ACTIONS.FETCH_METRIC});

    webToolApi().get(Routes.metric_path(id))
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_METRIC_FULFILLED, metric: response.data.metric});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_METRIC_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("metrics.messages.show.error"), response));
      })
      .finally(() => {
        dispatch(changeMetricsLoading(false));
      });
  }
}

export function destroyMetric(metricId) {
  return (dispatch) => {
    dispatch(changeMetricsLoading(true));

    webToolApi().delete(Routes.metric_path(metricId))
      .then(() => {
        dispatch({type: ACTIONS.DESTROY_METRIC_FULFILLED, metricId});
        dispatch(notifySuccess(I18n.t("metrics.messages.destroy.success")));
      })
      .catch(({response}) => {
        dispatch(notifyResponseError(I18n.t("metrics.messages.destroy.error"), response));
      })
      .finally(() => {
        dispatch(changeMetricsLoading(false));
      });
  }
}
