import React, {Component} from "react";
import PropTypes from "prop-types";
import {SortableHandle} from "react-sortable-hoc";
import _ from "lodash";

import handleFieldChange from "../../helpers/handleFieldChange";

import ContextMenu from "../../components/shared/context_menu/ContextMenu";
import DeleteScenarioModal from "./DeleteScenarioModal";

const DragHandle = SortableHandle(() => <div className="course-scenario__icon course-scenario__icon--drag"/>);

class ScenarioEdit extends Component {
  get scenarioIndex() {
    return _.findIndex(this.props.formAttributes.user_scenarios, {id: this.props.id});
  };

  get scenario() {
    return this.props.formAttributes.user_scenarios[this.scenarioIndex];
  };

  onChange = (event) => {
    const attributes = this.props.formAttributes;
    const changedAttributes = {...attributes, ...handleFieldChange(event, attributes)};
    this.props.assignCourseAttributes(changedAttributes);
  };

  render() {
    const attributes = this.props.formAttributes;
    const {scenario, scenarioIndex} = this;

    if (!scenario) return null;

    return (
      <tr className="course-scenario">

        <td>
          <div className="course-scenario__number">
            {scenarioIndex + 1}
          </div>
        </td>

        <td>
          <div className="course-scenario__title">
            {scenario.title}
          </div>
        </td>

        <td>
          <ContextMenu className="course-scenario__actions-context-menu">
            <DeleteScenarioModal
              scenarioId={scenario.id}
              formAttributes={attributes}
              assignCourseAttributes={this.props.assignCourseAttributes}
            />
          </ContextMenu>
        </td>

        <td>
          <DragHandle/>
        </td>

      </tr>
    )
  }
}

ScenarioEdit.propTypes = {
  id: PropTypes.string.isRequired,
  formAttributes: PropTypes.object.isRequired,
  assignCourseAttributes: PropTypes.func.isRequired
};

export default ScenarioEdit;
