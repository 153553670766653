import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {SortableHandle} from "react-sortable-hoc";
import _ from "lodash";

import handleFieldChange from "../../helpers/handleFieldChange";

import ContextMenu from "../../components/shared/context_menu/ContextMenu";
import DeleteTagModal from "./DeleteTagModal";
import EditTagModal from "./_EditTagModal";
import ContextMenuItem from "../../components/shared/context_menu/ContextMenuItem";

const DragHandle = SortableHandle(() => <div className="course-scenario__icon course-scenario__icon--drag"/>);

class TagEdit extends Component {
  get tagIndex() {
    return _.findIndex(this.props.tags.collection, {id: this.props.id});
  };

  get tag() {
    return this.props.tags.collection[this.tagIndex];
  };

  onChange = (event) => {
    const attributes = this.props.formAttributes;
    const changedAttributes = {...attributes, ...handleFieldChange(event, attributes)};
    this.props.assignTagAttributes(changedAttributes);
  };

  renderEditButton = () => {
    return (
      <div onClick={() => this.props.openEditModal(this.tag)}>
        <ContextMenuItem color="green" hideContextMenu={this.props.hideContextMenu}>
          {I18n.t("edit")}
        </ContextMenuItem>
      </div>
    );
  };

  render() {
    const attributes = this.props.formAttributes;
    const {tag, tagIndex} = this;

    if (!tag) return null;

    return (
      <tr className="course-scenario">

        <td>
          <div className="course-scenario__number">
            {tag.order + 1}
          </div>
        </td>

        <td>
          <div className="course-scenario__title">
            {tag.english_name}
          </div>
        </td>

        <td>
          <ContextMenu className="course-scenario__actions-context-menu">
            {this.renderEditButton()}
            <DeleteTagModal
              tagId={tag.id}
              formAttributes={attributes}
              assignTagAttributes={this.props.assignTagAttributes}
            />
          </ContextMenu>
        </td>

        <td>
          <DragHandle/>
        </td>

      </tr>
    )
  }
}

function mapStateToProps(state) {
  return {
    tags: state.tags
  }
}

TagEdit.propTypes = {
  id: PropTypes.string.isRequired,
  formAttributes: PropTypes.object.isRequired,
  assignTagAttributes: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(TagEdit);
