import React, {Component} from "react";
import PropTypes from "prop-types";

import {changeLocale} from "../../helpers/localesHelper";

class UseLocale extends Component {
  availableLanguage = ['en', 'ja']

  constructor(props) {
    super(props);

    this.updateLocale();
  }

  componentDidUpdate() {
    this.updateLocale();
  }

  updateLocale() {
    const languageCode = this.props.currentUser.language_code || this.getSystemLocale();

    languageCode ? changeLocale(languageCode) : changeLocale();
  }

  getSystemLocale() {
    let systemLanguageCode = window.navigator.language;

    if (!systemLanguageCode) {
      return undefined;
    }

    systemLanguageCode = systemLanguageCode.split('-')[0]

    if (this.availableLanguage.includes(systemLanguageCode)) {
      return systemLanguageCode;
    }

    return undefined;
  }

  render() {
    return null;
  }
}

UseLocale.propTypes = {
  currentUser: PropTypes.object
};

export default UseLocale;
