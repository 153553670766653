import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import ConfirmationModal from "components/shared/modals/ConfirmationModal";
import {destroyUser} from "store/actions/usersActions";

const MODAL_NAME = "USERS_DELETE_MODAL";

class DeleteModal extends Component {
  onSubmit = (closeModal) => {
    this.props.destroyUser(this.props.userId, this.props.history);
    closeModal();
  };

  render() {
    return (
      <ConfirmationModal
        modalName={MODAL_NAME}
        button={<button className="button button_color_grey btn-border mb-5 w-100" type="button">{I18n.t("users.delete_account")}</button>}
        message={`Are you sure you want to delete this account?`}
        contentStyle={{width: "600px"}}
        onSubmit={this.onSubmit}
      />
    );
  }
}

DeleteModal.propTypes = {
  userId: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  destroyUser: PropTypes.func.isRequired
};

const mapActionsToProps = {
  destroyUser
};

export default connect(null, mapActionsToProps)(withRouter(DeleteModal));
