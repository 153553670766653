import {ACTIONS} from "../reducers/userActivitiesReducer";

import webToolApi from "../../helpers/webToolApiHelper";

import {defaultErrorAction} from "./errorsActions";
import {notifyResponseError} from "./notificationsActions";

export function changeUserActivitiesLoading(isLoading) {
  return {type: ACTIONS.CHANGE_USER_ACTIVITIES_LOADING, isLoading};
}

export function changeUserActivitiesWeek(weekStartsAt) {
  return {type: ACTIONS.CHANGE_USER_ACTIVITIES_WEEK, weekStartsAt};
}

export function changeUserActivitiesOrder(order) {
  return {type: ACTIONS.CHANGE_USER_ACTIVITIES_ORDER, order};
}

export function refreshUserActivities({groupId, weekStartsAt, perPage, query} = {}) {
  return (dispatch) => {
    dispatch(changeUserActivitiesLoading(true));
    dispatch({type: ACTIONS.REFRESH_USER_ACTIVITIES});

    webToolApi().post(Routes.refresh_activities_cache_path({group_id: groupId, week_starts_at: weekStartsAt, per_page: perPage, query }))
      .then((response) => {
        dispatch(notifySuccess(I18n.t("user_activities.messages.refresh_cache.success")));
      })
      .catch((response) => {
        console.log(response);
        dispatch(notifyResponseError(I18n.t("user_activities.messages.refresh_cache.error"), response));
      })
      .finally(() => {
        dispatch(changeUserActivitiesLoading(false));
      });
  }
}

export function fetchUserActivities({groupId, weekStartsAt, perPage, query} = {}) {
  return (dispatch) => {
    dispatch(changeUserActivitiesLoading(true));
    dispatch({type: ACTIONS.FETCH_USER_ACTIVITIES, groupId, weekStartsAt});

    webToolApi().get(Routes.user_activities_path({group_id: groupId, week_starts_at: weekStartsAt, per_page: perPage, query}))
      .then((response) => {
        dispatch({type: ACTIONS.FETCH_USER_ACTIVITIES_FULFILLED, payload: response.data, groupId, weekStartsAt});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_USER_ACTIVITIES_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("user_activities.messages.index.error"), response));
      })
      .finally(() => {
        dispatch(changeUserActivitiesLoading(false));
      });
  }
}
