import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchUserScenariosError} from "../../helpers/errorsHelpers";

import {fetchUserScenarios} from "../../store/actions/userScenariosActions";

class FetchUserScenarios extends Component {
  componentDidMount() {
    this.fetchUserScenarios(true);
  }

  componentDidUpdate() {
    this.fetchUserScenarios(false);
  }

  fetchUserScenarios(isComponentMount) {
    const {packId, environmentId, userScenarios, page} = this.props;
    const isWrongCollection =
      userScenarios.page !== page ||
      userScenarios.packId !== packId ||
      userScenarios.environmentId !== environmentId;
    const isReasonToFetch = isComponentMount || !userScenarios.isCollectionLoaded || isWrongCollection;

    if (isReasonToFetch && !userScenarios.isLoading && !isFetchUserScenariosError(userScenarios)) {
      this.props.fetchUserScenarios(packId, {page, environmentId});
    }
  }

  render() {
    return null;
  }
}

FetchUserScenarios.propTypes = {
  packId: PropTypes.string.isRequired,
  page: PropTypes.number,
  environmentId: PropTypes.string,
  userScenarios: PropTypes.object.isRequired,
  fetchUserScenarios: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    userScenarios: state.userScenarios
  }
}

const mapActionsToProps = {
  fetchUserScenarios
};

export default connect(mapStateToProps, mapActionsToProps)(FetchUserScenarios);
