import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {destroyEnglishScore} from "../../../store/actions/englishScoresActions";
import {closeModal} from "../../../store/actions/modalsActions";

import ConfirmationModal from "../../../components/shared/modals/ConfirmationModal";

export const MODAL_NAME = "DELETE_SCORE_MODAL";

class DeleteScoreModal extends Component {
  handleSubmit = () => {
    const {userId, modals, destroyEnglishScore, closeModal} = this.props;
    const {id} = modals.options;
    destroyEnglishScore(userId, id, {onSuccess: () => closeModal(MODAL_NAME)});
  };

  render() {
    return (
      <ConfirmationModal
        modalName={MODAL_NAME}
        message={I18n.t("profiles.scores.popups.delete_score")}
        onSubmit={this.handleSubmit}
        isRenderButton={false}
      />
    );
  }
}

DeleteScoreModal.propTypes = {
  modals: PropTypes.shape({
    options: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  closeModal: PropTypes.func.isRequired,
  destroyEnglishScore: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    modals: state.modals
  }
}

const mapActionsToProps = {
  closeModal,
  destroyEnglishScore
};

export default connect(mapStateToProps, mapActionsToProps)(DeleteScoreModal);
