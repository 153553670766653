import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchProfileError} from "../../helpers/errorsHelpers";

import {fetchProfile} from "../../store/actions/profilesActions";

class FetchProfile extends Component {
  componentDidMount() {
    this.fetchProfile(true);
  }

  componentDidUpdate() {
    this.fetchProfile(false);
  }

  fetchProfile(isComponentMount) {
    const {profiles} = this.props;
    const isReasonToFetch = isComponentMount || !profiles.current.id;

    if (isReasonToFetch && !profiles.isLoading && !isFetchProfileError(profiles)) {
      this.props.fetchProfile();
    }
  }

  render() {
    return null;
  }
}

FetchProfile.propTypes = {
  profiles: PropTypes.object.isRequired,
  fetchProfile: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    profiles: state.profiles
  }
}

const mapActionsToProps = {
  fetchProfile
};

export default connect(mapStateToProps, mapActionsToProps)(FetchProfile);
