import {applyMiddleware, compose, createStore} from "redux";

import thunk from "redux-thunk"

import reducer from "./reducers";

const middleware = applyMiddleware(thunk);

let composeWithDevTools;
if (process.env.NODE_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeWithDevTools =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({name: "SmartTutor", actionsBlacklist: ["REDUX_STORAGE_SAVE"]});
} else {
  composeWithDevTools = compose;
}

export default createStore(
  reducer,
  composeWithDevTools(middleware)
);
