/* eslint-disable max-lines */
import React, {Fragment} from "react";
import {Switch, BrowserRouter, Route} from "react-router-dom";
import queryString from "query-string";

import pageFromLocation from "../helpers/pageFromLocation";

import CoursesEdit from "../views/courses/Edit";
import CoursesIndex from "../views/courses/Index";
import CoursesNew from "../views/courses/New";
import CoursesShow from "../views/courses/Show";
import DashboardGroupsShow from "../views/dashboard/groups/Show";
import DashboardScenarioReviewsShow from "../views/dashboard/scenario_reviews/Show";
import DashboardShow from "../views/dashboard/Show";
import DashboardUserScenariosEdit from "../views/dashboard/user_scenarios/Edit";
import DashboardUserScenariosShow from "../views/dashboard/user_scenarios/Show";
import DashboardUsersShow from "../views/dashboard/users/Show";
import MainPagesShow from "../views/main_pages/Show";
import PackCategoriesIndex from "../views/pack_categories/Index";
import PackCategoriesShow from "../views/pack_categories/Show";
import PacksShow from "../views/packs/Show";
import PasswordsEdit from "../views/devise/passwords/Edit";
import PasswordsEmailSent from "../views/devise/passwords/EmailSent";
import PasswordsNew from "../views/devise/passwords/New";
import PasswordsUpdated from "../views/devise/passwords/Updated";
import ProfilesEdit from "../views/profiles/Edit";
import ProfilesShow from "../views/profiles/Show";
import SessionsNew from "../views/devise/sessions/New";
import TagsEdit from "../views/tags/Edit";
import TagsIndex from "../views/tags/Index";
import TagsNew from "../views/tags/New";
import TagsShow from "../views/tags/Show";
import UserScenariosEdit from "../views/user_scenarios/Edit";
import UserScenariosNew from "../views/user_scenarios/New";
import UserScenariosSearch from "../views/user_scenarios/Search";
import UserScenariosShow from "../views/user_scenarios/Show";
import UsersEdit from "../views/users/Edit";
import UsersIndex from "../views/users/Index";
import UsersNew from "../views/users/New";
import UsersShow from "../views/users/Show";

const Router = () => (
  <BrowserRouter>
    <Fragment>
      <Switch>
        <Route component={MainPagesShow} exact path="/" />
        <Route component={SessionsNew} exact path="/users/sign_in" />
        <Route component={PasswordsNew} exact path="/users/password/new" />
        <Route
          exact
          path="/users/password/edit"
          render={({location}) => (
            <PasswordsEdit
              email={queryString.parse(location.search).email}
              resetPasswordToken={queryString.parse(location.search).reset_password_token}
              username={queryString.parse(location.search).username}
            />
          )}
        />
        <Route component={PasswordsUpdated} exact path="/users/password/updated" />
        <Route
          exact
          path="/users/password/email_sent/:email"
          render={({match}) => <PasswordsEmailSent email={match.params.email} />}
        />
        <Route component={ProfilesShow} exact path="/profile" />
        <Route component={ProfilesEdit} exact path="/profile/edit" />
        <Route
          exact
          path="/users"
          render={({location}) => <UsersIndex page={pageFromLocation(location)} />}
        />
        <Route component={UsersNew} exact path="/users/new" />
        <Route exact path="/users/:id" render={({match}) => <UsersShow id={match.params.id} />} />
        <Route
          exact
          path="/users/:id/edit"
          render={({match}) => <UsersEdit id={match.params.id} />}
        />
        <Route
          exact
          path="/tags"
          render={({location}) => <TagsIndex page={pageFromLocation(location)} />}
        />
        <Route component={TagsNew} exact path="/tags/new" />
        <Route
          exact
          path="/tags/:id/edit"
          render={({match}) => <TagsEdit id={match.params.id} />}
        />
        <Route
          exact
          path="/tags/:id"
          render={({match}) => <TagsShow id={match.params.id} />}
        />
        <Route
          exact
          path="/courses"
          render={({location}) => <CoursesIndex page={pageFromLocation(location)} />}
        />
        <Route component={CoursesNew} exact path="/courses/new" />
        <Route
          exact
          path="/courses/:id/edit"
          render={({match}) => <CoursesEdit id={match.params.id} />}
        />
        <Route
          exact
          path="/courses/:id"
          render={({match}) => <CoursesShow id={match.params.id} />}
        />
        <Route component={PackCategoriesIndex} exact path="/pack_categories" />
        <Route
          exact
          path="/pack_categories/:id"
          render={({match, location}) => (
            <PackCategoriesShow id={match.params.id} page={pageFromLocation(location)} />
          )}
        />
        <Route
          exact
          path="/packs/:id"
          render={({match, location}) => (
            <PacksShow
              backPath={queryString.parse(location.search).back_path}
              environmentId={queryString.parse(location.search).environment_id}
              id={match.params.id}
              page={pageFromLocation(location)}
            />
          )}
        />
        <Route
          exact
          path="/user_scenarios/search"
          render={({location}) => (
            <UserScenariosSearch
              category={queryString.parse(location.search).category}
              page={pageFromLocation(location)}
              query={queryString.parse(location.search).query}
            />
          )}
        />
        <Route
          exact
          path="/user_scenarios/:id"
          render={({location, match}) => (
            <UserScenariosShow
              backPath={queryString.parse(location.search).back_path}
              id={match.params.id}
            />
          )}
        />
        <Route
          exact
          path="/user_scenarios/:id/edit"
          render={({location, match}) => (
            <UserScenariosEdit
              backPath={queryString.parse(location.search).back_path}
              id={match.params.id}
            />
          )}
        />
        <Route
          exact
          path="/packs/:packId/user_scenarios/new"
          render={({match}) => <UserScenariosNew packId={match.params.packId} />}
        />
        <Route component={DashboardShow} exact path="/dashboard" />
        <Route
          exact
          path="/dashboard/groups/:groupId"
          render={({match}) => <DashboardGroupsShow
            // Force a re-render when groupId changes so 
            // So that the query param will re-initialize
            key={match.params.groupId}
            groupId={match.params.groupId}
          />}
        />
        <Route
          exact
          path="/dashboard/scenario_reviews/:metricId"
          render={({match, location}) => (
            <DashboardScenarioReviewsShow
              groupId={queryString.parse(location.search).group_id}
              metricId={match.params.metricId}
              userId={match.params.userId}
            />
          )}
        />
        <Route
          exact
          path="/dashboard/users/:userId"
          render={({match, location}) => (
            <DashboardUsersShow
              groupId={queryString.parse(location.search).group_id}
              userId={match.params.userId}
            />
          )}
        />
        <Route
          exact
          path="/dashboard/:userId/user_scenarios/:id/edit"
          render={({match}) => (
            <DashboardUserScenariosEdit id={match.params.id} userId={match.params.userId} />
          )}
        />
        <Route
          exact
          path="/dashboard/:userId/user_scenarios/:id"
          render={({match}) => (
            <DashboardUserScenariosShow id={match.params.id} userId={match.params.userId} />
          )}
        />
      </Switch>
    </Fragment>
  </BrowserRouter>
);

export default Router;
