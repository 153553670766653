import React, {memo} from "react";
import PropTypes from "prop-types";
import sanitizeHtml from "sanitize-html";
import _ from "lodash";

const wrapText = ({text, type, words}) => {
  let resultText = text;

  _.reverse(_.filter(words, {english: true, is_correct: false})).forEach(word => {
    const wrappedWord = `<span class="scenario-review-lines__${type}">${word.text}</span>`;
    resultText =
      resultText.substring(0, word.boundaries[0]) +
      wrappedWord +
      resultText.substring(word.boundaries[1] + 1, resultText.length);
  });

  return resultText;
};

const WrappedText = ({text, type, words}) => (
  <div
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: sanitizeHtml(wrapText({text, type, words}), {
        allowedAttributes: {span: ["class"]},
        allowedTags: ["span"]
      })
    }}
  />
);

WrappedText.propTypes = {
  type: PropTypes.oneOf(["failure", "warning"]).isRequired
};

export default memo(WrappedText);
