import {ACTIONS} from "../reducers/modalsReducer";

export function openModal(modal, options = {}) {
  return {type: ACTIONS.OPEN_MODAL, modal, options};
}

export function closeModal(modal) {
  return {type: ACTIONS.CLOSE_MODAL, modal};
}

export function closeModals() {
  return {type: ACTIONS.CLOSE_MODALS};
}
