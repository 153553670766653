import generatePinCode from "../../helpers/generatePinCode";
import generatePassword from "../../helpers/generatePassword";

import {SUCCESS_STATUS_CODE} from "../../constants";

export const ACTIONS = {
  ASSIGN_USER_ATTRIBUTES: "ASSIGN_USER_ATTRIBUTES",
  CHANGE_USERS_LOADING: "CHANGE_USERS_LOADING",
  CHANGE_USERS_QUERY: "CHANGE_USERS_QUERY",
  CLEAR_USER_ATTRIBUTES: "CLEAR_USER_ATTRIBUTES",
  CREATE_USER_FULFILLED: "CREATE_USER_FULFILLED",
  CREATE_USER_REJECTED: "CREATE_USER_REJECTED",
  DESTROY_USER_FULFILLED: "DESTROY_USER_FULFILLED",
  DESTROY_USER_REJECTED: "DESTROY_USER_REJECTED",
  FETCH_USER: "FETCH_USER",
  FETCH_USERS: "FETCH_USERS",
  FETCH_USERS_FULFILLED: "FETCH_USERS_FULFILLED",
  FETCH_USERS_REJECTED: "FETCH_USERS_REJECTED",
  FETCH_USER_FULFILLED: "FETCH_USER_FULFILLED",
  FETCH_USER_REJECTED: "FETCH_USER_REJECTED",
  ORDER_COURSES: "ORDER_COURSES",
  ORDER_COURSES_FULFILLED: "ORDER_COURSES_FULFILLED",
  ORDER_COURSES_REJECTED: "ORDER_COURSES_REJECTED",
  UPDATE_USER_FULFILLED: "UPDATE_USER_FULFILLED",
  UPDATE_USER_REJECTED: "UPDATE_USER_REJECTED"
};

const DEFAULT_SUCCESS_STATUS = {errors: {}, status: SUCCESS_STATUS_CODE};
const DEFAULT_STATUSES = {
  [ACTIONS.FETCH_USERS]: {errors: {}, status: null},
  [ACTIONS.FETCH_USER]: {errors: {}, status: null}
};

const INITIAL_FORM_ATTRIBUTES = {
  activation_ends_on: "",
  activation_starts_on: "",
  avatar: {
    url: ""
  },
  birthday_hash: {
    "birthday(1i)": "",
    "birthday(2i)": "",
    "birthday(3i)": ""
  },
  email: "",
  errors: {},
  first_name: "",
  first_name_ja: "",
  gender: "male",
  language_code: "en",
  last_name: "",
  last_name_ja: "",
  password: "",
  password_confirmation: "",
  pin_code: "",
  role: "user",
  show_initials_only: false,
  status: "active",
  username: ""
};

export default function reducer(
  state = {
    collection: [],
    current: {},
    formAttributes: INITIAL_FORM_ATTRIBUTES,
    groupId: "",
    inputQuery: "",
    isCollectionLoaded: false,
    isLoading: false,
    loadingCount: 0,
    page: 0,
    pagination: {},
    query: "",
    statuses: DEFAULT_STATUSES
  },
  action
) {
  switch (action.type) {
    case ACTIONS.CHANGE_USERS_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);

      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.CHANGE_USERS_QUERY: {
      return {...state, inputQuery: action.query};
    }

    case ACTIONS.FETCH_USERS: {
      return {
        ...state,
        collection: [],
        isCollectionLoaded: false,
        page: 0,
        pagination: {},
        query: ""
      };
    }
    case ACTIONS.FETCH_USERS_FULFILLED: {
      return {
        ...state,
        collection: action.payload.users,
        isCollectionLoaded: true,
        page: action.page,
        pagination: action.payload.pagination,
        query: action.query,
        statuses: {...state.statuses, [ACTIONS.FETCH_USERS]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_USERS_REJECTED: {
      return {
        ...state,
        statuses: {
          ...state.statuses,
          [ACTIONS.FETCH_USERS]: {errors: action.errors, status: action.status}
        }
      };
    }

    case ACTIONS.FETCH_USER: {
      return {...state, current: {}};
    }
    case ACTIONS.FETCH_USER_FULFILLED: {
      return {
        ...state,
        current: action.user,
        groupId: action.groupId,
        statuses: {...state.statuses, [ACTIONS.FETCH_USER]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_USER_REJECTED: {
      return {
        ...state,
        statuses: {
          ...state.statuses,
          [ACTIONS.FETCH_USER]: {errors: action.errors, status: action.status}
        }
      };
    }

    case ACTIONS.ASSIGN_USER_ATTRIBUTES: {
      return {
        ...state,
        formAttributes: {errors: state.formAttributes.errors, ...action.attributes}
      };
    }
    case ACTIONS.CLEAR_USER_ATTRIBUTES: {
      const randomPassword = generatePassword();
      const formAttributes = {
        ...INITIAL_FORM_ATTRIBUTES,
        password: randomPassword,
        password_confirmation: randomPassword,
        pin_code: generatePinCode(),
        ...action.defaultAttributes
      };

      return {...state, formAttributes};
    }

    case ACTIONS.CREATE_USER_FULFILLED: {
      return {...state, collection: [], current: action.user, isCollectionLoaded: false};
    }
    case ACTIONS.CREATE_USER_REJECTED: {
      return {...state, formAttributes: {...state.formAttributes, errors: action.errors}};
    }

    case ACTIONS.UPDATE_USER_FULFILLED: {
      return {...state, collection: [], current: action.user, isCollectionLoaded: false};
    }
    case ACTIONS.UPDATE_USER_REJECTED: {
      return {...state, formAttributes: {...state.formAttributes, errors: action.errors}};
    }

    case ACTIONS.DESTROY_USER_FULFILLED: {
      const newState = {...state};

      if (state.current.id === action.userId) {
        Object.assign(newState, {...newState, current: {}});
      }

      return Object.assign(newState, {collection: [], isCollectionLoaded: false});
    }

    case ACTIONS.ORDER_COURSES: {
      if (state.current.id === action.userId) {
        return {...state, current: {...state.current, courses: action.courses}};
      }

      return state;
    }

    default: {
      return state;
    }
  }
}
