import {notify} from "reapop";

export function notifySuccess(message) {
  return notify({title: I18n.t("notifications.titles.success"), message: message, status: "success"});
}

export function notifyError(message) {
  return notify({title: I18n.t("notifications.titles.error"), message: message, status: "error"});
}

export function notifyResponseError(message, response) {
  return response ? notifyError(message) : notifyServiceUnavailableError(message);
}

export function notifyServiceUnavailableError(message) {
  if (message) {
    return notifyError(`[${I18n.t("messages.service_unavailable")}] ${message}`);
  } else {
    return notifyError(I18n.t("messages.service_unavailable"));
  }
}
