import _ from "lodash";

import {SUCCESS_STATUS_CODE} from "../../constants";

export const ACTIONS = {
  CHANGE_USER_SCENARIOS_LOADING: "CHANGE_USER_SCENARIOS_LOADING",
  FETCH_USER_SCENARIOS: "FETCH_USER_SCENARIOS",
  FETCH_USER_SCENARIOS_FULFILLED: "FETCH_USER_SCENARIOS_FULFILLED",
  FETCH_USER_SCENARIOS_REJECTED: "FETCH_USER_SCENARIOS_REJECTED",
  FETCH_USER_SCENARIO: "FETCH_USER_SCENARIO",
  FETCH_USER_SCENARIO_FULFILLED: "FETCH_USER_SCENARIO_FULFILLED",
  FETCH_USER_SCENARIO_REJECTED: "FETCH_USER_SCENARIO_REJECTED",
  ASSIGN_USER_SCENARIO_ATTRIBUTES: "ASSIGN_USER_SCENARIO_ATTRIBUTES",
  CLEAR_USER_SCENARIO_ATTRIBUTES: "CLEAR_USER_SCENARIO_ATTRIBUTES",
  CREATE_USER_SCENARIO_FULFILLED: "CREATE_USER_SCENARIO_FULFILLED",
  CREATE_USER_SCENARIO_REJECTED: "CREATE_USER_SCENARIO_REJECTED",
  UPDATE_USER_SCENARIO_FULFILLED: "UPDATE_USER_SCENARIO_FULFILLED",
  UPDATE_USER_SCENARIO_REJECTED: "UPDATE_USER_SCENARIO_REJECTED",
  SET_USER_SCENARIO_REMOVING_STATE: "SET_USER_SCENARIO_REMOVING_STATE",
  DESTROY_USER_SCENARIO_FULFILLED: "DESTROY_USER_SCENARIO_FULFILLED",
  DESTROY_USER_SCENARIO_REJECTED: "DESTROY_USER_SCENARIO_REJECTED",
  DUPLICATE_USER_SCENARIO_FULFILLED: "DUPLICATE_USER_SCENARIO_FULFILLED",
  DUPLICATE_USER_SCENARIO_REJECTED: "DUPLICATE_USER_SCENARIO_REJECTED",
  COPY_USER_SCENARIO_FULFILLED: "COPY_USER_SCENARIO_FULFILLED",
  COPY_USER_SCENARIO_REJECTED: "COPY_USER_SCENARIO_REJECTED",
  MOVE_USER_SCENARIOS_FULFILLED: "MOVE_USER_SCENARIOS_FULFILLED",
  MOVE_USER_SCENARIOS_REJECTED: "MOVE_USER_SCENARIOS_REJECTED",
  SELECT_USER_SCENARIO: "SELECT_USER_SCENARIO",
  DESELECT_USER_SCENARIO: "DESELECT_USER_SCENARIO",
  DESELECT_ALL_USER_SCENARIOS: "DESELECT_ALL_USER_SCENARIOS"
};

const DEFAULT_SUCCESS_STATUS = {errors: {}, status: SUCCESS_STATUS_CODE};
const DEFAULT_STATUSES = {
  [ACTIONS.FETCH_USER_SCENARIOS]: {errors: {}, status: null},
  [ACTIONS.FETCH_USER_SCENARIO]: {errors: {}, status: null}
};

const INITIAL_FORM_ATTRIBUTES = {
  title: "",
  description: "",
  summary: "",
  scene: {},
  image: {},
  lines: [],
  participants: [],
  dialogflow_agents: [],
  slides: [],
  errors: {}
};

export default function reducer(state = {
  collection: [],
  current: {},
  formAttributes: INITIAL_FORM_ATTRIBUTES,
  selectedIds: [],
  packId: "",
  environmentId: null,
  isLoading: false,
  loadingCount: 0,
  isCollectionLoaded: false,
  pagination: {},
  page: 0,
  statuses: DEFAULT_STATUSES
}, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_USER_SCENARIOS_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);
      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.FETCH_USER_SCENARIOS: {
      return {
        ...state,
        packId: "",
        environmentId: null,
        collection: [],
        pagination: {},
        page: 0,
        isCollectionLoaded: false
      };
    }
    case ACTIONS.FETCH_USER_SCENARIOS_FULFILLED: {
      return {
        ...state,
        packId: action.packId,
        environmentId: action.environmentId,
        collection: action.payload.user_scenarios,
        pagination: action.payload.pagination,
        page: action.page,
        isCollectionLoaded: true,
        statuses: {...state.statuses, [ACTIONS.FETCH_USER_SCENARIOS]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_USER_SCENARIOS_REJECTED: {
      return {
        ...state,
        statuses: {...state.statuses, [ACTIONS.FETCH_USER_SCENARIOS]: {errors: action.errors, status: action.status}}
      };
    }

    case ACTIONS.FETCH_USER_SCENARIO: {
      return {...state, current: {}};
    }
    case ACTIONS.FETCH_USER_SCENARIO_FULFILLED: {
      return {
        ...state,
        current: action.userScenario,
        statuses: {...state.statuses, [ACTIONS.FETCH_USER_SCENARIO]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_USER_SCENARIO_REJECTED: {
      return {
        ...state,
        statuses: {...state.statuses, [ACTIONS.FETCH_USER_SCENARIO]: {errors: action.errors, status: action.status}}
      };
    }

    case ACTIONS.ASSIGN_USER_SCENARIO_ATTRIBUTES: {
      return {...state, formAttributes: {errors: state.formAttributes.errors, ...action.attributes}};
    }
    case ACTIONS.CLEAR_USER_SCENARIO_ATTRIBUTES: {
      return {...state, formAttributes: INITIAL_FORM_ATTRIBUTES};
    }

    case ACTIONS.CREATE_USER_SCENARIO_FULFILLED: {
      return {...state, isCollectionLoaded: false, collection: [], current: action.userScenario};
    }
    case ACTIONS.CREATE_USER_SCENARIO_REJECTED: {
      return {...state, formAttributes: {...state.formAttributes, errors: action.errors}};
    }

    case ACTIONS.UPDATE_USER_SCENARIO_FULFILLED: {
      return {...state, isCollectionLoaded: false, collection: [], current: action.userScenario};
    }
    case ACTIONS.UPDATE_USER_SCENARIO_REJECTED: {
      return {...state, formAttributes: {...state.formAttributes, errors: action.errors}};
    }

    case ACTIONS.SET_USER_SCENARIO_REMOVING_STATE: {
      const clonedCollection = [...state.collection];
      const userScenarioIndex = _.findIndex(clonedCollection, {id: action.userScenarioId});
      clonedCollection[userScenarioIndex] =
        {...clonedCollection[userScenarioIndex], removingState: action.removingState};
      return {...state, collection: clonedCollection};
    }
    case ACTIONS.DESTROY_USER_SCENARIO_FULFILLED: {
      return {...state, isCollectionLoaded: false, collection: []};
    }

    case ACTIONS.DUPLICATE_USER_SCENARIO_FULFILLED: {
      return {...state, isCollectionLoaded: false, collection: []};
    }

    case ACTIONS.MOVE_USER_SCENARIOS_FULFILLED: {
      return {...state, isCollectionLoaded: false, collection: [], selectedIds: []};
    }

    case ACTIONS.SELECT_USER_SCENARIO: {
      return {...state, selectedIds: [...state.selectedIds, action.userScenarioId]};
    }

    case ACTIONS.DESELECT_USER_SCENARIO: {
      return {...state, selectedIds: _.without(state.selectedIds, action.userScenarioId)};
    }

    case ACTIONS.DESELECT_ALL_USER_SCENARIOS: {
      return {...state, selectedIds: []};
    }
  }
  return state;
}
