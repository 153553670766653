import React, {Component} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import UserCell from "../UserCell";

class UserRow extends Component {
  durationOrDash = (duration, dayOfWeek) => {
    const {inputWeekStartsAt} = this.props;
    const currentDate = moment(inputWeekStartsAt).add(dayOfWeek, "day");

    if (currentDate.isAfter(moment(), "day")) {
      return "-";
    }

    return this.formatDuration(duration);
  };

  formatDuration(duration) {
    const secondsInMinute = 60;

    return Math.floor(duration / secondsInMinute);
  }

  scoreMuted(duration, dayOfWeek) {
    if (this.lowDuration(duration)) {
      return true;
    }

    const {group, inputWeekStartsAt} = this.props;
    const currentDate = moment(inputWeekStartsAt).add(dayOfWeek, "day");

    return (
      currentDate.isBefore(moment(group.starts_on), "day") ||
      currentDate.isAfter(moment(group.ends_on), "day")
    );
  }

  lowDuration(duration) {
    return this.formatDuration(duration) < 1;
  }

  renderSummaryScore(scoreValue) {
    return (
      <td
        className={classnames(
          "activity-stats__cell",
          "activity-stats__score",
          "activity-stats__score--summary",
          {"activity-stats__score--bad": this.lowDuration(scoreValue)}
        )}
      >
        {this.formatDuration(scoreValue)}
      </td>
    );
  }

  render() {
    const {group, userActivity} = this.props;
    const activityRanges = userActivity.activity_durations;

    return (
      <tr className="activity-stats__row">
        <td className="activity-stats__cell">
          <UserCell groupId={group.id} user={userActivity} />
        </td>

        {activityRanges.days.map((activityDuration, dayOfWeek) => (
          <td
            key={dayOfWeek}
            className={classnames("activity-stats__cell activity-stats__score", {
              "activity-stats__score--muted": this.scoreMuted(activityDuration, dayOfWeek)
            })}
          >
            {this.durationOrDash(activityDuration, dayOfWeek)}
          </td>
        ))}

        {this.renderSummaryScore(activityRanges.week_total)}

        {this.renderSummaryScore(activityRanges.weekly_average_duration)}

        {this.renderSummaryScore(activityRanges.total)}
      </tr>
    );
  }
}

UserRow.propTypes = {
  group: PropTypes.object.isRequired,
  inputWeekStartsAt: PropTypes.object.isRequired,
  userActivity: PropTypes.object.isRequired
};

export default UserRow;
