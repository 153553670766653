export function modeToBemModifier(mode) {
  return mode.identification.replace("_", "-");
}

export function isListeningMode(mode) {
  return mode.identification === "listening";
}

export function isReadingMode(mode) {
  return mode.identification === "reading";
}

export function isTranslatingMode(mode) {
  return mode.identification === "translating";
}

export function isFreeTalkMode(mode) {
  return mode.identification === "free_talk";
}
