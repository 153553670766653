import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {notify} from "reapop";

import {coursesPath} from "../../helpers/routesHelper";

import {assignCourseAttributes, clearCourseAttributes, createCourse} from "../../store/actions/coursesActions";

import ApplicationLayout from "../layouts/ApplicationLayout";
import CourseForm from "./_Form";

class New extends Component {
  componentDidMount() {
    this.props.clearCourseAttributes();
  }

  onSubmit = (course) => {
    this.props.createCourse(course, this.props.history);
  };

  render() {
    return (
      <ApplicationLayout headerTitle={I18n.t("courses.titles.edit")}>
        <div className="container">
          <div className="row py-4">
            <div className="col-12">
              <CourseForm
                backPath={coursesPath()}
                formAttributes={this.props.courses.formAttributes}
                assignCourseAttributes={this.props.assignCourseAttributes}
                onSubmit={this.onSubmit}
                notify={this.props.notify}
              />
            </div>
          </div>
        </div>
      </ApplicationLayout>
    );
  }
}

New.propTypes = {
  courses: PropTypes.object.isRequired,
  assignCourseAttributes: PropTypes.func.isRequired,
  createCourse: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    courses: state.courses
  }
}

const mapActionsToProps = {
  assignCourseAttributes,
  clearCourseAttributes,
  createCourse,
  notify
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(New));
