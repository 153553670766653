import {SUCCESS_STATUS_CODE} from "../../constants";

export const ACTIONS = {
  CHANGE_CHARACTERS_LOADING: "CHANGE_CHARACTERS_LOADING",
  FETCH_CHARACTERS: "FETCH_CHARACTERS",
  FETCH_CHARACTERS_FULFILLED: "FETCH_CHARACTERS_FULFILLED",
  FETCH_CHARACTERS_REJECTED: "FETCH_CHARACTERS_REJECTED"
};

const DEFAULT_SUCCESS_STATUS = {errors: {}, status: SUCCESS_STATUS_CODE};
const DEFAULT_STATUSES = {
  [ACTIONS.FETCH_CHARACTERS]: {errors: {}, status: null}
};

export default function reducer(state = {
  collection: [],
  isLoading: false,
  loadingCount: 0,
  isCollectionLoaded: false,
  statuses: DEFAULT_STATUSES
}, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_CHARACTERS_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);
      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.FETCH_CHARACTERS: {
      return {...state, collection: [], isCollectionLoaded: false};
    }
    case ACTIONS.FETCH_CHARACTERS_FULFILLED: {
      return {
        ...state,
        collection: action.payload.characters,
        isCollectionLoaded: true,
        statuses: {...state.statuses, [ACTIONS.FETCH_CHARACTERS]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_CHARACTERS_REJECTED: {
      return {
        ...state,
        statuses: {
          ...state.statuses,
          [ACTIONS.FETCH_CHARACTERS]: {errors: action.errors, status: action.status}
        }
      };
    }
  }
  return state;
}
