import React, {Component} from "react";

class ScrollUp extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

export default ScrollUp;
