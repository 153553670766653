import React, {Fragment, memo} from "react";

import Participant from "./Participant";

const DialogflowAgents = ({dialogflowAgents}) => {
  return (
    <Fragment>
      <table className="scenario-edit__table scenario-edit__table--agents">
        <thead>
          <tr>
            <th>{I18n.t("mongoid.attributes.scenario_dialogflow_agent.dialogflow_agent")}</th>
            <th>{I18n.t("mongoid.attributes.scenario_dialogflow_agent.user_participant")}</th>
            <th>{I18n.t("mongoid.attributes.scenario_dialogflow_agent.chatbot_participant")}</th>
            <th aria-label="Empty space" />
          </tr>
        </thead>

        <tbody>
          {dialogflowAgents.map(dialogflowAgent => (
            <tr key={dialogflowAgent.id}>
              <td>
                <div className="scenario-edit__form-value font-weight-bold">
                  {dialogflowAgent.dialogflow_agent.name}
                </div>
              </td>

              <td>
                <Participant
                  avatarUrl={dialogflowAgent.user_participant.avatar_url}
                  isBold
                  name={dialogflowAgent.user_participant.role}
                />
              </td>

              <td>
                <Participant
                  avatarUrl={dialogflowAgent.chatbot_participant.avatar_url}
                  isBold
                  name={dialogflowAgent.chatbot_participant.role}
                />
              </td>

              <td />
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default memo(DialogflowAgents);
