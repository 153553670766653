import React, {Component} from "react";
import PropTypes from "prop-types";
import Delayed from "react-delayed";

import Loader from "./Loader";

class MainLoader extends Component {
  render() {
    if (!this.props.isShow) {
      return null;
    }

    return (
      <Delayed mounted mountAfter={500}>
        <div className="dimmer dimmer_white dimmer_full_screen">
          <Loader size="large" isShow/>
        </div>
      </Delayed>
    )
  }
}

MainLoader.propTypes = {
  isShow: PropTypes.bool
};

export default MainLoader;
