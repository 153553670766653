import _ from "lodash";

import {LIMITED_PACK_VISIBILITY, PRIVATE_PACK_VISIBILITY, PUBLIC_PACK_VISIBILITY} from "../constants";

export function isPrivate(pack) {
  return pack.visibility === PRIVATE_PACK_VISIBILITY;
}

export function isPublic(pack) {
  return pack.visibility === PUBLIC_PACK_VISIBILITY;
}

export function isLimited(pack) {
  return pack.visibility === LIMITED_PACK_VISIBILITY;
}

export function defaultPack(packs) {
  return _.find(packs, {is_default: true});
}
