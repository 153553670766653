import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {newUserPasswordPath} from "helpers/routesHelper";
import {Link, withRouter} from "react-router-dom";
import {notify} from "reapop";
import _ from "lodash";
import handleFieldChange from "helpers/handleFieldChange";

import {
  assignSessionAttributes,
  clearSessionAttributes,
  createSession,
  disableSessionsResetCaptcha,
  enableSessionsSubmit
} from "store/actions/sessionsActions";

import SvgIcon from "components/SvgIcon";
import UnauthenticatedLayout from "views/layouts/UnauthenticatedLayout";
import Recaptcha from "../common/Recaptcha";

class New extends Component {
  componentDidMount() {
    this.props.clearSessionAttributes();
  }

  onChange = event => {
    const {sessions, assignSessionAttributes} = this.props;
    const attributes = sessions.formAttributes;
    assignSessionAttributes({...attributes, ...handleFieldChange(event, attributes)});
  };

  onSubmit = event => {
    event.preventDefault();

    if (this.isValid) {
      const {sessions, createSession, history} = this.props;
      createSession(_.omit(sessions.formAttributes, "errors"), history);
    } else {
      this.props.notify({
        message: I18n.t("devise.failure.not_found_in_database", {
          authentication_keys: [I18n.t("mongoid.attributes.user.email")]
        }),
        status: "error",
        title: I18n.t("notifications.titles.error")
      });
    }
  };

  get isValid() {
    const attributes = this.props.sessions.formAttributes;
    const errors = {};

    _.each(["email", "password"], field => {
      if (!attributes[field].trim()) {
        errors[field] = [I18n.t("errors.messages.blank")];
      }
    });

    const attributesWithNewErrors = {...attributes, errors};
    this.props.assignSessionAttributes(attributesWithNewErrors);

    return _.isEmpty(errors);
  }

  verifyCaptchaCallback = response => {
    const {sessions, assignSessionAttributes, enableSessionsSubmit} = this.props;
    const attributes = sessions.formAttributes;
    enableSessionsSubmit();
    assignSessionAttributes({...attributes, recaptcha_response: response});
  };

  render() {
    const {disableSessionsResetCaptcha, sessions} = this.props;
    const attributes = sessions.formAttributes;
    const {isSubmitDisabled} = sessions;

    const {isLoading} = sessions;

    return (
      <UnauthenticatedLayout isLoading={isLoading}>
        <div className="login-section">
          <SvgIcon className="login-section__title" icon="smart-tutor-logo" />
          <div className="login-section__subtitle">{I18n.t("log_in")}</div>
          <form>
            <input
              autoFocus
              className="login-section__input mt-3"
              name="email"
              onChange={this.onChange}
              placeholder={I18n.t("mongoid.attributes.user.email_or_username")}
              type="text"
              value={attributes.email}
            />

            <input
              autoComplete="off"
              className="login-section__input mt-3"
              name="password"
              onChange={this.onChange}
              placeholder={I18n.t("mongoid.attributes.user.password")}
              type="password"
              value={attributes.password}
            />

            {sessions.isShowCaptcha && (
              <div className="mt-3">
                <Recaptcha
                  disableResetCaptcha={disableSessionsResetCaptcha}
                  isReset={sessions.isResetCaptcha}
                  verifyCallback={this.verifyCaptchaCallback}
                />
              </div>
            )}

            <button
              className="login-section__submit button mt-3"
              disabled={isLoading || isSubmitDisabled}
              onClick={this.onSubmit}
            >
              {I18n.t("log_in")}
            </button>

            <div className="mt-4 pt-1">
              <a
                className="login-section__secondary-button button button_color_grey"
                href="https://www.plusone.space"
              >
                {I18n.t("sessions.sign_up")}
              </a>
              <Link
                className="login-section__secondary-button button button_color_grey ml-2"
                to={newUserPasswordPath()}
              >
                {I18n.t("sessions.forgot_password")}
              </Link>
            </div>
          </form>
        </div>
      </UnauthenticatedLayout>
    );
  }
}

New.propTypes = {
  assignSessionAttributes: PropTypes.func.isRequired,
  clearSessionAttributes: PropTypes.func.isRequired,
  createSession: PropTypes.func.isRequired,
  disableSessionsResetCaptcha: PropTypes.func.isRequired,
  enableSessionsSubmit: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
  sessions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    sessions: state.sessions
  };
}

const mapActionsToProps = {
  assignSessionAttributes,
  clearSessionAttributes,
  createSession,
  disableSessionsResetCaptcha,
  enableSessionsSubmit,
  notify
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(New));
