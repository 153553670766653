import {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchDashboardUserError} from "helpers/errorsHelpers";

import {fetchDashboardUser} from "store/actions/dashboardUsersActions";

class FetchDashboardUser extends Component {
  componentDidMount() {
    this.fetchDashboardUser(true);
  }

  componentDidUpdate() {
    this.fetchDashboardUser(false);
  }

  fetchDashboardUser(isComponentMount) {
    const {id, groupId, dashboardUsers} = this.props;
    const isReasonToFetch =
      isComponentMount || dashboardUsers.current.id !== id || dashboardUsers.groupId !== groupId;

    if (
      isReasonToFetch &&
      !dashboardUsers.isLoading &&
      !isFetchDashboardUserError(dashboardUsers)
    ) {
      this.props.fetchDashboardUser(id, {groupId});
    }
  }

  render() {
    return null;
  }
}

FetchDashboardUser.propTypes = {
  dashboardUsers: PropTypes.object.isRequired,
  fetchDashboardUser: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  id: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    dashboardUsers: state.dashboardUsers
  };
}

const mapActionsToProps = {
  fetchDashboardUser
};

export default connect(mapStateToProps, mapActionsToProps)(FetchDashboardUser);
