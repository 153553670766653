import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isAdmin} from "../../helpers/userRolesHelper";
import {isFetchCompanyError} from "../../helpers/errorsHelpers";

import {fetchCompany} from "../../store/actions/companiesActions";

class FetchCompany extends Component {
  componentDidMount() {
    this.fetchCompany(true);
  }

  componentDidUpdate() {
    this.fetchCompany(false);
  }

  fetchCompany(isComponentMount) {
    const {currentUser, companies} = this.props;
    const isReasonToFetch = isComponentMount || !companies.current.id;

    if (isReasonToFetch && !isAdmin(currentUser) && !companies.isLoading && !isFetchCompanyError(companies)) {
      this.props.fetchCompany();
    }
  }

  render() {
    return null;
  }
}

FetchCompany.propTypes = {
  companies: PropTypes.object.isRequired,
  fetchCompany: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    currentUser: state.profiles.current,
    companies: state.companies
  }
}

const mapActionsToProps = {
  fetchCompany
};

export default connect(mapStateToProps, mapActionsToProps)(FetchCompany);
