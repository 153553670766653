import {SUCCESS_STATUS_CODE} from "../../constants";
import _ from "lodash";

export const ACTIONS = {
  CHANGE_COURSES_LOADING: "CHANGE_COURSES_LOADING",
  FETCH_COURSES: "FETCH_COURSES",
  FETCH_COURSES_FULFILLED: "FETCH_COURSES_FULFILLED",
  FETCH_COURSES_REJECTED: "FETCH_COURSES_REJECTED",
  FETCH_COURSE: "FETCH_COURSE",
  FETCH_COURSE_FULFILLED: "FETCH_COURSE_FULFILLED",
  FETCH_COURSE_REJECTED: "FETCH_COURSE_REJECTED",
  ASSIGN_COURSE_ATTRIBUTES: "ASSIGN_COURSE_ATTRIBUTES",
  CLEAR_COURSE_ATTRIBUTES: "CLEAR_COURSE_ATTRIBUTES",
  CREATE_COURSE_FULFILLED: "CREATE_COURSE_FULFILLED",
  CREATE_COURSE_REJECTED: "CREATE_COURSE_REJECTED",
  UPDATE_COURSE_FULFILLED: "UPDATE_COURSE_FULFILLED",
  UPDATE_COURSE_REJECTED: "UPDATE_COURSE_REJECTED",
  SET_COURSE_REMOVING_STATE: "SET_COURSE_REMOVING_STATE",
  DESTROY_COURSE_FULFILLED: "DESTROY_COURSE_FULFILLED",
  DESTROY_COURSE_REJECTED: "DESTROY_COURSE_REJECTED"
};

const DEFAULT_SUCCESS_STATUS = {errors: {}, status: SUCCESS_STATUS_CODE};
const DEFAULT_STATUSES = {
  [ACTIONS.FETCH_COURSES]: {errors: {}, status: null},
  [ACTIONS.FETCH_COURSE]: {errors: {}, status: null}
};

const INITIAL_FORM_ATTRIBUTES = {
  title: "",
  users: [],
  user_scenarios: [],
  image: {},
  course_tags: [],
  errors: {}
};

export default function reducer(state = {
  collection: [],
  current: {},
  formAttributes: INITIAL_FORM_ATTRIBUTES,
  isCollectionLoaded: false,
  isLoading: false,
  loadingCount: 0,
  pagination: {},
  page: 0,
  statuses: DEFAULT_STATUSES
}, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_COURSES_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);
      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.FETCH_COURSES: {
      return {...state, collection: [], pagination: {}, page: 0, isCollectionLoaded: false};
    }
    case ACTIONS.FETCH_COURSES_FULFILLED: {
      return {...state,
        collection: action.payload.courses,
        pagination: action.payload.pagination,
        page: action.page,
        isCollectionLoaded: true,
        statuses: {...state.statuses, [ACTIONS.FETCH_COURSES]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_COURSES_REJECTED: {
      return {
        ...state,
        statuses: {...state.statuses, [ACTIONS.FETCH_COURSES]: {errors: action.errors, status: action.status}}
      };
    }

    case ACTIONS.FETCH_COURSE: {
      return {...state, current: {}};
    }
    case ACTIONS.FETCH_COURSE_FULFILLED: {
      return {
        ...state,
        current: action.course,
        statuses: {...state.statuses, [ACTIONS.FETCH_COURSE]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_COURSE_REJECTED: {
      return {
        ...state,
        statuses: {...state.statuses, [ACTIONS.FETCH_COURSE]: {errors: action.errors, status: action.status}}
      };
    }

    case ACTIONS.ASSIGN_COURSE_ATTRIBUTES: {
      return {...state, formAttributes: {errors: state.formAttributes.errors, ...action.attributes}};
    }
    case ACTIONS.CLEAR_COURSE_ATTRIBUTES: {
      return {...state, formAttributes: INITIAL_FORM_ATTRIBUTES};
    }

    case ACTIONS.CREATE_COURSE_FULFILLED: {
      return {...state, isCollectionLoaded: false, collection: [], current: action.course};
    }
    case ACTIONS.CREATE_COURSE_REJECTED: {
      return {...state, formAttributes: {...state.formAttributes, errors: action.errors}};
    }

    case ACTIONS.UPDATE_COURSE_FULFILLED: {
      return {...state, isCollectionLoaded: false, collection: [], current: action.course};
    }
    case ACTIONS.UPDATE_COURSE_REJECTED: {
      return {...state, formAttributes: {...state.formAttributes, errors: action.errors}};
    }

    case ACTIONS.SET_COURSE_REMOVING_STATE: {
      const clonedCollection = [...state.collection];
      const courseIndex = _.findIndex(clonedCollection, {id: action.courseId});
      clonedCollection[courseIndex] = {...clonedCollection[courseIndex], removingState: action.removingState};
      return {...state, collection: clonedCollection};
    }
    case ACTIONS.DESTROY_COURSE_FULFILLED: {
      let newState = {...state};

      if (state.current.id === action.courseId) {
        newState = {...newState, current: {}};
      }

      return {...newState, isCollectionLoaded: false, collection: []};
    }
  }
  return state;
}
