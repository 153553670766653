import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {VisibleIf} from "react-pundit";

import {capitalize} from "../../../helpers/capitalize";
import {packCategoriesPath} from "../../../helpers/routesHelper";

import {
  assignPackAttributes,
  clearPackAttributes,
  createPack
} from "../../../store/actions/packsActions";
import {closeModal} from "../../../store/actions/modalsActions";

import PackFormModal, {MODAL_NAME} from "../../packs/_FormModal";
import PackForm from "../../packs/_Form";
import PacksImport from "./PacksImport";
import SearchBar from "./SearchBar";

class TopPanel extends Component {
  get packPolicyCreateAction() {
    return `Create${capitalize(this.props.categoryId)}`;
  }

  onPackFormSubmit = attributes => {
    this.props.createPack(attributes, {onSuccess: () => this.props.closeModal(MODAL_NAME)});
  };

  render() {
    const {
      assignPackAttributes,
      categoryId,
      clearPackAttributes,
      company,
      packCategory,
      packs,
      query
    } = this.props;
    const title = I18n.t(`packs.categories.${categoryId}`, {company_title: company.title});
    const iconUrl = packCategory.avatar.thumb_url;

    return (
      <div className="top-panel">
        <div className="top-panel__left">
          <Link
            className="top-panel__button top-panel__button_back hide-on-sm-and-down"
            to={packCategoriesPath()}
          />
          <div className="avatar top-panel__owner-icon">
            <img alt="" src={iconUrl} />
          </div>
          <div className="top-panel__title">{title}</div>
        </div>
        <div className="top-panel__right top-panel__right--pack-category">
          <SearchBar category={categoryId} query={query} />
          <VisibleIf action={this.packPolicyCreateAction} type="Pack">
            <PacksImport />
            <PackFormModal
              buttonClass="top-panel__button_create top-panel__button_margin_sm"
              title={I18n.t("packs.add_pack")}
            >
              <PackForm
                assignPackAttributes={assignPackAttributes}
                clearPackAttributes={clearPackAttributes}
                formAttributes={packs.formAttributes}
                isLoading={packs.isLoading}
                onSubmit={this.onPackFormSubmit}
              />
            </PackFormModal>
          </VisibleIf>
        </div>
      </div>
    );
  }
}

TopPanel.defaultProps = {
  query: ""
};

TopPanel.propTypes = {
  assignPackAttributes: PropTypes.func.isRequired,
  categoryId: PropTypes.string.isRequired,
  clearPackAttributes: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  createPack: PropTypes.func.isRequired,
  packs: PropTypes.object.isRequired,
  query: PropTypes.string
};

function mapStateToProps(state) {
  return {
    company: state.companies.current,
    packCategory: state.packCategories.current,
    packs: state.packs
  };
}

const mapActionsToProps = {
  assignPackAttributes,
  clearPackAttributes,
  closeModal,
  createPack
};

export default connect(mapStateToProps, mapActionsToProps)(TopPanel);
