import {combineReducers} from "redux";
import {reducer as notifications} from "reapop";

import {DEFAULT_NOTIFICATION_PROPS} from "../../constants";

import audioPlayers from "./audioPlayersReducer";
import characters from "./charactersReducer";
import companies from "./companiesReducer";
import contextMenus from "./contextMenusReducer";
import courseCategories from "./courseCategoriesReducer";
import courses from "./coursesReducer";
import coursesUsers from "./coursesUsersReducer";
import dashboardUsers from "./dashboardUsersReducer";
import dialogflowAgents from "./dialogflowAgentsReducer";
import dropdownMenu from "./dropdownMenuReducer";
import englishScores from "./englishScoresReducer";
import environments from "./environmentsReducer";
import filter from "./filterReducer";
import flash from "./flashReducer";
import groups from "./groupsReducer";
import metrics from "./metricsReducer";
import modals from "./modalsReducer";
import packCategories from "./packCategoriesReducer";
import packShares from "./packSharesReducer";
import packs from "./packsReducer";
import passwords from "./passwordsReducer";
import profiles from "./profilesReducer";
import sessions from "./sessionsReducer";
import tags from "./tagsReducer";
import targetScores from "./targetScoresReducer";
import userActivities from "./userActivitiesReducer";
import userScenarioHeaders from "./userScenarioHeadersReducer";
import userScenarios from "./userScenariosReducer";
import users from "./usersReducer";

export default combineReducers({
  audioPlayers,
  characters,
  companies,
  contextMenus,
  courseCategories,
  courses,
  coursesUsers,
  dashboardUsers,
  dialogflowAgents,
  dropdownMenu,
  englishScores,
  environments,
  filter,
  flash,
  groups,
  metrics,
  modals,
  notifications: notifications(DEFAULT_NOTIFICATION_PROPS),
  packCategories,
  packShares,
  packs,
  passwords,
  profiles,
  sessions,
  tags,
  targetScores,
  userActivities,
  userScenarioHeaders,
  userScenarios,
  users
});
