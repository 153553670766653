import {SUCCESS_STATUS_CODE} from "../../constants";

export const ACTIONS = {
  CHANGE_ENVIRONMENTS_LOADING: "CHANGE_ENVIRONMENTS_LOADING",
  FETCH_ENVIRONMENTS: "FETCH_ENVIRONMENTS",
  FETCH_ENVIRONMENTS_FULFILLED: "FETCH_ENVIRONMENTS_FULFILLED",
  FETCH_ENVIRONMENTS_REJECTED: "FETCH_ENVIRONMENTS_REJECTED"
};

const DEFAULT_SUCCESS_STATUS = {errors: {}, status: SUCCESS_STATUS_CODE};
const DEFAULT_STATUSES = {
  [ACTIONS.FETCH_ENVIRONMENTS]: {errors: {}, status: null}
};

export default function reducer(state = {
  collection: [],
  isLoading: false,
  loadingCount: 0,
  isCollectionLoaded: false,
  statuses: DEFAULT_STATUSES
}, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_ENVIRONMENTS_LOADING: {
      const newLoadingCount = state.loadingCount + (action.isLoading ? 1 : -1);
      return {...state, isLoading: newLoadingCount > 0, loadingCount: newLoadingCount};
    }

    case ACTIONS.FETCH_ENVIRONMENTS: {
      return {...state, collection: [], isCollectionLoaded: false};
    }
    case ACTIONS.FETCH_ENVIRONMENTS_FULFILLED: {
      return {
        ...state,
        collection: action.payload.environments,
        isCollectionLoaded: true,
        statuses: {...state.statuses, [ACTIONS.FETCH_ENVIRONMENTS]: DEFAULT_SUCCESS_STATUS}
      };
    }
    case ACTIONS.FETCH_ENVIRONMENTS_REJECTED: {
      return {
        ...state,
        statuses: {
          ...state.statuses,
          [ACTIONS.FETCH_ENVIRONMENTS]: {errors: action.errors, status: action.status}
        }
      };
    }
  }
  return state;
}
