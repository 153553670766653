import {PunditPolicy} from "react-pundit";

import {isAdmin} from "../helpers/userRolesHelper";

class TagPolicy extends PunditPolicy {
  constructor() {
    super("Tag");
  }

  Index(model, user) {
    return isAdmin(user);
  }

  Update(model, user) {
    return isAdmin(user);
  }
}

export default TagPolicy;
