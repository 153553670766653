import React, {Fragment, useCallback} from "react";
import Select2 from "react-select2-wrapper";
import _ from "lodash";

import {MAX_DIALOGFLOW_AGENTS_COUNT} from "../../../../constants";

import {renderSelect2Participant} from "../../../../helpers/participantsHelper";
import generateObjectId from "../../../../helpers/generateObjectId";

import ContextMenu from "../../context_menu/ContextMenu";
import DeleteDialogflowAgentModal from "./DeleteDialogflowAgentModal";
import DialogflowAgentsModal from "./DialogflowAgentsModal";

const DialogflowAgents = ({
  assignUserScenarioAttributes,
  dialogflowAgents,
  formAttributes,
  renderErrors
}) => {
  const {participants} = formAttributes;

  const handleParticipantChange = useCallback(
    (event, dialogflowAgentIndex, participantAttribute) => {
      const participantId = event.target.value;
      const updatedDialogflowAgents = [...formAttributes.dialogflow_agents];
      updatedDialogflowAgents[dialogflowAgentIndex] = {
        ...updatedDialogflowAgents[dialogflowAgentIndex],
        [participantAttribute]: participantId
      };
      assignUserScenarioAttributes({...formAttributes, dialogflow_agents: updatedDialogflowAgents});
    },
    [assignUserScenarioAttributes, formAttributes]
  );

  const handleUserParticipantChange = useCallback(
    (event, dialogflowAgentIndex) => {
      handleParticipantChange(event, dialogflowAgentIndex, "user_participant_id");
    },
    [handleParticipantChange]
  );

  const handleChatbotParticipantChange = useCallback(
    (event, dialogflowAgentIndex) => {
      handleParticipantChange(event, dialogflowAgentIndex, "chatbot_participant_id");
    },
    [handleParticipantChange]
  );

  const participantSelect2Data = _.map(participants, participant => ({
    avatar_url: participant.avatar_url,
    id: participant.id,
    text: participant.role
  }));

  const buildDialogflowAgent = useCallback(() => {
    return {
      chatbot_participant_id: participants[0].id,
      dialogflow_agent_id: dialogflowAgents.collection[0].id,
      id: generateObjectId(),
      user_participant_id: participants[0].id
    };
  }, [dialogflowAgents.collection, participants]);

  const addDialogflowAgent = useCallback(() => {
    const newDialogflowAgent = buildDialogflowAgent();
    const updatedDialogflowAgents = [...formAttributes.dialogflow_agents, newDialogflowAgent];

    assignUserScenarioAttributes({...formAttributes, dialogflow_agents: updatedDialogflowAgents});
  }, [assignUserScenarioAttributes, buildDialogflowAgent, formAttributes]);

  const getDialogflowAgentName = useCallback(
    scenarioDialogflowAgent =>
      _.find(dialogflowAgents.collection, {id: scenarioDialogflowAgent.dialogflow_agent_id}).name,
    [dialogflowAgents.collection]
  );

  const isMaxDialogflowAgents =
    formAttributes.dialogflow_agents.length >= MAX_DIALOGFLOW_AGENTS_COUNT;

  return (
    <Fragment>
      <table className="scenario-edit__table scenario-edit__table--agents">
        <thead>
          <tr>
            <th>{I18n.t("mongoid.attributes.scenario_dialogflow_agent.dialogflow_agent")}</th>
            <th>{I18n.t("mongoid.attributes.scenario_dialogflow_agent.user_participant")}</th>
            <th>{I18n.t("mongoid.attributes.scenario_dialogflow_agent.chatbot_participant")}</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {formAttributes.dialogflow_agents.map((dialogflowAgent, index) => (
            <tr key={dialogflowAgent.id}>
              <td>
                {getDialogflowAgentName(dialogflowAgent)}
                <DialogflowAgentsModal
                  dialogflowAgents={dialogflowAgents}
                  formAttributes={formAttributes}
                  scenarioDialogflowAgent={dialogflowAgent}
                />
              </td>

              <td>
                <Select2
                  className="select2-participant select2-transparent"
                  data={participantSelect2Data}
                  name={`dialogflow_agents.${index}.user_participant_id`}
                  onChange={event => handleUserParticipantChange(event, index)}
                  options={{
                    minimumResultsForSearch: -1,
                    templateResult: renderSelect2Participant,
                    templateSelection: renderSelect2Participant
                  }}
                  value={dialogflowAgent.user_participant_id}
                />
              </td>

              <td>
                <Select2
                  className="select2-participant select2-transparent"
                  data={participantSelect2Data}
                  name={`dialogflow_agents.${index}.chatbot_participant_id`}
                  onChange={event => handleChatbotParticipantChange(event, index)}
                  options={{
                    minimumResultsForSearch: -1,
                    templateResult: renderSelect2Participant,
                    templateSelection: renderSelect2Participant
                  }}
                  value={dialogflowAgent.chatbot_participant_id}
                />
                {renderErrors(`dialogflow_agents.${index}.chatbot_participant_id`)}
              </td>

              <td>
                <div className="scenario-edit__table-actions">
                  <ContextMenu className="scenario-edit__actions-context-menu">
                    <DeleteDialogflowAgentModal
                      assignUserScenarioAttributes={assignUserScenarioAttributes}
                      dialogflowAgentId={dialogflowAgent.id}
                      formAttributes={formAttributes}
                    />
                  </ContextMenu>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="text-center mt-4">
        {!isMaxDialogflowAgents && (
          <button
            aria-label="Add chatbot"
            className="button button_theme_plus-grey"
            onClick={addDialogflowAgent}
          />
        )}
      </div>
    </Fragment>
  );
};

export default DialogflowAgents;
