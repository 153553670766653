import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {notify} from "reapop";

import {coursePath} from "../../helpers/routesHelper";
import {isFetchCourseError} from "../../helpers/errorsHelpers";

import {assignCourseAttributes, clearCourseAttributes, updateCourse} from "../../store/actions/coursesActions";

import ApplicationLayout from "../layouts/ApplicationLayout";
import CourseForm from "./_Form";
import FetchCourse from "../../components/data_loaders/FetchCourse";

class Edit extends Component {
  componentDidMount() {
    this.assignCourseAttributes(true);
  }

  componentDidUpdate() {
    this.assignCourseAttributes(false);
  }

  assignCourseAttributes = (isComponentMount) => {
    const {id, courses} = this.props;
    const course = courses.current;
    const {formAttributes} = courses;

    if (course.id !== id || (!isComponentMount && formAttributes.id === id)) {
      return;
    }

    this.props.assignCourseAttributes({...course, errors: {}});
  };

  onSubmit = (course) => {
    this.props.updateCourse(course, {
      onSuccess: () => this.props.history.push(coursePath(course.id))
    });
  };

  render() {
    const {id, courses} = this.props;

    const isLoaded = courses.formAttributes.id === id;
    const isLoadingError = isFetchCourseError(courses);
    const isLoading = (!isLoaded && !isLoadingError) || courses.isLoading;

    return (
      <ApplicationLayout headerTitle={I18n.t("courses.titles.edit")} isLoading={isLoading}>
        <FetchCourse id={id}/>
        {
          isLoaded &&
          <div className="container">
            <div className="row py-4">
              <div className="col-12">
                <CourseForm
                  backPath={coursePath(id)}
                  formAttributes={this.props.courses.formAttributes}
                  assignCourseAttributes={this.props.assignCourseAttributes}
                  onSubmit={this.onSubmit}
                  notify={this.props.notify}
                />
              </div>
            </div>
          </div>
        }
      </ApplicationLayout>
    );
  }
}

Edit.propTypes = {
  id: PropTypes.string.isRequired,
  courses: PropTypes.object.isRequired,
  assignCourseAttributes: PropTypes.func.isRequired,
  updateCourse: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    courses: state.courses
  }
}

const mapActionsToProps = {
  assignCourseAttributes,
  clearCourseAttributes,
  updateCourse,
  notify
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Edit));
