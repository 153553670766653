import React, {useCallback} from "react";
import PropTypes from "prop-types";

import {isFreeTalkMode, isListeningMode, isReadingMode} from "../../../../../helpers/modesHelper";

import Line from "./Line";
import VRVersion from "./VRVersion";

const Body = ({metric, user}) => {
  const completedScenario = metric.completed_scenario;
  const {mode, lines, vr_version: vrVersion} = completedScenario;

  const isListeningSummaryLine = useCallback(line => isListeningMode(mode) && line.summary, [mode]);

  const isUserLine = useCallback(
    line => {
      const userParticipant = completedScenario.user_participant;

      if (userParticipant) return line.participant.id === userParticipant.id;

      return isListeningSummaryLine(line);
    },
    [completedScenario.user_participant, isListeningSummaryLine]
  );

  return (
    <tbody>
      {lines.map((line, index) => (
        <Line
          key={line.id}
          index={index}
          isListeningSummaryLine={isListeningSummaryLine(line)}
          isUserLine={isUserLine(line)}
          line={line}
          metric={metric}
          user={user}
        />
      ))}
      <tr>
        <td colSpan={2} />
        <td className="text-left">
          {isReadingMode(mode) && (
            <div className="scenario-review-lines__mispronounced-words-label">
              {I18n.t("dashboard.scenario_review.mispronounced_words")}
            </div>
          )}
        </td>
        {!isFreeTalkMode(mode) && <td />}
        <td className="text-right" colSpan={3}>
          <VRVersion vrVersion={vrVersion} />
        </td>
      </tr>
    </tbody>
  );
};

Body.propTypes = {
  metric: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default Body;
