import React, {useCallback} from "react";
import _ from "lodash";

import ConfirmationModal from "../../modals/ConfirmationModal";
import ContextMenuItem from "../../context_menu/ContextMenuItem";

const MODAL_NAME = "USER_SCENARIO_DELETE_DIALOGFLOW_AGENT_MODAL";

const DeleteDialogflowAgentModal = ({
  assignUserScenarioAttributes,
  dialogflowAgentId,
  formAttributes
}) => {
  const modalName = `${MODAL_NAME}_${dialogflowAgentId}`;

  const handleSubmit = useCallback(
    closeModal => {
      const changedDialogflowAgents = _.reject(formAttributes.dialogflow_agents, {
        id: dialogflowAgentId
      });
      assignUserScenarioAttributes({...formAttributes, dialogflow_agents: changedDialogflowAgents});
      closeModal();
    },
    [assignUserScenarioAttributes, dialogflowAgentId, formAttributes]
  );

  return (
    <ConfirmationModal
      button={<ContextMenuItem color="red">{I18n.t("delete")}</ContextMenuItem>}
      message={I18n.t("user_scenarios.popups.confirm_delete_dialogflow_agent")}
      modalName={modalName}
      onSubmit={handleSubmit}
    />
  );
};

export default DeleteDialogflowAgentModal;
