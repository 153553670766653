import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import _ from "lodash";

import {COMPANY_PACK_CATEGORY, GENERAL_PACK_CATEGORY} from "../../constants";

import {isPublic} from "../../helpers/packsHelper";
import {scenarioTags} from "../../helpers/scenariosHelper";
import {isFetchPacksError, isFetchUserScenariosError} from "../../helpers/errorsHelpers";
import {isAdmin} from "../../helpers/userRolesHelper";

import {closeModal, openModal} from "../../store/actions/modalsActions";
import {assignCourseAttributes} from "../../store/actions/coursesActions";

import DefaultModal from "../../components/shared/modals/DefaultModal";
import FetchPacks from "../../components/data_loaders/FetchPacks";
import FetchUserScenarios from "../../components/data_loaders/FetchUserScenarios";
import Loader from "../../components/shared/Loader";

const PAGES = {
  PACKS: "PACKS",
  USER_SCENARIOS: "USER_SCENARIOS"
};
const MODAL_NAME = "COURSE_ADD_SCENARIO_MODAL";

class AddScenarioModal extends Component {
  setDefaultPage = () => {
    this.changePage(PAGES.PACKS);
  };

  get modalTitle() {
    const {options} = this.props.modals;
    const {currentUser, company, userScenarios} = this.props;

    switch (options.page) {
      case PAGES.PACKS:
        return isAdmin(currentUser) ? I18n.t("packs.categories.general") : company.title;
      case PAGES.USER_SCENARIOS:
        const userScenario = _.find(userScenarios.collection, {id: options.packId});
        return userScenario ? userScenario.title : "";
      default:
        return "";
    }
  }

  onBack() {
    const {page} = this.props.modals.options;

    if (page === PAGES.USER_SCENARIOS) {
      return () => this.changePage(PAGES.PACKS);
    }

    return null;
  }

  changePage = (page, packId) => {
    this.props.openModal(MODAL_NAME, {page, packId});
  };

  addUserScenario = (userScenario) => {
    const {formAttributes, assignCourseAttributes} = this.props;

    assignCourseAttributes({...formAttributes, user_scenarios: [...formAttributes.user_scenarios, userScenario]});
  };

  isScenarioAdded = (userScenario) => {
    return _.find(this.props.formAttributes.user_scenarios, {id: userScenario.id});
  };

  get packCategoryId() {
    return isAdmin(this.props.currentUser) ? GENERAL_PACK_CATEGORY : COMPANY_PACK_CATEGORY;
  }

  renderPacks() {
    const {packs} = this.props;

    const isLoaded = packs.isCollectionLoaded && packs.categoryId === this.packCategoryId;
    const isLoadingError = isFetchPacksError(packs);
    const isLoading = !isLoaded && !isLoadingError;

    return (
      <Fragment>
        <FetchPacks categoryId={this.packCategoryId}/>
        {
          isLoaded && _.map(packs.collection, (pack) => {
            const iconClass =
              isPublic(pack) ? "scenario-list-item__icon--pack-public" : "scenario-list-item__icon--pack-private";
            return (
              <button key={pack.id} className="scenario-list-item"
                      onClick={() => this.changePage(PAGES.USER_SCENARIOS, pack.id)}>
                <div className={classnames("scenario-list-item__icon", iconClass)}/>
                <div className="scenario-list-item__content">
                  <div className="scenario-list-item__title" title={pack.title}>
                    {pack.title}
                  </div>
                  <div className="scenario-list-item__secondary-content">
                    <div className="scenario-list-item__subtitle">
                      {I18n.t("packs.scenarios", {scenarios_count: pack.user_scenarios_count})}
                    </div>
                  </div>
                </div>
                <div className="scenario-list-item__icon scenario-list-item__icon--arrow-right"/>
              </button>
            );
          })
        }
        <Loader isShow={isLoading}/>
      </Fragment>
    );
  }

  renderUserScenarios() {
    const {userScenarios} = this.props;
    const {packId} = this.props.modals.options;

    const isLoaded = userScenarios.isCollectionLoaded && userScenarios.packId === packId;
    const isLoadingError = isFetchUserScenariosError(userScenarios);
    const isLoading = !isLoaded && !isLoadingError;

    return (
      <Fragment>
        <FetchUserScenarios packId={packId}/>
        {
          isLoaded && _.map(userScenarios.collection, (userScenario) => {
            const isScenarioAdded = this.isScenarioAdded(userScenario);
            const itemClass = isScenarioAdded ? "scenario-list-item--selected scenario-list-item--non-hoverable" : "";

            return (
              <button key={userScenario.id}
                      onClick={() => this.addUserScenario(userScenario)}
                      className={classnames("scenario-list-item", itemClass)}
                      disabled={isScenarioAdded}>
                <div className="scenario-list-item__icon scenario-list-item__icon--scenario"/>
                <div className="scenario-list-item__content">
                  <div className="scenario-list-item__title" title={userScenario.title}>
                    {userScenario.title}
                  </div>
                  <div className="scenario-list-item__secondary-content">
                    <div className="scenario-list-item__tags-content">
                      <div className="scenario-list-item__tags">
                        {scenarioTags(userScenario)}
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            );
          })
        }
        <Loader isShow={isLoading}/>
      </Fragment>
    );
  }

  renderCurrentPage() {
    switch (this.props.modals.options.page) {
      case PAGES.PACKS:
        return this.renderPacks();
      case PAGES.USER_SCENARIOS:
        return this.renderUserScenarios();
      default:
        return null;
    }
  }

  render() {
    return (
      <DefaultModal modalName={MODAL_NAME}
                    modalTitle={this.modalTitle}
                    buttonClass="button button_theme_plus-grey"
                    onOpen={this.setDefaultPage}
                    onBack={this.onBack()}>
        <div className="bem-popup__content">
          {this.renderCurrentPage()}
        </div>
      </DefaultModal>
    );
  }
}

AddScenarioModal.propTypes = {
  formAttributes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  packs: PropTypes.object.isRequired,
  userScenarios: PropTypes.object.isRequired,
  modals: PropTypes.shape({
    options: PropTypes.shape({
      page: PropTypes.string,
      packId: PropTypes.string
    })
  }),
  assignCourseAttributes: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    currentUser: state.profiles.current,
    company: state.companies.current,
    packs: state.packs,
    userScenarios: state.userScenarios,
    modals: state.modals
  }
}

const mapActionsToProps = {
  assignCourseAttributes,
  openModal,
  closeModal
};

export default connect(mapStateToProps, mapActionsToProps)(AddScenarioModal);
