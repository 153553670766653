import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import _ from "lodash";

import {tagsPath} from "../../helpers/routesHelper";
import handleFieldChange from "../../helpers/handleFieldChange";

import Cover from '../../components/shared/_Cover';

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      english_name: '',
      japanese_name: '',
      id: null,
      errors: {}
    };
  }

  componentDidMount = () => {
    this.setState({
      english_name: this.props.formAttributes.english_name,
      japanese_name: this.props.formAttributes.japanese_name,
      id: this.props.formAttributes.id,
      errors: this.props.formAttributes.errors
    });
  };

  onChange = (event) => {
    event.persist();

    this.setState({
      [event.target.name]: event.target.value
    }, () => {
      const attributes = {
        english_name: this.state.english_name,
        japanese_name: this.state.japanese_name,
        id: this.state.id,
        errors: this.state.errors
      };
      const {assignTagAttributes} = this.props;
      assignTagAttributes({...attributes, ...handleFieldChange(event, attributes)});
    });
  };

  onSubmit = (event) => {
    event.preventDefault();

    const formAttributes = {
      english_name: this.state.english_name,
      japanese_name: this.state.japanese_name,
      id: this.state.id,
      errors: this.state.errors
    };

    const attributes = _.omit(formAttributes, "errors");

    if (this.isValid) {
      this.props.onSubmit(attributes);
    } else {
      const action = attributes.id ? "update" : "create";
      this.props.notify({
        title: I18n.t("notifications.titles.error"),
        message: I18n.t(`tags.messages.${action}.error`),
        status: "error"
      });
    }
  };

  get isValid() {
    const formAttributes = {
      english_name: this.state.english_name,
      japanese_name: this.state.japanese_name,
      id: this.state.id,
      errors: this.state.errors
    };
    const attributes = formAttributes;
    let errors = {};

    if (!attributes.english_name.trim()) {
      errors.english_name = [I18n.t("errors.messages.blank")];
    }

    if (!attributes.japanese_name.trim()) {
      errors.japanese_name = [I18n.t("errors.messages.blank")];
    }

    this.props.assignTagAttributes({...attributes, errors: errors});

    return _.isEmpty(errors);
  }

  renderErrors = (name) => {
    const formAttributes = {
      english_name: this.state.english_name,
      japanese_name: this.state.japanese_name,
      id: this.state.id,
      errors: this.state.errors
    };
    const errors = formAttributes.errors[name];
    if (_.isEmpty(errors)) return null;
    return <div className="form-group__error">{errors.join(", ")}</div>;
  };

  render() {
    const {backPath, assignTagAttributes} = this.props;
    const attributes = this.props.formAttributes;

    return (
      <div className="scenario-edit mt-2">

        <div className="transparent-form-group">
          <label className="transparent-form-group__label" htmlFor="english_name">
            {I18n.t("mongoid.attributes.tag.english_name")}
          </label>
          <input
            id="english_name"
            name="english_name"
            className="transparent-form-group__input"
            type="text"
            value={this.state.english_name}
            placeholder={I18n.t("tags.english_name_placeholder")}
            onChange={this.onChange}
          />
          {this.renderErrors("english_name")}
        </div>

        <div className="transparent-form-group">
          <label className="transparent-form-group__label" htmlFor="japanese_name">
            {I18n.t("mongoid.attributes.tag.japanese_name")}
          </label>
          <input
            id="japanese_name"
            name="japanese_name"
            className="transparent-form-group__input"
            type="text"
            value={this.state.japanese_name}
            placeholder={I18n.t("tags.japanese_name_placeholder")}
            onChange={this.onChange}
          />
          {this.renderErrors("japanese_name")}
        </div>

        <div className="form-actions">
          <Link to={tagsPath} className="form-actions__button button button_color_grey">
            {I18n.t("btn_back")}
          </Link>
          <button type="submit" className="form-actions__button button" onClick={this.onSubmit}>
            {I18n.t("save")}
          </button>
        </div>

      </div>
    )
  }
}

Form.propTypes = {
  backPath: PropTypes.string.isRequired,
  formAttributes: PropTypes.object.isRequired,
  assignTagAttributes: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired
};

export default Form;
