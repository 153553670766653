import React, {Component} from "react";
import PropTypes from "prop-types";
import handleFieldChange from "helpers/handleFieldChange";
import moment from "moment-timezone";
import _ from "lodash";

import DateInput from "components/shared/DateInput";
import DatePicker from "react-datepicker";
import DefaultModal from "components/shared/modals/DefaultModal";

export const MODAL_NAME = "GROUP_SETTINGS_MODAL";

class GroupSettingsModal extends Component {
  get isDefault() {
    return this.props.groups.current.default;
  }

  get isValid() {
    const attributes = this.props.groups.formAttributes;
    const errors = {};

    if (!this.isDefault && !attributes.title.trim()) {
      errors.title = [I18n.t("errors.messages.blank")];
    }

    const attributesWithNewErrors = {...attributes, errors};
    this.props.assignGroupAttributes(attributesWithNewErrors);

    return _.isEmpty(errors);
  }

  assignGroupAttributes = () => {
    const group = this.props.groups.current;
    this.props.assignGroupAttributes({
      ..._.pick(group, "id", "title", "starts_on", "ends_on"),
      errors: {}
    });
  };

  onChange = event => {
    const {groups, assignGroupAttributes} = this.props;
    const {formAttributes} = groups;
    assignGroupAttributes({...formAttributes, ...handleFieldChange(event, formAttributes)});
  };

  onDateChange = (attribute, value) => {
    const attributes = this.props.groups.formAttributes;
    let date;
    if (attributes[attribute] && value) {
      date = value;
    } else if (!attributes[attribute]) {
      date = moment().toDate();
    } else {
      date = null;
    }
    this.props.assignGroupAttributes({...attributes, [attribute]: date});
  };

  onSubmit = event => {
    event.preventDefault();

    if (this.isValid) {
      const {groups, updateGroup, closeModal} = this.props;
      updateGroup(_.omit(groups.formAttributes, "errors"), {
        onSuccess: () => closeModal(MODAL_NAME)
      });
    } else {
      this.props.notify({
        message: I18n.t("user_scenarios.messages.update.error"),
        status: "error",
        title: I18n.t("notifications.titles.error")
      });
    }
  };

  renderErrors(name) {
    const errors = this.props.groups.formAttributes.errors[name];
    if (_.isEmpty(errors)) return null;

    return <div className="form-group__error">{errors.join(", ")}</div>;
  }

  render() {
    const {groups, isLoading} = this.props;
    const attributes = groups.formAttributes;

    return (
      <DefaultModal
        isRenderButton={false}
        modalName={MODAL_NAME}
        modalTitle={I18n.t("settings")}
        onOpen={this.assignGroupAttributes}
      >
        <div className="bem-popup__content bem-popup__content_margin_scroll">
          <div className="mt-1">
            <form className="group-settings">
              <div className="row no-gutters justify-content-center">
                <div className="col-md-7 col-sm-8 col-12">
                  {!this.isDefault && (
                    <div className="form-group form-group_size_sm align-items-baseline">
                      <label
                        className="form-group__label form-group__label_width_sm"
                        htmlFor="title"
                      >
                        {I18n.t("mongoid.attributes.group.title")}
                      </label>

                      <div className="form-group__input-wrapper">
                        <input
                          className="form-group__input form-group__input_size_sm"
                          id="title"
                          name="title"
                          onChange={this.onChange}
                          type="text"
                          value={attributes.title}
                        />
                        {this.renderErrors("title")}
                      </div>
                    </div>
                  )}

                  <div className="form-group form-group_size_sm mt-2">
                    <label className="form-group__label form-group__label_width_sm">
                      {I18n.t("mongoid.attributes.group.starts_on")}
                    </label>
                    <div className="date-picker">
                      <DatePicker
                        customInput={<DateInput />}
                        dateFormat="yyyy/M/d"
                        onChange={value => this.onDateChange("starts_on", value)}
                        popperModifiers={{
                          preventOverflow: {
                            enabled: true
                          }
                        }}
                        selected={Date.parse(attributes.starts_on)}
                        showPopperArrow={false}
                      />
                    </div>
                  </div>

                  <div className="group-settings__explanation mt-1">
                    {I18n.t("dashboard.groups.settings.starts_on_explanation")}
                  </div>

                  <div className="form-group form-group_size_sm mt-2">
                    <label className="form-group__label form-group__label_width_sm">
                      {I18n.t("mongoid.attributes.group.ends_on")}
                    </label>
                    <div className="date-picker">
                      <DatePicker
                        customInput={<DateInput />}
                        dateFormat="yyyy/M/d"
                        onChange={value => this.onDateChange("ends_on", value)}
                        popperModifiers={{
                          preventOverflow: {
                            enabled: true
                          }
                        }}
                        selected={Date.parse(attributes.ends_on)}
                        showPopperArrow={false}
                      />
                    </div>
                  </div>

                  <div className="group-settings__explanation mt-1">
                    {I18n.t("dashboard.groups.settings.ends_on_explanation")}
                  </div>
                </div>
              </div>

              <div className="mt-5 text-center">
                <button
                  className="button"
                  disabled={isLoading}
                  onClick={this.onSubmit}
                  type="submit"
                >
                  {I18n.t("save")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </DefaultModal>
    );
  }
}

GroupSettingsModal.propTypes = {
  assignGroupAttributes: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  groups: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  notify: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired
};

export default GroupSettingsModal;
