import {PunditPolicy} from "react-pundit";

import {isAdmin, isManager} from "../helpers/userRolesHelper";

class CoursePolicy extends PunditPolicy {
  constructor() {
    super("Course");
  }

  Index(model, user) {
    return isAdmin(user) || isManager(user);
  }

  Update(model, user) {
    return isAdmin(user) || isManager(user);
  }
}

export default CoursePolicy;
