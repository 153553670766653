import React from "react";

import ModeFreeTalkLite from "./ModeFreeTalkLite";
import ModeListeningLite from "./ModeListeningLite";
import ModeReadingLite from "./ModeReadingLite";
import ModeStateFailed from "./ModeStateFailed";
import ModeStatePassed from "./ModeStatePassed";
import ModeStateUnstarted from "./ModeStateUnstarted";
import ModeTranslatingLite from "./ModeTranslatingLite";
import PackPrivate from "./PackPrivate";
import PackPublic from "./PackPublic";
import PlusOneLogo from "./PlusOneLogo";
import SmartTutorLogo from "./SmartTutorLogo";
import Star from "./Star";

const ICONS = {
  "mode-free-talk-lite": ModeFreeTalkLite,
  "mode-listening-lite": ModeListeningLite,
  "mode-reading-lite": ModeReadingLite,
  "mode-state-failed": ModeStateFailed,
  "mode-state-passed": ModeStatePassed,
  "mode-state-unstarted": ModeStateUnstarted,
  "mode-translating-lite": ModeTranslatingLite,
  "pack-private": PackPrivate,
  "pack-public": PackPublic,
  "plus-one-logo": PlusOneLogo,
  "smart-tutor-logo": SmartTutorLogo,
  star: Star
};

const SvgIcon = ({background, className, icon}) => {
  if (icon === undefined) return null;

  const Renderer = ICONS[icon];

  return <Renderer background={background} className={className} />;
};

export default SvgIcon;
