import {ACTIONS} from "store/reducers/dashboardUsersReducer";

import webToolApi from "helpers/webToolApiHelper";

import {defaultErrorAction} from "./errorsActions";
import {notifyResponseError} from "./notificationsActions";

export function changeDashboardUsersLoading(isLoading) {
  return {isLoading, type: ACTIONS.CHANGE_DASHBOARD_USERS_LOADING};
}

export function fetchDashboardUser(id, {groupId} = {}, {silentReload = false} = {}) {
  return dispatch => {
    dispatch(changeDashboardUsersLoading(true));
    if (!silentReload) {
      dispatch({type: ACTIONS.FETCH_DASHBOARD_USER});
    }

    webToolApi()
      .get(Routes.dashboard_user_path(id, {group_id: groupId}))
      .then(response => {
        dispatch({groupId, type: ACTIONS.FETCH_DASHBOARD_USER_FULFILLED, user: response.data.user});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.FETCH_DASHBOARD_USER_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("users.messages.show.error"), response));
      })
      .finally(() => {
        dispatch(changeDashboardUsersLoading(false));
      });
  };
}

export function orderCourses(userId, courses) {
  return dispatch => {
    const coursesOrder = courses.map((course, order) => ({courses_user_id: course.id, order}));
    dispatch(changeDashboardUsersLoading(true));
    dispatch({courses, type: ACTIONS.ORDER_COURSES, userId});

    webToolApi()
      .put(Routes.user_courses_order_path(userId), {courses_order: coursesOrder})
      .then(() => {
        dispatch({courses, type: ACTIONS.ORDER_COURSES_FULFILLED, userId});
      })
      .catch(({response}) => {
        dispatch(defaultErrorAction(ACTIONS.ORDER_COURSES_REJECTED, response));
        dispatch(notifyResponseError(I18n.t("courses.messages.update.error"), response));
      })
      .finally(() => {
        dispatch(changeDashboardUsersLoading(false));
      });
  };
}
