import React, {Component} from "react"
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {VisibleIf} from "react-pundit";

import {capitalize} from "../../helpers/capitalize";
import {isLimited, isPublic} from "../../helpers/packsHelper";
import {isManager} from "../../helpers/userRolesHelper";
import {newUserScenarioPath} from "../../helpers/routesHelper";

import FormModal, {MODAL_NAME} from "./_FormModal";
import Form from "./_Form";
import UserScenariosFilter from "./_UserScenariosFilter";
import PackShareModal from "./_PackShareModal";

class TopPanel extends Component {
  componentDidMount() {
    this.assignPackAttributes(true);
  }

  componentDidUpdate() {
    this.assignPackAttributes(false);
  }

  assignPackAttributes = (isComponentMount = true) => {
    const {packs, assignPackAttributes} = this.props;
    const pack = packs.current;
    const {formAttributes} = packs;

    if (isComponentMount || pack.id !== formAttributes.id) {
      assignPackAttributes({...pack, errors: {}});
    }
  };

  onPackFormSubmit = (attributes) => {
    this.props.updatePack(attributes, {onSuccess: () => this.props.closeModal(MODAL_NAME)});
  };

  get userScenarioPolicyCreateAction() {
    return `Create${capitalize(this.props.packs.current.category_id)}`;
  }

  get visibilityLabel() {
    const {pack} = this.props;
    let suffix = "";
    if (isLimited(pack)) {
      suffix = ` (${pack.allowed_user_ids.length})`;
    }
    return `${I18n.t(`mongoid.attributes.pack.visibilities.${pack.visibility}`)}${suffix}`;
  }

  render() {
    const {currentUser, pack, backPath, packs, assignPackAttributes} = this.props;
    const iconClass = isPublic(pack) ? "top-panel__owner-icon_pack_public" : "top-panel__owner-icon_pack_private";

    return (
      <div className="top-panel">
        <div className="top-panel__left">
          <Link to={backPath} className="top-panel__button top-panel__button_back hide-on-sm-and-down"/>
          <div className={`top-panel__owner-icon ${iconClass}`}/>
          <div className="top-panel__title">
            {pack.title}
          </div>
        </div>
        <div className="top-panel__right">
          <UserScenariosFilter packId={pack.id}/>
          <div className="top-panel-label">
            <div className="top-panel-label__text">
              {this.visibilityLabel}
            </div>
          </div>
          <VisibleIf type="Pack" action="Update" model={pack}>
            <FormModal title={I18n.t("packs.edit_pack")}
                       buttonClass="top-panel__button_edit top-panel__button_margin_sm">
              <Form
                formAttributes={packs.formAttributes}
                assignPackAttributes={assignPackAttributes}
                clearPackAttributes={this.assignPackAttributes}
                onSubmit={this.onPackFormSubmit}
                isLoading={packs.isLoading}
              />
            </FormModal>
          </VisibleIf>
          {
            isManager(currentUser) &&
            <PackShareModal
              packIds={[pack.id]}
              mode="single"
              button={<button className="top-panel__button top-panel__button_share top-panel__button_margin_sm"/>}
            />
          }
          <VisibleIf type="Pack" action={this.userScenarioPolicyCreateAction}>
            <Link
              to={newUserScenarioPath(pack.id)}
              className="top-panel__button top-panel__button_create top-panel__button_margin_sm hide-on-sm-and-down"
            />
          </VisibleIf>
        </div>
      </div>
    )
  }
}

TopPanel.propTypes = {
  currentUser: PropTypes.object.isRequired,
  pack: PropTypes.object.isRequired,
  backPath: PropTypes.string.isRequired,
  packs: PropTypes.object.isRequired,
  assignPackAttributes: PropTypes.func.isRequired,
  updatePack: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default TopPanel;
