import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";
import NumericInput from 'react-numeric-input';

import {METRIC_MEASURES, RAW_SCORE_RANGES} from "../../../constants";

import {isNumber} from "../../../helpers/numbersHelper";

import {assignTargetScoreAttributes, updateTargetScore} from "../../../store/actions/targetScoreActions";
import {closeModal} from "../../../store/actions/modalsActions";

import DefaultModal from "../../../components/shared/modals/DefaultModal";
import RangeSlider from "../../../components/shared/RangeSlider";

export const MODAL_NAME = "SCENARIO_DETAILS_TARGET_SCORE_MODAL";

// TODO: This component should be updated or removed
class TargetScoreModal extends Component {
  setTargetScoreAttributes = () => {
    const {scoreAttribute, targetRange, scoreEnabled} = this.props.modals.options;
    this.props.assignTargetScoreAttributes({
      targetScore: {[scoreAttribute]: targetRange},
      enabledScore: {[scoreAttribute]: scoreEnabled}
    });
  };

  onSubmit = () => {
    const {closeModal} = this.props;
    const {formAttributes} = this.props.targetScores;
    const {userScenarioId} = this.props.modals.options;
    this.props.updateTargetScore(userScenarioId, formAttributes.targetScore, formAttributes.enabledScore);
    closeModal(MODAL_NAME);
  };

  get modalTitle() {
    const {scoreAttribute} = this.props.modals.options;
    return I18n.t(`dashboard.scenario_details.target_scores.${scoreAttribute}.title`);
  }

  formatValue = (value) => {
    const {scoreAttribute} = this.props.modals.options;
    return `${value}${METRIC_MEASURES[scoreAttribute]}`;
  };

  onRangeInputChange = (event, index) => {
    if ((event.which < 48 || event.which > 57)) {
      return;
    }

    const {formAttributes} = this.props.targetScores;
    const {targetScore} = formAttributes;
    const {scoreAttribute} = this.props.modals.options;
    const newRange = _.clone(targetScore[scoreAttribute]);
    const newValue = parseInt(event.target.value);
    newRange[index] = isNumber(newValue) ? newValue : newRange[index];
    const changedAttributes = {...formAttributes, targetScore: {[scoreAttribute]: newRange}};
    this.props.assignTargetScoreAttributes(changedAttributes);
  };

  onRangeChange = (range) => {
    const {formAttributes} = this.props.targetScores;
    const {scoreAttribute} = this.props.modals.options;
    const changedAttributes = {...formAttributes, targetScore: {[scoreAttribute]: range}};
    this.props.assignTargetScoreAttributes(changedAttributes);
  };

  onScoreEnabledChange = () => {
    const {formAttributes} = this.props.targetScores;
    const {enabledScore} = formAttributes;
    const {scoreAttribute} = this.props.modals.options;
    const scoreEnabled = enabledScore[scoreAttribute];
    const changedAttributes = {...formAttributes, enabledScore: {[scoreAttribute]: !scoreEnabled}};
    this.props.assignTargetScoreAttributes(changedAttributes);
  };

  get minValue() {
    return RAW_SCORE_RANGES[this.props.modals.options.scoreAttribute][0];
  }

  get maxValue() {
    return RAW_SCORE_RANGES[this.props.modals.options.scoreAttribute][1];
  }

  render() {
    const {modals} = this.props;

    if (this.props.modals.current !== MODAL_NAME) {
      return null;
    }

    const {isUpdateAllowed, scoreAttribute} = modals.options;
    const {formAttributes} = this.props.targetScores;

    let targetRange = formAttributes.targetScore[scoreAttribute];
    if (targetRange === undefined) {
      targetRange = this.props.modals.options.targetRange;
    }

    let scoreEnabled = formAttributes.enabledScore[scoreAttribute];
    if (scoreEnabled === undefined) {
      scoreEnabled = this.props.modals.options.scoreEnabled;
    }

    if (isUpdateAllowed) {
      return (
        <DefaultModal modalName={MODAL_NAME}
                      modalTitle={this.modalTitle}
                      isRenderButton={false}
                      onOpen={this.setTargetScoreAttributes}>
          <div className="bem-popup__content">
            <div className="row no-gutters">
              <div className="col-12 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                <div className="target-score-form">
                  <div className="target-score-form__score-enabled-switch">
                    <div className="target-score-form__subtitle">
                      {I18n.t("target_scores.score_enabled")}
                    </div>
                    <div className="custom-control custom-switch custom-switch--without-label">
                      <input
                        type="checkbox"
                        id={`score_enabled`}
                        name={`score_enabled`}
                        className="custom-control-input"
                        checked={scoreEnabled}
                        onChange={this.onScoreEnabledChange}
                      />
                      <label className="custom-control-label" htmlFor={`score_enabled`}/>
                    </div>
                  </div>

                  <hr className="mt-3"/>

                  {
                    scoreEnabled &&
                    <Fragment>
                      <div className="target-score-form__subtitle mb-5 mt-4 pt-2">
                        {I18n.t("target_scores.target_range")}
                      </div>

                      <RangeSlider
                        min={this.minValue}
                        max={this.maxValue}
                        value={targetRange}
                        tipFormatter={this.formatValue}
                        onChange={this.onRangeChange}
                      />

                      <div className="target-score-form__form-groups">
                        <div className="target-score-form__form-group form-group form-group_size_sm">
                          <label className="form-group__label form-group__label_width_auto">
                            {I18n.t("from")}
                          </label>
                          <NumericInput
                            style={false}
                            min={this.minValue}
                            max={targetRange[1]}
                            className="target-score-form__input form-group__input form-group__input_size_sm"
                            name={`${scoreAttribute}.0`}
                            value={targetRange[0]}
                            onBlur={(event) => this.onRangeInputChange(event, 0)}
                          />
                          <label className="target-score-form__measure-label form-group__label">
                            {METRIC_MEASURES[scoreAttribute]}
                          </label>
                        </div>
                        <div className="target-score-form__form-group form-group form-group_size_sm">
                          <label className="form-group__label form-group__label_width_auto">
                            {I18n.t("to")}
                          </label>
                          <NumericInput
                            style={false}
                            min={targetRange[0]}
                            max={this.maxValue}
                            className="target-score-form__input form-group__input form-group__input_size_sm"
                            name={`${scoreAttribute}.1`}
                            value={targetRange[1]}
                            onBlur={(event) => this.onRangeInputChange(event, 1)}
                          />
                          <label className="target-score-form__measure-label form-group__label">
                            {METRIC_MEASURES[scoreAttribute]}
                          </label>
                        </div>
                      </div>
                    </Fragment>
                  }

                  <div className="target-score-form__actions">
                    <button className="button" onClick={this.onSubmit}>{I18n.t("update")}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DefaultModal>
      );
    }

    return (
      <div>
        {this.buttonText}
      </div>
    );
  }
}

TargetScoreModal.propTypes = {
  modals: PropTypes.shape({
    options: PropTypes.shape({
      userScenarioId: PropTypes.string,
      isUpdateAllowed: PropTypes.bool,
      scoreAttribute: PropTypes.string,
      targetRange: PropTypes.array,
      scoreEnabled: PropTypes.bool
    })
  }),
  targetScores: PropTypes.object.isRequired,
  assignTargetScoreAttributes: PropTypes.func.isRequired,
  updateTargetScore: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    modals: state.modals,
    targetScores: state.targetScores
  }
}

const mapActionsToProps = {
  assignTargetScoreAttributes,
  updateTargetScore,
  closeModal
};

export default connect(mapStateToProps, mapActionsToProps)(TargetScoreModal);
