import React, {useCallback} from "react";
import {connect} from "react-redux";
import {SortableContainer} from "react-sortable-hoc";
import {VisibleIf} from "react-pundit";
import arrayMove from "array-move";

import {orderCourses} from "store/actions/dashboardUsersActions";

import AssignCourseModal from "./AssignCourseModal";
import SortableCourse from "./SortableCourse";

const SortableCourseListContainer = SortableContainer(({courses, groupId}) => {
  return (
    <div>
      {courses.map((course, index) => (
        <SortableCourse key={course.id} course={course} groupId={groupId} index={index} />
      ))}
    </div>
  );
});

const SortableCourseList = ({company, groupId, orderCourses, user}) => {
  const {courses} = user;

  const handleReorderingEnd = useCallback(
    ({oldIndex, newIndex}) => {
      const courses = arrayMove(user.courses, oldIndex, newIndex);
      orderCourses(user.id, courses);
    },
    [orderCourses, user.courses, user.id]
  );

  return (
    <div className="pd-courses">
      <div className="pd-courses__header">
        <div className="pd-courses__label">{I18n.t("mongoid.models.course.other")}</div>
        <VisibleIf action="Update" type="Course">
          <AssignCourseModal company={company} groupId={groupId} userId={user.id} />
        </VisibleIf>
      </div>
      <SortableCourseListContainer
        courses={courses}
        groupId={groupId}
        onSortEnd={handleReorderingEnd}
        useDragHandle
        useWindowAsScrollContainer
      />
    </div>
  );
};

const mapActionsToProps = {
  orderCourses
};

export default connect(null, mapActionsToProps)(SortableCourseList);
