import React, {Component} from "react";
import PropTypes from "prop-types";

class ActiveStudentsCounter extends Component {
  get activeStudentsCount() {
    let count = 0;
    this.props.userActivities.collection.forEach((userActivity) => {
      if (userActivity.activity_durations.week_total > 0) {
        count++;
      }
    });
    return count;
  }

  get inactiveStudentsCount() {
    return this.props.userActivities.collection.length - this.activeStudentsCount;
  }

  render() {
    return (
      <div>
        <div className="active-students-counter">
          <div className="active-students-counter__label">
            {I18n.t("dashboard.active_students")}
          </div>
          <div className="active-students-counter__value">
            {this.activeStudentsCount}
          </div>
        </div>
        <div className="active-students-counter">
          <div className="active-students-counter__label">
            {I18n.t("dashboard.inactive_students")}
          </div>
          <div className="active-students-counter__value active-students-counter__value--inactive">
            {this.inactiveStudentsCount}
          </div>
        </div>
      </div>
    );
  }
}

ActiveStudentsCounter.propTypes = {
  userActivities: PropTypes.object.isRequired
};

export default ActiveStudentsCounter;
