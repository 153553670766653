import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {isFetchCourseError} from "../../helpers/errorsHelpers";

import {fetchCourse} from "../../store/actions/coursesActions";

class FetchCourse extends Component {
  componentDidMount() {
    this.fetchCourse(true);
  }

  componentDidUpdate() {
    this.fetchCourse(false);
  }

  fetchCourse(isComponentMount) {
    const {id, courses} = this.props;
    const isReasonToFetch = isComponentMount || courses.current.id !== id;

    if (isReasonToFetch && !courses.isLoading && !isFetchCourseError(courses)) {
      this.props.fetchCourse(id);
    }
  }

  render() {
    return null;
  }
}

FetchCourse.propTypes = {
  id: PropTypes.string.isRequired,
  courses: PropTypes.object.isRequired,
  fetchCourse: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    courses: state.courses
  }
}

const mapActionsToProps = {
  fetchCourse
};

export default connect(mapStateToProps, mapActionsToProps)(FetchCourse);
